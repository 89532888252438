
import React, { useState, useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { PIMProductService } from 'services/pimProduct.service';
import Icon from 'components/Icon';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from 'components/InputField';
import noImageAvailable from "assets/images/no-image.png";
import ProductComponent from '../../../components/ProductField';
import appContext from 'context/app.context';
import { EToasterVariants } from "services/types/common.type";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from 'components/Loader';
import ProductMrp from 'components/ProductCardTable/ProductMrp';

import "./styles.scss"

type ProductionSelectionDialogProps = {
  show: boolean,
  onExit: (data: boolean, data2?: any) => void
  fieldName?: String
}
interface IProduct {
  name: string;
  erp_item_code: string;
}

export const ProductionSelectionDialog: React.FC<ProductionSelectionDialogProps> = ({ show, onExit, fieldName }) => {

  const pimService = new PIMProductService();
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [inputValue, setInputValue] = useState<string>("")
  const [flag, setFlag] = useState<boolean>(false);
  const { showToaster } = useContext(appContext);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly IProduct[]>([]);
  const loading = open && options.length === 0;
  const [isLoading, setLoading] = useState<boolean>(false);

  const onCloseHandler = () => {
    onExit(false)
  }

  const onSaveHandeler = (e: any) => {
    e.preventDefault();
    if (fieldName === "ComboOffer") {
      setFlag(true)
      inputValue !== "" && onExit(true, selectedProduct)
    }
    else onExit(true, selectedProduct)
  }

  const onchangeHandeler = (e: any) => {
    setInputValue(e.target.value)
    selectedProduct.offerPrice = e.target.value
  }


  const onSearchProduct = async (newInputValue: string) => {
    setOptions([]);
    try {
      setLoading(true);
      const products: any = await pimService.getProductByTypesense(newInputValue);
      const newArray: any = _.get(products, "results[0].hits", []);
      setOptions(newArray.map((item: any) => ({ name: item.document.name, erp_item_code: item.document.erp_item_code })));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const getSelectedProduct = async (itemCode: any) => {
    try {
      setLoading(true);
      const { data: { product = {} } } = await pimService.getVariantProduct(itemCode);
      const { brand, category, erp_item_code, item_code, image, name, mrp, prices, gallery, model } = product;
      setSelectedProduct({ brand, category, erp_item_code, item_code, image, name, mrp, prices, gallery, model, type: 'Product' });
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      showToaster(EToasterVariants.error, "Product not found on Pim");
      console.log(error);

    }
  }

  return (
    <div className={"container-right"} >
      <div className="add-product-slider">
        <div className="slider-header">

          {fieldName === "ComboOffer" ? <h4>Add Combo Option</h4> : <h4>Add Freebie Option</h4>}
          <div onClick={() => onCloseHandler()}>
            <Icon icon={faXmark} />
          </div>
        </div>
        <div className="body-content">

          <Autocomplete
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={async (event: any, item: any) => {
              if (item)
                getSelectedProduct(item?.erp_item_code)
            }}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            options={options}
            loading={loading}
            renderInput={params => (
              <TextField
                {...params}
                label="Search Product Name or Item Code"
                variant="outlined"
                onChange={ev => {
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    onSearchProduct(ev.target.value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </div>
        {isLoading && <Loader />}

        {!_.isEmpty(selectedProduct) && <div className="form-wrapper">

          <div className="productView">
            <div className="productView-image">
              {selectedProduct?.gallery?.[0] && <img height={150} width={100} className="img" alt="" src={selectedProduct?.gallery?.[0]?.media?.url || noImageAvailable} />}
            </div>
            <div className="productView-details">
              <p>{selectedProduct?.name}</p>
              <p className="sub-heading"><ProductMrp item_code={selectedProduct.erp_item_code} /></p>
              {fieldName === "ComboOffer" && <div className="inputField-wraper">
                <InputField
                  onChange={(e) => onchangeHandeler(e)}
                  helpertext="Offer Price"
                  name="offerPrice"
                  type="number"
                  step={0.01}

                  value={inputValue}
                  error={flag && !inputValue ? "Offer Price is required " : ""}
                />
              </div>}

            </div>
          </div>
          <div className='product_variant_container'>
            {selectedProduct?.model?.variants && <p className='variantTitle'>Variants</p>}
            {selectedProduct?.model?.variants?.map((data: any) => {
              return (
                <div className='product_variant1'>
                  <ProductComponent data={data}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </div>)
            })}
          </div>


          <div className="action-buttons">
            <button
              className="btn primary"
              type="button"
              onClick={() => onCloseHandler()}
            >
              Cancel
            </button>
            <button className="btn primary" type="submit"
              onClick={(e) => onSaveHandeler(e)}
            >
              Save
            </button>
          </div>
        </div>}
      </div>
    </div>
  )
}