import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import DropDown from "components/DropDown";
import Icon from "components/Icon";
import InputField from "components/InputField";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss";
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/store';
import { CouponEventService } from "services/couponEvents.services";
import moment from "moment";

const CouponEvent: React.FC = () => {
  /* State variables */
  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number>(0);

  /* Services */
  const couponEventService = new CouponEventService();
  /* Context */
  const context = useContext(appContext);
  const {setSpinnerVisibility} = useContext(appContext);

  /*User Details */

  const userEmail = useSelector<RootStore>((state) => state.userDetails.userDetails.userEmail)

  /* Form hooks */
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();


  const sliderVisbilityHandler = async () => {
    reset();
    setSliderVisibility(!sliderVisibility);
    setEditMode(false);
  }

  const getUserList = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const couponEventListResponse = await couponEventService.getAllCouponEvents(page + 1,rowsPerPage)
      setSpinnerVisibility(false);
      if (couponEventListResponse.status === STATUS.SUCCESS) {
        setUserList(couponEventListResponse.data);     
        setTotalPages(Math.ceil(couponEventListResponse.count / rowsPerPage))
      } else {
        context.showToaster(
          EToasterVariants.error,
          "Unable to get User List"
        );
      }
    } catch (error) {
      context.showToaster(EToasterVariants.error, "Unable to get User List");
    }
  };

  /* Offer Type Edit Handler */
  const UserEditHanlder = async (data: any, attributeIndex: number): Promise<void> => {

    setEditMode(true);
    setSelectedUserIndex(attributeIndex);
    unregister("password")
    const { eventName, status } = data;
    setValue("eventName", eventName);
    setValue("couponEventStatus", status);    
    setSliderVisibility(true)

  }


  const createUserHandler = async (values: any): Promise<void> => {
    try {
      context.setSpinnerVisibility(true);
      var userAction;
      
      if (editMode) {
        values = { ...values, modifiedBy: userEmail }
        userAction = await couponEventService.updateCouponEvent({ ...values,eventName: values.eventName.trim(), status: values.couponEventStatus, _id: userList[selectedUserIndex]._id })
      } else {
        values = { ...values, eventName: values.eventName.trim(), status: values.couponEventStatus }
        userAction = await couponEventService.addCouponEvent(values);
      }

      if (userAction.status === STATUS.SUCCESS) {
        await sliderVisbilityHandler();
        await getUserList();
        const successMessage = editMode
          ? "Coupon Updated Successfully!"
          : "Coupon Created Successfully!";

        context.showToaster(EToasterVariants.success, successMessage);
        context.setSpinnerVisibility(false);
      } else {
        context.setSpinnerVisibility(false);
        context.showToaster(
          EToasterVariants.error,
          `unable to ${editMode ? "Update" : "Add"} User Details`
        );
      }



    } catch (error) {
      context.setSpinnerVisibility(false);
      context.showToaster(
        EToasterVariants.error,
        `unable to ${editMode ? "Update" : "Add"} User Details`
      );
    }
  };


  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const trimAndValidate = (value) => {
    const trimmedValue = value.trim();
    return trimmedValue.length > 0 ? trimmedValue : undefined;
  };

  useEffect(() => {
    getUserList();
  }, [page, rowsPerPage]);
  return (
    <div className="user-table">
      <div className="header">
        <h3> Coupon Event Info</h3>
        <button
          className="btn primary"
          onClick={sliderVisbilityHandler}
        >
          Create Coupon
        </button>
      </div>

      <table className="shadow permisssion-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user: any, index: number) => {
            return (
              <tr>
                <td data-label="eventName">{user.eventName}</td>
                <td data-label="Created At">{moment(user?.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                {moment(user?.createdAt).valueOf() ===
                moment(user?.updatedAt).valueOf() ? (
                  "-"
                ) : (
                  <td data-label="Updated At">{user?.updatedAt ? moment(user?.updatedAt).format('DD-MM-YYYY hh:mm A') : '-'}</td>
                )}
                <td data-label="couponEventStatus">{user.status ? 'Active' : "InActive"}</td>
                <td data-label="Action" className="action">
                  <div onClick={() => UserEditHanlder(user, index)}>
                    <Icon icon={faPen} />
                  </div>
                  {/* <Icon icon={faCircleInfo} /> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination
        backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
        nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
        component="div"
        count={totalPages}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className={`slider-right ${sliderVisibility ? "show" : "hide"}`}>
        <div className="add-user-slider">
          <div className="slider-header">
            <h1>{editMode ? "Edit user Info":"Create Coupon Form"}</h1>
            <div onClick={sliderVisbilityHandler}>
              <Icon icon={faXmark} />
            </div>
          </div>

          <div className="form-wrapper">
            <form onSubmit={handleSubmit(createUserHandler)} autoComplete="off">
              <InputField
                helpertext="Name"
                name="eventName"
                mandatory={true}
                register={register("eventName", { required: "Name is Required",validate: {
                  notEmpty: (value) =>
                    !!trimAndValidate(value) || "Name cannot be empty",
                }, })}
                error={errors.eventName?.message as any}
              />
              <DropDown
                options={[{name:'Active', value:true}, {name:'InActive', value:false}]}
                label="Status"
                name="couponEventStatus"
                register={register("couponEventStatus")}
              />
              <div className="submitbutton">
                <div className="action-buttons">
                  <button
                    className="btn secondary cancelbtn"
                    type="button"
                    onClick={sliderVisbilityHandler}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className="btn primary"
                    type="submit"
                  >
                    {editMode ? "Save" : "Create"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponEvent;
