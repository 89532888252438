import React from 'react'

const DashboardPage: React.FC = () => {
  document.title = "Poorvika - Dashboard";

  return (
    <div>Dashboard Page</div>
  )
}

export default DashboardPage