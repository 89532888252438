import React,{useState} from 'react'

const UseInput = (props:any) => {


    const [value, setValue] = useState('');
    const { type, className, placeholder } = props;


    const handleChange = (e:any) => {
        setValue(e.target.value);
        props.onChange(e.target.value);  //<-- Use e.target.vale instead of value
      };



    return (
        <div>
            <input
          onChange={handleChange}
          type={type == null ? 'text' : type}
          value={value == null ? '' : value}
          className={className == null ? 'form-control' : className}
          placeholder={placeholder == null ? '' : placeholder}
        />
        </div>
    )
}

export default UseInput
