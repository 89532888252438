import React from "react";
import { EToasterVariants } from "services/types/common.type";


const offerData: any = {}
const offerGroupData: any = {}
const userPermission: any = {}
const offersFilter: any = {}
const offerListFilter: any = {}
const financeFilter: any = {}
const offersFilterOptions: any = []
const activeOffersFilterOptions: any = {}
const selectedProducts: any = []

const appState = {
    sideMenuVisibility: false,
    setSideMenuVisibility: (visibility: boolean) => { },
    toasterProps: { type: "success", message: "Success Message", visibility: false },
    showToaster: (type: EToasterVariants, message: string) => { },
    spinnerVisibility: false,
    setSpinnerVisibility: (visibility: boolean) => { },
    selectedProducts,
    setSelectedProducts: (products: any) => { },
    offerData,
    setOfferData: (offerData: any) => { },
    offerGroupData,
    setOfferGroupData: (offerGroupData: any) => { },
    menuIndex: 0,
    setMenuIndex: (index: number) => { },
    userPermission,
    setUserPermission: (userPermission: any) => { },
    offersFilter,
    setOffersFilter: (offersFilter: any) => { },
    offersFilterUpdate: false,
    setOffersFilterUpdate: (offersFilter: boolean) => { },
    offersFilterOptions,
    setOffersFilterOptions: (offersFilterOptions: any) => { },
    offerListFilter,
    setOfferListFilter: (offerListFilter: any) => { },
    financeFilter,
    setFinanceFilter: (financeFilter: any) => { },
    setPage: (index: number) => { },
    page: 0,
    activeOffersFilterOptions,
    setActiveOffersFilterOptions: (activeOffersFilterOptions: any) => { },
    historyNavigate: false,
    setHistoryNavigate: (ishistoryTab: boolean) => { },
    viewOnlyMode: true,
    setViewOnlyMode: (isView: boolean) => { },

}

export const appContext = React.createContext(appState);


export default appContext;

