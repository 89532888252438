/* eslint-disable react-hooks/exhaustive-deps */
import appContext from "context/app.context";
import CouponsInfoForm from "../Forms/CouponsInfoForm";
import React, { useContext, useEffect, useState } from "react";
import DiscountIcon from "../../../assets/icons/discount.svg"
import AddProductsIcon from "../../../assets/icons/add-product.svg"
import SignatureIcon from "../../../assets/icons/signature.svg"
import SearchIcon from "../../../assets/icons/search.svg"

import "./styles.scss";
import Summary from "../Summary";
import AddCouponProducts from "./AddCouponProducts";
import ApplyCoupon from "../ApplyCoupon";
import CouponAssigned from "../CouponAssigned";
import { COUPON_APPLIES_TO, COUPON_TYPE, removeDuplicateByKey } from "common/constant";

const AddCouponsPage: React.FC = () => {

  type ISubMenu = { name: string, img: any, compenents: any }[]

  const menuArray: ISubMenu = [
    { name: "COUPON GENERATIONS", img: DiscountIcon, compenents: <CouponsInfoForm /> },
    { name: "PRODUCTS", img: AddProductsIcon, compenents: <AddCouponProducts /> },
    // { name: "CLAIM RULES", img: SignatureIcon, compenents: <ClaimRules /> },
    { name: "SUMMARY", img: SearchIcon, compenents: <Summary /> }]

  const uniqueCouponsMenu: ISubMenu = [
    { name: "APPLY COUPON", img: SignatureIcon, compenents: <ApplyCoupon /> },
    { name: "COUPON ASSIGNED", img: SignatureIcon, compenents: <CouponAssigned /> }]


  const { menuIndex, offerData, setMenuIndex, setViewOnlyMode } = useContext(appContext);
  const [subMenu, setSubMenu] = useState<ISubMenu>(menuArray)


  useEffect(() => {
    onofferDataChange(offerData?.appliesTo, offerData?.couponCodeType)
  }, [offerData])

  const onofferDataChange = async (appliesTo: string, couponType: string) => {
    const filteredByAppliesToMenu = (appliesTo === COUPON_APPLIES_TO?.CART) ? subMenu.filter((data) => data?.name !== "PRODUCTS") : menuArray
    const filterByCouponType = (couponType === COUPON_TYPE.UNIQUE_COUPON) ? filteredByAppliesToMenu.concat(uniqueCouponsMenu) : filteredByAppliesToMenu?.filter((data) => !uniqueCouponsMenu?.map((val) => val?.name).includes(data?.name))
    const newArr = removeDuplicateByKey(filterByCouponType, "name")
    setSubMenu(newArr)
  }

  useEffect(() => {
    setViewOnlyMode(false);
  }, [])

  return (
    <div className="coupons-page">
      <div className="coupons-page-heading">
        <h3 className="offers-page-heading-label">{"Add Coupons"}</h3>
      </div>
      <div className="coupons-tabs">
        {subMenu?.map((menu: any, index: number) => {
          return (
            <div
              className={menuIndex === index ? "coupon-active" : "coupon-inactive"}
              onClick={() => offerData?._id && setMenuIndex(index)}
            >
              <img src={menu.img} alt="" />
              <p>{menu.name}</p>
            </div>
          );
        })}
      </div>
      <div className="add-coupons-page-content">
        <div className="offer-info shadow">
          {subMenu[menuIndex].compenents}
        </div>
      </div>
    </div>
  );
};

export default AddCouponsPage;
