import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import "./styles.scss";
import moment from "moment";
import { ProductService } from "services/product.service";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";

type DatePickerProps = {
  heading: string[];
  ReportTitle: string;
  height?: true;
  products?: any,
  offerId?: string
};
const DownloadTemplates: React.FC<DatePickerProps> = ({
  heading,
  ReportTitle,
  height,
  products = [],
}) => {
  const productServices = new ProductService();
  const { showToaster, offerData, setSpinnerVisibility } = useContext(appContext);

  const JSONToCSVConvertor = async (ReportTitle: string, heading: string[]) => {
    setSpinnerVisibility(true);
    if (ReportTitle === 'combo' || ReportTitle === 'freebies') {
      const apiResponse = await productServices.getOffersProductsList(offerData._id, 1000, 1, {});

      if (apiResponse.status === STATUS.SUCCESS) {
        products = apiResponse.data;
      } else {
        showToaster(EToasterVariants.error, "Unable to download template");

      }
    }

    let CSV = "";
    let row = "";

    for (const index in heading) {
      row += heading[index] + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";

    let rowData = "";

    /* Prefilling Product ERP item code and name for combo and freebies */
    if (products && products?.length) {
      rowData = products?.reduce((accumulator: string, currentValue: any) => {
        return accumulator + `${currentValue?.itemCode},${currentValue?.varientName?.replaceAll(",", "")}\n`
      }, "");
      CSV += rowData;
    };


    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    let fileName = "Template_";
    fileName += ReportTitle.replace(/ /g, "_");
    fileName += moment();
    const uri = "data:text/csv;charset=utf-8," + escape(CSV);
    const link = document.createElement("a");
    link.href = uri;
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSpinnerVisibility(false);

  };

  return (
    <div
      className={height ? "download-product-btn" : "download-tepm-btn"}
      onClick={() => JSONToCSVConvertor(ReportTitle, heading)}
    >
      <FontAwesomeIcon className="" icon={faArrowDown} />
      <p>Download Template</p>
    </div>
  );
};

export default DownloadTemplates;
