import "./styles.scss";
interface ProductOfferProp {
    additionalOffers: [];
    setAdditionalOffers: any,
    product: any
}

export default function AdditionalOffers(props: ProductOfferProp) {
    const { additionalOffers, setAdditionalOffers } = props;

    const handleOfferAction = (offerId: string, value: string) => {

        const checked_offer: any = additionalOffers.map((item: any) => {
            if (item.offerId === offerId) {
                item[value] = !item[value];

                if (value === 'showTc') {
                    item.showBan = false;
                }
                else if (value === 'showBan') {
                    item.showTc = false;
                }

            }
            else if (value === 'checked')
                item[value] = false;
            return item;
        });

        setAdditionalOffers(checked_offer);
    };


    return (
        <div className="offer-rows">

            {additionalOffers.map((offer: any) => {
                return (
                    <div className="offer-row">
                        <div className="offer-details">
                            <div className="offer-list">
                                <div className="offer-names">
                                    <span className="offer-name-items">
                                        <input disabled={offer.offerSelection === 'mandatory'} name={offer.offerId} checked={offer.checked} id={offer.offerId}
                                            onClick={() => handleOfferAction(offer.offerId, 'checked')} type="checkbox"></input>
                                    </span>
                                    <span className="offer-name-items">{offer?.offerName}</span>

                                    {
                                        offer.termsConditions && <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showTc')}>{`T&C`}</span>
                                    }
                                    {
                                        offer.bannerDiscription && <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showBan')}>{`Details`}</span>
                                    }


                                </div>

                                <div className="offer-prices">

                                </div>


                            </div>
                        </div>
                        <div className="offer-details">
                            {offer.showTc &&
                                <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }}>
                                </div>
                            }
                            {offer.showBan &&
                                <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }}>
                                </div>
                            }
                        </div>

                    </div>
                )
            })
            }
        </div>
    );
}
