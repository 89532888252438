import { usePermission } from "components/Permission";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import DropDown from "components/DropDown";
import {
  YES_NO_OPTION,
  OFFER_SELECTION_OPTION
} from "common/constant";
import { useContext } from "react";
import appContext from "context/app.context";
import _ from "lodash";

const ShowOffersSection = (props: any) => {
  const {
    viewOnlyMode,
    register,
    permissionError
  } = props;

  const { offerData } = useContext(appContext);

  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

  const showInfoPermission = _.isEmpty(offerData) ? createOffersPermission : !viewOnlyMode && editOffersPermission;

  return (
    <div className="sub-section">
      <p className="section-heading" id="offer-info">
        3. Show Offer Settings
        {showInfoPermission &&
          <div className="tooltipContainer">
            <Icon icon={faCircleInfo} />
            <div>{permissionError}</div>
          </div>
        }
      </p>
      <div className="input-fields-wrapper">

        <DropDown
          disabled={!showInfoPermission}
          options={YES_NO_OPTION}
          label="Add to Benefits"
          register={register("addBenefits")}
        />
        <DropDown
          disabled={!showInfoPermission}
          options={YES_NO_OPTION}
          label="Reduce on Effective"
          register={register("reduceEffective")}
        />
        <DropDown
          disabled={!showInfoPermission}
          options={YES_NO_OPTION}
          label="Banner Enable"
          register={register("bannerEnable")}
        />
        <DropDown
          disabled={!showInfoPermission}
          options={OFFER_SELECTION_OPTION}
          label="Offer Selection"
          register={register("offerSelection")}
        />
      </div>
    </div>
  )
}

export default ShowOffersSection