
import React from 'react';
import "./styles.scss"
import CloseIcon from "../../../assets/icons/Close.svg"
import moment from 'moment';

type DeleteConfirmationProps = {
  show: boolean,
  onExit: (data: boolean) => void
  notes: { rejectedBy: string, note: string }[]
}

export const RejectedNotesDialog: React.FC<DeleteConfirmationProps> = ({ show, onExit, notes }) => {

  const onClickOutsideListener = () => {
    onExit(false)
    document.removeEventListener("click", onClickOutsideListener)
  }


  return (
    <div className="rejection-page" onMouseEnter={() => {
      document.addEventListener("click", onClickOutsideListener)
    }}>
      <div className="rejection-box" onMouseLeave={() => {
        document.addEventListener("click", onClickOutsideListener)
      }} onMouseEnter={() => {
        document.removeEventListener("click", onClickOutsideListener)
      }}>
        <img alt="close-icon" className="close-icon" src={CloseIcon} onClick={() => onExit(false)} />
        <div className="header-bar">
          <p>Rejected Notes</p>
        </div>
        <div className="body-content">
          <div className="body-header-tab rejected-table">
            <table>
              <thead>
                <th className="select-option">
                  <p>S.No</p>
                </th>
                <th className="name">
                  <p>Rejected By</p>
                </th>
                <th className="date">
                  <p>Rejected On</p>
                </th>
                <th className='notes'>
                  <p>Note</p>
                </th>
              </thead>
            </table>
            <table>
              <div className="product-list">
                <tbody>
                  {notes?.map((element: any, index: number) => {
                    return (
                       <tr key={index}>
                        <td data-label="S.No" className="select-option">
                          <div>
                            {index + 1}
                          </div>
                        </td>
                        <td data-label="Rejected By" className="name">
                          <div>
                            {element.rejectedBy}
                          </div>
                        </td>
                        <td data-label="Rejected On" className="date">
                          <div>
                            {element.rejectedOn ? moment(element.rejectedOn).format("DD-MMM-YYYY hh:mm A") : ""}
                          </div>
                        </td>
                        <td data-label="Note" className='notes'>
                          <div dangerouslySetInnerHTML={{ __html:element.note}}>
                          </div>
                        
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </div>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}