import _ from 'lodash';
export const toLocaleNumber = (number: any) => {
    try {
        return `₹${number.toLocaleString('en-IN')}`
    } catch (error) {
        return `₹0`;

    }
}

export const getChannelNames = (channel: any): string => {
    return channel.map((ch: any) => ch.name).join(', ');
}

export const getPriceValue = (priceArr: any[]) => {
    let price = 0;

    if (!_.isEmpty(priceArr)) {
        priceArr = _.sortBy(priceArr, 'startDate', 'desc');
        const currentdate = new Date();
        priceArr.forEach((element) => {
            const startDate = new Date(element.startDate)
            let endDate: any = '';

            if (element.endDate && element.endDate !== null)
                endDate = new Date(element.endDate)
            else
                endDate = null

            if (currentdate >= startDate && ((endDate && currentdate <= endDate) || _.isNull(endDate)))
                price = element.price;
        });

    }

    return +price;

}

const getPricePriority = (priorityList: any = [], priorityPrice = []) => {
    let found: any = {};
    let valid = true;
    (priorityList || []).map(function (a) {
        const matched = priorityPrice?.find((b) => a?.code === b?.pl);
        if (Object.keys(matched || {}).length && valid && getPriceValue(matched?.sp)) {
            valid = false;
            found = matched;
        }
        return a;
    });
    return found;
};

export const getProductPrice = (product: any, channel = 'Online') => {

    let { mrp = [], priority = [] } = product;
    let [{ price = [] }] = mrp;

    const [prices] = product.prices;
    let sellPrice = 0;
    if (channel === 'Shop') {
        const found = getPricePriority(priority, prices)
        if (!_.isEmpty(found)) {
            sellPrice = getPriceValue(found.sp);
        } else {
            sellPrice = getPriceValue(prices.sp)
        }
    } else {
        sellPrice = getPriceValue(prices.sp);
    }

    mrp = getPriceValue(price);

    if (sellPrice === 0)
        sellPrice = mrp

    return { mrp, sp: sellPrice };
}

/**
* @param  {object} params;
* Api request params convert to queryParams
*/

export const encodeGetParams = (params: any) => {
    return Object.entries(params).map((key: any) => key.map((k: any) => {
        k = typeof k === 'object' ? JSON.stringify(k) : k;
        return encodeURIComponent(k);
    }).join("=")).join("&");
};

export const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page} of ${props.count !== -1 ? props.count : `more than ${props.to}`
        }`;
};