import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./styles.scss";
import ReactQuill from "react-quill";

type DeleteConfirmationProps = {
  show: boolean;
  onConfirmation: (data: {
    status: boolean;
    note?: string;
    rejectedOn?: any;
  }) => void;
};

const RejectedConfirmationDialog: React.FC<DeleteConfirmationProps> = ({
  show,
  onConfirmation,
}) => {
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const onSubmit = (value: any) => {
    const now = moment();
    onConfirmation({ status: true, note: value.reason, rejectedOn: now });
    reset();
  };

  return (
    <div
      className={
        show
          ? "reject-confirmation-dialog show"
          : "reject-confirmation-dialog hide"
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dialog-container shadow">
          <p>Do you really want to Reject this record ?</p>
          <div className="rejected-note-text">
            <ReactQuill
              theme="snow"
              value={watch("reason")}
              onChange={(value: any) => setValue("reason", value)}
            />
          </div>
          <div className="buttons-wrapper">
            <button
              className="btn secondary"
              onClick={() => {
                onConfirmation({ status: false });
                reset();
              }}
            >
              Cancel
            </button>
            <button className="btn primary" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RejectedConfirmationDialog;
