import React, { useContext, useEffect, useState } from "react";

import "./styles.scss";
import appContext from "context/app.context";
import { useForm } from "react-hook-form";
import { OffersService } from "services/offers.service";
import { OffersGroupService } from "services/offerGroup.service";
import { Autocomplete, TablePagination, TextField } from "@mui/material";
import { EToasterVariants } from "services/types/common.type";
import Checkbox from "components/Checkbox";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type AddProductsProps = {
    viewOnlyMode?: boolean;
};

const AddOffersTab: React.FC<AddProductsProps> = ({ viewOnlyMode }) => {

    const {
        setValue,
        watch,
        handleSubmit,
    } = useForm();
    const { setMenuIndex, offerGroupData, setOfferGroupData, showToaster } = useContext(appContext);
    const [offersList, setOffersList] = useState([]);
    const [selecetdProduct, setSelectedProducts] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const defaultLabelDisplayedRows = (props: any) => {
        return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
    }


    const navigateTo = useNavigate();

    const offersService = new OffersService();
    const offersGroupService = new OffersGroupService()



    const goToPreviousFormHandler = async (): Promise<void> => {
        setMenuIndex(0);
    }




    const onSubmit = async (offerInfovalues: any): Promise<void> => {
        const updatedOfferGroup = { ...offerGroupData, offers: selecetdProduct }

        const offerDetailsResponse = await offersGroupService.updateOfferGroup(updatedOfferGroup);
        if (offerDetailsResponse.status === "success") {
            setOfferGroupData([]);
            showToaster(EToasterVariants.success, "Offer Group Updated successfully");
            navigateTo("/offers/offer_grouping");
        }
        else {
            showToaster(EToasterVariants.error, "Offer Group Updated failed");
        }

    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleSelectedProduct = (offer: any) => {
        setSelectedProducts(offer)
    }

    const getOfferList = async () => {
        const data = { "startDate": new Date(offerGroupData.startDate).getTime(), "endDate": new Date(offerGroupData.endDate).getTime(), "rowsPerPage": rowsPerPage, "status": 'Active', "pageNo": page }
        const offerResponse = await offersService.getActiveOffersList(data);
        console.log(offerResponse, 'offerResponse');

        if (offerResponse.status === "success") {
            setOffersList(offerResponse?.data?.offerList)
            setTotalPages(offerResponse?.data?.totalPages);
        }
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (viewOnlyMode === false || viewOnlyMode === true) {
            setSelectedProducts(offerGroupData.offers || [])
            offerGroupData?.offers?.map((offer: any) => {
                setValue(`${offer?.offerName}`, true);
                return;
            })
        }
        getOfferList()
    }, []);

    useEffect(() => {
        if (selecetdProduct.length !== offersList.length) {
            setValue("selectedAll", false)
        }
        else if (selecetdProduct.length === offersList.length) {
            setValue("selectedAll", true)
        }
        if (selecetdProduct.length > 0) {
            selecetdProduct?.map((offer: any) => {
                setValue(`${offer?.offerName}`, true)
                return
            })
        }
        else {
            offersList?.map((offer: any) => {
                setValue(`${offer?.offerName}`, false)
                return
            })
        }
    }, [selecetdProduct, offersList])

    useEffect(() => {
        getOfferList();
    }, [page, rowsPerPage]);

    return (
        <div className="add-products-page">
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { handleKeyDown(e) }}>
                <Autocomplete
                    multiple
                    disabled={viewOnlyMode}
                    value={selecetdProduct}
                    id="controllable-states-demo"
                    options={offersList}
                    onChange={(e: any, offers: any) => {
                        handleSelectedProduct(offers)

                    }}
                    getOptionLabel={(option: any) => option?.offerName}
                    renderInput={(params) => (
                        <TextField {...params} label="Search Offers" />
                    )}
                />
                {!viewOnlyMode && <div>
                    <table className="content-table offers-table" id="offertable">
                        <thead>
                            <tr>
                                <th className="cursor-pointer"><Checkbox
                                    disabled={
                                        viewOnlyMode}
                                    value={watch("selectedAll")}
                                    onChange={(check) => check ? setSelectedProducts(offersList) : setSelectedProducts([])}
                                /></th>
                                <th>Offers ID</th>
                                <th>Offers Name</th>
                                <th>Offer Type</th>
                                <th>StateDate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offersList?.map((item: any, index: number) => (

                                <tr
                                    className="cursor-pointer"
                                    key={index}
                                // onClick={()=>{navigate(`/offers/list/edit/${item._id}`)}}
                                >
                                    <td><Checkbox key={index}
                                        disabled={
                                            viewOnlyMode
                                        }
                                        value={watch(`${item?.offerName}`)}
                                        onChange={(check) => { check ? setSelectedProducts([...selecetdProduct, item]) : setSelectedProducts(selecetdProduct?.filter((e: any) => e.offerName !== item.offerName)) }}
                                    />
                                    </td>
                                    <td>{item.offerId}</td>
                                    <td>{item.offerName}</td>
                                    <td>{item.offerType}</td>
                                    <td>{moment(item.startDate).format("DD-MMM-YYYY hh:mm A")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-wrapper">
                        <TablePagination
                            backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
                            nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
                            component="div"
                            count={totalPages}
                            labelDisplayedRows={defaultLabelDisplayedRows}
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />


                    </div>
                </div>}


                {!viewOnlyMode && <div className="btn-group-align">
                    <button type="button" className="btn secondary" onClick={goToPreviousFormHandler}>
                        Previous
                    </button>
                    <button type="submit" className="btn primary mr-2">
                        Save
                    </button>
                </div>}
            </form>
        </div>
    );
};

export default AddOffersTab;
