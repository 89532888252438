import React, { useEffect, useRef, useState } from "react";
import Multiselect from "multiselect-react-dropdown";

import "./style.scss";
import Icon from "components/Icon";
import { faAngleDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

type MutiSelectProps = {
  fieldName: string;
  fields: { name: string; id: number | string}[];
  onChange?: (fields: { name: string; id: number | string }[]) => void;
  register?: any,
  error?: string
  selectedValues?: any[]
  disabled?: boolean,
  mandatory?: boolean,
  focusHandleText?: any
};

const MultiSelect: React.FC<MutiSelectProps> = ({
  fieldName,
  fields,
  onChange,
  register,
  error,
  selectedValues,
  disabled,
  mandatory,
  focusHandleText
}) => {
  const [placeHolder, setPlaceHolder] = useState<string>(selectedValues?.length === 0 || selectedValues === undefined ? `Select ${fieldName}` : "")
  const onSelect = (selectedList: any, selectedItem: any) => {
    if (onChange) {
      onChange(selectedList);
    }
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    if (onChange) {
      onChange(selectedList);
    }
  };


  useEffect(() => {
    setPlaceHolder(selectedValues?.length === 0 || selectedValues === undefined ? `Select ${fieldName}` : "")
  }, [selectedValues])

  const field = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false)


  useEffect(() => {
    function handleClickOutside(event: any) {
      if (field.current && !field?.current?.contains(event.target)) {
        setShowPopup(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [field]);

  return (
    <div className={error ? "common-multi-select error" : disabled ? "common-multi-select disabled" : "common-multi-select"}>
      <Multiselect
        disable={disabled}
        options={fields}
        selectedValues={selectedValues ? selectedValues : []}
        onSelect={onSelect}
        onRemove={onRemove}
        placeholder={placeHolder}
        displayValue="name"
        {...register}
        name={fieldName}
      />
      <p className={disabled ? "field-name disabled" : "field-name"}>{fieldName}{mandatory && <strong className="mandatory"> *</strong>}</p>
      <div className="icon-area">
        <Icon icon={faAngleDown} />
      </div>
      {error && <p className="error-text">* {error}</p>}
      {focusHandleText && <div className="informationHandle" onClick={() => { setShowPopup(!showPopup) }}>?</div>}
      {showPopup && <div className="dropdownFocusHandle" ref={field} >
        <label className="dropdownfocus">{focusHandleText}</label>
        <div className="dropdownFocusHandleCaret">
          <Icon className="dropdownfocusCaret" icon={faCaretUp} />
        </div>
      </div>}
    </div>
  );
};

export default MultiSelect;
