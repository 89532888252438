import { appContext } from "context/app.context";
import { useContext, useState, useEffect } from "react";
import { CouponsService } from "services/coupon.service";
import { EToasterVariants } from "services/types/common.type";
import { STATUS } from "config/config";
import "./styles.scss";
import CustomerSearch from "components/customerSearch";
import { couponAssignBulkUploadColumns, removeFalsy } from "common/constant";
import { IAddCouponCodeForUser } from "services/interfaces/coupon.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faPlus } from "@fortawesome/free-solid-svg-icons";
import DownloadTemplates from "components/DownloadeTemplates";
import { BulkProductUpload } from "components/BulkProductUpload";
import BulkUploadHistory from "components/BulkUploadHistory";
import InputField from "components/InputField";
import { Autocomplete, TextField } from "@mui/material";
import { CouponProductService } from "services/couponProduct.service";
import { useParams } from "react-router";
import moment from "moment";

interface IProduct {
  varientName: string;
  itemCode: string;
  status: string;
  endDate: string;
  startDate: string;
  maxDiscountValue: number;
  imageURL: string;
  discountValue: number;
  [key: string]: any;
}

const ApplyCoupon = (props: any) => {
  const [options, setOptions] = useState<IProduct[]>([]);
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const {
    offerData,
    setSpinnerVisibility,
    showToaster,
    setMenuIndex,
    menuIndex,
  } = useContext(appContext);
  const couponsService = new CouponsService();
  const [customerData, setCustomerData] = useState<any>({});
  const [reset, setReset] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [bulkVisibility, setBulkVisibility] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  const couponProductService = new CouponProductService();
  const params = useParams();

  const onSearchProduct = async () => {
    try {
      const couponDetailsResponse =
        await couponProductService.getCouponProductById(params.id);
      const newArray = couponDetailsResponse.data;
      setOptions(newArray);
      setAllProducts(newArray);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onSearchProduct();
  }, []);

  const onSubmit = async () => {
    try {
      if (!customerData?.id) {
        showToaster(EToasterVariants.error, "Select the Customer");
        return;
      }

      setSpinnerVisibility(true);

      const payloads: IAddCouponCodeForUser[] = [];

      const selectedProductsArray = selectedProductIds
        .map((productId) => {
          const selectedProduct = allProducts.find(
            (product) => product.itemCode === productId
          );
          return selectedProduct;
        })
        .filter(Boolean);

      //    if (selectedProductsArray.length === 0) {
      //        showToaster(EToasterVariants.error, "Select at least one Product");
      //        return;
      //    }

      const payload = {
        customerId: String(customerData?.cus_id),
        couponID: offerData?._id,
        customerEmail: customerData?.email,
        customerMobile: customerData?.mobile,
        customerName: customerData?.name,
        couponCode: couponCode,
        couponProduct: selectedProductsArray,
      };

      payloads.push(removeFalsy(payload));

      const addCouponCodePromises = payloads.map((payload) =>
        couponsService.addCouponCodeForCustomer(payload)
      );

      const addCouponCodes = await Promise.all(addCouponCodePromises);
      const allSuccess = addCouponCodes.every(
        (response) => response.status === STATUS.SUCCESS
      );

      if (allSuccess) {
        showToaster(EToasterVariants.success, "Coupons Added Successfully");
        setReset(true);
        setSelectedProductIds([]);
        onSearchProduct();
        setMenuIndex(menuIndex + 1);
      } else {
        const errorMessage = addCouponCodes.find(
          (response) => response.status !== STATUS.SUCCESS
        )?.error?.response?.data?.message;
        showToaster(EToasterVariants.error, errorMessage);
      }
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to create new offer");
    } finally {
      setSpinnerVisibility(false);
    }
  };
  const selectedProducts = allProducts.filter((product) =>
    selectedProductIds.includes(product.itemCode)
  );

  return (
    <div className="coupon-summary add-couponproducts ">
      <div className="btn-wrapper">
        <div
          className="btn-add-products"
          onClick={() => setBulkVisibility(true)}
        >
          <FontAwesomeIcon className="" icon={faPlus} />
          <p>Bulk Upload</p>
        </div>
        <DownloadTemplates
          heading={couponAssignBulkUploadColumns}
          ReportTitle={"CouponAssign"}
          height={true}
        />
        <div
          className="btn-add-products"
          onClick={() => setShowHistory(!showHistory)}
        >
          <FontAwesomeIcon className="" icon={faHistory} />
          <p>History</p>
        </div>
      </div>
      <div className="sub-section">
        <CustomerSearch
          reset={reset}
          setReset={setReset}
          handleChange={(customerData: any) => setCustomerData(customerData)}
        />
      </div>
      <div className="sub-section" style={{ width: "250px" }}>
        <InputField
          name="coupon"
          value={couponCode}
          helpertext="Coupon code (Optional)"
          onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
        />
      </div>
      {/* <div className="sub-section">
               <Autocomplete
                   multiple
                   id='asynchronous-demo'
                   options={options}
                   filterSelectedOptions
                   getOptionLabel={(option) => `${option.varientName}`}
                   filterOptions={(options, { inputValue }) =>
                    options.filter(
                        (option) =>
                        (option.varientName && option.varientName.toLowerCase().includes(inputValue.toLowerCase())) ||
                        (option.itemCode && option.itemCode.toLowerCase().includes(inputValue.toLowerCase()))
                    )
                   }
                   inputValue={inputValue}
                   onInputChange={(event, newInputValue) => {
                       setInputValue(newInputValue);
                   }}
                   onChange={(event, newValues) => {
                       setSelectedProductIds(newValues.map(value => value.itemCode));
                       setInputValue('');
                   }}
                   renderInput={(params) => (
                       <TextField
                           {...params}
                           label='Search Coupon Product Name or Item Code (Optional)'
                           variant='outlined'
                       />
                   )}
               />
           </div> */}
      {selectedProducts.length > 0 && (
        <div className="sub-section">
          <h3>Selected Products Details:</h3>
          <table>
            <thead>
              <tr>
                <th>Variant Name</th>
                <th>Item Code</th>
                <th>Discount Value</th>
                <th>Max Discount Value</th>
                <th>Image URL</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((product) => (
                <tr key={product.itemCode}>
                  <td>{product.varientName}</td>
                  <td>{product.itemCode}</td>
                  <td>{product.discountValue}</td>
                  <td>{product.maxDiscountValue}</td>
                  <td>
                    <img
                      src={product.imageURL ? product.imageURL : ""}
                      alt="Product"
                      style={{ maxWidth: "100px" }}
                    />
                  </td>
                  <td>
                    {moment(product.startDate).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td>
                    {moment(product.endDate).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td>{product.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="coupon-buttons-wrapper">
        <button className="btn primary" onClick={() => onSubmit()}>
          Apply Coupon
        </button>
      </div>
      {bulkVisibility && (
        <BulkProductUpload
          setBulkVisibility={setBulkVisibility}
          importVarient="couponAssign"
          offerTemplate={"coupons"}
        />
      )}
      {showHistory && (
        <BulkUploadHistory module="CouponAssign" Id={offerData?._id} />
      )}
    </div>
  );
};

export default ApplyCoupon;
