import { useContext, useEffect, useState } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import appContext from "context/app.context";
import OffersInfoForm from "../Forms/OffersInfoForm";
import AddProducts from "../AddOffersPage/AddProducts";
import DynamicRulesForm from "../Forms/DynamicRulesForm";
import FreebiesPage from '../ComboOfferPage/index';
import ComboOfferPage from "../FreeOfferPage";
import HistorySideMenu from "pages/HistoryPage";
import { usePermission } from "components/Permission";

let DefaultSubMenus = ["INFO", "PRODUCTS"];

const EditOffersPage = () => {
  const { menuIndex, setMenuIndex, offerData } = useContext(appContext);
  const [SubMenus, setSubMenus] = useState<Array<string>>(DefaultSubMenus)
  const [viewMode, setViewMode] = useState<boolean>(true);
  const [editOffersPermission] = usePermission(["EDIT_OFFERS"])
  const [addOfferProductsPermission] = usePermission(["ADD_OFFER_PRODUCTS"])
  const [editOfferProductsPermission] = usePermission(["EDIT_OFFER_PRODUCTS"])
  const [deleteOfferProductsPermission] = usePermission(["DELETE_OFFER_PRODUCTS"])
  const isComboOffer = (offerData?.selectedOfferType?.offerValues === "Freebies" || offerData?.offerType === "Freebie Offer");
  const isFreebie = (offerData?.selectedOfferType?.offerValues === "ComboOffer" || offerData?.offerType === "Combo Offer");
  const isBankOffer = (offerData?.selectedOfferType?.offerValues === "AllProduct" || offerData?.offerType === "Standard EMI");


  useEffect(() => {
    if (isComboOffer) {
      const tempMenu = ["INFO", "PRODUCTS", "FREEBIES", "RULES"]
      if (viewMode) {
        tempMenu.push("HISTORY")
      }
      setSubMenus(tempMenu)
    } else if (isFreebie) {
      const tempMenu = ["INFO", "PRODUCTS", "COMBOOFFER", "RULES"]
      if (viewMode) {
        tempMenu.push("HISTORY")
      }
      setSubMenus(tempMenu)
    }
    else if (isBankOffer) {
      const tempMenu = ["INFO", "RULES"]
      if (viewMode) {
        tempMenu.push("HISTORY")
      }
      setSubMenus(tempMenu)
    }
    else {
      const tempMenu = ["INFO", "PRODUCTS", "RULES"]
      if (viewMode) {
        tempMenu.push("HISTORY")
      }
      setSubMenus(tempMenu)
    }
  }, [offerData])

  useEffect(() => {
    if (!viewMode) {
      setSubMenus(SubMenus?.filter((e: string) => e !== "HISTORY"))
    }

    /* When user on history tab we need to push them to info page on offer edit mode */
    if ((menuIndex === 3 && SubMenus.length === 4) || (menuIndex === 4 && SubMenus.length === 5)) {
      setMenuIndex(0);
    }

  }, [viewMode])



  return (
    <div className="offers-page">
      <div className="header">
        <div className="offers-page-heading">
          <h3 className="offers-page-heading-label">{offerData.offerName}</h3>

          <p className={`offers-page-heading-status ${offerData?.status?.toLowerCase()?.replaceAll(" ", "_")}`}>{offerData.status}</p>
        </div>

        <div className="header" >

          {viewMode && (editOffersPermission || addOfferProductsPermission || editOfferProductsPermission || deleteOfferProductsPermission) && (
            <>
              <div
                className="edit-offer-action-warpper btn primary"
                onClick={() => {
                  setViewMode(false)
                }}
                style={{ margin: "10px", color: "white" }}
              >
                <Icon icon={faPencil} />
                <p>Edit Offer</p>
              </div>
            </>
          )}

        </div>

      </div>

      <div className="tabs">
        {SubMenus?.map((menu: string, index: number) => {
          return (
            <div
              className={index === menuIndex ? "active" : ""}
              onClick={() => setMenuIndex(index)}
            >
              <p>{menu}</p>
            </div>
          );
        })}
      </div>
      <div className="add-offers-page-content">
        {menuIndex === 0 && (
          <div className="offer-info shadow">
            <OffersInfoForm viewOnlyMode={viewMode} />
          </div>
        )}
        {menuIndex === 1 && (!isBankOffer) && (
          <div className="offer-info shadow">
            <AddProducts viewOnlyMode={viewMode} />
          </div>
        )}
        {menuIndex === 1 && (isBankOffer) && (
          <div className="offer-info shadow">
            <DynamicRulesForm viewOnlyMode={viewMode} />
          </div>
        )}

        {menuIndex === 2 && isBankOffer && (
          <div className="offer-info shadow">
            <HistorySideMenu offerId={offerData?._id || ""} name="Offers History" />
          </div>
        )}

        {menuIndex === 2 && (!isBankOffer) && (isFreebie || isComboOffer) && (
          <div className="offer-info shadow">
            {isFreebie && <FreebiesPage viewOnlyMode={viewMode} />}
            {isComboOffer && <ComboOfferPage viewOnlyMode={viewMode} />}
          </div>
        )}

        {menuIndex === 2 && (!isBankOffer) && (!isFreebie && !isComboOffer) && (
          <div className="offer-info shadow">
            <DynamicRulesForm viewOnlyMode={viewMode} />
          </div>
        )}

        {menuIndex === 3 && (isFreebie || isComboOffer) && (
          <div className="offer-info shadow">
            <DynamicRulesForm viewOnlyMode={viewMode} />
          </div>
        )}

        {menuIndex === 3 && (!isFreebie && !isComboOffer) && (
          <div className="offer-info shadow">
            <HistorySideMenu offerId={offerData?._id || ""} name="Offers History" />
          </div>
        )}

        {menuIndex === 4 && (!isFreebie && !isComboOffer) && (
          <div className="offer-info shadow">
            <HistorySideMenu offerId={offerData?._id || ""} name="Offers History" />
          </div>
        )}

        {menuIndex === 4 && (isFreebie || isComboOffer) && (
          <div className="offer-info shadow">
            <HistorySideMenu offerId={offerData?._id || ""} name="Offers History" />
          </div>
        )}

      </div>

    </div>
  );
};

export default EditOffersPage;
