import { TextFieldProps } from '@mui/material/TextField';
import React from 'react'
import "./styles.scss"

type CouponTypeProps = {
    label: string;
    active:boolean;
    onClick?: (value: any) => void;
    value?: string
  } & TextFieldProps;

const CouponType: React.FC<CouponTypeProps> = ({label,active,onClick,value }) => {
  
  return (
    <div className={active ? "coupon-type-header active" : "coupon-type-header"} onClick={onClick}>
        <div>
            <p>{label}</p>
            <p>{value}</p>
        </div>
        {/* <img src={coupon1} alt="" /> */}
    </div>
  )
}

export default CouponType