import { faCaretUp, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { OfferType, editorToolbar, exclusivity } from "common/constant";
import DateAndTimePicker from "components/DateTimePicker";
import DropDown from "components/DropDown";
import Icon from "components/Icon";
import InputField from "components/InputField";
import { usePermission } from "components/Permission";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { OffersService } from "services/offers.service";
import { EToasterVariants } from "services/types/common.type";

function InfoSection(props: any) {
  const {
    watch,
    viewOnlyMode,
    register,
    errors,
    setValue,
    permissionError,
    setFocus,
    selectedOfferType,
    setSelectedOfferType,
    offerTypesList,
    setOfferTypesList,
    setDynamicText,
    setEndDateErr,
    endDateErr,
    offerData,
    setOfferData,
    ckeditorDiscriptionVisiblity,
    setCkeditorDiscriptionVisiblity,
    textEditorVisibility,
    editorConfig,
    ckeditorDiscriptionRef,
    offerDetails,
  } = props;

  const { showToaster, historyNavigate, setMenuIndex } = useContext(appContext);

  const offersService = new OffersService();

  const [offerTypes, setOfferTypes] = useState([]);
  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);
  const offerInfoPermission = _.isEmpty(offerData)
    ? createOffersPermission
    : !viewOnlyMode && editOffersPermission;

  const getOfferTypes = async (): Promise<void> => {
    try {
      const offerTypesResponse = await offersService.getOfferTypes();
      if (offerTypesResponse.status === STATUS.SUCCESS) {
        setOfferTypesList(offerTypesResponse.data.offerTypeData);
        const offersArr = offerTypesResponse.data.offerTypeData.map(
          (offerType: any) => offerType.offerTypes
        );
        setOfferTypes(offersArr);
        window.scrollTo(0, 0);
        setTimeout(() => {
          setFocus("offerName");
        }, 10);
      } else {
        showToaster(EToasterVariants.error, "Unable to get Offers Type");
      }
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to get Offers Type");
    }
  };

  /* Date Range Selection Validator & handler */
  const dateRangeSelectionHandler = async (
    dateFieldName: string,
    value: string
  ): Promise<void> => {
    if (dateFieldName === "startDate") {
      setValue("startDate", value);
    } else if (dateFieldName === "endDate") {
      setValue("endDate", value);
    }
    if (watch("startDate") && watch("endDate")) {
      if (
        new Date(watch("startDate")).getTime() >=
        new Date(watch("endDate")).getTime()
      ) {
        setEndDateErr(true);
      } else {
        setEndDateErr(false);
      }
    }
  };

  /* on offer type change action we need to dynamically manipulate the field */
  const offerTypeSelectionHandler = async (type: string): Promise<void> => {
    const selectedOffer: any = offerTypesList?.find(
      (offer: any) => offer?.offerTypes === type
    );
    if (selectedOffer) {
      const { addBenefits, reduceEffective, bannerEnable, offerSelection } =
        selectedOffer;
      setValue("addBenefits", addBenefits);
      setValue("reduceEffective", reduceEffective);
      setValue("bannerEnable", bannerEnable);
      setValue("offerSelection", offerSelection);

      setSelectedOfferType(selectedOffer);
    }
  };

  useEffect(() => {
    if (selectedOfferType?.offerValues === OfferType.Freebies)
      setDynamicText(
        `{{Product Name}}, {{Brand Name}}, {{Category Name}}, {{Selling Price}}, {{Model Name}} and {{Discount Amount}}`
      );
    else if (selectedOfferType?.offerValues === OfferType.ComboOffer)
      setDynamicText(
        `{{Product Name}}, {{Brand Name}}, {{Category Name}}, {{Selling Price}}, {{Model Name}}, {{MRP Price}},  {{Offer Price}} and {{Discount Amount}}`
      );
    else
      setDynamicText(
        `{{Discount Amount}} and Same name an Rule Settings manipulate the dynamic values`
      );
  }, [selectedOfferType]);

  useEffect(() => {
    if (offerData) {
      const offerType: any = offerTypesList.find(
        (e: any) => e.offerTypes === offerData.offerType
      );

      if (offerType && offerData?.values) {
        offerData?.values?.map((value: any) => {
          setValue(value.name, value.value);
        });

        if (offerType?.values?.length && offerData?.values?.length) {
          offerType.values[0].value = offerData.values[0].value;
        }

        setSelectedOfferType(offerType);
        setValue("offerType", offerType?.offerTypes);
        setOfferData({ ...offerData, selectedOfferType: offerType });

        historyNavigate && setMenuIndex(4);
      }
    }
  }, [offerDetails, offerTypesList]);

  useEffect(() => {
    getOfferTypes();
  }, []);

  return (
    <div className="sub-section">
      <p className="section-heading" id="offer-info">
        1. Offer Info
        {offerInfoPermission && (
          <div className="tooltipContainer">
            <Icon icon={faCircleInfo} />
            <div>{permissionError}</div>
          </div>
        )}
      </p>

      <div className="input-fields-wrapper">
        <InputField
          disabled={true}
          helpertext="Offer Id"
          hint="(Offer Id is Auto generated)"
          register={register("offerId")}
          name="offerId"
        />
        <InputField
          disabled={!offerInfoPermission}
          helpertext="Offer Name"
          register={register("offerName", {
            required: "Offer Name is required",
          })}
          name="offerName"
          error={errors.offerName?.message as any}
          mandatory
          focusHandleText="Enter Offer name  ,This will be Internal Name to Poorvika. "
          hoverHandleText={watch("offerName")}
        />
        <DropDown
          disabled={!offerInfoPermission}
          options={offerTypes}
          register={register("offerType", {
            required: "Offer Type is required",
          })}
          onChange={(event) => offerTypeSelectionHandler(event.target.value)}
          placeholder="Select offer Type"
          label="Offer Type"
          name="offerType"
          error={errors.offerType?.message as any}
        />
        <DropDown
          disabled={!offerInfoPermission}
          options={exclusivity}
          register={register("exclusivity")}
          label="Exclusivity"
          name="exclusivity"
          focusHandleText={
            <p>
              No : Offer Can be combined with any Offer (default), <br />
              Yes : Offer Cannot be combined with a Offer of the same offer type
            </p>
          }
        />
        <DateAndTimePicker
          disabled={!offerInfoPermission}
          label="Start Date"
          name="startDate"
          value={watch("startDate")}
          onDateSelection={(date) => {
            dateRangeSelectionHandler("startDate", date);
          }}
        />
        <div className="endDatePicker">
          <DateAndTimePicker
            disabled={!offerInfoPermission}
            label="End Date"
            name="endDate"
            value={watch("endDate")}
            onDateSelection={(date) => {
              dateRangeSelectionHandler("endDate", date);
            }}
            minDateTime={watch("startDate")}
          />
          {endDateErr && (
            <p className="hint-text error-text">
              * End date should be greather than start date{" "}
            </p>
          )}
        </div>
      </div>
      <div className="editor">
        <div className="editor-field-wrapper">
          <div className="headingAndInformation">
            <p className="heading"> Offer Description</p>
            <div
              className="informationField"
              onClick={() => {
                setCkeditorDiscriptionVisiblity(!ckeditorDiscriptionVisiblity);
              }}
            >
              ?
            </div>
            {ckeditorDiscriptionVisiblity && (
              <>
                <div
                  className="ckeditorInformation"
                  ref={ckeditorDiscriptionRef}
                >
                  {
                    "(Description of Offer Communication from Brand / Bank / Poorvika. Add the Content of the offer so this can be view by Other departments like Finance , Logistics)"
                  }
                </div>
                <div className="ckeditorFocusHandleCaret">
                  <Icon className="ckeditorfocusCaret" icon={faCaretUp} />
                </div>
              </>
            )}
          </div>
        </div>
        {textEditorVisibility && (
          <ReactQuill
            readOnly={!offerInfoPermission}
            theme="snow"
            value={watch("offerDescription")}
            onChange={(value: any) => setValue("offerDescription", value)}
            modules={{
              toolbar: editorToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default InfoSection;
