/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import appContext from "context/app.context";
import DiscountIcon from "../../../assets/icons/discount.svg";
import AddProductsIcon from "../../../assets/icons/add-product.svg";
import SignatureIcon from "../../../assets/icons/signature.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import CouponsInfoForm from "../Forms/CouponsInfoForm";
import Summary from "../Summary";
import AddCouponProducts from "../AddcouponsPage/AddCouponProducts";
import "./styles.scss";
import ApplyCoupon from "../ApplyCoupon";
import CouponAssigned from "../CouponAssigned";
import {
  COUPON_APPLIES_TO,
  COUPON_TYPE,
  removeDuplicateByKey,
} from "common/constant";
import HistorySideMenu from "../../HistoryPage";
import { useParams } from "react-router";
import CouponTransaction from "../CouponTransaction";
import { usePermission } from "components/Permission";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";

const EditCouponsPage = () => {
  const param = useParams();
  const {
    menuIndex,
    setMenuIndex,
    offerData,
    viewOnlyMode = true,
    setViewOnlyMode,
  } = useContext(appContext);

  type ISubMenu = { name: string; img: any; components: any }[];
  const menuArray: ISubMenu = [
    {
      name: "COUPON GENERATIONS",
      img: DiscountIcon,
      components: <CouponsInfoForm />,
    },
    {
      name: "PRODUCTS",
      img: AddProductsIcon,
      components: <AddCouponProducts />,
    },
    { name: "SUMMARY", img: SearchIcon, components: <Summary /> },
    {
      name: "HISTORY",
      img: SearchIcon,
      components: (
        <HistorySideMenu couponId={param?.id} name="Coupons History" />
      ),
    },
    {
      name: "COUPON TRANSACTIONS",
      img: SearchIcon,
      components: <CouponTransaction />,
    },
  ];

  const uniqueCouponsMenu: ISubMenu = [
    { name: "APPLY COUPON", img: SignatureIcon, components: <ApplyCoupon /> },
    {
      name: "COUPON ASSIGNED",
      img: SignatureIcon,
      components: <CouponAssigned />,
    },
  ];

  const [subMenu, setSubMenu] = useState<ISubMenu>(menuArray);

  const [createAndEditCouponsPermission] = usePermission([
    "CREATE_AND_EDIT_COUPONS",
  ]);

  useEffect(() => {
    onofferDataChange(offerData?.appliesTo, offerData?.couponCodeType);
    document.title = offerData?.couponName;
  }, [offerData]);

  const onofferDataChange = async (appliesTo: string, couponType: string) => {
    const filteredByAppliesToMenu =
      appliesTo === COUPON_APPLIES_TO?.CART
        ? subMenu.filter((data) => data?.name !== "PRODUCTS")
        : menuArray;
    const filterByCouponType =
      couponType === COUPON_TYPE.UNIQUE_COUPON
        ? filteredByAppliesToMenu.concat(uniqueCouponsMenu)
        : filteredByAppliesToMenu?.filter(
            (data) =>
              !uniqueCouponsMenu?.map((val) => val?.name).includes(data?.name)
          );
    const newArr = removeDuplicateByKey(filterByCouponType, "name");
    setSubMenu(newArr);
  };

  return (
    <div className="coupons-page">
      <div className="coupon-header">
        <div className="coupons-page-heading">
          <h3 className="coupons-page-heading-label" style={{ flex: 1 }}>
            {offerData.couponName}
          </h3>

          {offerData.status === "Draft" &&
            param?.id &&
            createAndEditCouponsPermission &&
            viewOnlyMode && (
              <div style={{ display: "flex" }}>
                <div
                  className="btn primary flex publish-btn"
                  onClick={() => {
                    setViewOnlyMode(!viewOnlyMode);
                  }}
                  style={{ margin: "10px", color: "white" }}
                >
                  <Icon icon={faPencil} />
                  <p>Edit Coupon</p>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className="coupons-tabs">
        {subMenu?.map((menu: any, index: number) => {
          return (
            <div
              className={
                index === menuIndex ? "coupon-active" : "coupon-inactive"
              }
              onClick={() => setMenuIndex(index)}
            >
              <img src={menu.img} alt="" />
              <p>{menu.name}</p>
            </div>
          );
        })}
      </div>
      <div className="add-coupons-page-content">
        <div className="offer-info shadow">{subMenu[menuIndex].components}</div>
      </div>
    </div>
  );
};

export default EditCouponsPage;
