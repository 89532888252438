import moment from "moment";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { TablePagination, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/UseInput";
import RefreshIcon from "@mui/icons-material/Refresh";

import DropDown from "components/DropDown";
import {
  PublishTypes,
  PublishStatus,
  PublishJobStatus,
  PublishObjTypes,
} from "common/constant";
import { PublishService } from "services/publish.service";
import { defaultLabelDisplayedRows } from "common/commonFunc";

import "./styles.scss";

type Tprops = {};

const PublishList: React.FC<Tprops> = () => {
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [publishData, setFinanceData] = useState<any>([]);
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const [norecord, setNorecord] = useState("No Records found");
  const [search, setSearch] = useState("");
  const [filterVisibility, setFilterVisibility] = useState<boolean>(false);
  const [mobileFilterVisibility, setMobileFilterVisibility] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    publish_type: "ALL PUBLISH TYPE",
    obj_type: "ALL OBJECT TYPE",
    status: "ALL STATUS",
    job_status: "ALL JOB STATUS",
  });

  const [windowDimension, setWindowDimension] = useState<any>(null);
  const isDesktop = windowDimension >= 640;

  useEffect(() => {
    setWindowDimension(window.innerWidth);
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /* Searching published offers with text */
  const searchHandler = async (search: any) => {
    if (search.length > 3) {
      setSearch(search);
    }

    if (search.length === 0) {
      setSearch("");
    }
  };

  const publishService = new PublishService();

  const getPublishList = async (): Promise<void> => {
    setSpinnerVisibility(true);

    if (filter.publish_type === "ALL PUBLISH TYPE") filter.publish_type = "";
    if (filter.obj_type === "ALL OBJECT TYPE") filter.obj_type = "";
    if (filter.status === "ALL STATUS") filter.status = "";
    if (filter.job_status === "ALL JOB STATUS") filter.job_status = "";

    const filterData = {
      rowsPerPage,
      page,
      search,
      ...filter,
    };

    try {
      const apiResponse = await publishService.getPublishObjects(filterData);

      if (apiResponse.status === STATUS.SUCCESS) {
        let response = apiResponse.data;
        setFinanceData(response);
        setTotalPages(Math.ceil(Number(apiResponse.count) / rowsPerPage));
      } else {
        showToaster(EToasterVariants.error, "Unable to get Publish List");
      }
    } catch (error) {
      console.log(error);
      showToaster(
        EToasterVariants.error,
        "An error occurred while fetching data"
      );
    }

    setSpinnerVisibility(false);
  };

  useEffect(() => {
    getPublishList();
  }, [page, rowsPerPage, search, filter]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const sliderVisibilityHandler = () => {
    setFilterVisibility(!filterVisibility);
    setMobileFilterVisibility(!mobileFilterVisibility);
  };

  const syncRecord = async (id: string) => {
    try {
      setSpinnerVisibility(true);
      const apiResponse = await publishService.updateRecord(id);

      if (apiResponse.status === STATUS.SUCCESS) {
        setSpinnerVisibility(false);
        showToaster(
          EToasterVariants.success,
          "Status and Job status updated successfully"
        );
        getPublishList();
      }
    } catch (error) {
      console.log(error);
      showToaster(
        EToasterVariants.error,
        "An error occurred while syncing record"
      );
    } finally {
      setSpinnerVisibility(false);
    }
  };

  useEffect(() => {
    getPublishList();
  }, [page, rowsPerPage, search, filter]);

  return (
    <div className="priceList-table">
      <div className="price-tab">
        <div>
          <h3>Publish List</h3>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="priceList-header">
            <div className="price-main-btn">
              {isDesktop ? (
                <div className={`finance-filter`}>
                  <FontAwesomeIcon
                    className="cursor-pointer sort-filter-icon"
                    icon={faFilter}
                    // onClick={sliderVisibilityHandler}
                  />
                </div>
              ) : (
                <div className={`finance-filter-mobile`}>
                  <div className="filter-wrp">
                    <div>
                      <FontAwesomeIcon
                        className="cursor-pointer sort-filter-icon"
                        icon={faFilter}
                        onClick={sliderVisibilityHandler}
                      />
                    </div>
                    <div>
                      <p>Filter</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="publish-filter">
                <DropDown
                  options={["ALL PUBLISH TYPE", ...PublishTypes]}
                  onChange={(e) =>
                    setFilter({ ...filter, publish_type: e.target.value })
                  }
                  label="Publish Type"
                  name="publish_type"
                  value={filter?.publish_type}
                />
              </div>
              <div className="publish-filter">
                <DropDown
                  options={["ALL OBJECT TYPE", ...PublishObjTypes]}
                  onChange={(e) =>
                    setFilter({ ...filter, obj_type: e.target.value })
                  }
                  label="All Object Types"
                  name="obj_type"
                  value={filter?.obj_type}
                />
              </div>
              <div className="publish-filter">
                <DropDown
                  options={["ALL STATUS", ...PublishStatus]}
                  onChange={(e) =>
                    setFilter({ ...filter, status: e.target.value })
                  }
                  label="Status"
                  name="status"
                  value={filter?.status}
                />
              </div>
              <div className="publish-filter">
                <DropDown
                  options={["ALL JOB STATUS", ...PublishJobStatus]}
                  onChange={(e) =>
                    setFilter({ ...filter, job_status: e.target.value })
                  }
                  label="Job Status"
                  name="job_status"
                  value={filter?.job_status}
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <Input
                className="inputwrp"
                placeholder="Search Published Offers"
                onChange={searchHandler}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="financeList-table">
        <table className="sticky-left">
          <thead>
            <tr>
              <th>
                <div className="tableHeader-Elements-finance">ID</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Name</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Publish Type</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Object Type</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Status</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Job Status</div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">Published By</div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">Created At</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Last Updated</div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {publishData?.map((publish: any, index: number) => (
              <tr
                key={index}
                className="cursor-pointer"
                // onClick={() =>
                //   navigate(`/offers/finance_list/edit/${publish._id}`)
                // }
              >
                <td
                  data-label="Id"
                  className="tooltip"
                  data-content={
                    publish?.obj?.offerId ||
                    publish?.obj?.couponId ||
                    publish?.obj?.itemCode
                  }
                >
                  {publish?.obj?.offerId ||
                    publish?.obj?.couponId ||
                    publish?.obj?.itemCode}
                </td>
                <td
                  data-label="Name"
                  className="tooltip"
                  data-content={
                    publish?.obj?.offerName ||
                    publish?.obj?.couponName ||
                    publish?.obj?.varientName ||
                    publish?.obj?.financeName
                  }
                >
                  {publish?.obj?.offerName ||
                    publish?.obj?.couponName ||
                    publish?.obj?.varientName ||
                    publish?.obj?.financeName}
                </td>
                <td data-label="Publish Type">{publish?.publish_type}</td>
                <td data-label="Object Type">{publish?.obj_type}</td>
                <td data-label="Status">{publish?.status}</td>
                <td data-label="Job Status">{publish?.job_status}</td>
                <td data-label="Published By">{publish?.createdBy}</td>
                <td
                  data-label="Created At"
                  className="tooltip"
                  data-content={moment(publish.createdAt).format(
                    "DD-MMM-YYYY hh:mm A"
                  )}
                >
                  {moment(publish.createdAt).format("DD-MMM-YYYY hh:mm A")}
                </td>
                <td
                  data-label="Published At"
                  className="tooltip"
                  data-content={moment(publish.updatedAt).format(
                    "DD-MMM-YYYY hh:mm A"
                  )}
                >
                  {moment(publish.updatedAt).format("DD-MMM-YYYY hh:mm A")}
                </td>

                {publish.status === "READY" &&
                publish.job_status === "PROCESSING" &&
                moment(new Date()).valueOf() >
                  moment(new Date(publish.createdAt))
                    .add(1, "hours")
                    .valueOf() &&
                moment(new Date()).valueOf() <
                  moment(new Date(publish.createdAt))
                    .add(24, "hours")
                    .valueOf() ? (
                  <td data-label="Refresh">
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: "#ff8000",
                      }}
                      onClick={(e) => {
                        syncRecord(publish._id);
                      }}
                    >
                      <RefreshIcon />
                    </Button>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {publishData.length === 0 && <div className="norecord">{norecord}</div>}
      </div>

      {publishData.length > 0 ? (
        <TablePagination
          backIconButtonProps={
            page === 1 ? { disabled: true } : { disabled: false }
          }
          nextIconButtonProps={
            page === totalPages || totalPages === 0
              ? { disabled: true }
              : { disabled: false }
          }
          component="div"
          count={totalPages}
          labelDisplayedRows={defaultLabelDisplayedRows}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}

      <div
        className={`sidebar-overlay ${
          filterVisibility === true ? "active" : ""
        }`}
        onClick={sliderVisibilityHandler}
      ></div>
    </div>
  );
};

export default PublishList;
