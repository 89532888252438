import { TextField, InputAdornment } from "@mui/material";

const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }: any) => {
    return (
      <TextField
        {...props}
        InputProps={{
          ...InputProps,
          startAdornment: iconStart ? (
            <InputAdornment position="start">{iconStart}</InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment position="end">{iconEnd}</InputAdornment>
          ) : null,
        }}
      />
    );
  };

  export default IconTextField;