// import React from 'react'
import React, { useContext } from "react";
import appContext from "context/app.context";
import "./style.scss";

type PaginatorProps = {
pagecount: number;
index: number
getCurrentpage: ( pagedata :number) => void
};

const Pagination: React.FC<PaginatorProps> = ({
pagecount,
index,
getCurrentpage
}) => {
    const { page,setPage } = useContext(appContext);
  return (
    <div className={page===index? 'page' : 'disabled-page-btn'} onClick={()=>{setPage(index) 
      getCurrentpage(index+1)
}}><p>{pagecount}</p></div>
  )
}

export default Pagination