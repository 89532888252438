import React from 'react'
import spinner from 'assets/buffer.gif';
import "./styles.scss"

const Loader: React.FC = () => {
  return (
    <div className="fetching-records">
     <img src={spinner} alt="loader" />
     <p>Fetching Product Information</p>
     </div>
  )
}

export default Loader