import noImageAvailable from "assets/images/no-image.png";
import { useContext, useEffect, useState } from "react";
import appContext from "context/app.context";
import Icon from "components/Icon";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { DiscountType } from "common/constant";
import { EToasterVariants } from "services/types/common.type";
import { CouponProductService } from "services/couponProduct.service";
import { STATUS } from "config/config";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import Checkbox from "components/Checkbox";
import InputField from "components/InputField";
import { useForm } from "react-hook-form";
import { usePermission } from "components/Permission";
import DateAndTimePicker from "components/DateTimePicker";
import moment from "moment";

const CouponProductList = (props: any) => {
  const {
    product = {},
    getProductLists,
    filter,
    productLists = [],
    setSelectAll,
    setProductLists,
  } = props;
  const {
    offerData,
    setOfferData,
    showToaster,
    setSpinnerVisibility,
    viewOnlyMode,
  } = useContext(appContext);
  const { _id = "", checked = false } = product;
  const [editProductPermission] = usePermission(["EDIT_COUPON_STATUS"]);
  const [deleteProductPermission] = usePermission(["DELETE_COUPONS"]);

  const [deleteDialog, setDeleteDialog] = useState(false);

  const couponProductService = new CouponProductService();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  /* Delete Confirmation handler */
  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      try {
        setSpinnerVisibility(true);
        const apiResponse = await couponProductService.deleteCouponProduct(_id);
        if (apiResponse.status === STATUS.SUCCESS) {
          setDeleteDialog(false);
          setOfferData(apiResponse.data);
          await getProductLists(offerData._id, filter);
          setSpinnerVisibility(false);
          showToaster(EToasterVariants.success, "Record Deleted Successfully");
        }
      } catch (error) {
        setSpinnerVisibility(false);
        showToaster(EToasterVariants.error, "Unable to delete the record");
      }
    } else {
      setDeleteDialog(false);
    }
  };

  const onSubmit = async (couponInfoValues: any): Promise<void> => {
    try {
      setSpinnerVisibility(true);

      let {
        varientName,
        imageURL,
        itemCode,
        startDate,
        endDate,
        discountValue,
        maxDiscountValue,
      } = couponInfoValues;

      const couponStartDate = moment(offerData.validityStartDate);
      const couponEndDate = moment(offerData.validityEndDate);
      const couponDiscountValue = offerData.discountValue;

      const prodStartDate = startDate
        ? moment(startDate)
        : moment(offerData.validityStartDate);

      const prodEndDate = endDate
        ? moment(endDate)
        : moment(offerData.validityEndDate);

      startDate = startDate ? startDate : couponStartDate;
      endDate = endDate ? endDate : couponEndDate;

      if (discountValue && couponDiscountValue < discountValue) {
        showToaster(
          EToasterVariants.error,
          "Discount value should be lesser than Coupon's Discount value"
        );
        setSpinnerVisibility(false);

        return;
      }
      if (couponStartDate > prodStartDate) {
        showToaster(
          EToasterVariants.error,
          "Start Date should be greater than Coupon Start Date"
        );
        setSpinnerVisibility(false);

        return;
      }

      if (prodEndDate < prodStartDate) {
        showToaster(
          EToasterVariants.error,
          "End Date should be greater than Start Date"
        );
        setSpinnerVisibility(false);

        return;
      }

      if (prodEndDate.isAfter(couponEndDate)) {
        showToaster(
          EToasterVariants.error,
          "End Date should be less than Coupon End Date"
        );
        setSpinnerVisibility(false);

        return;
      }

      const productData = {
        varientName: varientName,
        itemCode: itemCode,
        imageURL: imageURL,
        discountValue,
        maxDiscountValue,
        startDate,
        endDate,
      };

      const apiResponse = await couponProductService.updateCouponProduct(
        _id,
        productData
      );

      if (apiResponse.status === STATUS.SUCCESS) {
        setSpinnerVisibility(false);
        showToaster(
          EToasterVariants.success,
          "Coupon Product updated successfully"
        );
        getProductLists(offerData._id, filter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectCheckBox = (checkedStatus: boolean) => {
    let isAll = true;
    const updatedProducts = productLists.map((product: any) => {
      if (product._id === _id) {
        product.checked = checkedStatus;
      }
      if (!product.checked) {
        isAll = false;
      }
      return product;
    });
    setSelectAll(isAll);
    setProductLists(updatedProducts);
  };

  useEffect(() => {
    if (product) {
      let { startDate, endDate, discountValue, maxDiscountValue } = product;
      setValue("discountValue", discountValue);
      setValue("maxDiscountValue", maxDiscountValue);
      setValue("startDate", startDate);
      setValue("endDate", endDate);
    }
  }, [product]);

  return (
    <tr className="product-row">
      {!viewOnlyMode && (
        <td
          data-label="Item Code"
          className="checkbox_wrap"
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox key={_id} value={checked} onChange={selectCheckBox} />
        </td>
      )}

      <td>
        <img
          src={product?.image?.all?.url ?? product.imageURL ?? noImageAvailable}
          alt="product"
          className="product-image"
        />
      </td>

      <td>{product?.name ?? product?.varientName}</td>
      <td>{product?.erp_item_code ?? product?.itemCode}</td>

      <td data-label="Discount">
        <div className="discount-fields">
          <InputField
            disabled
            helpertext={
              offerData?.discountUnit === DiscountType.Percentage
                ? "Discount %"
                : "Discount Value"
            }
            name="discountValue"
            type="number"
            step={0.01}
            register={register(`discountValue`, {
              max: {
                value:
                  offerData?.selectedOfferType?.offerTypes !==
                  "TradeIn and Exchange"
                    ? Number(offerData?.discountValue)
                    : Infinity,
                message:
                  "Discount value should be lesser than Coupons's Discount value",
              },
            })}
            error={errors[`discountValue`]?.message as any}
          />
        </div>
      </td>
      {offerData?.discountType === DiscountType.Percentage && (
        <>
          <td data-label="MaxDiscount">
            <div className="discount-fields">
              <InputField
                disabled
                helpertext={"Max Discount"}
                type="number"
                step={0.01}
                register={register(`maxDiscountValue`)}
                error={errors[`maxDiscountValue`]?.message as any}
              />
            </div>
          </td>
        </>
      )}

      <td data-label="Applicable From - To">
        <div className="start-end-date-fields">
          <DateAndTimePicker
            onDateSelection={(date) => {
              setValue(`startDate`, date);
            }}
            value={watch("startDate")}
            disabled
            label="Start Date"
            name="startDate"
            minDateTime={offerData.validityStartDate}
          />
        </div>
      </td>
      <td>
        <DateAndTimePicker
          onDateSelection={(date) => {
            setValue(`endDate`, date);
          }}
          value={watch("endDate")}
          disabled
          label="End Date"
          name="endDate"
          minDateTime={offerData.validityStartDate}
          maxDateTime={offerData.validityEndDate}
        />
      </td>

      {!viewOnlyMode && (deleteProductPermission || editProductPermission) ? (
        <>
          {deleteProductPermission && (
            <td>
              <div
                className="cursor-pointer"
                onClick={() => setDeleteDialog(true)}
              >
                <Icon icon={faTrashCan} />
              </div>
            </td>
          )}
          {/* {editProductPermission && (
            <td>
              <button
                className="btn primary mr-2 cursor-pointer"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Update
              </button>
            </td>
          )} */}
        </>
      ) : null}

      <DeleteConfirmationDialog
        show={deleteDialog}
        onConfirmation={deleteConfirmationHandler}
      />
    </tr>
  );
};

export default CouponProductList;
