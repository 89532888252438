import { AxiosError } from "axios";
import { objectToQueryString, removeFalsy } from "common/constant";
import { API_ENDPOINTS, COMMON_API, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";
import { IAddCouponCodeForUser, IAddCouponTypes, IAssignProductToCoupon,  IGetCouponTypes, IGetUserByCouponId, IRemoveProductFromCoupon, IUpdateCouponTypes } from "./interfaces/coupon.interface";

export class CouponsService extends BaseService {

    public async getCoupons(rowsPerPage?: number, pageNo?: number, search?: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_COUPON_LIST}?limit=${rowsPerPage ? rowsPerPage : ""}&page=${pageNo ? pageNo : ""}&search=${search ? search : ""}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }


    public async createNewCoupon(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_COUPON, offerData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getCouponDetails(couponId: string): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_COUPON_BY_ID}${couponId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async bulkProductUpload(file: any, id: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.BULK_COUPON_PRODUCT_UPLOAD}/${id}`, file);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async bulkAssignUpload(file: any, id: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.BULK_COUPON_ASSIGN_UPLOAD}/${id}`, file);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async assignProductsToCoupon(productsData: IAssignProductToCoupon): Promise<IAPIReponse> {

        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ASSIGN_PRODUCTS_TO_COUPON, productsData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }


    public async updateCoupon(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.put(API_ENDPOINTS.UPDATE_COUPON_BY_ID, offerData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteCoupon(_id: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.delete(API_ENDPOINTS.DELETE_COUPON_BY_ID, { data: { _id } });
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

    public async addCouponCodeForCustomer(payload: IAddCouponCodeForUser): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.ADD_COUPON_CODE_FOR_CUSTOMER}`, payload);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }


    public async deleteCouponCodeForCustomer(couponId: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.delete(`${API_ENDPOINTS.DELETE_COUPON_FOR_CUSTOMER}/${couponId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getUserByCouponId(offerData: IGetUserByCouponId): Promise<IAPIReponse> {
        try {
            // removeFalsy method is used for removing empty params .
            // objectToQueryString method is used for converting an object to the query string.
            const payload = objectToQueryString(removeFalsy(offerData))
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_USER_BY_COUPON_ID}${payload}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getUploadHistoryCouponId(offerData: IGetUserByCouponId): Promise<IAPIReponse> {
        try {
            // removeFalsy method is used for removing empty params .
            // objectToQueryString method is used for converting an object to the query string.
            const payload = objectToQueryString(removeFalsy(offerData))
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_HISTORY_COUPON_ID}${payload}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async removeProductFromCoupon(removeData: IRemoveProductFromCoupon): Promise<IAPIReponse> {
        try {
            // removeFalsy method is used for removing empty params .
            // objectToQueryString method is used for converting an object to the query string.
            const payload = objectToQueryString(removeFalsy(removeData))
            const { data } = await this.httpClient.delete(`${API_ENDPOINTS.REMOVE_PRODUCTS_FROM_COUPON}${payload}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async addCouponTypes(payload: IAddCouponTypes): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.ADD_COUPON_TYPES}`, payload);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async updateCouponTypes(payload: IUpdateCouponTypes): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.put(`${API_ENDPOINTS.UPDATE_COUPON_TYPES}`, payload);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getCouponTypes(payload: IGetCouponTypes): Promise<IAPIReponse> {
        try {
            const toQueryString = objectToQueryString(removeFalsy(payload))
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_COUPON_TYPES}${toQueryString}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async statusChange(couponId: string, status: string, datas?: any): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.COUPON_STATUS_UPDATE}`, { id: couponId, status: status, rejectedNotes: datas });
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getTransactionsByCouponId(offerData: IGetUserByCouponId): Promise<IAPIReponse> {
        try {
            // removeFalsy method is used for removing empty params .
            // objectToQueryString method is used for converting an object to the query string.
            const { REACT_APP_API_URL = '' } = process.env;

            const payload = objectToQueryString(removeFalsy(offerData))
            const { data } = await this.httpClient.get(`${REACT_APP_API_URL}${API_ENDPOINTS.GET_COUPON_TRANSACTIONS}${payload}`, {
                headers: {
                    "x-api-key": COMMON_API.OFFER_API_KEY || '',
                }
            });
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

}