import { faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import DropDown from "components/DropDown";
import Icon from "components/Icon";
import InputField from "components/InputField";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EToasterVariants } from "services/types/common.type";
import { UserService } from "services/users.service";
import "./styles.scss";
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/store';
import { RolesService } from "services/roles.service";

const UserTable: React.FC = () => {
  /* State variables */
  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [roleList, setRolesList] = useState<any[]>([]);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number>(0);

  /* Services */
  const userService = new UserService();
  const rolesService: RolesService = new RolesService()

  /* Context */
  const context = useContext(appContext);
  const { setSpinnerVisibility } = useContext(appContext);

  /*User Details */

  const userEmail = useSelector<RootStore>((state) => state.userDetails.userDetails.userEmail)

  /* Form hooks */
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();


  const sliderVisbilityHandler = async () => {
    reset();
    setSliderVisibility(!sliderVisibility);
    setEditMode(false);
  }

  const getRolesList = async (): Promise<void> => {
    const getRoles = await rolesService.getRolesList()
    setRolesList(getRoles.data.map((value: any) => value.Role))

  }

  const getUserList = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const userListResponse = await userService.getUserList(page + 1, rowsPerPage);
      setSpinnerVisibility(false);
      if (userListResponse.status === STATUS.SUCCESS) {
        setUserList(userListResponse.data.UsersDetails);
        setTotalPages(Math.ceil(userListResponse.count / rowsPerPage))
      } else {
        context.showToaster(
          EToasterVariants.error,
          "Unable to get User List"
        );
      }
    } catch (error) {
      context.showToaster(EToasterVariants.error, "Unable to get User List");
    }
  };

  /* Offer Type Edit Handler */
  const UserEditHanlder = async (data: any, attributeIndex: number): Promise<void> => {

    setEditMode(true);
    setSelectedUserIndex(attributeIndex);
    unregister("password")
    const { name, email, role } = data;
    setValue("name", name);
    setValue("email", email);
    setValue("role", role);

    setSliderVisibility(true)

  }


  const createUserHandler = async (values: any): Promise<void> => {
    try {
      context.setSpinnerVisibility(true);
      var userAction;

      if (editMode) {
        values = { ...values, modifiedBy: userEmail }
        userAction = await userService.updateUser({ ...values, _id: userList[selectedUserIndex]._id })
      } else {
        values = { ...values, createdBy: userEmail, modifiedBy: userEmail }
        userAction = await userService.addUser(values);
      }

      if (userAction.status === STATUS.SUCCESS) {
        await sliderVisbilityHandler();
        await getUserList();
        context.setSpinnerVisibility(false);
      } else {
        context.setSpinnerVisibility(false);
        context.showToaster(
          EToasterVariants.error,
          `unable to ${editMode ? "Update" : "Add"} User Details`
        );
      }



    } catch (error) {
      context.setSpinnerVisibility(false);
      context.showToaster(
        EToasterVariants.error,
        `unable to ${editMode ? "Update" : "Add"} User Details`
      );
    }
  };


  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getUserList();
    getRolesList();
  }, [page, rowsPerPage]);

  return (
    <div className="user-edit">
      <div className="header">
        <h3> Users Info</h3>
        <button
          className="btn primary"
          onClick={sliderVisbilityHandler}
        >
          Create New User
        </button>
      </div>

      <table className="shadow permisssion-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Created By</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user: any, index: number) => {
            return (
              <tr>
                <td data-label="Name">{user.name}</td>
                <td data-label="Email">{user.email}</td>
                <td data-label="Created By">{user.createdBy}</td>
                <td data-label="Role">
                  {user?.role || "Nill"}
                </td>
                <td data-label="Action" className="action">
                  <div onClick={() => UserEditHanlder(user, index)}>
                    <Icon icon={faPen} />
                  </div>
                  {/* <Icon icon={faCircleInfo} /> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination
        backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
        nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
        component="div"
        count={totalPages}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className={`slider-right ${sliderVisibility ? "show" : "hide"}`}>
        <div className="add-user-slider">
          <div className="slider-header">
            <h1>{editMode ? "Edit user Info" : "Create New User"}</h1>
            <div onClick={sliderVisbilityHandler}>
              <Icon icon={faXmark} />
            </div>
          </div>

          <div className="form-wrapper">
            <form onSubmit={handleSubmit(createUserHandler)}>
              <InputField
                helpertext="Name"
                name="name"
                register={register("name", { required: "Name is Required" })}
                error={errors.name?.message as any}
              />
              <InputField
                helpertext="Email"
                name="email"
                register={register("email", {
                  required: 'Email is required',
                  pattern: {
                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: 'Please enter a valid email address'
                  }
                })}
                error={errors.email?.message as any}
              />
              {editMode ? null : <InputField
                helpertext="Password"
                name="password"
                register={register("password", {
                  required: "Password is required",
                  minLength: { value: 6, message: "Password must be at least 6 characters long" }
                })}
                error={errors.password?.message as any}
              />}
              <DropDown
                options={roleList}
                label="Role"
                name="role"
                register={register("role")}
              />
              <div className="action-buttons">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={sliderVisbilityHandler}
                >
                  Cancel
                </button>
                <button className="btn primary" type="submit">
                  {editMode ? "Save" : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
