import { IErrorResponse } from "../services/interfaces/common.interface";
import { AxiosError } from "axios";

const {
  REACT_APP_PIM_API: PIM_API,
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_TYPESENSE_API: TYPESENSE_API,
  REACT_APP_TYPESENSE_KEY: TYPESENSE_KEY,
  REACT_APP_INVENTORY_API: INVENTORY_API,
  REACT_APP_INVENTORY_KEY: INVENTORY_KEY,
  REACT_APP_CRM_KEY: CRM_KEY,
  REACT_APP_CRM_API: CRM_API,
  REACT_APP_OFFERS_SECURITY_KEY: OFFER_API_KEY } = process.env;


export const COMMON_API = {
  TYPESENSE_API: `${TYPESENSE_API}/multi_search`,
  TYPESENSE_KEY: TYPESENSE_KEY,
  PIM_PRODUCT_API: `${PIM_API}/product`,
  PIM_PRODUCT_API_PRODUCT: `${PIM_API}/productlisting`,
  BRANDS_LIST_API: `${PIM_API}/brand`,
  CATEGORY_LIST_API: `${PIM_API}/categorylist`,
  PIM_VARIANT_API: `${PIM_API}/variant`,
  PIM_PRICE_API: `${PIM_API}/productpricing/`,
  OFFER_EMI_DETAILS: `/nbfc/showFinance`,
  OFFER_DETAILS: `${BASE_URL}go-api/api/v1/product/productDetails`,
  OFFER_API_KEY: OFFER_API_KEY
};

export const API_ENDPOINTS = {
  //Login API
  LOGIN: "/users/login",
  SSO_LOGIN: "/users/ssoLogin",
  FORGOT_PWD: "/users/forgototp",
  VERIFY_OTP: "/users/verify-otp",
  RESET_PWD : "/users/reset-password",


  // Main Page API's ********************************************

  // 1.Dashboard API
  DASHBOARD_DATA: "dashboard/counts",

  // 2.Free Offers API
  FREEOFFERSGET: "/freeOffersDetails/getAllDatas",
  FREEOFFERSINSERT: "/freeOffersDetails/insert",
  FREEOFFERSUPDATE: "/freeOffersDetails/update",
  FREEOFFERSDELETE: "/freeOffersDetails/delete",
  FREEMULTIDELETE: "/freeOffersDetails/multiDelete",

  // 3.Offers API
  OFFERSGET: "/offersDetails/getAllDatas",
  OFFERSINSERT: "/offersDetails/insert",
  OFFERSUPDATE: "/offersDetails/update",
  OFFERSDELETE: "/offersDetails/delete",
  MULTIDELETE: "/offersDetails/multiDelete",

  // 3.Offers Product API
  OFFERSPRODUCTS: "/offer-products",
  MULTI_DELETE_PRODUCTS: "/offer-products/multiDelete",


  // 3.Offers Product API
  COUPONPRODUCTS: "/coupon-products",
  MULTI_DELETE_COUPONPRODUCTS: "/coupon-products/multiDelete",

  // 4.Offers Product API
  PUBLISH: "/publish",

  // 4.Get findItemCode
  FINDITEMCODE: "itemcodedetails/find",

  // 5.offline orders API
  OFFLINEDETAILS: "/offlineDetails/getAllDatas",
  OFFLINEINSERT: "/offlineDetails/insert",
  OFFLINEUPDATE: "/offlineDetails/update",
  OFFLINEDELETE: "/offlineDetails/delete",
  OFFLINE_MULTIDELETE: "/offlineDetails/multiDelete",

  //Sub Offline Orders

  // 1. Bankorders
  OFFLINEBANK_LIST: "/offlineBank/getAllDatas",
  OFFLINEBANK_INSERT: "/offlineBank/insert",
  OFFLINEBANK_UPDATE: "/offlineBank/update",
  OFFLINEBANK_DELETE: "/offlineBank/delete",

  // 2. OfflineModel
  OFFLINEMODEL_LIST: "/offlineModel/getAllDatas",
  OFFLINEMODEL_INSERT: "/offlineModel/insertMany",
  OFFLINEMODEL_UPDATE: "/offlineModel/update",
  OFFLINEMODEL_DELETE: "/offlineModel/delete",

  // 3. OfflineTrade
  OFFLINETRADE_LIST: "/offlineTrade/getAllDatas",
  OFFLINETRADE_INSERT: "/offlineTrade/insertMany",
  OFFLINETRADE_UPDATE: "/offlineTrade/update",
  OFFLINETRADE_DELETE: "/offlineTrade/delete",

  // 4. OfflineJio
  OFFLINE_JIO_LIST: "/offlineJio/getAllDatas",
  OFFLINE_JIO_INSERT: "/offlineJio/insertMany",
  OFFLINE_JIO_UPDATE: "/offlineJio/update",
  OFFLINE_JIO_DELETE: "/offlineJio/delete",

  // 6.offline Permission API
  OFFLINE_PERMISSION: "/offlineDetails/permissionUpdate",

  //End  Main Page API's ********************************************

  //  Setting *****************************
  SETTINGS_TRADEIN: "/settings/tradein",


  // 2.SPL Offer API
  SPL_OFFER_LIST: "/specialOffer/getAllDatas",
  SPL_OFFER_INSERT: "/specialOffer/insert",
  SPL_OFFER_UPDATE: "/specialOffer/update",
  SPL_OFFER_DELETE: "/specialOffer/delete",
  SPL_OFFER_LIST_MULTIDELETE: "/specialOffer/multiDelete",

  // 3.SPL Offer Category API
  SPL_CATEGORY_LIST: "/splCategory/getAllDatas",
  SPL_CATEGORY_INSERT: "/splCategory/insert",
  SPL_CATEGORY_UPDATE: "/splCategory/update",
  SPL_CATEGORY_DELETE: "/splCategory/delete",

  // 4.SPL Offer Sub Category API
  SPL_SUB_CATEGORY_LIST: "/splSubCategory/getAllDatas",
  SPL_SUB_CATEGORY_INSERT: "/splSubCategory/insert",
  SPL_SUB_CATEGORY_UPDATE: "/splSubCategory/update",
  SPL_SUB_CATEGORY_DELETE: "/splSubCategory/delete",

  // 5.SPL Offer Finance Category API
  SPL_FINANCE_LIST: "/splFinance/getAllDatas",
  SPL_FINANCE_INSERT: "/splFinance/insert",
  SPL_FINANCE_UPDATE: "/splFinance/update",
  SPL_FINANCE_DELETE: "/splFinance/delete",

  // 6.item code API
  ITEMCODEDETAILS: "/itemcodeDetails/getAllDatas",
  ITEMCODEINSERT: "/itemcodeDetails/insert",
  ITEMCODEDELETE: "/itemcodeDetails/delete",
  ITEMCODEUPDATE: "/itemcodeDetails/update",

  // 7.Bank API
  BANKDETAILS: "/bankDetails/getAllDatas",
  INSERTTABLE: "/bankDetails/insert",
  BANKUPDATE: "/bankDetails/update",
  BANKDELETE: "/bankDetails/delete",

  // 8.Payment API
  PAYMENTDETAILS: "/paymentDetails/getAllDatas",
  PAYMENTINSERT: "/paymentDetails/insert",
  PAYMENTUPDATE: "/paymentDetails/update",
  PAYMENTDELETE: "/paymentDetails/delete",

  // Select collection *************************************

  PAYMENT_COLLECTION: "common/findPaymentDetails",
  BANK_COLLECTION: "common/findBankDetails",
  SPL_ONLY_CATEGORY: "common/findSplcategory",
  SPL_ONLY_SUB_CATEGORY: "common/findSplsubcategory",
  SPL_ONLY_FINANCE: "common/findSplFinance",
  OFLINE_ORDERS_SElECT: "common/findSelectType",

  // End Select collection *************************************

  //UserDetails ApI
  USER_DETAILS_LIST: "/users/getAllDatas",
  USER_DETAILS_INSERT: "/users/insert",
  USER_DETAILS_UPDATE: "/users/update",
  USER_DETAILS_PWD_UPDATE: "/users/update_pwd",
  USER_DETAILS_DELETE: "/users/delete",

  //New Offline Offers

  NEW_OFFLINE_ORDERS_LIST: "/newOffers/getAllDatas",
  NEW_OFFLINE_ORDERS_INSERT: "/newOffers",

  /* Offers */
  OFFER_TYPE: "/offertypes",

  BULK_PRODUCT_UPLOAD: "/bulkupload",
  BULK_COUPON_PRODUCT_UPLOAD: "/bulkupload/coupon",
  BULK_COUPON_ASSIGN_UPLOAD: "/bulkupload/couponAssign",
  COMBO_BULK_UPLOAD: "/bulkupload/combo/",
  FREEBIE_BULK_UPLOAD: "/bulkupload/freebie/",

  /* CRM Related  */

  CRM_KEY: CRM_KEY,
  GET_CUSTOMER_SEARCH: `${CRM_API}/customers/get/customerList?search=`,

  /* Store Related  */

  GET_ALL_STORES: `${INVENTORY_API}/whlocation/store/IN`,
  INVENTORY_KEY: INVENTORY_KEY,


  FILE_UPLOAD: "/offers/documentUpload",

  /* Attribute CRUD operations */
  ADD_ATTRIBUTE: "/attributes/insert",
  GET_ATTRIBUTES:
    "/attributes/getAllDatas?status=true&code=&name=",
  DELETE_ATTRIBUTES: "/attributes/delete",
  UPDATE_ATTRIBUTE: "/attributes/update",
  CODE_ATTRIBUTE: "/attributes/code",
  ALL_ATTRIBUTE: "/attributes/rule_engine_attributes",

  /* Offers related End points */
  OFFER: "/offers",
  OFFER_GROUP: "/offersGroup",

  // Coupons related End points

  GET_COUPON_LIST: "/coupons/getAllCoupon",
  DELETE_COUPON_BY_ID: "/coupons/deleteCouponById",
  GET_COUPON_BY_ID: "/coupons/getCouponById?couponId=",
  UPDATE_COUPON_BY_ID: "/coupons/updateCouponById",
  ASSIGN_PRODUCTS_TO_COUPON: "/coupons/assignProductsToCoupon",
  ADD_COUPON: "/coupons/addCoupon",
  ADD_COUPON_CODE_FOR_CUSTOMER: "/coupons/addCouponCodeForCustomer",
  DELETE_COUPON_FOR_CUSTOMER: "/coupons/deleteCouponCodeForCustomer",
  GET_USER_BY_COUPON_ID: "/coupons/getUsersByCouponId?",
  GET_HISTORY_COUPON_ID: "/bulkupload/couponAssignHistory?",
  GET_UPLOAD_HISTORY: "/history/uploadHistory?",
  REMOVE_PRODUCTS_FROM_COUPON: "/coupons/removeProductsFromCoupon?",
  ADD_COUPON_TYPES: "/couponTypes/addCouponTypes",
  UPDATE_COUPON_TYPES: "/couponTypes/updateCouponTypes",
  GET_COUPON_TYPES: "/couponTypes/getCouponTypes?",
  COUPON_STATUS_UPDATE: "/coupons/changeStatus",
  GET_COUPON_TRANSACTIONS: "/coupons/getCouponTransactions?",

  /*Coupon Events*/
  COUPON_EVENT: 'settings/coupon-event',

  //Finance Name List
  FINANCE_NAME_LIST: "settings/finance-name-list",

  /*Role */
  ADD_ROLES: '/roles/addroles',
  GET_ROLESLIST: '/roles/getRoles',
  GET_ROLEPERMISSION: '/roles/getRoleandPermissions',
  UPDATE_ROLES: '/roles/updaterolse',

  /*User */
  ADD_USER: '/users/insert',
  GET_USER_LIST: '/users/getAllDatas',
  GET_USER_DETAILS: '/users/getAllDatas',
  UPDATE_USER: '/users/update',

  /*Rules Engine*/
  FILTER_ITEMS: "/ruleEngine/filterOffer",
  FILTER_OPTIONS: "/ruleEngine/filterOptions",
  FILTERS: "/ruleEngine/filters",
  GET_OFFERS_WITH_FILTER: "/ruleEngine/getOffers",

  /*History */
  HISTORY: "/history",

  // Finance
  GET_FINANCE_LIST: "/nbfc",
  DELETE_FINANCE_LIST: "/nbfc",
  FINANCE_BULK_UPLOAD: "/nbfc",
  MULTI_DELETE_FINANCE_LIST: "/nbfc/multiDelete",
  GET_FINANCE_NAME_LIST : "/nbfc/financenamelist"
};

export const STATUS = {
  SUCCESS: "success",
  FAILURE: "failure",
};

export const DiscountVariable: any = {
  discountType: "Discount Type",
  flatDiscount: "Flat Discount",
  discountPercentage: "% Discount",
  discountPercent: "Percentage",
  discountValue: "Discount Value",
  maxDiscountValue: "Max IDB"
}

export const parseAxiosError = (error: AxiosError): IErrorResponse => {
  if (error.isAxiosError && error.response) {
    return { status: STATUS.FAILURE, message: error.message, error, data: {} };
  }

  return { status: STATUS.FAILURE, message: error.message, error, data: {} };
};
