import { usePermission } from 'components/Permission';
import React, { useContext, useState } from 'react'
import { useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import InputField from 'components/InputField';
import Icon from 'components/Icon';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import appContext from 'context/app.context';

function TradeInAndExchange(props) {
    const {
        viewOnlyMode,
        control,
        watch,
        errors,
        setError,
        clearErrors,
        register } = props;

    const { offerData } = useContext(appContext);

    const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
    const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

    const tradeInfoPermission = _.isEmpty(offerData) ? createOffersPermission : !viewOnlyMode && editOffersPermission;

    const [tradeInCount, settradeInCount] = useState<any[]>([0]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'tradeIn',
    });

    const handleAppend = (index: number) => {
        try {
            const currentStartVal = Number(watch(`tradeIn.${index}.startVal`));
            let currentEndVal: string | number = watch(`tradeIn.${index}.endVal`);
            let discountAmount: string | number = watch(`tradeIn.${index}.discountAmount`);

            if (_.isNumber(currentEndVal)) {
                currentEndVal = Number(currentEndVal);
            } else {
                setError(`tradeIn.${index}.endVal`, {
                    type: "manual",
                    message: "End Value is required"
                });
                return
            }

            if (_.isEmpty(discountAmount) && _.isNumber(discountAmount)) {
                discountAmount = Number(discountAmount);

            } else {
                setError(`tradeIn.${index}.discountAmount`, {
                    type: "manual",
                    message: "Discount Amount is required"
                });
                return
            }

            if (currentEndVal > currentStartVal) {
                append({
                    startVal: currentEndVal + 1,
                    endVal: '',
                    discountAmount: ''
                })
                clearErrors(`tradeIn.${index}.endVal`);
            }
            else {
                setError(`tradeIn.${index}.endVal`, {
                    type: "manual",
                    message: "Should be greater than start Value"
                });
                return;
            }
        } catch (error) {

        }
    }

    const removeRulesHandler = async (deletedIndex: number): Promise<void> => {
        const existingRules = [...tradeInCount];
        existingRules.splice(deletedIndex, 1);

        settradeInCount(existingRules);
        remove(deletedIndex);
    };

    return (
        <div className="editor" >
            <p className="section-heading">6. TradeIn and Exchange</p>
            <table className="tradein-exchange-table">
                <thead>
                    <th className="particulars">
                        <p>Start Value</p>
                    </th>
                    <th className="days">
                        <p>End Value</p>
                    </th>
                    <th className="percentage">
                        <p>Discount Amount</p>
                    </th>

                    {!tradeInfoPermission && fields.length > 1 && (
                        <th className="action">
                            <p>Action</p>
                        </th>
                    )}
                </thead>
                <tbody>
                    {fields?.map((ruleGroup: any, index: number) => {
                        return (
                            <tr key={index}>

                                <td data-label="StartValue" className="days">
                                    <InputField
                                        disabled={!tradeInfoPermission}
                                        helpertext="Start Value"
                                        type="number"
                                        value={watch(`tradeIn.${index}.startVal`)}
                                        name={`tradeIn.${index}.startVal`}
                                        register={register(`tradeIn.${index}.startVal` as const, {
                                            valueAsNumber: true,
                                            required: true
                                        })}
                                    // error={handleError(index, "startVal") as any}
                                    // hoverHandleText={watch(`tradeIn.${index}.startVal`)}
                                    />
                                    {errors?.tradeIn?.[index]?.startVal && (
                                        <p className="error-message">
                                            {errors.tradeIn?.[index]?.startVal?.message}
                                        </p>
                                    )}
                                </td>
                                <td data-label="endVal" className="endVal">
                                    <InputField
                                        disabled={!tradeInfoPermission}
                                        helpertext="End Value"
                                        type="number"
                                        value={watch(`tradeIn.${index}.endVal`)}
                                        name={`tradeIn.${index}.endVal`}
                                        register={register(`tradeIn.${index}.endVal` as const, {
                                            valueAsNumber: true,
                                            required: true
                                        })}
                                    // hoverHandleText={watch(`tradeIn.${index}.endVal`)}
                                    />
                                    {errors?.tradeIn?.[index]?.endVal && (
                                        <p className="error-message">
                                            {errors.tradeIn?.[index]?.endVal?.message}
                                        </p>
                                    )}
                                </td>

                                <td data-label="discountAmount" className="discountAmount">
                                    <InputField
                                        disabled={!tradeInfoPermission}
                                        helpertext="Discount Amount"
                                        type="number"
                                        // step={0.01}
                                        value={watch(`tradeIn.${index}.discountAmount`)}
                                        name={`tradeIn.${index}.discountAmount`}
                                        register={register(`tradeIn.${index}.discountAmount` as const, {
                                            valueAsNumber: true,
                                            required: true
                                        })}
                                    // error={handleError(index, "discountAmount") as any}
                                    // hoverHandleText={watch(`tradeIn.${index}.discountAmount`)}
                                    />
                                    {errors?.tradeIn?.[index]?.discountAmount && (
                                        <p className="error-message">
                                            {errors.tradeIn?.[index]?.discountAmount?.message}
                                        </p>
                                    )}
                                </td>
                                {tradeInfoPermission &&
                                    fields.length - 1 === index &&
                                    <button
                                        className="btn primary"
                                        type="button"
                                        // onClick={() => append2({ name: "" })}
                                        onClick={() => handleAppend(index)}
                                    >
                                        Add
                                    </button>
                                }
                                {tradeInfoPermission && index >= 0 && (
                                    <td className="action">
                                        <div>
                                            {index === fields.length - 1 && (
                                                <div

                                                    onClick={() => removeRulesHandler(index)}
                                                >
                                                    <Icon icon={faTrash} />
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
                {tradeInfoPermission &&
                    fields.length === 0 &&
                    <button
                        className="btn primary"
                        type="button"
                        onClick={() => append({ name: "" })}
                    >
                        Add
                    </button>
                }
            </table>
        </div>

    )
}

export default TradeInAndExchange