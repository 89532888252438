import { MultiSelectValues } from 'common/constant'
import DropDown from 'components/DropDown'
import React, { useEffect } from 'react'

function OfferChannel(props: any) {

    const { channel, setChannel, getSelectedProduct, product } = props;

    useEffect(() => {
          getSelectedProduct(product.erp_item_code)
      }, [channel])

    return (
        <div className='channel-section'>
            <div className='channel-drop'>
                <DropDown 
                onChange={(event) => setChannel(event.target.value)}
                 options={MultiSelectValues.channel} 
                 value={channel}
                 label="Channel" />

            </div>
        </div>
    )
}

export default OfferChannel