import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";
import { encodeGetParams } from "common/commonFunc";

export class FinanceListService extends BaseService {

    public async addFinanceList(formData: any, financeName: string): Promise<IAPIReponse> {

        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.FINANCE_BULK_UPLOAD}?financeName=${financeName}`, formData, {
                headers:
                {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getFinanceList(rowsPerPage: any, pageNo: any, search: any, filter: any, selectedBrand: any, channel:any, startDate?: any, endDate?: any): Promise<IAPIReponse> {
        try {
            
            const newObject = {
                limit: rowsPerPage,
                page: pageNo,
                search: search,
                financeName: filter,
                brand: selectedBrand,
                channel : channel === "All Channels" ? "" : channel,
                status: true,
                startDate:new Date(startDate),
                endDate:new Date(endDate)
            }
            const queryParams = encodeGetParams(newObject);

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_FINANCE_LIST}?${queryParams}`, {
                headers:
                    { 'authorization': `${localStorage.getItem('accessToken')}` }
            });
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async financeListName(): Promise<IAPIReponse> {
        try {
          const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_FINANCE_NAME_LIST}`);
          return data;
        } catch (error) {
          return parseAxiosError(error as AxiosError);
        }
    }

    public async deleteFinanceData(financeData: any): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.post(`${API_ENDPOINTS.MULTI_DELETE_FINANCE_LIST}`, {"ids":financeData});
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }


    public async getOffer(offerId: any): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.DELETE_FINANCE_LIST}/${offerId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async updateFinanceOffer(offerId: any = '', financeValues:any = {}): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.GET_FINANCE_LIST}/${offerId}`, financeValues);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}



