import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class CouponEventService extends BaseService {
    public async addCouponEvent(eventData:any):Promise<IAPIReponse>{
        try{
            const {data} = await this.httpClient.post(API_ENDPOINTS.COUPON_EVENT,eventData);
            return data;
        }
        catch(error){
            return parseAxiosError(error as AxiosError)
        }
    }
    public async getAllCouponEvents(page:number,limit:number):Promise<IAPIReponse>{
        try{
            const {data} = await this.httpClient.get(`${API_ENDPOINTS.COUPON_EVENT}?page=${page}&limit=${limit}`)
            return data
        }
        catch(error){
            return parseAxiosError(error as AxiosError)
        }
    }
    public async updateCouponEvent(eventData: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.put(`${API_ENDPOINTS.COUPON_EVENT}/${eventData._id}`, eventData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }
}