import React, { useContext, useEffect } from "react";
import InputField from "components/InputField";
import DateAndTimePicker from "components/DateTimePicker";
import DropDown from "components/DropDown";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import {
  OfferStatus,
} from "common/constant";

import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS } from "config/config";
import {  useForm } from "react-hook-form";
import { OffersGroupService } from "services/offerGroup.service";

type OffersInfoFormProps = {
  viewOnlyMode?: boolean;
};

const OfferGroupForm: React.FC<OffersInfoFormProps> = ({ viewOnlyMode }) => {
  const navigateTo = useNavigate();
  const location = useLocation();

  /* Context functionality*/
  const { showToaster, setMenuIndex, offerGroupData, setOfferGroupData, setSpinnerVisibility } =
    useContext(appContext);


  /* Form related hooks */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm();


  const offersGroupService = new OffersGroupService()

  /* Form submission Handler */
  const onSubmit = async (offerInfovalues: any): Promise<void> => {
    if (viewOnlyMode === false || viewOnlyMode === true) {
      const offerGroupResponse = await offersGroupService.updateOfferGroup({ ...offerInfovalues, offers: offerGroupData.offers, cartValue: offerGroupData.cartValue, _id:offerGroupData._id})
      if (offerGroupResponse.status === "success") {
        setOfferGroupData(offerGroupResponse.data.offerGroupData)
        showToaster(EToasterVariants.success, "Offer Group Updated Successfully");
      }
      else {
        showToaster(EToasterVariants.error, "Offer Group Updated failed");
      }
    }
    else {
      const offerGroupResponse = await offersGroupService.createNewOfferGroup(offerInfovalues)
      if (offerGroupResponse.status === "success") {
        setOfferGroupData(offerGroupResponse.data.offerGroupData)
        showToaster(EToasterVariants.success, "Offer Group Added Successfully");
      }
      else {
        showToaster(EToasterVariants.error, "Offer Group Updated failed");
      }
    }
    setMenuIndex(1);
  };

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const dateRangeSelectionHandler = async (
    dateFieldName: string,
    value: string
  ): Promise<void> => {
    if (dateFieldName === "startDate") {
      setValue("startDate", value);
    } else if (dateFieldName === "endDate") {
      setValue("endDate", value);
    }
  };

  const getOfferGroupDetails = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      let id = ""
      if (offerGroupData?._id) {
        id = offerGroupData?._id
      } else {
        id = location.pathname.split("/")[4]
      }

      const offerDetailsResponse = await offersGroupService.getOfferGroupDetails(id);
      if (offerDetailsResponse.status === STATUS.SUCCESS) {
        setSpinnerVisibility(false);
        const offerDataResult = { ...offerDetailsResponse.data.offerGroupData }
        setOfferGroupData(offerDataResult);
        /* Form Binding Actions */
        const {
          offerGroupId,
          offerGroupName,
          startDate,
          endDate,
          status
        } = offerDetailsResponse.data.offerGroupData;

        /* Offer Info */
        setValue("offerGroupId", offerGroupId);
        setValue("offerGroupName", offerGroupName);
        setValue("startDate", startDate);
        setValue("endDate", endDate);
        setValue("status", status);
      } else {
        setSpinnerVisibility(false);
        navigateTo("/offers/list");
        showToaster(EToasterVariants.error, "Unable to get Offers details");
      }
    } catch (error) {
      setSpinnerVisibility(false);
      navigateTo("/offers/list");
      showToaster(EToasterVariants.error, "Unable to get Offers details");
    }
  };

  const initializer = async (): Promise<void> => {
    /* Offer View mode handling */
    if (viewOnlyMode === false || viewOnlyMode === true || offerGroupData?._id) {
      await getOfferGroupDetails();
      return;
    }

    setValue("offerGroupId", String(Math.floor(Math.random() * 900000) + 100000));
  };
  useEffect(() => {
    initializer();
  }, []);

  useEffect(()=>{
    let publishStartDate = new Date(watch('startDate'))
    let publishEndDate = new Date(watch('endDate'))
    if(publishStartDate.getHours() == 0){
      const pubstartDate = publishStartDate.setHours(new Date().getHours(),new Date().getMinutes(),new Date().getSeconds());
      setValue("startDate", pubstartDate);
    }
    if(publishEndDate.getHours() == 0){
      const pubEndDate = publishEndDate.setHours(23,59,59);
      setValue("endDate", pubEndDate);
    }
  },[watch('startDate'),watch('endDate')])


  return (
    <div className="offers-info-form">
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e)=>{handleKeyDown(e)}}>
        <div className="sub-section">
          <p className="section-heading" id="offer-info">
            1. Offer Group Info
          </p>
          <div className="input-fields-wrapper">
            <InputField
              disabled={viewOnlyMode}
              helpertext="Offer Group Id"
              hint="(Offer group Id is Auto generated)"
              register={register("offerGroupId")}
              name="offerGroupId"
            />
            <InputField
              disabled={viewOnlyMode}
              helpertext="Offer Group Name"
              register={register("offerGroupName", {
                required: "Offer Group Name is required",
              })}
              name="offerGroupName"
              error={errors.offerGroupName?.message as any}
              mandatory
              hoverHandleText={watch("offerGroupName")}
            />
            <DropDown
              disabled={viewOnlyMode}
              options={OfferStatus}
              label="Status"
              name="status"
              register={register("status")}
            />
            <DateAndTimePicker
              disabled={viewOnlyMode}
              label="Start Date"
              name="startDate"
              value={watch("startDate")}
              onDateSelection={(date) =>
                dateRangeSelectionHandler("startDate", date)
              }
            />
            <DateAndTimePicker
              disabled={viewOnlyMode}
              label="End Date"
              name="endDate"
              value={watch("endDate")}
              onDateSelection={(date) =>
                dateRangeSelectionHandler("endDate", date)
              }
            />
          </div>
        </div>

        {!viewOnlyMode && <div className="buttons-wrapper">
          <button
            className="btn secondary"
            type="button"
            onClick={() => navigateTo("/offers/offer_grouping")}
          >
            Cancel
          </button>
          <button className="btn primary" type="submit">
            Save
          </button>
        </div>}
      </form>
    </div>
  );
};

export default OfferGroupForm;
