import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Input from "components/UseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
    faFilter,
    faPlus,
    faTrash,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import TablePagination from "@mui/material/TablePagination";
import Icon from "components/Icon";
import appContext from "context/app.context";
import { OffersGroupService } from '../../services/offerGroup.service';
import { EToasterVariants } from "services/types/common.type";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import { STATUS } from "config/config";
import { usePermission } from "components/Permission";
import DropDown from "components/DropDown";
import { OfferStatus } from "common/constant";

const OfferGroupingTable: React.FC = () => {
    let navigate = useNavigate();
    /* State Variables */
    const [offers, setOffers] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const offersGroupService = new OffersGroupService()
    const [offerId, setOfferId] = useState<string>("");
    const [deleteConfirmationDialog, setDeleteConfirmationDialogVisibility] =
        useState<boolean>(false);


    const getOfferGroupList = async () => {
        setSpinnerVisibility(true);
        const offerGroupResponse = await offersGroupService.getOffersGroupList(rowsPerPage, page)
        setSpinnerVisibility(false);
        if (offerGroupResponse.status === "success") {
            setOffers(offerGroupResponse.data.offerGroupList)
            setOfferGroupData(offerGroupResponse.data.offerGroupList)
            setTotalPages(offerGroupResponse.data.totalPages)

        }
    }


    const { setMenuIndex, showToaster, setSpinnerVisibility, setOfferGroupData } =
        useContext(appContext);

    const [search, setSearch] = useState<any>();
    const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
    const [filtersliderVisibility, setFilterSliderVisibility] =
        useState<boolean>(false);

    const [createAndEditOffersgroupPermission] = usePermission(["CREATE_AND_EDIT_OFFERSGROUP"])
    const [deleteOffersgroupPermission] = usePermission(["DELETE_OFFERS_GROUP"])
    const newOfferGroupHandler = () => {
        setMenuIndex(0);
        navigate("/offers/offer_grouping/add");
    }

    /* Pagination Actions */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [offerGroupStatusPermission] = usePermission(["EDIT_OFFERS_GROUP_STATUS"])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const showDeleteConfirmationHandler = (offerId: string) => {
        setOfferId(offerId);
        setDeleteConfirmationDialogVisibility(true);
    };

    const deleteConfirmationHandler = async (confirmation: boolean): Promise<void> => {
        if (confirmation) {
            try {
                const deleteOfferResponse = await offersGroupService.deleteOfferGroup(offerId);
                if (deleteOfferResponse.status === STATUS.SUCCESS) {
                    setDeleteConfirmationDialogVisibility(false);
                    showToaster(EToasterVariants.success, "Offer Group deleted successfully");
                    await getOfferGroupList();


                } else {
                    showToaster(EToasterVariants.error, "Unable to delete the Offer Group");
                }

            } catch (error) {
                showToaster(EToasterVariants.error, "Unable to delete the Offer Group");
            }
        } else {
            setDeleteConfirmationDialogVisibility(false);
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStatus = async (id: string, status: string) => {
        try {
            const statusChangeResponse = await offersGroupService.statusChange(id, status);
            if (statusChangeResponse.status === STATUS.SUCCESS) {
                showToaster(EToasterVariants.success, "Status Updated");
                await getOfferGroupList();
            } else {
                showToaster(EToasterVariants.error, statusChangeResponse.error.response.data.message);
            }
        } catch (error) {
            showToaster(EToasterVariants.error, "Unable to update Status");
        }
    };

    useEffect(() => {
        getOfferGroupList()
    }, [page, rowsPerPage]);

    const defaultLabelDisplayedRows = (props: any) => {
        return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
    }

    return (
        <div>
            <div className="offers-list-action-wrapper">
                <div className="offers-header">
                    <div className="action-button-wrapper">
                        <div>
                            {createAndEditOffersgroupPermission && <Button color={"#fb6c00"} onClick={newOfferGroupHandler}>
                                <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                                New Offer
                            </Button>}
                        </div>
                        {/* <div className="impt_offers dflex items-center">
              <FontAwesomeIcon icon={faArrowDown} />

              <p>Import Offers</p>
            </div> */}
                    </div>

                    <div className="icon_wpr dflex items-center justify-between w-82">
                        <div>
                            <Input className="inputwrp" value={search} placeholder="Search" />
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                        </div>
                        <div>
                            <FontAwesomeIcon
                                className="cursor-pointer sort-filter-icon"
                                icon={faFilter}
                            />
                        </div>
                    </div>
                </div>

                <table className="content-table offers-table" id="offertable">
                    <thead>
                        <tr>
                            <th>Offers Group ID</th>
                            <th>Offers Group Name</th>
                            <th>Start Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offers?.map((item: any, index: number) => (
                            
                            <tr 
                                className="cursor-pointer"
                                key={index}
                                onClick={()=>{navigate(`/offers/offer_grouping/edit/${item._id}`)}}
                                >
                                <td data-label="Offers Group ID" >{item.offerGroupId}</td>
                                <td data-label="Offers Group Name">{item.offerGroupName}</td>
                                <td data-label="Start Date">{moment(item.startDate).format("DD-MMM-YYYY hh:mm A")}</td>
                                <td data-label="status">
                                    {offerGroupStatusPermission?<div
                                        onClick={(e: any) => e.stopPropagation()}
                                        className="inner-dropdown"
                                    >
                                        <DropDown
                                            value={item.status}
                                            options={OfferStatus}
                                            label="Status"
                                            name="status"
                                            onChange={(e: any) =>
                                                handleStatus(item._id, e.target.value)
                                            }
                                        />
                                    </div> :
                           <div className={`status ${item.status.toLowerCase().replaceAll(" ", "_")}`}>{item.status}</div>}
                                </td>

                                <td data-label="Action" className="action-icons">
                                    <div onClick={() => navigate(`/offers/offer_grouping/edit/${item._id}`)}>
                                       <div className="action-inner-btn"><Icon icon={faCircleInfo} /></div> 
                                    </div>
                                    {item.status === "Draft" && <div onClick={(e: any) => {
                                        e.stopPropagation();
                                        showDeleteConfirmationHandler(item._id)
                                    }}>
                                        {deleteOffersgroupPermission &&<div className="delete-btn">
                                        <Icon icon={faTrash} />
                                        </div>}
                                    </div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination-wrapper">
                    <TablePagination
                        backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
                        nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
                        component="div"
                        count={totalPages}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>

                <div className={`slider-right ${sliderVisibility ? "show" : "hide"}`}>
                    <div className="add-product-slider">
                        <div className="slider-header">
                            <h1>Add Offers</h1>
                            <FontAwesomeIcon
                                className="action-icon"
                                icon={faXmark}
                                onClick={() => setSliderVisibility(!sliderVisibility)}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={`slider-right ${filtersliderVisibility ? "show" : "hide"}`}
                >
                    <div className="add-product-slider">
                        <div className="slider-header">
                            <h1>Add Filter</h1>
                            <FontAwesomeIcon
                                className="action-icon"
                                icon={faXmark}
                                onClick={() =>
                                    setFilterSliderVisibility(!filtersliderVisibility)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DeleteConfirmationDialog
                show={deleteConfirmationDialog}
                onConfirmation={deleteConfirmationHandler}
            />
        </div>
    );
};

export default OfferGroupingTable;
