import { encodeGetParams } from "common/commonFunc";
import { BaseService } from "./htttp";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { AxiosError } from "axios";
import { IAPIReponse } from "./interfaces/common.interface";

export class CouponProductService extends BaseService {
    public async getCouponsProductsList(couponId = '', rowsPerPage = 10, pageNo = 1, filter: any = {}, startDate: any = "", endDate: any = "", sortName: any = "", sort: any = "", isAll = ""): Promise<IAPIReponse> {
        try {
            const { search = "", offerType = "", status = "", brand = "", category = "" } = filter;

            const newObject = {
                limit: rowsPerPage,
                page: pageNo,
                search,
                offerType,
                status,
                brand,
                category,
                startDate,
                endDate,
                sortName,
                sort,
                isAll
            }
            const queryParams = encodeGetParams(newObject);
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.COUPONPRODUCTS}/${couponId}?${queryParams}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async addCouponProduct(productData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.post(`${API_ENDPOINTS.COUPONPRODUCTS}`, productData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async updateCouponProduct(couponId:string, productData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.COUPONPRODUCTS}/${couponId}`, productData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteCouponProduct(id:string): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.delete(`${API_ENDPOINTS.COUPONPRODUCTS}/${id}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteMultipleProduct(ids: [string]): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.MULTI_DELETE_COUPONPRODUCTS}`, { ids });
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getCouponProductById(id: string): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.COUPONPRODUCTS}/${id}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}