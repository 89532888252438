import React, { useContext, useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useForm, FieldError } from "react-hook-form";
import {
  ILoginForgot,
  ILoginError,
  ILoginForgotResponse,
} from "../../services/interfaces/login.interface";
import appContext from "../../context/app.context";
import { LoginService } from "../../services/login.service";
import { STATUS } from "../../config/config";
import { EToasterVariants } from "../../services/types/common.type";
import ResetPassword from "./ResetPassword";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm";

interface ForgotOtpValidateProps {
  email: string;
}

const ForgotOtpValidate: React.FC<ForgotOtpValidateProps> = ({ email }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [otp, setOtp] = useState("");
  const { setSpinnerVisibility, showToaster } = useContext(appContext);
  const loginService: LoginService = new LoginService();
  const [countdown, setCountdown] = useState(120);
  const [formattedCountdown, setFormattedCountdown] = useState("02:00");
  const [otpVerified, setOtpVerified] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    setFormattedCountdown(
      `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    );
  }, [countdown]);

  const handleOtpVerify = async () => {
    try {
      if (!otp) {
        showToaster(EToasterVariants.error, "Please enter OTP");
        return;
      }

      const encodedOtp = btoa(otp);
      const encodedEmail = btoa(email);
      setSpinnerVisibility(true);
      const loginResponse: ILoginForgot | ILoginError =
        await loginService.verifyOtp({
          otp: encodedOtp,
          email: encodedEmail,
        });

      if (loginResponse.status === STATUS.SUCCESS) {
        const { data } = loginResponse as ILoginForgotResponse;
        showToaster(EToasterVariants.success, loginResponse.message);
        setOtpVerified(true);
      } else {
        if (loginResponse.error) {
          showToaster(
            EToasterVariants.error,
            loginResponse.error.response.data.message
          );
        }
      }
    } catch (error) {
      console.log(error, "error");
      showToaster(EToasterVariants.error, "An error occurred");
    } finally {
      setSpinnerVisibility(false);
    }
  };

  const handleBackToLogin = () => {
    setShowLoginForm(true);
  };

  const handleResendOTP = () => {
    setCountdown(120);
  };

  const onForgotSubmit = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const loginResponse: ILoginForgot | ILoginError =
        await loginService.forgotOtp({ email });

      if (loginResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Password reset link sent!");
        setCountdown(120);
      } else {
        showToaster(EToasterVariants.error, "Failed to resend OTP");
      }
    } catch (error) {
      console.error(error);
      showToaster(EToasterVariants.error, "An error occurred");
    } finally {
      setSpinnerVisibility(false);
    }
  };

  if (otpVerified) {
    return <ResetPassword email={email} />;
  }
  if (showLoginForm) {
    return <LoginForm />;
  }

  return (
    <>
      <form
        className="loginForm-container"
        autoComplete="new-password"
        onSubmit={handleSubmit(handleOtpVerify)}
      >
        <div className="loginForm-container">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <input
              {...register("otp", {
                required: {
                  value: true,
                  message: "OTP is required",
                },
              })}
              id="otp"
              className="login-input-field-box"
              placeholder="Enter OTP"
              name="otp"
              value={otp}
              maxLength={6}
              onChange={(e) => setOtp(e.target.value)}
            />
            {errors.otp && typeof errors.otp === "object" && (
              <p className="forgot-otp-error">
                {(errors.otp as FieldError).message}
              </p>
            )}
            {countdown > 0 && (
              <p className="forgot-password-area forgot-otp-countdown">
                Time Left In: {formattedCountdown}
              </p>
            )}
            {countdown === 0 && (
              <div className="forgot-password-area">
                <Link
                  to="#"
                  className="forgot-password forgot-password-link"
                  onClick={() => {
                    handleResendOTP();
                    onForgotSubmit();
                  }}
                >
                  Resend OTP
                </Link>
              </div>
            )}
            <Button
              variant="contained"
              className={`${
                otp.length === 6 ? "verifyotp" : "verifyotpDisable"
              }`}
              onClick={handleOtpVerify}
              disabled={otp.length !== 6}
            >
              Verify OTP
            </Button>
          </Box>
        </div>
      </form>
      <div className="forgot-password-area forgot-otp-verify">
        <Link
          to="#"
          className="forgot-password forgot-password-link"
          onClick={handleBackToLogin}
        >
          Back to Login
        </Link>
      </div>
    </>
  );
};

export default ForgotOtpValidate;
