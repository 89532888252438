import { faPen, faXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import DropDown from "components/DropDown";
import Icon from "components/Icon";
import InputField from "components/InputField";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootStore } from "../../redux/store";
import { FinanceListNameService } from "services/financeListName.services";
import moment from "moment";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";

const FinanceNameList: React.FC = () => {
  /* State variables */
  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number>(0);
  const [
    deleteConfirmationDialogVisibilty,
    setDeleteConfirmationDialogVisibility,
  ] = useState<boolean>(false);

  /* Services */
  const financeListNameService = new FinanceListNameService();

  /* Context */
  const context = useContext(appContext);
  const { showToaster, setSpinnerVisibility } = useContext(appContext);

  /*User Details */
  const userEmail = useSelector<RootStore>(
    (state) => state.userDetails.userDetails.userEmail
  );

  /* Form hooks */
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();

  const sliderVisbilityHandler = async () => {
    reset();
    setSliderVisibility(!sliderVisibility);
    setEditMode(false);
  };

  const getUserList = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const FinanceNameListResponse =
        await financeListNameService.getAllFinanceName(page + 1, rowsPerPage);
      setSpinnerVisibility(false);
      if (FinanceNameListResponse.status === STATUS.SUCCESS) {
        setUserList(FinanceNameListResponse.data);
        setTotalPages(Math.ceil(FinanceNameListResponse.count / rowsPerPage));
      } else {
        context.showToaster(EToasterVariants.error, "Unable to get User List");
      }
    } catch (error) {
      context.showToaster(EToasterVariants.error, "Unable to get User List");
    }
  };

  /* Offer Type Edit Handler */
  const UserEditHanlder = async (
    data: any,
    FinanceIndex: number
  ): Promise<void> => {
    setEditMode(true);
    setSelectedUserIndex(FinanceIndex);
    unregister("password");
    const { financeName, status, priority } = data;
    setValue("financeName", financeName);
    setValue("financeListStatus", status);
    setValue("priority", priority);
    setSliderVisibility(true);
  };

  const deletePopupHandler = (FinanceIndex: number) => {
    setSelectedUserIndex(FinanceIndex);
    setDeleteConfirmationDialogVisibility(true);
  };

  /* Delete Confirmation handler */
  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      try {
        setSpinnerVisibility(true);

        const deleteResponse = await financeListNameService.deleteFinanceName(
          userList[selectedUserIndex]._id
        );

        if (deleteResponse.status === STATUS.SUCCESS) {
          setDeleteConfirmationDialogVisibility(false);
          await getUserList();
          setSpinnerVisibility(false);
          showToaster(EToasterVariants.success, "Record Deleted Successfully");
        }
      } catch (error) {
        setSpinnerVisibility(false);
        showToaster(EToasterVariants.error, "Unable to delete the record");
      }
    } else {
      setDeleteConfirmationDialogVisibility(false);
    }
  };

  const createUserHandler = async (values: any): Promise<void> => {
    try {
      context.setSpinnerVisibility(true);
      var userAction;

      if (editMode) {
        values = { ...values, modifiedBy: userEmail };
        userAction = await financeListNameService.updateFinanceName({
          ...values,
          financeName: values.financeName.trim(),
          status: values.financeListStatus,
          priority: values.priority,
          _id: userList[selectedUserIndex]._id,
        });
      } else {
        values = {
          ...values,
          financeName: values.financeName.trim(),
          status: values.financeListStatus,
          priority: values.priority,
        };
        userAction = await financeListNameService.addFinanceName(values);
      }

      if (userAction.status === STATUS.SUCCESS) {
        await sliderVisbilityHandler();
        await getUserList();
        const successMessage = editMode
          ? "Finance Name Updated Successfully!"
          : "Finance Name Created Successfully!";

        showToaster(EToasterVariants.success, successMessage);

        context.setSpinnerVisibility(false);
      } else if (
        userAction.error &&
        userAction.error.response &&
        userAction.error.response.status === 409
      ) {
        context.setSpinnerVisibility(false);
        context.showToaster(
          EToasterVariants.error,
          `${userAction.error.response.data.message}`
        );
      } else {
        context.setSpinnerVisibility(false);
        context.showToaster(
          EToasterVariants.error,
          `Unable to ${editMode ? "Update" : "Add"} User Details`
        );
      }
    } catch (error) {
      context.setSpinnerVisibility(false);
      context.showToaster(
        EToasterVariants.error,
        `unable to ${editMode ? "Update" : "Add"} User Details`
      );
    }
  };

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${
      props.count !== -1 ? props.count : `more than ${props.to}`
    }`;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const trimAndValidate = (value) => {
    const trimmedValue = value.trim();
    return trimmedValue.length > 0 ? trimmedValue : undefined;
  };

  useEffect(() => {
    getUserList();
  }, [page, rowsPerPage]);
  return (
    <div className="user-table">
      <div className="header">
        <h3> Finance Name List</h3>
        <button className="btn primary" onClick={sliderVisbilityHandler}>
          Create New Finance Name
        </button>
      </div>

      <table className="shadow permisssion-table">
        <thead>
          <tr>
            <th style={{ textAlign: "justify" }}>Name</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th style={{ textAlign: "justify" }}>Status</th>
            <th>Priority</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user: any, index: number) => {
            return (
              <tr>
                <td style={{ textAlign: "justify" }} data-label="financeName">
                  {user.financeName}
                </td>
                <td data-label="Created At">
                  {moment(user?.createdAt).format("DD-MM-YYYY hh:mm A")}
                </td>

                {moment(user?.createdAt).valueOf() ===
                moment(user?.updatedAt).valueOf() ? (
                  "-"
                ) : (
                  <td data-label="Updated At">
                    {user?.updatedAt
                      ? moment(user?.updatedAt).format("DD-MM-YYYY hh:mm A")
                      : "-"}
                  </td>
                )}

                <td
                  style={{ textAlign: "justify" }}
                  data-label="financeListStatus"
                >
                  {user.status ? "Active" : "InActive"}
                </td>
                <td data-label="priority">{user.priority}</td>
                <td data-label="Action" className="action">
                  <div onClick={() => UserEditHanlder(user, index)}>
                    <Icon icon={faPen} />
                  </div>
                  <div
                    className="delete-btn"
                    onClick={() => deletePopupHandler(index)}
                  >
                    <Icon icon={faTrash} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {userList.length > 0 ? (
        <TablePagination
          backIconButtonProps={
            page === 0 ? { disabled: true } : { disabled: false }
          }
          nextIconButtonProps={
            page + 1 === totalPages || totalPages === 0
              ? { disabled: true }
              : { disabled: false }
          }
          component="div"
          count={totalPages}
          labelDisplayedRows={defaultLabelDisplayedRows}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <>
          <div style={{ textAlign: "center", fontFamily: "ui-monospace" }}>
            No Records found
          </div>
        </>
      )}

      <div className={`slider-right ${sliderVisibility ? "show" : "hide"}`}>
        <div className="add-user-slider">
          <div className="slider-header">
            <h1>{editMode ? "Edit user Info" : "Create Finance Name"}</h1>
            <div onClick={sliderVisbilityHandler}>
              <Icon icon={faXmark} />
            </div>
          </div>

          <div className="form-wrapper">
            <form onSubmit={handleSubmit(createUserHandler)} autoComplete="off">
              <InputField
                helpertext="Name"
                mandatory={true}
                name="financeName"
                register={register("financeName", {
                  required: "Name is Required",
                  validate: {
                    notEmpty: (value) =>
                      !!trimAndValidate(value) || "Name cannot be empty",
                  },
                })}
                error={errors.financeName?.message as any}
              />
              <DropDown
                options={[
                  { name: "Active", value: true },
                  { name: "InActive", value: false },
                ]}
                label="Status"
                name="financeListStatus"
                register={register("financeListStatus")}
              />

              <InputField
                helpertext="Priority"
                name="priority"
                mandatory={true}
                register={register("priority", {
                  required: "Priority is Required",
                })}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={3}
                error={errors.priority?.message as any}
              />
              <div className="submitbutton">
                <div className="action-buttons">
                  <button
                    className="btn secondary cancelbtn"
                    type="button"
                    onClick={sliderVisbilityHandler}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="btn primary" type="submit">
                    {editMode ? "Save" : "Create"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeleteConfirmationDialog
        show={deleteConfirmationDialogVisibilty}
        onConfirmation={deleteConfirmationHandler}
      />
    </div>
  );
};

export default FinanceNameList;
