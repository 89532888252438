import React from 'react'

function CouponsHistory() {
  document.title = "Poorvika - Coupons History";
  
  return (
    <div>CouponsHistory</div>
  )
}

export default CouponsHistory