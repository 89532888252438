import "./styles.scss"
import noImageAvailable from "assets/images/no-image.png";


type TProp = {
    products: any;
    combooff: boolean;
}


export const ProductDisplay: React.FC<TProp> = ({ products, combooff }) => {


    return (
        <>
            {products?.map((product: any, index: any) => {
                return (
                    <>
                        <div className="product-multiField-box">
                            {index !== 0 && <div className="btn-Wrapper">
                                <p>+</p>
                            </div>}
                            <div className="product-multiField">
                                <div className="product-Img">
                                    <img className="combo-img" alt={"combo-img" + index} src={product?.image?.url || noImageAvailable} />
                                </div>
                                <div className="product-details">
                                    <p>{product?.model?.name}</p>
                                </div>

                                <div className="variant">

                                    {product.addVariant && product?.addVariant?.map((variantData: any, index: number) => {
                                        return (
                                            <>

                                                <p>(

                                                    {Object.keys(variantData["attr"])?.map((varian: any) => {
                                                        return (variantData?.attr[varian] + ',')
                                                    })}
                                                    {variantData?.erp_item_code ? variantData?.erp_item_code : "not provided"}
                                                    )</p>
                                                {index !== product?.addVariant?.length - 1 && <p>  |   </p>}
                                            </>
                                        )
                                    })}
                                </div>
                                <div className="product-price">
                                    <p className="lineThrough">{`Rs: ${product?.mrpPrice ? product?.mrpPrice : "Price Not Available"}`}</p>
                                    {product?.offerPrice && <p className="newPrice">{`Rs: ${product?.offerPrice} `}</p>}
                                </div>
                                {/* <span className="offer-name-items">
                                    <input type="checkbox"></input>
                                </span> */}
                            </div>
                        </div>


                    </>
                )
            })
            }

        </>
    )
}