import React, { useContext, useEffect, useState } from "react";
import { faHistory, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import { CouponsService } from "services/coupon.service";
import Icon from "components/Icon";
import { STATUS } from "config/config";
import { BulkProductUpload } from "components/BulkProductUpload";
import { useForm } from "react-hook-form";
import DialogboxforProducts from "components/ProductDialogbox";
import "./styles.scss";
import NoProducts from "components/NoProducts";
import DownloadTemplates from "components/DownloadeTemplates";
import { couponBulkUploadColumns } from "common/constant";
import { TablePagination } from "@mui/material";
import BulkUploadHistory from "components/BulkUploadHistory";
import { CouponProductService } from "services/couponProduct.service";
import { defaultLabelDisplayedRows } from "common/commonFunc";
import CouponProductList from "./CouponProductList";
import Checkbox from "components/Checkbox";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import { usePermission } from "components/Permission";
import { DiscountType } from "common/constant";

const AddCouponProducts = (props: any) => {
  const couponProductService = new CouponProductService();

  const [bulkVisibility, setBulkVisibility] = useState<boolean>(false);
  const [historyVisibility, setHistoryVisibility] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);

  const {
    showToaster,
    setMenuIndex,
    menuIndex,
    setSpinnerVisibility,
    offerData,
    viewOnlyMode,
  } = useContext(appContext);

  /* Pagination Actions */
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [productLists, setProductLists] = useState<any>([]);
  const [filter, setfilter] = useState<any>({});
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isSelectAll, setSelectAll] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [editProductPermission] = usePermission(["EDIT_COUPON_STATUS"]);
  const [deleteProductPermission] = usePermission(["DELETE_COUPONS"]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const goToPreviousFormHandler = () => {
    setMenuIndex(menuIndex - 1);
  };

  const downloadTemplateHandeler = () => {
    let template = couponBulkUploadColumns;
    // if (offerData?.discountType === DiscountType.Percentage) {
    //   template = couponBulkUploadColumns;
    // } else {
    //   template = couponBulkUploadColumnsWithoutMax;
    // }

    return template;
  };

  const getProductLists = async (offerId: string, filter = {}) => {
    setSpinnerVisibility(true);
    const apiResponse = await couponProductService.getCouponsProductsList(
      offerId,
      rowsPerPage,
      page,
      filter
    );
    if (apiResponse.status === STATUS.SUCCESS) {
      setProductLists(apiResponse.data);
      setTotalPages(apiResponse.count);
    } else {
      showToaster(EToasterVariants.error, "Unable to get coupon products");
    }
    setSpinnerVisibility(false);
  };

  const selectAllProduct = (checkedStatus: boolean) => {
    setSelectAll(checkedStatus);
    const result = productLists.map((item: any, index: number) => {
      return { ...item, checked: checkedStatus };
    });
    setProductLists(result);
  };

  const deleteProducts = () => {
    setDeleteDialog(true);
  };

  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    setSpinnerVisibility(true);
    if (confirmation) {
      try {
        const ids = selectedList.map((product: any) => product._id);
        const deleteFinanceResponse =
          await couponProductService.deleteMultipleProduct(ids);

        if (deleteFinanceResponse.status === STATUS.SUCCESS) {
          getProductLists(offerData._id, filter);
          setDeleteDialog(false);
          setSelectAll(false);
          showToaster(EToasterVariants.success, deleteFinanceResponse.message);
        } else {
          showToaster(EToasterVariants.error, "Unable to delete the Product");
        }
      } catch (error) {
        showToaster(
          EToasterVariants.error,
          "Unable to delete the Finance Offer"
        );
      }
    } else {
      setDeleteDialog(false);
    }
    setSpinnerVisibility(false);
  };

  useEffect(() => {
    if (offerData !== undefined) getProductLists(offerData._id, filter);
  }, [page, rowsPerPage, filter, offerData]);

  const selectedList = productLists.filter((prod: any) => prod.checked);

  return (
    <div className="add-couponproducts">
      {!viewOnlyMode && (
        <div className="btn-wrapper">
          <div
            className="btn-add-products"
            onClick={() => {
              setDialog(!dialog);
            }}
          >
            <FontAwesomeIcon className="" icon={faPlus} />
            <p>Add Products</p>
          </div>
          <div
            className="btn-add-products"
            onClick={() => setBulkVisibility(true)}
          >
            <FontAwesomeIcon className="" icon={faPlus} />
            <p>Bulk Upload</p>
          </div>
          <DownloadTemplates
            heading={downloadTemplateHandeler()}
            ReportTitle={"Coupons"}
            height={true}
          />
          <div
            className="btn-add-products"
            onClick={() => setHistoryVisibility(!historyVisibility)}
          >
            <FontAwesomeIcon className="" icon={faHistory} />
            <p>{!historyVisibility ? "Bulk History" : "See Products"}</p>
          </div>
        </div>
      )}
      {!historyVisibility && (
        <>
          {!viewOnlyMode && selectedList?.length > 0 && (
            <button
              onClick={deleteProducts}
              className="btn primary mr-2 cursor-pointer"
            >
              <Icon icon={faTrash} />{" "}
              {`Delete ${selectedList?.length} products`}
            </button>
          )}
          <div className="offers-table-table">
            <table className="content-table offers-table" id="offertable">
              <thead>
                <tr>
                  {!viewOnlyMode && (
                    <th>
                      <Checkbox
                        onChange={selectAllProduct}
                        value={isSelectAll}
                      />
                    </th>
                  )}
                  <th>Image</th>
                  <th>Variant Name</th>
                  <th>Item Code</th>
                  <th>Discount</th>
                  {offerData?.discountType === DiscountType.Percentage && (
                    <th>Maximum Discount</th>
                  )}
                  <th>Applicable From</th>
                  <th>Applicable To</th>
                  {!viewOnlyMode &&
                  (deleteProductPermission || editProductPermission) ? (
                    <th>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {productLists?.length > 0 ? (
                  productLists?.map((product: any, index: number) => {
                    return (
                      <CouponProductList
                        getProductLists={getProductLists}
                        productLists={productLists}
                        product={product}
                        filter={filter}
                        setProductLists={setProductLists}
                        setSelectAll={setSelectAll}
                      />
                    );
                  })
                ) : (
                  <NoProducts />
                )}
              </tbody>
            </table>
          </div>

          <div className="pagination-wrapper">
            <TablePagination
              backIconButtonProps={
                page === 1 ? { disabled: true } : { disabled: false }
              }
              nextIconButtonProps={
                page === (Math.ceil(totalPages / rowsPerPage) || 1)
                  ? { disabled: true }
                  : { disabled: false }
              }
              component="div"
              labelDisplayedRows={defaultLabelDisplayedRows}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
              page={page}
              count={Math.ceil(totalPages / rowsPerPage)}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <div className="btn-group-align">
            <button
              type="button"
              className="btn secondary"
              onClick={goToPreviousFormHandler}
            >
              Previous
            </button>
            <button
              type="submit"
              onClick={() => setMenuIndex(2)}
              className="btn primary mr-2"
            >
              Next
            </button>
          </div>
        </>
      )}

      <DeleteConfirmationDialog
        show={deleteDialog}
        onConfirmation={deleteConfirmationHandler}
      />

      {bulkVisibility && (
        <BulkProductUpload
          setBulkVisibility={setBulkVisibility}
          importVarient="coupons"
          offerTemplate={"coupons"}
        />
      )}

      {historyVisibility && (
        <BulkUploadHistory module="Coupons" Id={offerData?._id} />
      )}

      {dialog && (
        <DialogboxforProducts
          setDialog={setDialog}
          type={"coupons"}
          getProductLists={getProductLists}
          filter={filter}
        />
      )}
    </div>
  );
};

export default AddCouponProducts;
