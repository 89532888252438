import Checkbox from 'components/Checkbox'
import React, { useState } from 'react'
import "./styles.scss"
type Component1Props = {
  data?: any
  selectedProduct?: any
  setSelectedProduct?: any
}

export const ProductComponent: React.FC<Component1Props> = ({ data, selectedProduct, setSelectedProduct }) => {
  const [active, setActive] = useState<boolean>(false);

  const variantSelection = (variant: any) => {
    let temp = selectedProduct;
    (!temp?.addVariant && (temp.addVariant = []))

    if (!active) {
      temp?.addVariant?.push(variant)
    }
    else {
      temp.addVariant = temp?.addVariant?.filter((x: any) => {
        return x?._id !== variant?._id
      })
    }
   
    setSelectedProduct(temp)
    setActive(!active);
  }

  return (


    <div className='product-variant2' >
      <div className='checkBox' onClick={() => variantSelection(data)}>
        <Checkbox value={active} />
      </div>
      {Object.keys(data.attr).map((variant: any) => {
        return (
          <div className='product-varient-values'>
            <p>{data.attr[variant]}</p>
          </div>
        )
      })}
    </div >
  )
}

export default ProductComponent