import { toLocaleNumber } from "common/commonFunc";
import "./styles.scss";
interface ProductOfferProp {
    paymentOffers: [];
    setPaymentOffers: any,
    product: any
}

export default function PaymentOffers(props: ProductOfferProp) {
    const { paymentOffers, setPaymentOffers } = props;

    const handleOfferAction = (offerId: string, value: string) => {

        const checked_offer: any = paymentOffers.map((item: any) => {
            if (item.offerId === offerId) {
                item[value] = !item[value];

                if (value === 'showTc') {
                    item.showBan = false;
                }
                else if (value === 'showBan') {
                    item.showTc = false;
                }

            }
            else if (value === 'checked')
                item[value] = false;
            return item;
        });

        setPaymentOffers(checked_offer);
    };

   

    return (
        <div className="offer-rows">

            {                paymentOffers.map((offer: any) => {
                    return (
                        <div className="offer-row">
                            <div className="offer-details">
                                <div className="offer-list">
                                    <div className="offer-names">
                                        <span className="offer-name-items">
                                            <input disabled={offer.offerSelection === 'mandatory'} name={"payment_offer"} onClick={() => handleOfferAction(offer.offerId, 'checked')} checked={offer.checked} type="radio"></input></span>
                                        <span className="offer-name-items">{offer?.offerName}</span>

                                        {
                                            offer.termsConditions && <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showTc')}>{`T&C`}</span>
                                        }
                                        {
                                            offer.bannerDiscription && <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showBan')}>{`Details`}</span>
                                        }


                                    </div>

                                    <div className="offer-prices">
                                        {
                                            (offer.offerType !== 'Offer Text' && offer.offerType !== 'Standard EMI') &&
                                            <span className={!offer.checked ? 'text-strike' : ''}> {toLocaleNumber(offer?.maxBenefites)}</span>
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className="offer-details">
                                {offer.showTc &&
                                    <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }}>
                                    </div>
                                }
                                {offer.showBan &&
                                    <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }}>
                                    </div>
                                }
                            </div>

                        </div>
                    )
                })
            }
        </div>
    );
}
