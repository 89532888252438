import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class SettingsService extends BaseService {
    public async addAttribute(attribute: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_ATTRIBUTE, attribute);
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }


    public async updateAttribute(attribute: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.put(API_ENDPOINTS.UPDATE_ATTRIBUTE, attribute);
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

    public async getAttributes(reqData?: { page?: number, pageLimit?: number, rule?: boolean, offergroup?: boolean }): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ATTRIBUTES}&pageSize=${reqData?.pageLimit || ""}&pageNo=${reqData?.page || ""}&isRule=${reqData?.rule === undefined ? "" : reqData.rule}&isOffergroup=${reqData?.offergroup === undefined ? "" : reqData.offergroup}`);
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

    public async deleteAttribute(_id: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.delete(API_ENDPOINTS.DELETE_ATTRIBUTES, { data: { _id } });
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

    public async attributeCodeCheck(code: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.CODE_ATTRIBUTE + `/${code}`);
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

    public async setTradeIn(name:string, values: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SETTINGS_TRADEIN, { name, values });
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

    public async getTradeIn(name: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.SETTINGS_TRADEIN}?name=${name}`);
            return data;

        } catch (error) {

            return parseAxiosError(error as AxiosError)
        }
    }

}