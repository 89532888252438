import { BaseService } from "./htttp";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { AxiosError } from "axios";
import { IAPIReponse } from "./interfaces/common.interface";
import { encodeGetParams } from "common/commonFunc";

export class PublishService extends BaseService {
  public async publishObject(publishData: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(
        `${API_ENDPOINTS.PUBLISH}`,
        publishData
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getPublishObjects(filterData: any): Promise<IAPIReponse> {
    try {
      const queryParams = encodeGetParams(filterData);
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.PUBLISH}?${queryParams}`
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async deletePublish(id: string): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.delete(
        `${API_ENDPOINTS.PUBLISH}/${id}`
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async deletePublishMultiple(publishData: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(`${API_ENDPOINTS.PUBLISH}`, {
        ids: publishData,
      });
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async updateRecord(publishId: string): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.PUBLISH}/${publishId}`
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
}
