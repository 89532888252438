import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";
import { IUploadHistory } from "./interfaces/coupon.interface";
import { objectToQueryString, removeFalsy } from "common/constant";


export class HistoryService extends BaseService {

    public async getHistoryList(rowsPerPage?: number, pageNo?: number, offerId?: string, couponId?: string, module?: string, operation?: string, filterValue?: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.HISTORY}/${rowsPerPage}/${pageNo}?offerId=${offerId || ""}&couponId=${couponId || ""}&module=${module || ""}&operation=${operation || ""}&startDate=${filterValue?.startDate || ""}&endDate=${filterValue?.endDate || ""}`,);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getUploadHistory(offerData: IUploadHistory): Promise<IAPIReponse> {
        try {
            // removeFalsy method is used for removing empty params .
            // objectToQueryString method is used for converting an object to the query string.
            const payload = objectToQueryString(removeFalsy(offerData))
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_UPLOAD_HISTORY}${payload}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
}
