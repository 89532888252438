import { AxiosError } from "axios";
// import * as _ from "lodash";
import { store } from "../redux/store";
import { API_ENDPOINTS, parseAxiosError } from "../config/config";
import { LOGIN } from "../redux/actions/UserActionTypes";
import { IAPIReponse } from "./interfaces/common.interface";
import { ILoginCredentials } from "./interfaces/login.interface";
import { BaseService } from "./htttp";
import {
  IErrorResponse,
  ISuccessResponse,
} from "./interfaces/common.interface";

export class LoginService extends BaseService {
  public async login(
    credentials: ILoginCredentials
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.LOGIN,
        credentials
      );
      data.data.Permission = data.data.allowedPermission;
      store.dispatch({
        type: LOGIN,
        payload: data.data,
      });
      console.log(data.data.allowedPermission, "allowedPermission");
      console.log(data.data);
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async ssoLogin(
    token: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.SSO_LOGIN, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      data.data.Permission = data.data.allowedPermission;
      store.dispatch({
        type: LOGIN,
        payload: data.data,
      });
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async forgotOtp(email: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(
        `${API_ENDPOINTS.FORGOT_PWD}`,
        email
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async verifyOtp(datas: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(
        `${API_ENDPOINTS.VERIFY_OTP}`,
        datas
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async resetPassword(datas: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(
        `${API_ENDPOINTS.RESET_PWD}`,
        datas
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
}
