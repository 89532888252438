import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class CrmService extends BaseService {
    public async getCustomerDetails(searchText: string): Promise<IAPIReponse> {
        try {
            const params = {
                headers: {
                    "Authorization": API_ENDPOINTS.CRM_KEY || "",
                }
            }
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_CUSTOMER_SEARCH}${searchText}`, params);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
}
