import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import React from "react";

type Tprops = {
    reportTitle: string;
    data: any[];
    buttonName?: string
};
const CSVDownloadGenerator: React.FC<Tprops> = ({ reportTitle, data, buttonName }) => {

    const JSONToCSVConvertor = () => {
        let csvText = ""
        const tempHeading: any = []
        data?.forEach((headings: any) => tempHeading?.push(...Object?.keys(headings)))
        const headings = [...new Set(tempHeading)]
        csvText += [...new Set(headings)]?.toString()
        let newLine: any[] = []
        newLine.length = headings.length
        csvText += `\n` + newLine.toString() // Creating a gap between heading and the data.
        data?.forEach((keyValues: any) => {
            csvText += `\n`
            headings?.forEach((keys: any, index: number) => {
                csvText += (index !== 0 ? "," : "") + (typeof keyValues[keys] === "object" ? JSON?.stringify(keyValues[keys]) : keyValues[keys] ?? "")
            })
        })
        const uri = "data:text/csv;charset=utf-8," + encodeURI(csvText);
        const link = document.createElement("a");
        link.href = uri;
        link.download = reportTitle + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={"download-product-btn"} onClick={() => JSONToCSVConvertor()}>
            <FontAwesomeIcon className="" icon={faArrowDown} />
            <p>{buttonName ?? `Download`}</p>
        </div>
    );
};

export default CSVDownloadGenerator;
