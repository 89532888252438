import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { CouponsService } from "services/coupon.service";
import { TablePagination } from "@mui/material";
import Input from "components/UseInput";
import { removeFalsy } from "common/constant";
import { IGetUserByCouponId } from "services/interfaces/coupon.interface";

const CouponTransaction = (props: any) => {

    document.title = "Poorvika - Coupons";

    let navigateTo = useNavigate();
    /* State variables */

    const [couponTransaction, setCouponTransaction] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);


    /* Context  */
    const { showToaster, setMenuIndex, offerData } = useContext(appContext);

    /* Services */
    const couponsService = new CouponsService();


    const getTransactionsByCouponId = async (search?: string): Promise<void> => {
        try {
            const payload: IGetUserByCouponId = {
                couponId: offerData?._id,
                page: page,
                limit: rowsPerPage,
            }
            if (search) payload.search = search?.trim()
            const couponListResponse = await couponsService.getTransactionsByCouponId(removeFalsy(payload));
            if (couponListResponse.status === STATUS.SUCCESS) {
                setCouponTransaction(couponListResponse?.data)
                setTotalPages(couponListResponse?.data?.totalCount)
            } else {
                showToaster(EToasterVariants.error, "Unable to get user List");
            }
        } catch (error: any) {
            showToaster(EToasterVariants.error, error?.message);
        }
    };

    const goToHomeFormHandler = () => {
        setMenuIndex(0);
        navigateTo("/offers/coupons/list");
    }

    const defaultLabelDisplayedRows = (props: any) => {

        return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    useEffect(() => {
        getTransactionsByCouponId()
    }, [page, rowsPerPage])

    return (
        <div className="coupon-transactions">
            <div className="search-warp">
                <h1 className="heading">Coupon  Transactions</h1>
                <Input
                    className="inputwrp"
                    placeholder="Search Coupon Name"
                    onChange={getTransactionsByCouponId}
                />
            </div>

            <table className="shadow couponinner-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Order ID</th>
                        <th>Coupon Code</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {couponTransaction?.transactionsData?.length > 0 ? couponTransaction?.transactionsData?.map((coupons: any, index: number) => {
                        return (
                            <tr key={index} className="cursor-pointer">
                                <td data-label="Name">{coupons?.customerName ?? "-"}</td>
                                <td data-label="Mobile">{coupons?.customerMobile ?? '-'}</td>
                                <td data-label="Email">{coupons?.customerEmail ?? '-'}</td>
                                <td data-label="Order ID">{coupons?.orderId ?? '-'}</td>
                                <td data-label="Coupon Code">{coupons?.couponCode ?? '-'}</td>
                                <td data-label="Status">{coupons?.status ?? "-"}</td>
                            </tr>
                        );
                    }) : <tr> <td colSpan={3}>No data found !</td></tr>}
                </tbody>
            </table>
            <TablePagination
                backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
                nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
                component="div"
                count={Math.ceil(totalPages / rowsPerPage)}
                labelDisplayedRows={defaultLabelDisplayedRows}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div className="btn-group-align">
                <button type="button" className="btn secondary" onClick={goToHomeFormHandler}>
                    Back to Home
                </button>
            </div>
        </div>
    );
}

export default CouponTransaction;