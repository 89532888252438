import InputField from 'components/InputField';
import { useForm } from 'react-hook-form';
import noImageAvailable from "assets/images/no-image.png";
import { useContext, useEffect, useState } from 'react';
import appContext from 'context/app.context';
import { DiscountType, OfferType } from 'common/constant';
import DateAndTimePicker from 'components/DateTimePicker';
import Icon from 'components/Icon';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { EToasterVariants } from 'services/types/common.type';
import { ProductService } from 'services/product.service';
import { STATUS } from 'config/config';
import DeleteConfirmationDialog from 'containers/Dialogs/DeleteConfirmationDialog';
import Checkbox from 'components/Checkbox';
import { usePermission } from 'components/Permission';

const OfferProducts = (props: any) => {
    const { product = {}, viewOnlyMode, getProductLists, filter, productLists = [], setProductLists, isSelectAll, setSelectAll } = props;
    const { _id = '', checked = false } = product;

    const { offerData, setOfferData, showToaster, setSpinnerVisibility } = useContext(appContext);
    const [editProductPermission] = usePermission(["EDIT_OFFER_PRODUCTS"]);
    const [deleteProductPermission] = usePermission(["DELETE_OFFER_PRODUCTS"])

    const [deleteDialog, setDeleteDialog] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm();

    const productServices = new ProductService();



    /* Delete Confirmation handler */
    const deleteConfirmationHandler = async (
        confirmation: boolean
    ): Promise<void> => {
        if (confirmation) {
            try {
                setSpinnerVisibility(true);
                const apiResponse = await productServices.deleteOfferProduct(
                    _id
                );
                if (apiResponse.status === STATUS.SUCCESS) {
                    setDeleteDialog(false);
                    setOfferData(apiResponse.data)
                    await getProductLists(offerData._id, filter);
                    setSpinnerVisibility(false);
                    showToaster(EToasterVariants.success, "Record Deleted Successfully");
                }
            } catch (error) {
                setDeleteDialog(false);
                setSpinnerVisibility(false);
                showToaster(EToasterVariants.error, "Unable to delete the record");
            }
        } else {
            setDeleteDialog(false);
        }
    };


    const onSubmit = async (offerInfovalues: any): Promise<void> => {
        try {
            setSpinnerVisibility(true);

            let { name, erp_item_code, image, imageURL, itemCode, varientName, minValue, maxValue, startDate, endDate, discountValue, maxDiscountValue } = offerInfovalues;
            const offerStartDate = moment(offerData.startDate);
            const offerEndDate = moment(offerData.endDate);
            const offerDiscountValue = offerData.discountValue;
            const prodStartDate = startDate ? moment(startDate) : moment(offerData.startDate)
            const prodEndDate = endDate ? moment(endDate) : moment(offerData.endDate)

            startDate = startDate ? startDate : offerStartDate;
            endDate = endDate ? endDate : offerEndDate;

            const { brand = {}, category = {} } = product;
            if (discountValue && offerDiscountValue < discountValue) {
                showToaster(
                    EToasterVariants.error,
                    "Discount value should be lesser than Offer's Discount value"
                );
                setSpinnerVisibility(false);

                return
            }
            if (offerStartDate > prodStartDate) {
                showToaster(
                    EToasterVariants.error,
                    "Start Date should be lesser than Offer start Date"
                );
                setSpinnerVisibility(false);

                return;

            }
            if (prodEndDate.isAfter(offerEndDate)) {
                showToaster(
                    EToasterVariants.error,
                    "End Date should be lesser than Offer end Date"
                );
                setSpinnerVisibility(false);

                return;
            }

            const productData = {
                varientName: name ? name : varientName,
                itemCode: name ? erp_item_code : itemCode,
                imageURL: name ? image?.all.url : imageURL,
                minValue,
                maxValue,
                startDate,
                endDate,
                discountValue,
                maxDiscountValue,
                brand,
                category
            }

            const apiResponse = await productServices.updateOfferProduct(_id, productData);

            if (apiResponse.status === STATUS.SUCCESS) {
                setSpinnerVisibility(false);
                showToaster(EToasterVariants.success, "Offer Product updated successfully")
                getProductLists(offerData._id, filter);
            }
        } catch (error) {
            console.log(error);

        }


    };

    const selectCheckBox = (checkedStatus: boolean) => {
        let isAll = true;
        const updatedProducts = productLists.map((product: any) => {
            if (product._id === _id) {
                product.checked = checkedStatus
            }
            if (!product.checked) {
                isAll = false;
            }
            return product;
        })
        setSelectAll(isAll)
        setProductLists(updatedProducts);
    }

    useEffect(() => {
        if (product) {
            let { minValue, maxValue, startDate, endDate, discountValue, maxDiscountValue } = product;
            setValue('discountValue', discountValue)
            setValue('maxDiscountValue', maxDiscountValue)
            setValue('minValue', minValue)
            setValue('maxValue', maxValue)
            setValue('startDate', startDate)
            setValue('endDate', endDate)
        }

    }, [product])

    return (
        <tr>
            {
                !viewOnlyMode && deleteProductPermission &&
                <td
                    data-label="Item Code"
                    className="checkbox_wrap"
                    onClick={(e) => e.stopPropagation()}
                >
                    <Checkbox
                        key={_id}
                        value={checked}
                        onChange={selectCheckBox}
                    />
                </td>
            }

            <td data-label="Image">
                <img
                    src={product?.imageURL ? product.imageURL : noImageAvailable}
                    alt="product"
                    className="product-image"
                />
            </td>

            <td data-label="Variant Name">
                {product?.name ?? product?.varientName}
            </td>
            <td data-label="Item Code">
                {product?.erp_item_code ?? product?.itemCode}
            </td>
            {offerData?.selectedOfferType?.offerValues === OfferType.InstantDiscount &&
                offerData?.selectedOfferType?.offerTypes !== 'Offer Text' && <>
                    <td data-label="Discount">
                        <div className="discount-fields">
                            <InputField
                                disabled={viewOnlyMode || !editProductPermission}
                                helpertext={offerData?.discountUnit === DiscountType.Percentage ? "Discount %" : "Discount Value"}
                                name="discountValue"
                                type="number"
                                step={0.01}

                                register={register(`discountValue`, {
                                    max: {
                                        value: offerData?.selectedOfferType?.offerTypes !== 'TradeIn and Exchange' ? Number(offerData?.discountValue) : Infinity,
                                        message: "Discount Value Limit Exceeds",
                                    }
                                })}

                                error={errors[`discountValue`]?.message as any}
                            />
                        </div>
                    </td>
                    {
                        offerData?.selectedOfferType?.offerTypes !== 'TradeIn and Exchange' &&
                        offerData?.selectedOfferType?.offerTypes !== 'Offer Text' &&
                        <>
                            <td data-label="MaxDiscount">
                                <div className="discount-fields">
                                    <InputField
                                        disabled={viewOnlyMode || !editProductPermission}
                                        helpertext={"Max Discount"}
                                        type="number"
                                        step={0.01}
                                        register={register(`maxDiscountValue`)}
                                        error={errors[`maxDiscountValue`]?.message as any}
                                    />
                                </div>
                            </td>
                            <td data-label="Minimum & Maximum Value">
                                <div className="min-max-fields">
                                    <InputField
                                        disabled={viewOnlyMode || !editProductPermission}
                                        helpertext="Minimum Value"
                                        type="number"
                                        step={0.01}
                                        register={register(`minValue`)}
                                        error={errors[`minValue`]?.message as any}
                                    />
                                </div>
                            </td>
                            <td>
                                <InputField
                                    disabled={viewOnlyMode || !editProductPermission}
                                    helpertext="Maximum Value"
                                    register={register(`maxValue`)}
                                    type="number"
                                    step={0.01}
                                    error={errors[`maxValue`]?.message as any}
                                />
                            </td>
                        </>
                    }
                </>}
            <td data-label="Applicable From - To">
                <div className="start-end-date-fields">
                    <DateAndTimePicker
                        onDateSelection={(date) => {
                            setValue(
                                `startDate`,
                                date
                            );
                        }}
                        value={watch('startDate')}
                        disabled={viewOnlyMode || !editProductPermission}
                        label="Start Date"
                        name="startDate"
                        minDateTime={offerData.startDate}
                    />

                </div>
            </td>
            <td>
                <DateAndTimePicker
                    onDateSelection={(date) => {
                        setValue(
                            `endDate`,
                            date
                        );
                    }}
                    value={watch('endDate')}
                    disabled={viewOnlyMode || !editProductPermission}
                    label="End Date"
                    name="endDate"
                    minDateTime={offerData.startDate}
                    maxDateTime={offerData.endDate}
                />
            </td>

            {!viewOnlyMode && (deleteProductPermission || editProductPermission) ? (
                <>
                    {
                        deleteProductPermission && <td>
                            <div
                                className="cursor-pointer"
                                onClick={() => setDeleteDialog(true)}
                            >
                                <Icon icon={faTrashCan} />
                            </div>
                        </td>
                    }
                    {
                        editProductPermission && <td>
                            <button
                                className="btn primary mr-2 cursor-pointer"
                                type='submit'
                                onClick={handleSubmit(onSubmit)}
                            >
                                Update
                            </button>
                        </td>
                    }

                </>
            ) : null
            }

            <DeleteConfirmationDialog show={deleteDialog} onConfirmation={deleteConfirmationHandler} />
        </tr>
    )
}

export default OfferProducts