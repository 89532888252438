import appContext from "context/app.context";
import React, { useContext, useEffect } from "react";
import FreebiesPage  from "../FreeOfferPage";
import DynamicRulesForm from "../Forms/DynamicRulesForm";
import OffersInfoForm from "../Forms/OffersInfoForm";
import ComboOfferPage from "../ComboOfferPage";
import AddProducts from "./AddProducts";

import "./styles.scss";

// const SubMenus = ["INFO", "PRODUCTS", "RULES"];
let SubMenus = ["INFO", "PRODUCTS"];



const AddOffersPage: React.FC = () => {
  const { menuIndex, offerData, setOfferData } = useContext(appContext);

  useEffect(() => {
    if (offerData?.selectedOfferType?.offerValues === "Freebies") {
      SubMenus = ["INFO", "PRODUCTS", "FREEBIES", "RULES"]
    }
    else if (offerData?.selectedOfferType?.offerValues === "ComboOffer") {
      SubMenus = ["INFO", "PRODUCTS", "COMBOOFFER", "RULES"]
    }
    else if (offerData?.selectedOfferType?.offerValues === "AllProduct") {
      SubMenus = ["INFO", "RULES"]
    }
    else {
      SubMenus = ["INFO", "PRODUCTS", "RULES"]
    }
  }, [offerData])

  useEffect(() => {
    setOfferData({})
  }, [])

  return (
    <div className="offers-page">
      <div className="offers-page-heading">
        <h3 className="offers-page-heading-label">{offerData?.offerName ? offerData?.offerName : "Add Offers"}</h3>
        {offerData?.status ? <p className={`offers-page-heading-status ${offerData?.status?.toLowerCase()?.replaceAll(" ", "_")}`}>{offerData?.status}</p> : <p className="offers-page-heading-status">Not Saved</p>}
      </div>
      <div className="tabs">
        {SubMenus?.map((menu: string, index: number) => {
          return (
            <div
              className={menuIndex === index ? "active" : ""}
            // onClick={() => setActiveTab(index)}
            >
              <p>{menu}</p>
            </div>
          );
        })}
      </div>
      <div className="add-offers-page-content">
        {menuIndex === 0 && <div className="offer-info shadow"> <OffersInfoForm /></div>}
        {menuIndex === 1 && !(offerData?.selectedOfferType?.offerValues === "AllProduct") && <div className="offer-info shadow"> <AddProducts /></div>}
        {menuIndex === 1 && (offerData?.selectedOfferType?.offerValues === "AllProduct") && (
          <div className="offer-info shadow">
            <DynamicRulesForm autoPopulate={false} />
          </div>
        )}
        {/* {menuIndex === 2 && <div className="offer-info shadow"> <DynamicRulesForm /></div>} */}

        {menuIndex === 2 && SubMenus.length == 4 && (
          <div className="offer-info shadow">
            {offerData?.selectedOfferType?.offerValues === "Freebies" && <FreebiesPage />}
            {offerData?.selectedOfferType?.offerValues === "ComboOffer" && <ComboOfferPage />}
          </div>
        )}

        {menuIndex === 3 && SubMenus.length == 4 && (
          <div className="offer-info shadow">
            <DynamicRulesForm autoPopulate={false} />
          </div>
        )}
        {menuIndex === 2 && SubMenus.length == 3 && (
          <div className="offer-info shadow">
            <DynamicRulesForm autoPopulate={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOffersPage;
