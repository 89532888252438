import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { CouponsService } from "services/coupon.service";
import moment from "moment";
import { TablePagination } from "@mui/material";
import Input from "components/UseInput";
import { removeFalsy } from "common/constant";
import { IGetUserByCouponId } from "services/interfaces/coupon.interface";
import CSVDownloadGenerator from "components/CSVDownloadGenerator";
import Icon from "components/Icon";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";

const CouponAssigned = (props: any) => {
  document.title = "Poorvika - Coupons";

  let navigateTo = useNavigate();
  /* State variables */

  const [couponsAssigned, setCouponsAssigned] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [couponId, setCouponId] = useState<string>("");

  /* Context  */
  const { showToaster, setMenuIndex, offerData } = useContext(appContext);
  const [deleteConfirmationDialog, setDeleteConfirmationDialogVisibility] =
    useState<boolean>(false);

  /* Services */
  const couponsService = new CouponsService();

  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      try {
        const deleteOfferResponse =
          await couponsService.deleteCouponCodeForCustomer(couponId);
        if (deleteOfferResponse.status === STATUS.SUCCESS) {
          setDeleteConfirmationDialogVisibility(false);
          showToaster(EToasterVariants.success, deleteOfferResponse.message);
          await getUserByCouponId();
        } else {
          showToaster(EToasterVariants.error, "Unable to delete the Offer");
        }
      } catch (error) {
        showToaster(EToasterVariants.error, "Unable to delete the Offer");
      }
    } else {
      setDeleteConfirmationDialogVisibility(false);
    }
  };

  const showDeleteConfirmationHandler = (couponId: string) => {
    setCouponId(couponId);
    setDeleteConfirmationDialogVisibility(true);
  };

  const getUserByCouponId = async (search?: string): Promise<void> => {
    try {
      const payload: IGetUserByCouponId = {
        couponId: offerData?._id,
        page: page,
        limit: rowsPerPage,
      };
      if (search) payload.search = search?.trim();
      const couponListResponse = await couponsService.getUserByCouponId(
        removeFalsy(payload)
      );
      if (couponListResponse.status === STATUS.SUCCESS) {
        setCouponsAssigned(couponListResponse?.data);
        setTotalPages(couponListResponse?.data?.totalCount);
      } else {
        showToaster(EToasterVariants.error, "Unable to get user List");
      }
    } catch (error: any) {
      showToaster(EToasterVariants.error, error?.message);
    }
  };

  const goToHomeFormHandler = () => {
    setMenuIndex(0);
    navigateTo("/offers/coupons/list");
  };

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${
      props.count !== -1 ? props.count : `more than ${props.to}`
    }`;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getUserByCouponId();
  }, [page, rowsPerPage]);

  return (
    <div className="apply-coupon">
      <div className="search-warp">
        <Input
          className="inputwrp"
          placeholder="Search Coupon Name"
          onChange={getUserByCouponId}
        />
      </div>
      <div className="commondata">
        <p className="summary-content">
          Coupon Status:{" "}
          <span className={offerData?.status}>{offerData?.status}</span>
        </p>
        <p className="summary-content">
          Discount Type: <span>{offerData?.discountType}</span>
        </p>
        <p className="summary-content">
          Discount Value: <span>{offerData?.discountValue}</span>
        </p>
        <p className="summary-content">
          Validity Start Date:{" "}
          <span>
            {moment(offerData?.validityStartDate).format("DD-MMM-YYYY hh:mm A")}
          </span>
        </p>
        <p className="summary-content">
          Validity End Date:{" "}
          <span>
            {moment(offerData?.validityEndDate).format("DD-MMM-YYYY hh:mm A")}
          </span>
        </p>
        {couponsAssigned?.customerData?.length > 0 && (
          <CSVDownloadGenerator
            buttonName={"Download Coupons"}
            reportTitle={"CouponList"}
            data={couponsAssigned?.customerData?.map(
              (coupon: any, index: number) => {
                return {
                  "S.No": index + 1,
                  Name: coupon?.customerName,
                  Mobile: coupon?.customerMobile,
                  Email: coupon?.customerEmail,
                  "Coupon code": coupon?.couponCode,
                  "Customer ID": coupon?.customerId,
                  "Created Date & Time": moment(coupon.createdAt).format(
                    "DD-MMM-YYYY hh:mm A"
                  ),
                  "Expiry Date": moment(
                    couponsAssigned?.offerData?.validityEndDate
                  ).format("DD-MMM-YYYY hh:mm A"),
                  Status: coupon.status,
                };
              }
            )}
          />
        )}
      </div>
      <table className="shadow couponinner-table">
        <thead>
          <tr>
            <th>Mobile</th>
            <th>Email</th>
            <th>Coupon Code</th>
            {/* <th>Item Code</th> */}
            <th>Discount Value</th>
            <th>Validity Start Date</th>
            <th>Validity End Date</th>
            <th>Created At</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {couponsAssigned?.customerData?.length > 0 ? (
            couponsAssigned?.customerData?.map(
              (coupons: any, index: number) => {
                let itemCodes = Array.isArray(coupons.itemCode)
                  ? coupons.itemCode
                  : [coupons.itemCode];
                let itemCodesString = itemCodes.join(",");

                return (
                  <tr key={index} className="cursor-pointer">
                    <td data-label="Mobile">
                      {coupons?.customerMobile ?? "-"}
                    </td>
                    <td data-label="Email">{coupons?.customerEmail ?? "-"}</td>
                    <td data-label="Coupon Code">
                      {coupons?.couponCode ?? "-"}
                    </td>
                    {/* <td data-label="Item Code">{itemCodesString ?? '-'}</td> */}
                    <td data-label="Discount Value">
                      {coupons?.discountValue ?? "-"}
                    </td>
                    <td data-label="Validity Start Date">
                      {moment(coupons?.validityStartDate).format(
                        "DD-MMM-YYYY hh:mm A"
                      ) ?? "-"}
                    </td>
                    <td data-label="Validity End Date">
                      {moment(coupons?.validityEndDate).format(
                        "DD-MMM-YYYY hh:mm A"
                      ) ?? "-"}
                    </td>
                    <td data-label="Created At">
                      {moment(coupons?.createdAt).format(
                        "DD-MMM-YYYY hh:mm A"
                      ) ?? "-"}
                    </td>
                    {/* <td>
                  <div
                    onClick={(e: any) => {
                      e.stopPropagation();
                      showDeleteConfirmationHandler(coupons?._id);
                    }}
                  >
                    <Icon icon={faTrash} />
                  </div>
                </td> */}
                  </tr>
                );
              }
            )
          ) : (
            <tr>
              {" "}
              <td colSpan={3}>No data found !</td>
            </tr>
          )}
        </tbody>
      </table>
      <TablePagination
        backIconButtonProps={
          page === 0 ? { disabled: true } : { disabled: false }
        }
        nextIconButtonProps={
          page + 1 === totalPages || totalPages === 0
            ? { disabled: true }
            : { disabled: false }
        }
        component="div"
        count={Math.ceil(totalPages / rowsPerPage)}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div className="btn-group-align">
        <button
          type="button"
          className="btn secondary"
          onClick={goToHomeFormHandler}
        >
          Back to Home
        </button>
      </div>
      <DeleteConfirmationDialog
        show={deleteConfirmationDialog}
        onConfirmation={deleteConfirmationHandler}
      />
    </div>
  );
};

export default CouponAssigned;
