import "./styles.scss";
import CloseIcon from "../../assets/icons/Close.svg";
import AddFile from "../../assets/icons/add-file.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import appContext from "context/app.context";
import { FinanceListService } from "services/financeList.service";
import DropDown from "components/DropDown";
import * as XLSX from "xlsx";
import { STATUS } from "config/config";
import { FinanceData } from "common/constant";

type TProps = {
  setBulkVisibility: (a: boolean) => void;
  combo?: string;
  reload: () => void;
};

export const FinanceListUpload: React.FC<TProps> = ({
  setBulkVisibility,
  combo,
  reload,
}) => {
  const { showToaster } = useContext(appContext);
  const [file, setFile] = useState<File>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const financeListService = new FinanceListService();
  const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false);

  const [percentageCompleted, setPercentageCompleted] = useState<number>(0);
  const [resultVisibility, setResultVisibility] = useState<boolean>(false);

  const [financeName, setFinanceName] = useState<any>(FinanceData[0]);
  const [financeListName, setFinanceListName] = useState<any>([]);

  const [showData, setshowData] = useState<boolean>(false);
  const [showDatas, setshowDatas] = useState<boolean>(false);
  const [isDuplicate, setisDuplicate] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(true);
  const [csvData, setcsvData] = useState<any>([]);
  const drop: any = useRef(null);
  const input: any = useRef(null);

  const onClickOutsideListener = () => {
    document.removeEventListener("click", onClickOutsideListener);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length && !isCompleted) {
      setFile(files[0]);
    }
  };

  const getFinanceList = async (): Promise<void> => {
    const financeListResponse = await financeListService.financeListName();

    if (financeListResponse.status === STATUS.SUCCESS) {
      let response = financeListResponse.data;
      var financeName = [];
      response.forEach((element: any) => {
        financeName.push(element.financeName);
      });
      setFinanceListName(financeName);
    } else {
      showToaster(EToasterVariants.error, "Unable to get Finance Name List");
    }
  };

  useEffect(() => {
    getFinanceList();
  }, []);

  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver);
    drop?.current?.addEventListener("drop", handleDrop);

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver);
      drop?.current?.removeEventListener("drop", handleDrop);
    };
  }, []);

  useEffect(() => {
    if (progressBarVisible && percentageCompleted < 99) {
      setTimeout(() => {
        setPercentageCompleted(percentageCompleted + 1);
      }, 7);
    }
  }, [progressBarVisible, percentageCompleted]);

  useEffect(() => {
    isCompleted && setPercentageCompleted(100);
  }, [isCompleted]);

  const uploadFile = async (file: any) => {
    if (file && !isDuplicate) {
      const uploadfile = new FormData();
      uploadfile.append("uploadfile", file);
      uploadfile.append("financeName", financeName);
      let financeUpload: any;
      try {
        financeUpload = await financeListService.addFinanceList(uploadfile, financeName);

        if (financeUpload.status === STATUS.SUCCESS) {

          setStatus(false);
          setshowData(false);
          setBulkVisibility(false);
          setshowDatas(false);
          showToaster(EToasterVariants.success, financeUpload.message);
        } else {
          setBulkVisibility(false);
          showToaster(
            EToasterVariants.error,
            financeUpload.error.response.data.message
          );
        }
      } catch (error: any) {
        setBulkVisibility(false);
        showToaster(EToasterVariants.error, error.message);
      }
    } else {
      showToaster(EToasterVariants.error, "Dublicates Itemcode Contain");
    }
  };

  const onfileChange = (e: any) => {
    let isDuplicate;
    console.log(isDuplicate, "=====>llllllhgfdsa");
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt: any) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });


      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let index = 0;

      for (const cellAddress in ws) {
        index++;
        if (!ws.hasOwnProperty(cellAddress)) continue;

        const cell = ws[cellAddress];

        // Check if the cell contains a date value
        if (index % 7 === 0 || index % 7 === 6) {
          const excelDate = cell.v;
          const formattedDate = XLSX.SSF.format('mm-dd-yyyy H:mm', excelDate);

          cell.t = 's'; // Set the cell type to string
          cell.v = formattedDate; // Set the formatted date as the cell value
        }
      }

      const xlData = XLSX.utils.sheet_to_json(ws);

      console.log(xlData);


      setcsvData(xlData);

      setshowData(true);

    };
    reader.readAsBinaryString(file);
  };

  const convertToDate = (excelDate: any) => {
    const isDate = XLSX.SSF.is_date(excelDate);
    console.log(isDate, 'isDate');


    if (isDate) {
      const formattedDate = XLSX.SSF.format('dd-mm-yyyy H:mm', excelDate);
      return formattedDate;
    }
    return excelDate;

  }

  useEffect(() => {
    !resultVisibility && isCompleted && setBulkVisibility(false);
  }, [resultVisibility]);

  return (
    <div
      className="bulk-uploader-page"
      onMouseEnter={() => {
        document.addEventListener("click", onClickOutsideListener);
      }}
    >
      <div
        className="bulk-uploader"
        ref={drop}
        onMouseLeave={() => {
          document.addEventListener("click", onClickOutsideListener);
        }}
        onMouseEnter={() => {
          document.removeEventListener("click", onClickOutsideListener);
        }}
      >
        <img
          alt="close-icon"
          className="close-icon"
          src={CloseIcon}
          onClick={() => {
            reload();
            setBulkVisibility(false);
          }}
        />

        <div>
          <form>
            <div className="form-wrapper">
              {!showDatas && (
                <div className="formFinance">
                  <DropDown
                    options={[...financeListName.map((values: any) => values)]}
                    label="Finance Name"
                    onChange={(event) => setFinanceName(event.target.value)}
                    name="financeName"
                  />
                </div>
              )}
            </div>
          </form>
        </div>

        {!showData && !showDatas && (
          <div className="header-bar">
            <p>File Upload</p>
          </div>
        )}
        <div className="header-body">
          {!showData && !showDatas && (
            <div
              className="drag-and-drop"
              onClick={() => input.current.click()}
            >
              <input
                type="file"
                disabled={isCompleted}
                style={{ display: "none" }}
                ref={input}
                onChange={(e: any) => {
                  setFile(e.target.files[0]);
                  onfileChange(e);
                }}
              />

              <img src={AddFile} />
              <p>
                Drag and drop or{" "}
                <span style={{ color: "#FB6C00", cursor: "pointer" }}>
                  browse
                </span>{" "}
                your files
              </p>
            </div>
          )}

          {showData && (
            <div>
              <h3>File Preview</h3>
              <div className="financelist-table error-list">
                <table className="upload-table">
                  <thead className="header-1">
                    <tr>
                      <th>Item code</th>
                      <th>Scheme Name</th>
                      <th>Processing Fees</th>
                      <th>Interest(%)</th>
                      <th>Channel</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {csvData?.map((product: any, index: number) => {
                      return (
                        <tr className="add-product-row" key={index}>
                          <td data-label="Item Code">{product["Item Code"]} </td>
                          <td data-label="Scheme Name">{product["Scheme Name"]}</td>
                          <td data-label="Processing Fees">{product["Processing Fees"]}</td>
                          <td data-label="Interest(%)">{product["Interest(%)"]}</td>
                          <td data-label="Channel">{product["Channel"]}</td>
                          <td data-label="Start Date">{convertToDate(product["Start Date(DD-MM-YYYY HH:MM)"])} </td>
                          <td data-label="End Date">{convertToDate(product["End Date(DD-MM-YYYY HH:MM)"])} </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="button-wrapper">
                <button
                  type="button"
                  className="btn secondary"
                  onClick={() => {
                    setBulkVisibility(false);
                    reload();
                  }}
                >
                  Cancel
                </button>
                {status && (
                  <button
                    type="button"
                    className="btn primary mr-2"
                    onClick={() => {
                      uploadFile(file);
                    }}
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
