import { appContext } from 'context/app.context';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { CouponsService } from 'services/coupon.service';
import { EToasterVariants } from "services/types/common.type";
import { STATUS } from "config/config";
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import { COUPON_TYPE } from 'common/constant';
import _ from 'lodash';

const CouponSummary: React.FC = () => {
  const [checkStatus, setCheckstatus] = useState<boolean>(false);
  const { offerData, setMenuIndex, menuIndex, setSpinnerVisibility, showToaster, setOfferData } = useContext(appContext);
  const navigateTo = useNavigate();
  const couponsService = new CouponsService();
  const {
    setValue,
  } = useForm();

  const onSubmit = async (data: any) => {
    const newOfferData: any = {
      ...offerData,
      ...data
    };
    try {
      setSpinnerVisibility(true);
      const updateCouponResponse = await couponsService.updateCoupon(
        newOfferData
      );
      if (updateCouponResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Coupon Updated Successfully");
        setSpinnerVisibility(false);
        setOfferData(updateCouponResponse?.data?.offerData);
        if (offerData?.couponCodeType === COUPON_TYPE.UNIQUE_COUPON) {
          setMenuIndex(menuIndex + 1);
        } else {
          navigateTo("/offers/coupons/list");
          setMenuIndex(0);
        }
      } else {
        setSpinnerVisibility(false);
      }
    } catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "Unable to create new offer");
    }
  }
  
  useEffect(() => {
    setValue("noofcoupons", offerData.noofcoupons)
    setValue("MultipleCoupons", offerData.MultipleCoupons)
    setCheckstatus(offerData.MultipleCoupons)
  }, [])

  // To remove HTML tags from a string(CKEditor)
  function stripHTML(myString: any) {
    if (_.isEmpty(myString))
      return '';
    else
      return myString.replace(/(<([^>]+)>)/ig, '').replace(/(&.+;)/ig, '');
  }
  return (
    <div className='coupon-summary'>
      <div className='summary-promo'>
        <div className='summary-details'><p className='summary-heading'>Promo name:</p>
          <p className='summary-content'>{offerData.couponName}</p></div>
        <div className='summary-details'><p className='summary-heading'>Discount ({offerData.discountType === 'Percentage' ? "%" : "₹"}):</p>
          <p className='summary-content'>{offerData.discountValue}</p>
        </div>
        {offerData.couponCode && <div className='summary-details'><p className='summary-heading'>Coupon Code:</p>
          <p className='summary-content'>{offerData.couponCode}</p></div>}
      </div>
      <div className='valid-date'>
        <div className='summary-details'> <p className='summary-heading'>Valid from:</p>
          <p className='summary-content'>{moment(offerData.validityStartDate).format("DD, MMM YYYY")}</p></div>
        <div className='summary-details'> <p className='summary-heading'>Valid upto:</p>
          <p className='summary-content'>{moment(offerData.validityEndDate).format("DD, MMM YYYY")}</p></div>
      </div>
      <div className='description'>
        <div className='summary-details'><p className='summary-heading'>Description:</p>
          <p className='summary-content'>{stripHTML(offerData?.couponDescription)}</p>
        </div>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <div className="checkbox-container">
          <Checkbox
            value={watch("MultipleCoupons")}
            lable="Multiple Coupons"
            onChange={(checkedStatus: boolean) => {
              setValue("MultipleCoupons", checkedStatus)
              setCheckstatus(checkedStatus)
            }
            }
          />
        </div>
        {checkStatus &&
          <div className='multi-coupons'>
            <div className='sum-input'>
              <InputField
                helpertext="No of coupons"
                name="noofcoupons"
                register={register("noofcoupons", { required: "no of coupons is Required" })}
                error={errors.name?.message as any}
              />

            </div>
            <button className="btn primary" type="submit">
              Create Coupon
            </button>
          </div>
        }

        <div className='coupon-illustration'><img src={couponillustration} alt="" /></div>

        <div className="coupon-buttons-wrapper">
          <button
            className="btn secondary"
            type="button"
            onClick={() => { navigateTo("/offers/coupons/list") }}
          >
            Back to Coupon List
          </button>
        </div>
      </form> */}
    </div>
  )
}

export default CouponSummary