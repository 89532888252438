import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appContext from "context/app.context";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";

type sideMenuItemProps = {
  name: string;
  navigationLink: string,
  icon: IconProp,
  paths?: string[] ,
  onClick?:any
};

const pathName = window.location.pathname;

const SideMenuItem: React.FC<sideMenuItemProps> = ({ name, navigationLink, icon, paths,onClick }) => {

  const [activeMenuStatus] = useState<boolean | undefined>(paths?.includes(pathName));
  const { sideMenuVisibility } = useContext(appContext);


  return (
    <NavLink to={navigationLink} className={({ isActive }) => (isActive || activeMenuStatus) ? "navigation-link active" : "navigation-link"} >
    <div className="sidemenu-item" >
      <FontAwesomeIcon className="action-icon" icon={icon}/>
      {sideMenuVisibility ? <h1>{name}</h1> : <h1 onClick={onClick} className="mobilemenu">{name}</h1>  }
    </div>
    </NavLink>
    
  );
};

export default SideMenuItem;
