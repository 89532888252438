import React, { useContext, useEffect, useState } from 'react'
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';

import { MultiSelectValues } from "common/constant";
import Icon from "components/Icon";
import MultiSelect from "components/MultiSelect";
import { usePermission } from 'components/Permission';
import appContext from "context/app.context";
import { StoreService } from "services/store.service";
import { EToasterVariants } from "services/types/common.type";

function ChannelSection(props: any) {
    const { watch, viewOnlyMode, register, errors, setValue } = props;
    const { showToaster, offerData } = useContext(appContext);


    const storeAPIService = new StoreService();

    const [storesData, setStoreData] = useState<{ name: string; id: number; state_code: string; district_code: '' }[]>([]);
    const [stateData, setStateData] = useState<{ name: string; state_code: string, id: number }[]>([]);
    const [regionData, setRegionData] = useState<{ name: string; district_code: '', id: number }[]>([]);
    const [filteredStores, setFilteredStores] = useState<{ name: string; id: number; state_code: string; district_code: '' }[]>([]);
    const [filteredStates, setFilterdStates] = useState<{ name: string; state_code: string, id: number }[]>([]);
    const [filteredRegions, setFilteredRegions] = useState<{ name: string; district_code: '', id: number }[]>([]);

    const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
    const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

    const marketInfoPermission = _.isEmpty(offerData) ? createOffersPermission : !viewOnlyMode && editOffersPermission;

    const getStoreInformations = async (): Promise<void> => {
        try {
            /* Need to modify this place */
            const { data } = await storeAPIService.getStores();
            let allStates = [{ id: 0, name: "All States", state_code: 'All' }];
            let allRegion: any = [{ id: 0, name: "All Regions", district_code: 'All' }];
            let allStores: any = [{ id: 0, name: "All Stores", shop_code: 'All' }];
            data?.forEach((showroom: any, index: number) => {
                const id = index + 1;

                allStates.push({
                    id,
                    name: showroom.state,
                    state_code: showroom.state_code,
                });
                allRegion.push({
                    id,
                    name: showroom.district,
                    state_code: showroom.state_code,
                    district_code: showroom.district_code,
                });
                allStores.push({
                    id,
                    name: showroom.branch,
                    state_code: showroom.state_code,
                    district_code: showroom.district_code,
                    shop_code: showroom.shortcode,
                });


            });
            const uniqueState: any = _.uniqBy(allStates, 'state_code');
            const uniqueRegions: any = _.uniqBy(allRegion, 'district_code');
            const uniqueStores: any = _.uniqBy(allStores, 'shop_code');

            setStateData(uniqueState);
            setRegionData(uniqueRegions);
            setStoreData(uniqueStores);
        } catch (error) {
            showToaster(EToasterVariants.error, "unable to fetch Store Informations");
        }
    };


    const channelDecisionHandler = (values: any) => {
        if (!_.isEmpty(values)) {
            setValue("state", [{ id: 0, name: "All States", state_code: 'All' }]);
            setValue("region", [{ id: 0, name: "All Regions", state_code: 'All', district_code: 'All' }]);
            setValue("storeId", [{ id: 0, name: "All Stores", shop_code: 'All' }]);
            setValue("channel", values);
        }

    };

    const stateSelectionHandler = (values: any) => {
        if (!_.isEmpty(values)) {
            const finalIndex = values?.length - 1;
            const isAllSelectedRecently = values[finalIndex]?.name === "All States";
            if (isAllSelectedRecently) {
                setValue("state", [{ id: 0, name: "All States", state_code: 'All' }]);
                setValue("region", [{ id: 0, name: "All Regions", state_code: 'All', district_code: 'All' }]);
                setValue("storeId", [{ id: 0, name: "All Stores", shop_code: 'All' }]);
            } else {
                const excludeAllOption = values?.filter((value: any) => value?.name !== "All States");
                setValue('state', excludeAllOption)
            }
        }
    }

    const regionSelectionHandler = (values: any) => {
        if (!_.isEmpty(values)) {

            const finalIndex = values?.length - 1;
            const isAllSelectedRecently = values[finalIndex]?.name === "All Regions";

            if (isAllSelectedRecently) {
                setValue("region", [{ id: 0, name: "All Regions", district_code: 'All' }]);
                setValue("storeId", [{ id: 0, name: "All Stores", shop_code: 'All' }]);
            } else {
                const excludeAllOption = values?.filter((value: any) => value?.name !== "All Regions");
                setValue('region', excludeAllOption)
            }
        }
    }

    const storeSelectionHandler = (values: any) => {
        const finalIndex = values?.length - 1;
        const isAllSelectedRecently = values[finalIndex]?.name === "All Stores";
        if (isAllSelectedRecently) {
            setValue("storeId", [{ id: 0, name: "All Stores", shop_code: 'All' }]);
        } else {
            const excludeAllOption = values?.filter((value: any) => value?.name !== "All Stores");
            setValue("storeId", excludeAllOption);

        }
    }

    useEffect(() => {
        getStoreInformations();
    }, []);

    useEffect(() => {
        const state = watch('state');

        if (!_.isEmpty(state)) {
            const filtered_state = stateData.filter(obj => {
                return state.some((value: any) => value.name !== obj.name);
            });
            setFilterdStates(filtered_state);

            const filtered_region = regionData.filter((obj: any) => {
                return state.some((value: any) => value.state_code === obj.state_code);
            });
            setFilteredRegions(filtered_region);

            const filtered_stores = storesData.filter((obj: any) => {
                return state.some((value: any) => value.state_code === obj.state_code);
            });
            setFilteredStores(filtered_stores);
        }

    }, [watch('state'), stateData]);


    useEffect(() => {
        const region = watch('region');

        if (!_.isEmpty(region)) {
            const filtered_region = regionData.filter(obj => {
                return region.some((value: any) => value.name !== obj.name);
            });
            setFilteredRegions(filtered_region);

            const filtered_stores = storesData.filter((obj: any) => {
                return region.some((value: any) => value.district_code === obj.district_code);
            });
            console.log(filtered_stores);

            setFilteredStores(filtered_stores);
        }

    }, [watch('region'), regionData]);

    useEffect(() => {
        const storeId = watch('storeId');

        if (!_.isEmpty(storeId)) {
            const filtered_store = storesData.filter(obj => {
                return storeId.some((value: any) => value.name !== obj.name);
            });
            setFilteredStores(filtered_store);
        }

    }, [watch('storeId'), storesData]);

    return (
        <div className="sub-section">
            <p className="section-heading">2. Market
                {marketInfoPermission &&
                    <div className="tooltipContainer">
                        <Icon icon={faCircleInfo} />
                    </div>
                }
            </p>
            <div className="input-fields-wrapper">
                <MultiSelect
                    disabled={!marketInfoPermission}
                    fieldName="channel"
                    fields={MultiSelectValues.channel}
                    selectedValues={watch("channel")}
                    register={register("channel", {
                        required: "Channel is Required",
                    })}
                    error={errors.channel?.message as any}
                    onChange={channelDecisionHandler}
                    focusHandleText={<p>Online : Only applicable to Poorvika Website and App ,<br /> Store : Only applicable to Poorvika Retail Stores ,<br /> All : Applicable both to Online and Stores</p>}
                />
                {watch("channel")?.some((code: any) => code.name === 'Shop') && (

                    <><MultiSelect
                        disabled={!marketInfoPermission}
                        fieldName="state"
                        fields={filteredStates}
                        selectedValues={watch("state")}
                        register={register("state", { required: "State is Required" })}
                        error={errors.state?.message as any}
                        onChange={stateSelectionHandler}
                    />
                        <MultiSelect
                            disabled={!marketInfoPermission}
                            fieldName="region"
                            fields={filteredRegions}
                            selectedValues={watch("region")}
                            register={register("region", { required: "Region is Required" })}
                            error={errors.region?.message as any}
                            onChange={regionSelectionHandler}
                        />
                        <MultiSelect
                            disabled={!marketInfoPermission}
                            fieldName="Store ID"
                            selectedValues={watch("storeId")}
                            register={register("storeId", { required: "Store Id is Required" })}
                            fields={filteredStores}
                            error={errors.storeId?.message as any}
                            onChange={storeSelectionHandler}
                        /></>)}
            </div>

        </div>

    )
}

export default ChannelSection;