import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LandingPage from "pages/LandingPage";
import appContext from "context/app.context";
import { EToasterVariants, ToasterProperties } from "services/types/common.type";
import "./App.scss";

import Spinner from "components/Spinner";
import Toaster from "components/Toaster";
import { toasterDuration } from "common/constant";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloakConfig from './keycloak'

var timeout: any;


const App: React.FC = () => {

  const [sideMenuVisibility, setSideMenuVisibility] = useState<boolean>(true);
  const [toasterProps, setToasterProps] = useState<ToasterProperties>({ type: EToasterVariants.success, message: "Success Message", visibility: false });
  const [spinnerVisibility, setSpinnerVisibility] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [offerGroupData, setOfferGroupData] = useState({});
  const [menuIndex, setMenuIndex] = useState(0);
  const [userPermission, setUserPermission] = useState({});
  const [offersFilter, setOffersFilter] = useState([]);
  const [offerListFilter, setOfferListFilter] = useState({});
  const [financeFilter, setFinanceFilter] = useState({});
  const [offersFilterOptions, setOffersFilterOptions] = useState({});
  const [activeOffersFilterOptions, setActiveOffersFilterOptions] = useState({});
  const [offersFilterUpdate, setOffersFilterUpdate] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [offerData, setOfferData] = useState({});
  const [historyNavigate, setHistoryNavigate] = useState<boolean>(false);
  const [viewOnlyMode, setViewOnlyMode] = useState<boolean>(true);

  const showToaster = (type: EToasterVariants, message: string) => {
    clearTimeout(timeout)
    setToasterProps({ type, message, visibility: true });
    timeout = setTimeout(() => {
      setToasterProps({ type, message, visibility: false });
    }, toasterDuration);
  }

  const contextItems = {
    sideMenuVisibility,
    setSideMenuVisibility,
    toasterProps,
    showToaster,
    spinnerVisibility,
    setSpinnerVisibility,
    selectedProducts,
    setSelectedProducts,
    offerData,
    setOfferData,
    viewOnlyMode,
    setViewOnlyMode,
    offerGroupData,
    setOfferGroupData,
    menuIndex,
    setMenuIndex,
    userPermission,
    setUserPermission,
    offersFilter,
    setOffersFilter,
    offerListFilter,
    setOfferListFilter,
    financeFilter,
    setFinanceFilter,
    offersFilterUpdate,
    setOffersFilterUpdate,
    offersFilterOptions,
    setOffersFilterOptions,
    setPage,
    page,
    activeOffersFilterOptions,
    setActiveOffersFilterOptions,
    historyNavigate,
    setHistoryNavigate    
  }

  return (
    <appContext.Provider value={contextItems}>
      <ReactKeycloakProvider
        authClient={keycloakConfig}
        initOptions={{
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
        }}>

        <Provider store={store}>
            <Router>
              <Routes>
                <Route path="/" element={localStorage.getItem("accessToken") ? <Navigate replace to="/offers" /> : <Navigate replace to="/login" />} />
                <Route path="/login" element={localStorage.getItem("accessToken") ? <Navigate replace to="/offers" /> : <LoginPage />} />
                <Route path="/offers/*" element={localStorage.getItem("accessToken") ? <LandingPage /> : <Navigate replace to="/login" />} />
              </Routes>
              <Spinner />
              <Toaster />
            </Router>
        </Provider>
      </ReactKeycloakProvider>
    </appContext.Provider >
  );
};

export default App;
