import React from "react";
import "./styles.scss";
import { ProductDisplay } from "./ProductDisplay";
import { DiscountCashCard } from "components/DiscountCashCard";

type AddProductsProps = {
    offers: [],
    comboFreeOffers: [],
    setComboFreeOffers: any,
    offer: any,
    isCombo: boolean,
    maxBenefites: number
};

const ComboFreeDisplay: React.FC<AddProductsProps> = ({ offers, offer, isCombo, comboFreeOffers, setComboFreeOffers, maxBenefites }) => {

    const handleCheck = (prod: any) => {
        const cfOffers = comboFreeOffers.map((item: any) => {
            if (item._id === offer._id) {
                let discountValue = 0;
                prod.forEach((offer: any) => {
                    if (isCombo) discountValue += offer.discountValue;
                    else discountValue += offer.mrpPrice;

                    if (offer.type === 'Cash') item.cashDiscount = true;
                    else item.cashDiscount = false;
                })
                item.maxBenefites = discountValue;
            }
            return item;
        })
        setComboFreeOffers(cfOffers);
    }

    const handleChosen = (prod: []): boolean => {
        let discountValue = 0;

        prod.forEach((offer: any) => {
            if (isCombo) discountValue += offer.discountValue;
            else discountValue += offer.mrpPrice;
        })

        return discountValue === maxBenefites;
    }

    return (
        <div className="combooffer-page">
            <div className="combooffer-selection">
                <div className="product-header">
                    <div className="product-card" >
                        <div className="combooffer-products">
                            <div className="freebiesorcomboproductCard">
                                {offers?.length ? offers?.map((prod: any, index: number) => {

                                    return (
                                        <>
                                            <input id="combofree_offer" type="radio" checked={handleChosen(prod)} onClick={() => handleCheck(prod)}></input>

                                            <div className="product-multiField-container-flex">
                                                <div className="product-multiField-container">
                                                    {prod?.[0]?.type === 'Cash' ?
                                                        <DiscountCashCard products={prod} /> :
                                                        <ProductDisplay combooff={true} products={prod} />}
                                                </div>
                                            </div>
                                            {
                                                (index < offers.length - 1) && < div className="hr-lin">
                                                    <hr className="hr-line" />
                                                    <div className="add-btn">
                                                        or
                                                    </div>

                                                </div>
                                            }

                                        </>
                                    )
                                }) : ""}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default ComboFreeDisplay;
