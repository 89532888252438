import React, { useContext } from 'react';

import './styles.scss';
import Icon from 'components/Icon';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { OffersService } from 'services/offers.service';
import { STATUS } from 'config/config';
import appContext from 'context/app.context';
import { EToasterVariants } from 'services/types/common.type';

type CloneConfirmationProps = {
  show: boolean,
  offer: any,
  onConfirmation?: (visibility: boolean) => void
  heading?: string
  description?: string
  secondaryBtnName?: string
  primaryBtnName?: string,
  getOffers: any
}





const CloneConfirmationDialog: React.FC<CloneConfirmationProps> = ({ show, offer = {}, onConfirmation, heading, description, primaryBtnName, secondaryBtnName, getOffers }) => {

  const { offerName, offerId } = offer;
  const { showToaster, setSpinnerVisibility } = useContext(appContext);
  const offersService = new OffersService();

  const dialogVisibilityHandler = (cloneConfirmation: boolean) => {
    if (onConfirmation) {
      onConfirmation(cloneConfirmation);
    }
  };

  const cloneOffer = async (isProduct = false) => {
    setSpinnerVisibility(true);
    const cloneResponse = await offersService.cloneOffer(offerId, isProduct);
    if (cloneResponse.status === STATUS.SUCCESS) {
      onConfirmation(false);
      getOffers();
      showToaster(EToasterVariants.success, cloneResponse.message);
    } else {

      showToaster(EToasterVariants.error, 'Something went wrong, please check');
    }
    onConfirmation(false);

    setSpinnerVisibility(false);
  }


  return (
    <div className={show ? "clone-confirmation-dialog show" : "clone-confirmation-dialog hide"}>
      <div className="dialog-container shadow">
        <div className="confirm_dialog">
          <div className="confirm_icon">
            <span className="exicon">
              <Icon icon={faTriangleExclamation} />
            </span>
          </div>
          <div className="dialog-details">
            <p>{heading ?? `Do you want to clone this ${offerName}?`}</p>
            {/* <div className="warning-text">
                <p>
                    {description ?? `Once the record is deleted You can't recover it at any cost since it is
                    permanently deleted from our database`}
                </p>
                </div> */}
            <div className="buttons-wrapper">
              <button className="btn secondary" onClick={() => dialogVisibilityHandler(false)}>{secondaryBtnName ?? `Cancel`}</button>
              <button className="btn primary" onClick={() => cloneOffer(true)}>{primaryBtnName ?? `Clone with Products`}</button>
              <button className="btn primary" onClick={() => cloneOffer(false)}>{primaryBtnName ?? `Clone Offer only`}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloneConfirmationDialog