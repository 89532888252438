import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProductView from "components/ProductView";
import { useState } from "react";

import './styles.scss'

const ShowOfferPage: React.FC = () => {
  document.title = "Poorvika - Show Offers";

  const [value, setValue] = useState(0);
  const [title, setTitle] = useState<any>([]);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }


  return (
    <div className="show-page">
      <Box sx={{ width: "100%" }}>
        <div className="filter-tab-control">
          <Tabs
            variant="scrollable"
            value={value}
            selectionFollowsFocus
            TabIndicatorProps={{ style: { background: '#fb6c00', color: 'white' } }}
          >

            <Tab
              iconPosition="end"
              label={title[0] || "Choose Product"}
              onClick={() => handleChange(0)}
              {...a11yProps(0)}
              style={{ fontSize: '.75rem' }}

            />

            <Tab
              iconPosition="end"
              label={title[1] || "Choose Product"}
              onClick={() => handleChange(1)}
              style={{ fontSize: '.75rem' }}
              {...a11yProps(1)}
            />

            <Tab
              iconPosition="end"
              label={title[2] || "Choose Product"}
              onClick={() => handleChange(2)}
              style={{ fontSize: '.75rem' }}
              {...a11yProps(2)}
            />

            <Tab
              iconPosition="end"
              label={title[3] || "Choose Product"}
              onClick={() => handleChange(3)}
              style={{ fontSize: '.75rem' }}
              {...a11yProps(3)}
            />

          </Tabs>
        </div>
        <div style={{ display: value === 0 ? 'block' : 'none' }}>
          <ProductView title={title} setTitle={setTitle} index={0} />
        </div>
        <div style={{ display: value === 1 ? 'block' : 'none' }}>
          <ProductView title={title} setTitle={setTitle}
            index={1} />
        </div>
        <div style={{ display: value === 2 ? 'block' : 'none' }}>
          <ProductView title={title} setTitle={setTitle}
            index={2} />
        </div>
        <div style={{ display: value === 3 ? 'block' : 'none' }}>
          <ProductView title={title} setTitle={setTitle}
            index={3} />
        </div>


      </Box>
    </div>
  );
};

export default ShowOfferPage;
