import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

type FinanceProps = {
  offerColumnName?: string,
  financeData?: any,
  sortedOffers?: any
}

const FinanceSortingComponent = ({offerColumnName,financeData,sortedOffers}:FinanceProps) => {
 

  const [sortOrder, setSortOrder] = useState(false)

  const sorting = (columnName: any, order: boolean) => {

    console.log(columnName,'sorrrttt');
    sortedOffers(order === true ? [...financeData].sort((a, b) => a[columnName].localeCompare(b[columnName]) * 1) : [...financeData].sort((a, b) => a[columnName].localeCompare(b[columnName]) * -1))
    
   }
 
 
  return (
    <div>
      <div onClick={() => {
      setSortOrder(!sortOrder)
      sorting(offerColumnName, !sortOrder)
    }}>
      <FontAwesomeIcon className="" icon={sortOrder ? faArrowDown : faArrowUp } />
    </div>
    </div>
  )
}

export default FinanceSortingComponent
