import "./styles.scss";
import moment from "moment";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { TablePagination } from "@mui/material";
import Icon from "components/Icon";
import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import { FinanceListUpload } from "components/FinanceListUpload";
import { FinanceListService } from "services/financeList.service";
import DownloadTemplates from "components/DownloadeTemplates";
import {
  financeBulkUploadColumns,
  sortTypes,
  sortColumnTypes,
} from "common/constant";
import Input from "components/UseInput";
import { faFilter, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {  useForm } from "react-hook-form";
import DropDown from "components/DropDown";
import FinanceSortingComponent from "./financeSortingComponent";
import { FinanceData } from "common/constant";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { getChannelNames } from "common/commonFunc";
import { PIMProductService } from "services/pimProduct.service";
import Checkbox from "components/Checkbox";
import FinanceFilter from "./FinanceFilter";
import _ from "lodash";

type Tprops = {};
const FinanceList: React.FC<Tprops> = () => {
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [financeData, setFinanceData] = useState<any>([]);
  const [openPriceListPopup, setOpenPriceListPopup] = useState<boolean>(false);
  const [brands, setBrands] = useState<any>([]);
  const {
    showToaster,
    setSpinnerVisibility,
    financeFilter,
  } = useContext(appContext);

  const { register, setValue, watch } = useForm();
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [norecord, setNorecord] = useState("No Records found");
  const [filterVisibility, setFilterVisibility] = useState<boolean>(false);
  const [mobileFilterVisibility, setMobileFilterVisibility] =
    useState<boolean>(false);
  const [sortMobileVisibility, setSortMobileVisibility] =
    useState<boolean>(false);
  const [financeName, setFinanceName] = useState<any>(FinanceData[0]);
  const [windowDimension, setWindowDimension] = useState<any>(null);
  const [deleteConfirmationDialog, setDeleteConfirmationDialogVisibility] =
    useState<boolean>(false);
  const isDesktop = windowDimension >= 640;

  useEffect(() => {
    setWindowDimension(window.innerWidth);
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const financeListService = new FinanceListService();
  const pimService = new PIMProductService();
  const getFinanceList = async (): Promise<void> => {
    setSpinnerVisibility(true);
    const { financeName = "", startDate = "", endDate = "", brand = "", channel = "" } = financeFilter;
    const selectedBrand = !!brand ? brand.label : "";
    const financeListResponse = await financeListService.getFinanceList(
      rowsPerPage,
      page,
      search,
      financeName,
      selectedBrand,
      channel,
      startDate,
      endDate
    );
    setSpinnerVisibility(false);
    if (financeListResponse.status === STATUS.SUCCESS) {
      let response = financeListResponse.data?.financeModelList;
      for (var i = 0; i < response.length; i++) {
        response[i]["checkedStatus"] = false;
      }
      setFinanceData(response);
      setTotalPages(financeListResponse.data?.totalPages);
    } else {
      showToaster(EToasterVariants.error, "Unable to get Finance List");
    }
  };

  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      const selectedData = [];
      const result = financeData.map((obj: any) => {
        if (obj.checkedStatus) {
          return selectedData.push(obj._id);
        }
      });
      try {
        const deleteFinanceResponse =
          await financeListService.deleteFinanceData(selectedData);

        if (deleteFinanceResponse.status === STATUS.SUCCESS) {
          getFinanceList();
          setValue("selectAll", false);
          setDeleteConfirmationDialogVisibility(false);
          showToaster(EToasterVariants.success, deleteFinanceResponse.message);
        } else {
          showToaster(EToasterVariants.error, "Unable to delete the Offer");
        }
      } catch (error) {
        showToaster(
          EToasterVariants.error,
          "Unable to delete the Finance Offer"
        );
      }
    } else {
      setDeleteConfirmationDialogVisibility(false);
    }
  };

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`
      }`;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchHandler = async (search: any) => {
    if (search.length > 3) {
      setSearch(search);
      setPage(0);
      const { financeName = "", startDate = "", endDate = "", brand = "", channel = "" } = financeFilter;
      const selectedBrand = !!brand ? brand.label : "";
      const offerListResponse = await financeListService.getFinanceList(
        rowsPerPage,
        page,
        search,
        financeName,
        selectedBrand,
        channel,
        startDate,
        endDate
      );

      setSpinnerVisibility(false);
      if (offerListResponse.status === STATUS.SUCCESS) {
        let response = offerListResponse.data?.financeModelList;
        for (var i = 0; i < response.length; i++) {
          response[i]["checkedStatus"] = false;
        }
        setFinanceData(response);
        setTotalPages(offerListResponse.data?.totalPages);
      } else {
        showToaster(EToasterVariants.error, "Unable to get Offer List");
      }
    } else if (search?.length === 0) {
      setSearch("");
      getFinanceList();
    }
  };
  const sliderVisibilityHandler = () => {
    setFilterVisibility(!filterVisibility);
    setMobileFilterVisibility(!mobileFilterVisibility);
  };
  const sortSliderVisibilityHandler = () => {
    setSortMobileVisibility(!sortMobileVisibility);
  };
  const getBrandList = async (): Promise<void> => {
    try {
      const {
        data: { brand = [] },
      } = await pimService.getBrandList();
      const brandList: [] = brand.map((item: any) => ({
        label: item.name,
        value: item.code,
      }));
      setBrands([{ label: "All brands", value: "" }, ...brandList]);
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to fetch Brand list");
    }
  };

  useEffect(() => {
    getFinanceList();
    getBrandList();
    setFilterVisibility(false);
  }, [page, rowsPerPage, search, financeFilter]);

  const handleCheckboxChange = (id: any, value: any) => {
    const result = financeData.map((obj: any) => {
      if (obj._id === id) {
        return { ...obj, checkedStatus: value };
      }
      return obj;
    });
    setFinanceData(result);
  };

  const handleDeleteAll = async () => {
    setDeleteConfirmationDialogVisibility(true);
  };

  const atleastCheckOne = financeData.some(
    (obj: any) => obj["checkedStatus"] === true
  );

  const resultString = `Delete ${financeData.filter((obj: any) => {
    return obj.checkedStatus === true
  }).length} Items`

  return (
    <div className="priceList-table">
      <div className="price-tab">
        <div>
          <h3>Finance List</h3>
        </div>

        <div className="priceList-header">
          <div>
            <div>
              <Input
                className="inputwrp"
                placeholder="Search"
                onChange={searchHandler}
              />
            </div>
          </div>

          <div className="price-main-btn">
            <div className="price-btn">
              <div className="download-pricelist-temp">
                <DownloadTemplates
                  heading={financeBulkUploadColumns}
                  ReportTitle={"Finance"}
                />
              </div>
            </div>

            <div className="upload-wrp">
              <button
                className="btn primary"
                onClick={() => setOpenPriceListPopup(true)}
              >
                Upload Finance List
              </button>
            </div>

            {isDesktop ? (
              <div className={`finance-filter`}>
                <FontAwesomeIcon
                  className="cursor-pointer sort-filter-icon"
                  icon={faFilter}
                  onClick={sliderVisibilityHandler}
                />
              </div>
            ) : (
              <div className={`finance-filter-mobile`}>
                <div className="filter-wrp">
                  <div>
                    <FontAwesomeIcon
                      className="cursor-pointer sort-filter-icon"
                      icon={faFilter}
                      onClick={sliderVisibilityHandler}
                    />
                  </div>
                  <div>
                    <p>Filter</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {atleastCheckOne && (
        <button
          onClick={(e: any) => {
            e.stopPropagation();
            handleDeleteAll();
          }}
          className="delete_btn show"
        >
          {resultString} <Icon icon={faTrash} />
        </button>
      )}

      <div className="financeList-table">
        <table className="sticky-left">
          <thead>
            <tr>
              <th>
                <div className="tableHeader-Elements-finance">
                  <Checkbox
                    value={watch("selectAll")}
                    onChange={(checkedStatus: boolean) => {
                      setValue("selectAll", checkedStatus);
                      const result = financeData.map(
                        (item: any, index: number) => {
                          setValue(`${item._id}`, checkedStatus);
                          return { ...item, checkedStatus: checkedStatus };
                        }
                      );
                      setFinanceData(result);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">
                  Item Code
                  <FinanceSortingComponent
                    offerColumnName="itemCode"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">
                  SKU
                  <FinanceSortingComponent
                    offerColumnName="sku"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">
                  Brand
                  <FinanceSortingComponent
                    offerColumnName="brand"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">
                  Model
                  <FinanceSortingComponent
                    offerColumnName="model"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">
                  Finance Name
                  <FinanceSortingComponent
                    offerColumnName="financeName"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">
                  Scheme Name
                  <FinanceSortingComponent
                    offerColumnName="schemeName"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">
                  Processing Fees
                  <FinanceSortingComponent
                    offerColumnName="processingFees"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">
                  Interest(%)
                  <FinanceSortingComponent
                    offerColumnName="interest"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">
                  Channel
                  <FinanceSortingComponent
                    offerColumnName="channel"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>

              <th>
                <div className="tableHeader-Elements-finance">
                  Version
                  <FinanceSortingComponent
                    offerColumnName="version"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">
                  Start Date
                  <FinanceSortingComponent
                    offerColumnName="startDate"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="tableHeader-Elements-finance">
                  End Date
                  <FinanceSortingComponent
                    offerColumnName="endDate"
                    financeData={financeData}
                    sortedOffers={(sortedOffers: any) => {
                      setFinanceData(sortedOffers);
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {financeData?.map((offers: any, index: number) => (
              <tr
                key={index}
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/offers/finance_list/edit/${offers._id}`)
                }
              >
                <td
                  data-label="Item Code"
                  className="checkbox_wrap"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    key={index}
                    {...register(`${offers._id}`)}
                    value={watch(`${offers._id}`)}
                    onChange={(checkedStatus: boolean) => {
                      setValue(`${offers._id}`, checkedStatus);
                      handleCheckboxChange(offers._id, checkedStatus);
                      const isSelectedAll = financeData.every((e: any) =>
                        watch(`${e._id}`)
                      );
                      isSelectedAll
                        ? setValue("selectAll", true)
                        : setValue("selectAll", false);
                    }}
                  />
                </td>
                <td data-label="Item Code">{offers?.itemCode}</td>
                <td data-label="SKU">{offers?.sku}</td>
                <td data-label="Brand">{offers?.brand}</td>
                <td data-label="Brand">{offers?.model}</td>
                <td data-label="Finance Name">{offers?.financeName}</td>
                <td data-label="Scheme Name">{offers?.schemeName}</td>
                <td data-label="Processing Fees">{offers?.processingFees}</td>
                <td data-label="Interest(%)">{offers?.interest}</td>
                <td data-label="Channel">{getChannelNames(offers.channel)}</td>
                <td data-label="Applies To">                    {
                  !_.isEmpty(offers?.publish )?
                    <div>
                      <div style={{ color: 'green', fontWeight: 'bold' }}>
                        {`
                          Active Version : ${offers?.publish?.version}`
                        }
                      </div>
                      <div className="tooltip" data-content={moment(offers?.publish?.publishedAt).format('DD-MMM-YYYY hh:mm A')} style={{color:'green', fontWeight:'bold'}}>

                        {`
                          Published At : ${moment(offers?.publish?.publishedAt).format('DD-MMM-YYYY hh:mm A')}
                        `}
                      </div>
                      <div style={{ color: '#ff8000', fontWeight: 'bold' }}>
                        {`Current Version : ${offers?.version}`
                        }
                      </div>
                    </div> :
                    <div style={{ color: 'green', fontWeight: 'bold' }}>{"New Scheme"}</div>}</td>
                <td data-label="Start Date">
                  {moment(offers.startDate).format("DD-MMM-YYYY hh:mm A")}
                </td>
                <td data-label="End Date">
                  {moment(offers.endDate).format("DD-MMM-YYYY hh:mm A")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {financeData.length === 0 && <div className="norecord">{norecord}</div>}
      </div>
      {openPriceListPopup && (
        <FinanceListUpload
          reload={() => getFinanceList()}
          setBulkVisibility={() => setOpenPriceListPopup(false)}
        />
      )}
  {financeData.length > 0 ? (
      <TablePagination
        backIconButtonProps={
          page === 0 ? { disabled: true } : { disabled: false }
        }
        nextIconButtonProps={
          page + 1 === totalPages || totalPages === 0
            ? { disabled: true }
            : { disabled: false }
        }
        component="div"
        count={totalPages}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ) : (
      ""
    )}

      {
        filterVisibility && <FinanceFilter filterVisibility={filterVisibility} setFilterVisibility={setFilterVisibility} />

      }

      <div
        className={`slider-right mobile-filter-right ${sortMobileVisibility ? "show" : "hide"
          } `}
      >
        <div className="add-product-slider">
          <div className="slider-header">
            <h1>Sort By</h1>
            <div className="action-icon" onClick={sortSliderVisibilityHandler}>
              <Icon icon={faXmark} />
            </div>
          </div>
        </div>

        <div className="sort-order-list">
          <form>
            <div className="sort-input">
              <DropDown
                options={sortColumnTypes}
                register={register("columnName")}
                onChange={(e) => setFinanceName(e.target.value)}
                label="SortBY Column"
                name="columnName"
              // value={columnName}
              />
            </div>

            <div className="sort-input">
              <DropDown
                options={sortTypes}
                register={register("sortByOrder")}
                onChange={(e) => setFinanceName(e.target.value)}
                label="Sort Order"
                name="sortByOrder"
              // value={sortByOrder}
              />
            </div>

            <div className="filter-action">
              <button
                className="btn secondary"
                type="reset"
                onClick={sortSliderVisibilityHandler}
              >
                Cancel
              </button>
              <button className="btn primary" type="submit">
                Sort
              </button>
            </div>
          </form>
        </div>
      </div>

      <DeleteConfirmationDialog
        show={deleteConfirmationDialog}
        onConfirmation={deleteConfirmationHandler}
      />

      <div
        className={`sidebar-overlay ${filterVisibility == true ? "active" : ""
          }`}
        onClick={sliderVisibilityHandler}
      ></div>
    </div>
  );
};

export default FinanceList;
