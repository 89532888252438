import ProductOffer from "./ProductOffer";
import EmiOffer from "./EmiOffer";
import GroupOffer from "../OffersGroups"

import _ from "lodash";
import { useState } from "react";
import OffersView from "./OffersView";
import { FinanceData } from "common/constant";

interface ProductOfferListProp {
  itemName: string;
  offerList: any;
  emiList: any,
  product: any,
  offerGroup: any,
  channel: string

}

const ProductOfferList = (props: ProductOfferListProp) => {
  const { offerList, offerGroup, product, channel } = props;
  let { emiList } = props;

  const [viewType, setViewType] = useState(1);

  const sortArrayByOrder = (array: any, order: any) => {
    return _.sortBy(array, item => {
      const index = order?.indexOf(item?.offerId);
      return index === -1 ? Infinity : index;
    });
  };
  emiList = sortArrayByOrder(emiList, FinanceData);

  return (
    <div className="offer-lists">
      <div className="offer-view">
        <span onClick={() => setViewType(1)} className={`cursor-pointer ${viewType === 1 ? 'text-blue-500 ' : ' text-gray-500'}`}>{` Group View`}</span>|
        <span onClick={() => setViewType(0)} className={`cursor-pointer ${viewType === 0 ? 'text-blue-500 ' : ' text-gray-500'}`}>Standard View</span>
      </div>
      {
        viewType === 0 ? <>
          {
            offerGroup?.map((offers: any, index: number) => {

              return (<>
                <GroupOffer offers={offers} index={index} />
              </>
              )


            })
          }
          {
            offerGroup?.length ? <div className="dashed-line">
              <span className="line-text">Group Offer Ended</span>
            </div> : <></>
          }


          {
            offerList?.map((offer: any, index: number) => {
              return (
                <ProductOffer
                  key={index}
                  offer_details={offer}
                  index={index}
                  total_count={offerList.length}
                  product={product}
                />
              );
            })
          }

        </> :
          <>{
            offerList.length > 0 && <OffersView channel={channel} product={product} offerList={offerList} />
          }
          </>}


      {
        !_.isEmpty(emiList) ? <div className="offers-section">
          <div className="offer-list offer-header ">
            <span>EMI Details</span>
            <span></span>
          </div>
          <div className="offer-content">

            {emiList?.map((emi: any, index: number) => {
              return (
                <EmiOffer
                  key={index}
                  index={index}
                  emi_details={emi}
                />
              );
            })}
          </div>
        </div > : ""

      }

    </div >
  );
};

export default ProductOfferList;
