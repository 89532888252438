import {
  faTrash,
  faStickyNote,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import Icon from "components/Icon";
import { STATUS } from "config/config";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import appContext from "context/app.context";

import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";

import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { CouponsService } from "services/coupon.service";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Input from "components/UseInput";
import DropDown from "components/DropDown";
import {
  couponStatusAdmin,
  couponStatusFinance,
  couponStatusLogistics,
  couponStatusStaff,
  roles,
} from "common/constant";
import { useSelector } from "react-redux";
import { RootStore } from "redux/store";
import RejectedConfirmationDialog from "containers/Dialogs/RejectedConfirmationDialog";
import { RejectedNotesDialog } from "containers/Dialogs/RejectedNotesDialog";
import { usePermission } from "components/Permission";

const Coupons: React.FC = () => {
  document.title = "Poorvika - Coupons";

  let navigate = useNavigate();
  /* State variables */
  const [
    deleteConfirmationDialogVisibilty,
    setDeleteConfirmationDialogVisibility,
  ] = useState<boolean>(false);
  const [selectedAttributeIndex, setSelectedAttributeIndex] =
    useState<number>(0);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [coupons, setCoupons] = useState<any>([]);
  const userRole: any = useSelector<RootStore>(
    (state) => state.userDetails.userDetails.userRole
  );

  const [couponStatus, setCouponStatus] = useState("");
  const [couponId, setCouponId] = useState<string>("");
  const [rejectDialog, setRejectDialogVisibility] = useState<boolean>(false);
  const [rejectedNotes, setRejectedNotes] = useState<Array<any>>([]);
  const [rejectNotesDialog, setRejectNotesDialogVisibility] =
    useState<boolean>(false);
  const userName: any = useSelector<RootStore>(
    (state) => state.userDetails.userDetails.userName
  );
  const [createAndEditCouponsPermission] = usePermission([
    "CREATE_AND_EDIT_COUPONS",
  ]);
  const [editCouponStatusPermission] = usePermission(["EDIT_COUPON_STATUS"]);
  const [deleteCouponPermission] = usePermission(["DELETE_COUPONS"]);

  /* Context  */
  const {
    showToaster,
    setSpinnerVisibility,
    setMenuIndex,
    setOfferData,
    setSelectedProducts,
    setViewOnlyMode,
  } = useContext(appContext);
  const dropdownOptionBasedOnRoles =
    userRole === roles.SuperAdmin
      ? couponStatusAdmin
      : userRole === roles.Logistics
      ? couponStatusLogistics
      : userRole === roles.FinanceManager
      ? couponStatusFinance
      : couponStatusStaff;

  /* Services */
  const couponsService = new CouponsService();

  /* Form hooks */

  const getCoupons = async (): Promise<void> => {
    const couponListResponse = await couponsService.getCoupons(
      rowsPerPage,
      page
    );
    if (couponListResponse.status === STATUS.SUCCESS) {
      setCoupons(couponListResponse.data?.couponList);
      setTotalPages(couponListResponse.data?.totalPages);
    } else {
      showToaster(EToasterVariants.error, "Unable to get Coupon List");
    }
  };

  const deletePopupHandler = (attributeIndex: number) => {
    setSelectedAttributeIndex(attributeIndex);
    setDeleteConfirmationDialogVisibility(true);
  };

  /* Delete Confirmation handler */
  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      try {
        setSpinnerVisibility(true);
        const deleteResponse = await couponsService.deleteCoupon(
          coupons[selectedAttributeIndex]._id
        );
        if (deleteResponse.status === STATUS.SUCCESS) {
          setDeleteConfirmationDialogVisibility(false);
          await getCoupons();
          setSpinnerVisibility(false);
          showToaster(EToasterVariants.success, "Coupon Deleted Successfully");
        }
      } catch (error) {
        setSpinnerVisibility(false);
        showToaster(EToasterVariants.error, "Unable to delete the record");
      }
    } else {
      setDeleteConfirmationDialogVisibility(false);
    }
  };
  // Coupon Search handler
  const searchHandler = async (search: any) => {
    if (search.length > 2) {
      const couponListResponse = await couponsService.getCoupons(
        rowsPerPage,
        page,
        search
      );

      setSpinnerVisibility(false);
      if (couponListResponse.status === STATUS.SUCCESS) {
        setCoupons(couponListResponse.data?.couponList);
        setTotalPages(couponListResponse.data?.totalPages);
      } else {
        showToaster(EToasterVariants.error, "Unable to get Offer List");
      }
    } else if (search?.length === 0) {
      getCoupons();
    }
  };

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${
      props.count !== -1 ? props.count : `more than ${props.to}`
    }`;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getCoupons();
  }, [page, rowsPerPage]);

  const navigateToDetails = async (e: any, id: string) => {
    e.preventDefault();
    navigate(`/offers/coupons/edit/${id}`);
    setMenuIndex(0);
  };

  /* Coupons status change handler */
  const handleStatus = async (id: string, status: string) => {
    setCouponStatus(status);
    if (status.includes("Rejected")) {
      setCouponId(id);
      setRejectDialogVisibility(true);
      return;
    }
    try {
      const statusChangeResponse = await couponsService.statusChange(
        id,
        status
      );
      if (statusChangeResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, statusChangeResponse.message);
        await getCoupons();
      } else {
        showToaster(
          EToasterVariants.error,
          statusChangeResponse.error.response.data.message
        );
      }
    } catch (error: any) {
      showToaster(EToasterVariants.error, error.message);
    }
  };

  const rejectConfirmationHandler = async (confirmation: {
    status: boolean;
    note?: any;
    rejectedOn?: any;
  }): Promise<void> => {
    if (confirmation.status) {
      try {
        const statusChangeResponse = await couponsService.statusChange(
          couponId,
          couponStatus,
          {
            rejectedBy: userName,
            note: confirmation.note,
            rejectedOn: confirmation.rejectedOn,
          }
        );
        if (statusChangeResponse.status === STATUS.SUCCESS) {
          showToaster(EToasterVariants.success, statusChangeResponse.message);
          await getCoupons();
          setRejectDialogVisibility(false);
        } else {
          showToaster(EToasterVariants.error, statusChangeResponse.message);
        }
      } catch (error: any) {
        showToaster(EToasterVariants.error, error.message);
      }
    } else {
      setRejectDialogVisibility(false);
    }
  };

  const showRejectDialogHandler = (notes: string[]) => {
    setRejectedNotes(notes);
    setRejectNotesDialogVisibility(true);
  };

  useEffect(() => {
    setViewOnlyMode(true);
  }, []);

  return (
    <div className="coupons-table">
      <div
        className={`coupon-header ${
          createAndEditCouponsPermission ? "justify-between" : "justify-end"
        }`}
      >
        {createAndEditCouponsPermission && (
          <button
            className="btn primary"
            onClick={() => {
              navigate("/offers/coupons/couponType");
              setMenuIndex(0);
              setSelectedProducts([]);
              setOfferData({});
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="plus_icon" />
            New Coupon
          </button>
        )}
        <Input
          className="inputwrp"
          placeholder="Search Coupon Name"
          onChange={searchHandler}
        />
      </div>

      <table className="shadow couponinner-table">
        <thead>
          <tr>
            <th>Coupon Name </th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Coupon Type</th>
            <th>Applies To</th>
            <th>Version</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {coupons?.map((coupons: any, index: number) => {
            return (
              <tr
                key={index}
                onClick={(e) => navigateToDetails(e, coupons?._id)}
                className="cursor-pointer"
              >
                <td data-label="Coupon name">{coupons.couponName}</td>
                <td data-label="Start date">
                  {moment(coupons.validityStartDate).format(
                    "DD-MMM-YYYY hh:mm A"
                  )}
                </td>
                <td data-label="End date">
                  {moment(coupons.validityEndDate).format(
                    "DD-MMM-YYYY hh:mm A"
                  )}
                </td>
                <td data-label="Coupon Type">{coupons.couponCodeType}</td>
                <td data-label="Applies To">{coupons.appliesTo}</td>
                <td data-label="Applies To">
                  {" "}
                  {coupons?.publish ? (
                    <div>
                      <div style={{ color: "green", fontWeight: "bold" }}>
                        {`
                          Active Version : ${coupons?.publish?.version}`}
                      </div>
                      <div
                        className="tooltip"
                        data-content={moment(
                          coupons?.publish?.publishedAt
                        ).format("DD-MMM-YYYY hh:mm A")}
                        style={{ color: "green", fontWeight: "bold" }}
                      >
                        offers
                        {`
                          Published At : ${moment(
                            coupons?.publish?.publishedAt
                          ).format("DD-MMM-YYYY hh:mm A")}
                        `}
                      </div>
                      <div style={{ color: "#ff8000", fontWeight: "bold" }}>
                        {`Current Version : ${coupons?.version}`}
                      </div>
                    </div>
                  ) : (
                    <div style={{ color: "green", fontWeight: "bold" }}>
                      {"New Coupon"}
                    </div>
                  )}
                </td>
                <td className="coupon-status">
                  {editCouponStatusPermission ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="status-wraper">
                        {dropdownOptionBasedOnRoles.includes(
                          coupons?.status ?? "Draft"
                        ) ? (
                          <DropDown
                            value={coupons?.status ?? "Draft"}
                            options={dropdownOptionBasedOnRoles}
                            label="Status"
                            name="status"
                            disabled={
                              coupons?.status === "Inactive" ||
                              coupons?.status === "Expired"
                            }
                            onChange={(e: any) =>
                              handleStatus(coupons?._id, e.target.value)
                            }
                          />
                        ) : (
                          <div
                            className={`status ${coupons?.status
                              ?.toLowerCase()
                              .replaceAll(" ", "_")}`}
                          >
                            {coupons?.status ?? "Draft"}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`status ${coupons?.status
                        ?.toLowerCase()
                        .replaceAll(" ", "_")}`}
                    >
                      {coupons?.status ?? "Draft"}
                    </div>
                  )}
                </td>
                <td data-label="Action" className="coupon-action">
                  <div
                    onClick={(e: any) => {
                      e.stopPropagation();
                      navigate(`/offers/coupons/edit/${coupons?._id}`);
                      setOfferData(coupons);
                      if (coupons?.appliesTo === "Cart") {
                        setMenuIndex(2);
                      } else {
                        setMenuIndex(3);
                      }
                    }}
                  >
                    <Icon icon={faCircleInfo} />
                  </div>
                  {coupons.status === "Draft" && deleteCouponPermission && (
                    <div
                      className="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        deletePopupHandler(index);
                      }}
                    >
                      <Icon icon={faTrash} />
                    </div>
                  )}
                  {coupons?.status?.includes("Rejected") && (
                    <div
                      onClick={(e: any) => {
                        e.stopPropagation();
                        showRejectDialogHandler(coupons?.rejectedNotes || []);
                      }}
                    >
                      <Icon icon={faStickyNote} />
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination
        backIconButtonProps={
          page === 0 ? { disabled: true } : { disabled: false }
        }
        nextIconButtonProps={
          page + 1 === totalPages || totalPages === 0
            ? { disabled: true }
            : { disabled: false }
        }
        component="div"
        count={totalPages}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DeleteConfirmationDialog
        show={deleteConfirmationDialogVisibilty}
        onConfirmation={deleteConfirmationHandler}
      />

      <RejectedConfirmationDialog
        show={rejectDialog}
        onConfirmation={rejectConfirmationHandler}
      />
      {rejectNotesDialog && (
        <RejectedNotesDialog
          show={rejectNotesDialog}
          onExit={setRejectNotesDialogVisibility}
          notes={rejectedNotes}
        />
      )}
    </div>
  );
};

export default Coupons;
