import Keycloak from 'keycloak-js';

const { REACT_APP_SSO_URL = "", REACT_APP_SSO_REALM = "", REACT_APP_SSO_CLIENT = "" } = process.env;

const keycloak: any = new Keycloak({
  url: REACT_APP_SSO_URL,
  realm: REACT_APP_SSO_REALM,
  clientId: REACT_APP_SSO_CLIENT,
});

export default keycloak;
