import "./styles.scss";

import _ from 'lodash'
import ComboFreeDisplay from "./ComboFreeDisplay";
import { toLocaleNumber } from "common/commonFunc";

interface ProductOfferProp {
    otherOffers: [];
    setOtherOffers: any,
    product: any
}

export default function OtherOffers(props: ProductOfferProp) {
    const { otherOffers, setOtherOffers, product } = props;

    const handleOfferAction = (offerId: string, value: string) => {

        setOtherOffers((prevOffers: any) => prevOffers.map((item: any) => {
            if (item.offerId === offerId) {
                item[value] = !item[value];

                if (value === 'showTc') {
                    item.showBan = false;
                    item.showOffer = false;
                } else if (value === 'showBan') {
                    item.showTc = false;
                    item.showOffer = false;
                } else if (value === 'showOffer') {
                    item.showTc = false;
                    item.showBan = false;
                }

            }
            return item;
        }));

    };

    const handleCheckboxClick = (offer: any) => {
        // if the item is exclusive and all other items are unchecked
        if (offer.exclusivity === 'Yes') {
            const offers = otherOffers.map((item: any) => {
                if (item.offerId !== offer.offerId && item.offerType === offer.offerType && item.checked) {
                    item.checked = !item.checked;
                } else if (item.offerId === offer.offerId) {
                    item.checked = !item.checked;
                }
                return item;
            })

            setOtherOffers(offers);
        } else {

            setOtherOffers((prevOffers: any) => prevOffers.map((item: any) => {
                if (item.offerId === offer.offerId) {
                    item.checked = !item.checked;
                }
                return item;
            }));
        }

    };


    return (

        <div className="offer-rows">
            {
                otherOffers.map((offer: any) => {
                    return (
                        <div className="offer-row">
                            <div className="offer-details">
                                <div className="offer-list">
                                    <div className="offer-names">
                                        <span className="offer-name-items">
                                            <input disabled={offer.offerSelection === 'mandatory'} name={offer.offerId} checked={offer.checked} id={offer.offerId}
                                                onClick={() => handleCheckboxClick(offer)} type="checkbox"></input>
                                        </span>
                                        <span className="offer-name-items">{offer?.offerName}</span>

                                        <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showTc')}>{`T&C`}</span>
                                        <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showBan')}>{`Details`}</span>
                                        {(!_.isEmpty(offer.comboOffers) || !_.isEmpty(offer.freebies)) &&
                                            <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showOffer')}>{`+Show Products`}</span>

                                        }

                                    </div>

                                    <div className="offer-prices">
                                        {

                                            (offer.offerType !== 'Standard EMI' || offer.offerType !== 'Offer Text') && <span className={!offer.checked ? 'text-strike' : ''}>{toLocaleNumber(offer?.maxBenefites)}</span>
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className="offer-details">
                                {offer.showTc &&
                                    <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }}>
                                    </div>
                                }
                                {offer.showBan &&
                                    <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }}>
                                    </div>
                                }

                                <div className={offer.showOffer ? 'show offer-expanded' : 'hide'}>
                                    {!_.isEmpty(offer?.comboOffers) && _.isArray(offer?.comboOffers[product?.erp_item_code]) &&

                                        <ComboFreeDisplay
                                            maxBenefites={offer?.maxBenefites}
                                            isCombo={true}
                                            offer={offer}
                                            comboFreeOffers={otherOffers}
                                            setComboFreeOffers={setOtherOffers}
                                            offers={offer.comboOffers[product?.erp_item_code]}
                                        />}

                                    {!_.isEmpty(offer?.freebies) && _.isArray(offer?.freebies[product?.erp_item_code]) &&
                                        <ComboFreeDisplay
                                            maxBenefites={offer?.maxBenefites}
                                            isCombo={false}
                                            offer={offer}
                                            comboFreeOffers={otherOffers}
                                            setComboFreeOffers={setOtherOffers}
                                            offers={offer.freebies[product?.erp_item_code]}
                                        />}
                                </div>

                            </div>

                        </div>
                    )
                })
            }

        </div>



    );
}
