import { usePermission } from "components/Permission";
import { editorToolbar, MultiSelectValues } from "common/constant";
import MultiSelect from "components/MultiSelect";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import appContext from "context/app.context";
import { useContext } from "react";
import _ from "lodash";
import ReactQuill from "react-quill";

const BannerInfoSection = (props: any) => {
  const {
    watch,
    viewOnlyMode,
    register,
    errors,
    setValue,
    permissionError,
    editorConfig,
    dynamicText,
    textEditorVisibility,
  } = props;

  const { offerData } = useContext(appContext);

  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

  const bannerInfoPermission = _.isEmpty(offerData)
    ? createOffersPermission
    : !viewOnlyMode && editOffersPermission;

  return (
    <div className="sub-section">
      <p className="section-heading">
        8. Banner Info
        {bannerInfoPermission && (
          <div className="tooltipContainer">
            <Icon icon={faCircleInfo} />
            <div>{permissionError}</div>
          </div>
        )}
      </p>
      <div className="input-fields-wrapper">
        <MultiSelect
          disabled={!bannerInfoPermission}
          fieldName="Banner Fields"
          fields={MultiSelectValues.bannerFieldTypes}
          selectedValues={watch("bannerFieldTypes")}
          register={register("bannerFieldTypes", {
            required: "bannerFieldTypes is Required",
          })}
          error={errors.bannerFieldTypes?.message as any}
          onChange={(values: any) => setValue("bannerFieldTypes", values)}
          focusHandleText={
            <p>
              This will be the Name of Offer Displayed in Website / App and CRM{" "}
              <br /> EMI : EMI to be selected only for Pine Offers and NBFC
              Offers. ,<br /> TC : Select the Buttons that needs to be seen in
              the Offer Display in Website / App and Store.{" "}
            </p>
          }
        />
      </div>
      <div className="editor">
        <div className="editor-field-wrapper">
          <p className="heading">Banner Description :</p>
          <p>{`Note : use constant value ${dynamicText} and Same name an Rule Settings manipulate the dynamic values `}</p>
        </div>
        <ReactQuill
          readOnly={!bannerInfoPermission}
          theme="snow"
          value={watch("bannerDiscription")}
          onChange={(value: any) => setValue("bannerDiscription", value)}
          modules={{
            toolbar: editorToolbar,
          }}
        />
      </div>
      <div className="offerBox">
        <div className="offerBox dottedborder">
          <div
            className="offer"
            dangerouslySetInnerHTML={{ __html: watch("bannerDiscription") }}
          />
          <div></div>
          <div className="BannerOfferFieldTypes">
            {watch("bannerFieldTypes")?.map((data: any) => (
              <span>{data.name}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerInfoSection;
