import React, { useContext, useEffect, useState } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import appContext from "context/app.context";
import OfferGroupForm from "../Forms/OfferGroupForm";
import AddOffersTab from "../AddOfferGroup/AddOffersTab";
import { usePermission } from "components/Permission"; 
import { SubMenus } from "common/constant";
import HistorySideMenu from "pages/HistoryPage";


const EditOffersGroupPage = () => {
  const { menuIndex, setMenuIndex, offerGroupData } = useContext(appContext);
  const [viewMode, setViewMode] = useState<boolean>(true);
  const [createAndEditOffersgroupPermission] = usePermission(["CREATE_AND_EDIT_OFFERSGROUP"])

useEffect(()=>{
  setMenuIndex(0)
},[])

  return (
    <div className="offers-page">
      <div className="header">
        <div className="offers-page-heading">
          <h3 className="offers-page-heading-label">{offerGroupData.offerGroupName}</h3>

          <p className={`offers-page-heading-status ${offerGroupData?.status?.toLowerCase()?.replaceAll(" ", "_")}`}>{offerGroupData.status}</p>
        </div>
        {createAndEditOffersgroupPermission && viewMode && (
          <div
            className="edit-offer-action-warpper"
            onClick={() => {
              setViewMode(false)
            }}
          >
            <Icon icon={faPencil} />
            <p>Edit Offer</p>
          </div>
        )}
      </div>

      <div className="tabs">
        {SubMenus?.map((menu: string, index: number) => {
          return (
            <div
              className={index === menuIndex ? "active" : ""}
              onClick={() => setMenuIndex(index)}
            >
              <p>{menu}</p>
            </div>
          );
        })}
      </div>
      <div className="add-offers-page-content">
        {menuIndex === 0 && (
          <div className="offer-info shadow">
            <OfferGroupForm viewOnlyMode={viewMode} />
          </div>
        )}
        {menuIndex === 1 && (
          <div className="offer-info shadow">
            <AddOffersTab viewOnlyMode={viewMode} />
          </div>
        )}
        {/* {menuIndex === 2 && (
          <div className="offer-info shadow">
            <RulesTab viewOnlyMode={viewMode} />
          </div>
        )} */}
         {menuIndex === 2 && (
          <div className="offer-info shadow">
            <HistorySideMenu offerId={offerGroupData?._id || ""} name="Group Offers History" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditOffersGroupPage;
