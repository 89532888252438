/* eslint-disable no-useless-concat */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import keycloak from '../keycloak';
export class BaseService {
  httpClient: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

  rulesEngineClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_RULE_ENGINE_URL,
  });

  async autoLogout(): Promise<void> {
    localStorage.clear();
    console.log('inside autologout');

    window.open(window.location.origin, "_self");
  }


  constructor() {
    /* HTTP Inceptors with Axios Instance */
    this.httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
      if (!request.headers?.Authorization) {
        let authorization = '';
        const accessType = localStorage.getItem("accessType");
        if (accessType === 'Bearer') {
          if (keycloak?.authenticated) {
            authorization = `Bearer ${keycloak?.token}`
            keycloak.updateToken(100).then((refreshed) => {
              if (refreshed)
                authorization = `Bearer ${keycloak?.token}`
            })
          }
        } else if (accessType === 'OFFER_TOKEN')
          authorization = `OFFER_TOKEN ${localStorage.getItem("accessToken")}`;

        request.headers = {
          authorization: authorization,
          ...request.headers,
        };
      }

      return request;
    });

    /* HTTP Response Interceptor to logout the application on Unauthorized Response */
    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: any) => {

        const err = Promise.reject(error);

        if (error.response.status === 401) {
          this.autoLogout();
        }

        return err;
      }
    );
  }


}

