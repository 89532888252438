import React, { useContext } from "react";

import PoorvikaBrandLogo from "assets/images/Poorvikabrandlogo.svg";
import SideMenuItem from "components/SideMenuItem";
import appContext from "context/app.context";
import {
  faBars, faGroupArrowsRotate, faTicket,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faPercentage,
  faShop,
  faHistory,
  faCreditCard,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
// import { sidemenu } from "../../common/constant"
import { RootStore } from "redux/store";
import { useSelector } from "react-redux";
import { usePermission } from "components/Permission";

const SideMenu: React.FC = () => {
  const { sideMenuVisibility, setSideMenuVisibility } = useContext(appContext);
  const userRole: any = useSelector<RootStore>((state) => state.userDetails.userDetails.userRole);
  const isAdmin = userRole === "Super Admin"

  /* Checkinig Sidemenu Permission  */
  const [viewOffersPermission] = usePermission(["VIEW_OFFERS"])
  const [viewOffersgroupPermission] = usePermission(["VIEW_OFFERS_GROUP"])
  const [viewShowoffersPermission] = usePermission(["VIEW_SHOW_OFFERS"])
  const [viewCouponsPermission] = usePermission(["VIEW_COUPONS"])
  const [viewFinancelistPermission] = usePermission(["VIEW_FINANCE_LIST"])
  const [viewSettingsPermission] = usePermission(["VIEW_SETTINGS"])
  const [viewHistoryPermission] = usePermission(["VIEW_HISTORY"])

  return (
    <>
      <div
        className="overlay"
        onClick={() => setSideMenuVisibility(!sideMenuVisibility)}
      ></div>
      <div className="side-menu">
        <div className="brand">

          <>
            <img src={PoorvikaBrandLogo} alt="" className="brand-logo" />
            <FontAwesomeIcon
              className="action-icon mobileHide"
              icon={faBars}
              onClick={() => setSideMenuVisibility(!sideMenuVisibility)}
            />
          </>

        </div>
        <div className="sidemenu-items">


          {(viewOffersPermission || isAdmin) && <SideMenuItem
            name={"Offers"}
            navigationLink={"/offers/list"}
            icon={faPercentage}
          />}

          {(viewOffersgroupPermission || isAdmin) && <SideMenuItem
            name={"Offer Grouping"}
            navigationLink={"/offers/offer_grouping"}
            icon={faGroupArrowsRotate}
          />}



          {(viewCouponsPermission || isAdmin) && <SideMenuItem
            name={"Coupons"}
            navigationLink={"/offers/coupons/list"}
            icon={faTicket}
          />}

          {(viewFinancelistPermission || isAdmin) && <SideMenuItem
            name={"Finance List"}
            navigationLink={"/offers/finance_list"}
            icon={faCreditCard}
          />}

          {(viewShowoffersPermission || isAdmin) && <SideMenuItem
            name={"Show Offers"}
            navigationLink={"/offers/show_offers"}
            icon={faShop}
          />}

          {(viewShowoffersPermission || isAdmin) && <SideMenuItem
            name={"Published Objects"}
            navigationLink={"/offers/publish"}
            icon={faUpload}
          />}

          {(viewSettingsPermission || isAdmin) && <SideMenuItem
            name={"Settings"}
            navigationLink={"/offers/settings"}
            icon={faGear}
          />}

          {(viewHistoryPermission || isAdmin) && <SideMenuItem
            name={"History"}
            navigationLink={"/offers/history"}
            icon={faHistory}
          />}

          {/* export const sidemenu = [ */}
          {/*  { 
     name:"Dashboard",
   navigationLink:"/offers/dashboard",
     icon:faTag
   },
   { 
     name:"Coupon Reports",
     navigationLink:"/offers/coupons/reports",
     icon:faReceipt
   },
   { 
     name:"Coupons History",
     navigationLink:"/offers/coupons/couponhistory",
    icon:faHistory
     }
   ] */}
        </div>
      </div>
    </>
  );
};

export default SideMenu;
