import InputField from "components/InputField";
import { toLocaleNumber } from 'common/commonFunc'
import "./styles.scss";

interface ProductOfferProp {
    exchangeOffers: [];
    setExchangeOffers: any,
    product: any
}

export default function ExchangeOffers(props: ProductOfferProp) {
    const { exchangeOffers, setExchangeOffers } = props;
    let isApplied = false;
    const handleOfferAction = (offerId: string, value: string, offerType: string) => {

        const checked_offer: any = exchangeOffers.map((item: any) => {
            if (item.offerId === offerId) {
                item[value] = !item[value];

                if (value === 'showTc') {
                    item.showBan = false;
                }
                else if (value === 'showBan') {
                    item.showTc = false;
                }

            }

            return item;
        });

        setExchangeOffers(checked_offer);
    };

    const handleOfferTradeIn = (event: any, offerId: any, tradeValues: any = []) => {

        if (event.keyCode === 13) {
            const inputValue = Number(event.target.value);
            if (!isNaN(inputValue)) {
                const traded_offer: any = exchangeOffers.map((item: any) => {

                    if (item.offerId === offerId) {
                        item.deviceVal = inputValue;

                        if (tradeValues.length > 0) {
                            const matchedTradeIn = tradeValues.find(
                                (tradeIn: any) => inputValue >= tradeIn.startVal && inputValue <= tradeIn.endVal
                            );

                            if (matchedTradeIn) {

                                if (item.exchangeBonus > 0) {
                                    if (matchedTradeIn.discountAmount !== 0) {
                                        item.exchangeVal = item.exchangeBonus;
                                        item.maxBenefites = item.exchangeBonus + inputValue;
                                    } else {
                                        item.maxBenefites = matchedTradeIn.discountAmount + inputValue;
                                        item.exchangeVal = matchedTradeIn.discountAmount;
                                    }

                                } else {
                                    item.maxBenefites = matchedTradeIn.discountAmount + inputValue;
                                    item.exchangeVal = matchedTradeIn.discountAmount;
                                }

                            } else if (item.exchangeBonus > 0) {
                                item.exchangeVal = item.exchangeBonus;
                                item.maxBenefites = item.exchangeBonus + inputValue;
                            }
                        } else {
                            if (item.exchangeBonus > 0) {
                                item.exchangeVal = item.exchangeBonus;
                                item.maxBenefites = item.exchangeBonus + inputValue;
                            } else {
                                item.maxBenefites = inputValue;
                                item.exchangeVal = 0;
                            }

                        }


                    }
                    return item;
                });
                setExchangeOffers(traded_offer);
            }
        }

    }

    return (
        <div className="offer-rows">

            {
                exchangeOffers.map((offer: any) => {

                    return (
                        <div className="offer-row">
                            <div className="offer-details">
                                <div className="offer-list">
                                    <div className="offer-names">
                                        <span className="offer-name-items">
                                            <input disabled={offer?.offerSelection === 'mandatory'} name={offer.offerId} checked={offer.checked} id={offer.offerId}
                                                onClick={() => handleOfferAction(offer.offerId, 'checked', 'other')} type="checkbox"></input>
                                        </span>
                                        <span className="offer-name-items">{offer?.offerName}</span>

                                        <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showTc', 'other')}>{`T&C`}</span>
                                        <span className="offer-tc offer-name-items" onClick={() => handleOfferAction(offer.offerId, 'showBan', 'other')}>{`Details`}</span>

                                        {(offer.offerType === "TradeIn and Exchange") &&
                                            <>
                                                <span className="offer-tc offer-name-items trade-in" onKeyDown={(e) => handleOfferTradeIn(e, offer.offerId, offer?.tradeValues)}><InputField helpertext="Exchange Device Amount" /></span>
                                                {offer?.checked &&
                                                    <span className="offer-tc offer-name-items trade-in tradein-val">{`${toLocaleNumber(offer?.deviceVal)} + (Bonus) ${toLocaleNumber(offer.exchangeVal) || 0}`}</span>
                                                }
                                            </>
                                        }


                                    </div>

                                    <div className="offer-prices">
                                        {
                                            (offer.offerType !== 'Offer Text' && offer.offerType !== 'Standard EMI') && <span className={!offer.checked ? 'text-strike' : ''}>{toLocaleNumber(offer?.maxBenefites)}</span>
                                        }
                                    </div>


                                </div>
                            </div>
                            <div className="offer-details">
                                {offer.showTc &&
                                    <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }}>
                                    </div>
                                }
                                {offer.showBan &&
                                    <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }}>
                                    </div>
                                }


                            </div>

                        </div>
                    )
                })
            }
        </div>
    );
}
