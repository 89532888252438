
import OfferGroupingTable from "./OfferGroupingTable"

export const OfferGroupingPage: React.FC = () => {
    document.title = "Poorvika - Offer Grouping";

    return (
        <div>
            <OfferGroupingTable />
        </div>
    )

}