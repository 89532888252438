import { AxiosError } from "axios"
import { API_ENDPOINTS, parseAxiosError, COMMON_API } from "config/config"
import { BaseService } from "./htttp"
import { IAPIReponse } from "./interfaces/common.interface"

 

export class RuleEngineService extends BaseService {
   
    public async getFilteredOffers(filter: any, itemCode: string, channel: string = 'Online'): Promise < IAPIReponse > {
    try {
        const { REACT_APP_API_URL = '' } = process.env;

        const { data } = await this.httpClient.post(`${REACT_APP_API_URL}${API_ENDPOINTS.GET_OFFERS_WITH_FILTER}/${itemCode}`, { ...filter }, {
            headers: {
                "x-api-key": COMMON_API.OFFER_API_KEY || '',
                channel: channel
            },
        },)

            return data
    } catch(error) {
        return parseAxiosError(error as AxiosError)
    }
}
    public async getFilterOptions(itemCode: string, channel: string = 'Online'): Promise < IAPIReponse > {
    try {
        const { REACT_APP_API_URL = '' } = process.env;

        const { data } = await this.httpClient.get(`${REACT_APP_API_URL}${API_ENDPOINTS.FILTER_OPTIONS}/${itemCode}`, {
            headers: {
                "x-api-key": COMMON_API.OFFER_API_KEY || ''
            },
        })


            return data
    } catch(error) {
        return parseAxiosError(error as AxiosError)
    }
}

    public async getFilters(itemCode: string, channel: string = 'Online'): Promise < IAPIReponse > {
    try {
        const { REACT_APP_API_URL = '' } = process.env;

        const { data } = await this.httpClient.get(`${REACT_APP_API_URL}${API_ENDPOINTS.FILTERS}/${itemCode}`, {
            headers: {
                "x-api-key": COMMON_API.OFFER_API_KEY || '',
                "channel": channel
            },
        })
            return data
    } catch(error) {
        return parseAxiosError(error as AxiosError)
    }
}

}