import {
  faPen,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import { attributeTypes } from "common/constant";
import Checkbox from "components/Checkbox";
import DropDown from "components/DropDown";
import Icon from "components/Icon";
import InputField from "components/InputField";
import { STATUS } from "config/config";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { SettingsService } from "services/settings.service";
import { EToasterVariants } from "services/types/common.type";
import slugify from "common/slugify";

import "./styles.scss";

const OfferAttributes: React.FC = () => {
  /* State variables */
  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<any>([]);
  const [
    deleteConfirmationDialogVisibilty,
    setDeleteConfirmationDialogVisibility,
  ] = useState<boolean>(false);
  const [selectedAttributeIndex, setSelectedAttributeIndex] =
    useState<number>(0);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  /* Context  */
  const { showToaster, setSpinnerVisibility } = useContext(appContext);

  /* Services */
  const settingsService = new SettingsService();

  /* Form hooks */
  const { register, reset, handleSubmit, setValue, watch, formState: { errors }, control } = useForm();
  var { fields, append, remove } = useFieldArray({ name: "lov", control });

  const getAttributesInfo = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const rule: boolean = false;
      const offergroup: boolean = false
      const getAttributeResponse = await settingsService.getAttributes({ page: page + 1, pageLimit: rowsPerPage, rule: rule, offergroup: offergroup });
      if (getAttributeResponse.status === STATUS.SUCCESS) {
        setAttributes(getAttributeResponse?.data?.AttributesData);
        setTotalPages(Math.ceil(getAttributeResponse?.data?.count / rowsPerPage));
      }
      setSpinnerVisibility(false);
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to get Attributes List");
      setSpinnerVisibility(false);
    }
  };

  const sliderVisibilityHandler = () => {
    setSliderVisibility(!sliderVisibility);
    setEditMode(false);
    reset();
  };

  const attributeSubmissionHandler = async (values: any): Promise<void> => {
    try {
      if (values.name.trim().length === 0) {
        showToaster(EToasterVariants.error, "Please fill Attribute name");
        return
      }
      values.code = slugify(values.name)
      setSliderVisibility(true);
      var attributeActionResponse;
      if (editMode) {
        attributeActionResponse = await settingsService.updateAttribute({ ...values, _id: attributes[selectedAttributeIndex]._id });
        if (attributeActionResponse.status === STATUS.SUCCESS) {
          getAttributesInfo();
          setSliderVisibility(false);
          sliderVisibilityHandler();
        } else {

          showToaster(EToasterVariants.error, `Unable to ${editMode ? "update" : "Add"} attribute`);
        }
      } else {
        var attributeCodeResponse = await settingsService.attributeCodeCheck(values.code);
        if (attributeCodeResponse.status !== STATUS.SUCCESS) {
          showToaster(EToasterVariants.error, "Already Exists Code");
        } else {
          values.isRule = false;
          values.isOffergroup = false;
          attributeActionResponse = await settingsService.addAttribute(values);
          if (attributeActionResponse.status === STATUS.SUCCESS) {
            getAttributesInfo();
            setSliderVisibility(false);
            sliderVisibilityHandler();
          } else {
            showToaster(EToasterVariants.error, `Unable to ${editMode ? "update" : "Add"} attribute`);
          }
        }
      }

    } catch (error) {
      showToaster(EToasterVariants.error, `Unable to ${editMode ? "update" : "Add"} attribute`);
    }
  };



  const deletePopupHandler = (attributeIndex: number) => {
    setSelectedAttributeIndex(attributeIndex);
    setDeleteConfirmationDialogVisibility(true);
  };

  /* Delete Confirmation handler */
  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      try {
        setSpinnerVisibility(true);
        const deleteResponse = await settingsService.deleteAttribute(
          attributes[selectedAttributeIndex]._id
        );
        if (deleteResponse.status === STATUS.SUCCESS) {
          setDeleteConfirmationDialogVisibility(false);
          await getAttributesInfo();
          setSpinnerVisibility(false);
          showToaster(EToasterVariants.success, "Record Deleted Successfully");
        }
      } catch (error) {
        setSpinnerVisibility(false);
        showToaster(EToasterVariants.error, "Unable to delete the record");
      }
    } else {
      setDeleteConfirmationDialogVisibility(false);
    }
  };


  /* Attribute Edit Handler */
  const attributeEditHandler = async (data: any, attributeIndex: number): Promise<void> => {

    setEditMode(true);
    setSelectedAttributeIndex(attributeIndex);

    const { code, name, isLov, dataType, lov, isMultiselect } = data;
    setValue("name", name);
    setValue("code", code);
    setValue("isLov", isLov);
    setValue("dataType", dataType);
    setValue("isMultiselect", isMultiselect);

    if (isLov) {
      lov?.forEach((element: any) => {
        append({ name: element.name, value: element.value })

      });
    }

    setSliderVisibility(true)

  }

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    getAttributesInfo();
    setValue("isLov", false);
    setValue("isMultiselect", watch("isMultiselect") !== undefined ? watch("isMultiselect") : false);
  }, [page, rowsPerPage]);

  return (
    <div className="attributes-table">
      <div className="header">
        <h3>Offer Value Attributes</h3>
        <button
          className="btn primary"
          onClick={sliderVisibilityHandler}
        >
          Add Attribute
        </button>
      </div>

      <table className="shadow">
        <thead>
          <tr>
            <th>Code</th>
            <th>Attribute</th>
            <th>Type</th>
            <th>Field Type</th>
            <th>Name - Value</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {attributes.map((attribute: any, index: number) => {
            return (
              <tr>
                <td data-label="Code">{attribute.code}</td>
                <td data-label="Attribute">{attribute.name}</td>
                <td data-label="Type">{attribute.isRule ? "Rule" : "Offer Value"}</td>
                <td data-label="Field Type">{attribute.isLov ? "List " : "Value"}</td>
                <td data-label="Name - Value">
                  {attribute?.isLov &&
                    attribute?.lov?.map((value: any, index: number) => (
                      <p>
                        {index + 1}.{value.name}-{value.value}
                      </p>
                    ))}
                </td>
                <td data-label="Action" className="action">
                  <div onClick={() => attributeEditHandler(attribute, index)}>
                  <div className="action-edit-btn"> <Icon icon={faPen} /></div>
                  </div>

                  <div
                    className="delete-btn"
                    onClick={() => deletePopupHandler(index)}
                  >
                    <Icon icon={faTrash} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination
        backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
        nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
        component="div"
        count={totalPages}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className={`slider-right ${sliderVisibility ? "show" : "hide"}`}>
        <div className="add-product-slider">
          <div className="slider-header">
            <h1>Add Attribute</h1>
            <div
              className="action-icon"
              onClick={sliderVisibilityHandler}
            >
              <Icon icon={faXmark} />
            </div>
          </div>
          <form onSubmit={handleSubmit(attributeSubmissionHandler)}>
            <div className="form-wrapper">
              <InputField
                helpertext="Name"
                name="name"
                // onChange={(e) => setValue("code", slugify(e.target.value))}
                register={register("name", { required: "Attribute name is required" })}
                error={errors.name?.message as any}
              />
              <DropDown
                label="Type"
                options={attributeTypes}
                register={register("dataType")}
                name="dataType"
              />
              <Checkbox
                value={watch("isLov")}
                lable="is LOV? "
                onChange={(checkedStatus: boolean) => {
                  if (checkedStatus) {
                    append({ name: "", value: "" })
                  } else {
                    setValue("lov", []);
                  }
                  setValue("isLov", checkedStatus)

                }
                }
              />
              {watch("isLov") && <Checkbox
                lable="is Multi Select ? "
                value={watch("isMultiselect")}
                onChange={(checkedStatus: boolean) => setValue("isMultiselect", checkedStatus)}
              />}
              <div className={fields.length > 6 ? "dynamic-fields scroller" : "dynamic-fields"}>

                {
                  fields?.map((keyValuePairs: any, index: number) => {
                    let validations = errors as any
                    return (
                      <div className="values" key={`rule ${index}`}>
                        <InputField
                          helpertext="Name"
                          register={register(`lov[${index}].name`, { required: "Name is required" })}
                          name={`lov[${index}].name`}
                          error={validations?.lov && validations?.lov[index]?.name?.message as any}
                        />
                        <InputField
                          helpertext="Value"
                          register={register(`lov[${index}].value`, { required: "Value is required" })}
                          name={`lov[${index}].value`}
                          error={validations?.lov && validations?.lov[index]?.value?.message as any}
                        />
                        <div className="icon">
                          {index === fields.length - 1 && (
                            <div
                              className="add"
                              onClick={() => append({ name: "", value: "" })}
                            >
                              <Icon icon={faPlus} />
                            </div>
                          )}
                          {index !== 0 && (
                            <div
                              className="remove"
                              onClick={() => remove(index)}
                            >
                              <Icon icon={faTrash} />
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              <div className="action-buttons">
                <button
                  className="btn secondary"
                  type="reset"
                  onClick={sliderVisibilityHandler}
                >
                  Cancel
                </button>
                <button className="btn primary" type="submit">
                  Save
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
      <DeleteConfirmationDialog
        show={deleteConfirmationDialogVisibilty}
        onConfirmation={deleteConfirmationHandler}
      />
    </div>
  );
};

export default OfferAttributes;
