import React from "react";
import documentImage from "assets/images/document.png";
import { faTrashCan, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.scss";

type uploadFileProps = {
  name: string;
  index: number;
  onRemove: (index: number) => void;
  onDownload: (file: any) => void;
  file: any;
};

const UploadedFile: React.FC<uploadFileProps> = ({
  name,
  index,
  onRemove,
  onDownload,
  file,
}) => {
  const getFileExtension = (name: string): string => {
    const source = name?.split(".").length;
    return name.split(".")[source - 1];
  };

  const removeItemHandler = async (): Promise<void> => {
    if (onRemove) {
      onRemove(index);
    }
  };

  /* Open the file in new tab for the preview or download */
  const openFileInNewTab = () => {
    window.open(file, "_blank")
  }

  return (
    <div className="uploaded-document shadow">
      <div className="image-and-details">
        <img src={documentImage} alt="document-image" />
        <p>{getFileExtension(name)}</p>
      </div>
      <div className="filename-and-size-wrapper">
        {name.length > 10 ? (
          <p className="filename">{name.substring(0, 10)}...</p>
        ) : (
          <p className="filename">{name}</p>
        )}
      </div>
      <div className="csv-downloader" >
          <FontAwesomeIcon
            className=""
            icon={faUpRightFromSquare}
            color={"#000000"}
            onClick={openFileInNewTab}
          />
      </div>
      <div className="cursor-pointer" onClick={removeItemHandler}>
        <Icon icon={faTrashCan} />
      </div>
    </div>
  );
};

export default UploadedFile;
