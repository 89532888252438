import Icon from "components/Icon";
import React, { useState, useRef, useEffect } from "react";
import './styles.scss';
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"


type DropDownProps = {
    options: any[], 
    label: string,
    register?: any,
    disabled?:boolean,
    placeholder?: string,
    error?: string,
    focusHandleText?:any
    autoWidth?:boolean
} & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>



const DropDown: React.FC<DropDownProps> = ({options, label, register,disabled ,placeholder, error, ...props}) => {
  const [showPopup,setShowPopup]=useState(false)
  const field=useRef<HTMLDivElement>(null);

useEffect(() => {
  function handleClickOutside(event:any) {
    if (field.current && !field?.current?.contains(event.target)) {
      setShowPopup(false)
    }
  }
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [field]);

  return (
    <div className="dropdown">
    <select
      className={disabled ? "common-drop-down disabled": error? "common-drop-down error": "common-drop-down"}
      {...register}
      {...props}
      disabled={disabled}
      autoWidth
    >
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option: any) => {
          if(typeof option === "string") {
            return <option value={option} key={option}>{option}</option>
          }

          return <option value={option.value} disabled={option?.disabled||false} key={option.value}>{option.name}</option>
        
        } )}
    </select>
    {label && <p className={disabled ?"field-name disabled":"field-name"}>{label}<strong>*</strong></p>}
    {error && error?.length > 0 && (<p className="hint-text error-text">* {error}</p>)}
    {props.focusHandleText && <div className="informationHandle"  onClick={()=>{setShowPopup(!showPopup)}}>?</div>}
      {showPopup && <div className="dropdownFocusHandle" ref={field} >
         <label  className="dropdownfocus">{props?.focusHandleText}</label>
         <div className="dropdownFocusHandleCaret">
        <Icon  className="dropdownfocusCaret" icon={faCaretUp} />
        </div>
      </div>}
    </div>
  );
};

export default DropDown;
