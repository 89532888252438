export const rulesComparisionConditions = {
  in: "In",
  lessThan: "Less Than",
  equalsTo: "Equals to",
  greaterThan: "Greater Than",
  greaterThanOrEquals: "Greater than or Equals",
  lessThanOrEquals: "Less than or Equals",
};

/* 
  @Params rules - Arrays of rules 

*/
export const generateRules = async (bunchOfRules: any, channels: any, states: any): Promise<any> => {
  var validRules: any = [];

  bunchOfRules?.forEach((rules: any) => {
    rules?.forEach((rule: any) => {
      const ruleName = removeSpecialCharacters(rule?.name);

      /* Multi Select Values */
      if (
        rule?.operation === rulesComparisionConditions.in &&
        rule?.value?.length > 0
      ) {
        rule?.value?.forEach((value: any) => {
          if (!isNaN(Number(value?.value))) {
            validRules.push({
              if: `${ruleName} == ${value?.value}`,
              then: 'isApplicable = "true"',
            });
          } else {
            validRules.push({
              if: `${ruleName} == \"${value?.value}\"`,
              then: 'isApplicable = "true"',
            });
          }
        });
      }

      /* equals to condition*/
      if (
        rule?.value &&
        rule?.operation === rulesComparisionConditions?.equalsTo
      ) {
        if (!isNaN(Number(rule?.value))) {
          validRules.push({
            if: `${ruleName} == ${rule?.value}`,
            then: 'isApplicable = "true"',
          });
        } else {
          validRules.push({
            if: `${ruleName} == \"${rule?.value}\"`,
            then: 'isApplicable = "true"',
          });
        }
      }

      /* Greater than or equals */
      if (
        rule?.value &&
        rule?.operation === rulesComparisionConditions?.greaterThanOrEquals
      ) {
        if (!isNaN(Number(rule?.value))) {
          validRules.push({
            if: `${ruleName} >= ${rule?.value}`,
            then: 'isApplicable = "true"',
          });
        }
      }

      /* Greater than */
      if (
        rule?.value &&
        rule?.operation === rulesComparisionConditions?.greaterThan
      ) {
        if (!isNaN(Number(rule?.value))) {
          validRules.push({
            if: `${ruleName} > ${Number(rule?.value) - 1}`,
            then: 'isApplicable = "true"',
          });
        }
      }

      /* Less Than */
      if (
        rule?.value &&
        rule?.operation === rulesComparisionConditions?.lessThan
      ) {
        if (!isNaN(Number(rule?.value))) {
          validRules.push({
            if: `${ruleName} < ${Number(rule?.value) + 1}`,
            then: 'isApplicable = "true"',
          });
        }
      }

      /* Less Than or equals */
      if (
        rule?.value &&
        rule?.operation === rulesComparisionConditions?.lessThanOrEquals
      ) {
        if (!isNaN(Number(rule?.value))) {
          validRules.push({
            if: `${ruleName} <= ${rule?.value}`,
            then: 'isApplicable = "true"',
          });
        }
      }
    });
  });

  /* Channel related values */
  channels?.forEach((channel: any) => {
      validRules.push({
        if: `channel == \"${channel?.name}\"`,
        then: 'isApplicable = "true"',
      });
  });

  /* State Related values */
  states?.forEach((state: any) => {
      validRules.push({
        if: `state == \"${state?.name}\"`,
        then: 'isApplicable = "true"',
      });
  });

  validRules.push({ return: "isApplicable" });

  return validRules;
};

interface IRulesFilter {
    filterGroup: string,
    name: string,
    filter:  {[key: string]: string}
}

/* Generate Filters to filter offers via rule Engine */
export const generateFilters = async (bunchOfRules: any, channels: any, states: any): Promise<IRulesFilter[]> => {
    var validFilters: IRulesFilter[] = [];

    bunchOfRules?.forEach((rules: any) => {
        rules?.forEach((rule: any) => {
            const ruleName = removeSpecialCharacters(rule?.name);

            /* Multi Select Values */
            if (rule?.operation === rulesComparisionConditions.in && rule?.value?.length > 0) {

                rule?.value?.forEach((ruleValue: any) => {
                    const filter: IRulesFilter = {
                        filterGroup: "",
                        name: "",
                        filter: {}
                    };
                    filter["filterGroup"] = rule?.name;
                    filter.name = ruleValue?.name;
                    filter.filter[ruleName] = isNaN(Number(ruleValue?.value)) ? ruleValue?.value : Number(ruleValue?.value);
                    validFilters.push(filter);
                });
            } else {

                const filter: IRulesFilter = {
                    filterGroup: "",
                    name: "",
                    filter: {}
                };
                filter["filterGroup"] = rule?.name;
                if(rule?.operation === rulesComparisionConditions.greaterThan) {
                  filter.name = `Greater Than ${rule?.value}`;
                } else if(rule?.operation === rulesComparisionConditions.lessThan) {
                  filter.name = `Less Than ${rule?.value}`;
                } else {
                  filter.name = rule?.value;
                }
                filter.filter[ruleName] = isNaN(Number(rule?.value)) ? rule?.value : Number(rule?.value);
                validFilters.push(filter);
            }
        });        
    });

    channels?.forEach((channel: any) => {
      const filter: IRulesFilter = {
        filterGroup: "",
        name: "",
        filter: {}
    };
    filter["filterGroup"] = "Channel";
    filter.name = channel?.name;
    filter.filter["channel"] = channel?.name;
    validFilters.push(filter);
    })

    states?.forEach((state: any) => {
      const filter: IRulesFilter = {
        filterGroup: "",
        name: "",
        filter: {}
    };
    filter["filterGroup"] = "State";
    filter.name = state?.name;
    filter.filter["state"] = state?.name;
    validFilters.push(filter);
    })


    return validFilters;
}

export const removeSpecialCharacters = (name: string): string => {
  return name?.replace(/[^a-z]/gi, "").toLowerCase();
};
