import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class OffersGroupService extends BaseService {

    public async getOffersGroupList(rowsPerPage?: number, pageNo?: number): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER_GROUP}/${rowsPerPage}/${pageNo}/`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }


    public async createNewOfferGroup(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.post(API_ENDPOINTS.OFFER_GROUP, offerData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async updateOfferGroup(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.OFFER_GROUP}/${offerData["_id"]}`, offerData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteOfferGroup(offerId: string): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.delete(`${API_ENDPOINTS.OFFER_GROUP}/${offerId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOfferGroupDetails(offerId: string): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER_GROUP}/${offerId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async statusChange(offerGroupId: string, status: string, datas?: any): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.OFFER_GROUP}/change_offergroup_status/${offerGroupId}`, { status: status });
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}
