import { useEffect, useState } from "react";
import { RootStore } from "redux/store";
import { useSelector } from "react-redux";
import _ from "lodash";


export const usePermission = (Permission: any) => {
    const userPermission: any = useSelector<RootStore>((state) => state.userDetails.userDetails.Permission);
    const [isAllowedPermission, setIsallowedPermission] = useState<boolean>(false)

    useEffect(() => {
        setIsallowedPermission(_.intersection(Permission, userPermission).length > 0)
    }, [userPermission])

    return [isAllowedPermission]
}