
import React, { useState } from 'react';
import Icon from 'components/Icon';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from 'components/InputField';

import "./styles.scss"

type CashDiscountDialogProps = {
  show: boolean,
  onExit: (data: boolean, data2?: any) => void
  fieldName?: String
}

export const CashDiscountDialog: React.FC<CashDiscountDialogProps> = ({ show, onExit, fieldName }) => {

  const [inputValue, setInputValue] = useState<string>("")
  const [error, setError] = useState<string>("")

  const onCloseHandler = () => {
    onExit(false)
  }

  const onSaveHandeler = (e: any) => {
    e.preventDefault();
    const discountAmount = Number(inputValue);

    if (discountAmount <= 0 || isNaN(discountAmount)) {
      setError('Please enter valid amount');
      return;
    }
    onExit(true, { cashDiscount: discountAmount, type: 'Cash' })
  }

  return (
    <div className={"container-right"} >
      <div className="add-product-slider">
        <div className="slider-header">

          <h4>Add Cash Discount</h4>
          <div onClick={() => onCloseHandler()}>
            <Icon icon={faXmark} />
          </div>
        </div>
        <div className="body-content" style={{ width: '80%' }}>
          <InputField mandatory helpertext='Cash Discount Amount' error={error} onChange={(e: any) => setInputValue(e.target.value)} />
        </div>

        <div className="action-buttons">
          <button
            className="btn primary"
            type="button"
            onClick={() => onCloseHandler()}
          >
            Cancel
          </button>
          <button className="btn primary" type="submit"
            onClick={(e) => onSaveHandeler(e)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}