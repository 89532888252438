import OffersTypeTable from 'containers/OffersTypeTable';
import UserTable from 'containers/UserTable';
import RolePermission from 'pages/RolesPermissionPage';
import OfferAttributes from 'containers/OfferAttributes'
import RuleAttributes from 'containers/RuleAttributes'
import React, { useState } from 'react'

import "./styles.scss"
import { useSelector } from 'react-redux';
import { RootStore } from 'redux/store';
import CouponEvent from 'containers/CouponEvent';
import FinanceNameList from 'containers/FinanceNameList';

const SettingsPage: React.FC = () => {

  document.title = "Poorvika - Settings";

  const [activeMenu, setActiveMenu] = useState<number>(0);
  const userRole: any = useSelector<RootStore>((state) => state.userDetails.userDetails.userRole);
  const settingsSideMenu = userRole === "Super Admin" ? ["Rules", "Offer Value Attributes", "Offer Types", "Roles & Permissions", "Users & Permissions","Coupon Event","Finance List"] :
    ["Rules", "Offer Value Attributes", "Offer Types"];

  return (
    <div className="settings-page">
      <div className="side-sub-menu shadow">
        {settingsSideMenu?.map((menu: string, index: number) => {
          return <div className={`sub-menu ${activeMenu === index ? 'active' : ''}`} onClick={() => setActiveMenu(index)} key={index}>
            <div className="indicator" />
            <p>{menu}</p>
          </div>
        })}
      </div>
      <div className="sub-pages shadow">
        {activeMenu === 0 && <RuleAttributes />}
        {activeMenu === 1 && <OfferAttributes />}
        {/* {activeMenu === 2 && <GroupAttributes />} */}
        {activeMenu === 2 && <OffersTypeTable />}
        {userRole === "Super Admin" && activeMenu === 3 && <RolePermission />}
        {userRole === "Super Admin" && activeMenu === 4 && <UserTable />}
        {userRole === "Super Admin" && activeMenu === 5 && <CouponEvent />}
        {userRole === "Super Admin" && activeMenu === 6 && <FinanceNameList />}
        {/* {userRole === "Super Admin" && activeMenu === 5 && <TradeIn />} */}
      </div>

    </div>
  )
}

export default SettingsPage