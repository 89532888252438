import React from "react";
import "./style.scss";

import noProducts from "assets/icons/noProducts.svg";

const NoProductsSad: React.FC<any> = ({ text, coloumn }) => {
    return (
        <tr className="no-product-container2">
            <td colSpan={coloumn}>
                <img alt="add-more" src={noProducts} className="add-more" />
                <p className="line">{text}</p>
            </td>
        </tr>
    );
};

export default NoProductsSad;