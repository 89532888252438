export type ToasterProperties = {
  type: EToasterVariants;
  message: string;
  visibility: boolean;
};


export enum EToasterVariants {
    success = "success", 
    error = "error",
    warning = "warning"
}
