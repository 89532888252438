import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import TablePagination from "@mui/material/TablePagination";
import Icon from "components/Icon";
import { faXmark, faFilter } from "@fortawesome/free-solid-svg-icons";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import moment from "moment";
import { HistoryService } from "services/history.service";
import { useForm } from "react-hook-form";
import DropDown from "components/DropDown";
import { historyFilterStatus, historyFilterStatusNbfc } from "common/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateAndTimePicker from "components/DateTimePicker";

type TProp = {
  offerId?: string;
  module?: string;
  name?: string;
  couponId?: string;
};

const HistorySideMenu: React.FC<TProp> = ({ offerId, module, name, couponId }) => {
  document.title = "Poorvika - History";

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [offersHistory, setOffersHistory] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [isExpandedRow, setExpandedRow] = useState<number>(-1);
  const [filterVisibility, setFilterVisibility] = useState<boolean>(false);
  const { register, handleSubmit, setValue, watch, reset } = useForm()
  const [endDateErr, setEndDateErr] = useState<boolean>(false)

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`
      }`;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setExpandedRow(-1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setExpandedRow(-1);
  };


  const { showToaster, setHistoryNavigate } = useContext(appContext);

  //services

  const historyService = new HistoryService();

  const getHistory = async (): Promise<void> => {
    const offerHistoryResponse = await historyService.getHistoryList(rowsPerPage, page, offerId, couponId, module);
    if (offerHistoryResponse.status === STATUS.SUCCESS) {
      setOffersHistory(offerHistoryResponse.data?.historyList);
      setTotalPages(offerHistoryResponse.data?.totalPages);
    } else {
      showToaster(EToasterVariants.error, "Unable to get Offer List");
    }
  };

  /* filter history */
  const sliderVisibilityHandler = () => {
    setFilterVisibility(!filterVisibility);
  };
  const filterSubmissionHandler = async (offerInfovalues: any): Promise<any> => {
    try {
      if (endDateErr) {
        showToaster(
          EToasterVariants.error, "End date should be greather than start date")
        return
      }
      else {
        const offerHistoryResponse = await historyService.getHistoryList(rowsPerPage, page, offerId || "", couponId || "", module || "", (offerInfovalues.activityType).toUpperCase(), offerInfovalues)
        if (offerHistoryResponse.status === STATUS.SUCCESS) {
          setOffersHistory(offerHistoryResponse.data?.historyList);
          setTotalPages(offerHistoryResponse.data?.totalPages);
        } else {
          showToaster(EToasterVariants.error, "Unable to get Offer List");
        }
      }
      sliderVisibilityHandler()
    }
    catch (error) {
      showToaster(EToasterVariants.error, "Unable to get Offer List");
    }
  }

  const clearFilterHandeler = () => {
    getHistory();
    reset();
    sliderVisibilityHandler();
  }

  /* Date Range Selection Validator & handler */
  const dateRangeSelectionHandler = async (dateFieldName: string, value: string): Promise<void> => {
    if (dateFieldName === "startDate") {
      setValue("startDate", value);
    } else if (dateFieldName === "endDate") {
      setValue("endDate", value);
    }
    if (watch("startDate") && watch("endDate")) {
      if (new Date(watch("startDate")).getTime() >= new Date(watch("endDate")).getTime()) {
        setEndDateErr(true)
      }
      else {
        setEndDateErr(false)
      }
    }
  };

  /* *** filter End *** */




  useEffect(() => {
    setHistoryNavigate(false)
  }, [])

  useEffect(() => {
    getHistory();
  }, [page, offerId, rowsPerPage, couponId]);

  return (
    <div className="product-listing-history-page">
      <div className="listing-history">
        <h1 className="history-heading">{name ?? "Offers Listing History"}</h1>
        <div className="filter-icon">
          <FontAwesomeIcon
            className="cursor-pointer sort-filter-icon"
            icon={faFilter}
            onClick={sliderVisibilityHandler}
          />
        </div>
      </div>
      <table className="shadow history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Activity Type</th>
            <th>Module</th>
            <th>Description</th>
            <th>Updated by</th>
          </tr>
        </thead>
        <tbody>
          {
            offersHistory?.map((data: any, index: number) => {
              return (<>
                <tr key={index + 1}>
                  <td data-label="Date">{moment(data.createdDate).format("DD-MMM-YYYY hh:mm A")}</td>
                  <td data-label="Activity Type">{data.operation}</td>
                  <td data-label="Module">{data.module}</td>
                  <td data-label="Description">{data.title}</td>
                  <td data-label="Updated by">{data.createdBy}</td>
                </tr>
              </>
              );
            })}
        </tbody>
      </table>

      <div className="pagination-wrp">
        <TablePagination
          backIconButtonProps={
            page === 0 ? { disabled: true } : { disabled: false }
          }
          nextIconButtonProps={
            page + 1 === totalPages || totalPages === 0
              ? { disabled: true }
              : { disabled: false }
          }
          component="div"
          count={totalPages}
          labelDisplayedRows={defaultLabelDisplayedRows}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <div
        className={`slider-right filter-right ${filterVisibility ? "show" : "hide"
          }`}
      >
        <div className="add-product-slider">

          <div className="slider-header">
            <h1>Filter</h1>
            <div className="action-icon" onClick={sliderVisibilityHandler}>
              <Icon icon={faXmark} />
            </div>
          </div>

          <div className="filterTop">
            <form onSubmit={handleSubmit(filterSubmissionHandler)}>
              <div className="form-wrapper">

                <div className="formfilter">
                  <DropDown
                    options={module ? historyFilterStatusNbfc : historyFilterStatus}
                    register={register("activityType")}
                    onChange={(e) => setValue("activityType", e.target.value)}
                    label="Activity Type"
                    name="Activity Type"

                  />
                </div>
                <div className="formfilter">
                  <DateAndTimePicker
                    label="startDate"
                    name="startDate"
                    value={watch("startDate")}
                    onDateSelection={(date) =>
                      dateRangeSelectionHandler("startDate", date)
                    }
                  />
                </div>

                <div className="formfilter">
                  <DateAndTimePicker
                    label="End Date"
                    name="endDate"
                    value={watch("endDate")}
                    onDateSelection={(date) =>
                      dateRangeSelectionHandler("endDate", date)
                    }
                  />
                </div>



                <div className="filter-action">
                  <button
                    className="btn secondary"
                    type="reset"
                    onClick={clearFilterHandeler}
                  >
                    Clear
                  </button>
                  <button className="btn primary" type="submit">
                    Apply
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistorySideMenu;
