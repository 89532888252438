import * as Yup from "yup";

export const COUPON_TYPE = {
  UNIQUE_COUPON: "Unique Coupon",
  COMMON_COUPON: "Common Coupon",
};

export const objectToQueryString = (object: object) => {
  //converts a json object to query string
  return Object.entries(object)
    .map((e) => e.join("="))
    .join("&");
};

export const removeFalsy = (obj: any) => {
  // remove the falsy key value pair in the object based on the values.
  const falsyArray: any = ["", null, undefined];
  Object?.keys(obj).map((x) => {
    if (falsyArray.includes(obj[x])) delete obj[x];
    return;
  });
  return obj;
};

export const removeDuplicateByKey = (data: any[], key: string) => {
  const value: string[] = [];
  const result: any[] = [];
  data.forEach((element) => {
    if (!value.includes(element[key])) {
      result.push(element);
      value.push(element[key]);
    }
  });
  return result;
};

export const COUPON_APPLIES_TO = {
  CART: "Cart",
  PRODUCT: "Product",
};

export const APPLY_ONCE_OPTION = {
  YES: "Yes",
  NO: "No",
};

export const OFFER_SELECTION_OPTION = [
  {
    name: "Mandatory",
    value: "mandatory",
  },
  {
    name: "Selected",
    value: "selected",
  },
  {
    name: "No Selection",
    value: "notselected",
  },
];

export const YES_NO_OPTION = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

export const DISCOUNT_APPLIED_AT = {
  BEFORE_TAX: "BeforeTax",
  AFTER_TAX: "AfterTax",
};

export const Aplicablecollection = () => [
  { id: "1", title: "Instant" },
  { id: "2", title: "Later" },
];

export const Discountcollection = () => [
  { id: "1", title: "Percentage" },
  { id: "2", title: "FlatDiscount" },
];

export const Offertypecollection = () => [
  { id: "1", title: "CashBack" },
  { id: "2", title: "Instant CashBack" },
  { id: "3", title: "Combo" },
  { id: "4", title: "Freebie" },
  { id: "5", title: "Discount" },
  { id: "6", title: "Exchange" },
];

export const OffersCollection = () => [
  { id: "1", title: "CashBack" },
  { id: "2", title: "Instant CashBack" },
  { id: "3", title: "Combo" },
  { id: "4", title: "Freebie" },
  { id: "5", title: "Discount" },
  { id: "6", title: "Exchange" },
];

export const BankCollection = () => [
  { id: "1", title: "SBI Bank" },
  { id: "2", title: "ICICI" },
  { id: "3", title: "IDFC" },
  { id: "4", title: "HDFC" },
  { id: "5", title: "HDFC Bank" },
  { id: "6", title: "HDFC Flexipay" },
  { id: "7", title: "Standard Chartered Bank" },
  { id: "8", title: "BOB Bank" },
  { id: "9", title: "YES Bank" },
  { id: "10", title: "Kotak Bank" },
  { id: "11", title: "AU Bank" },
];

export const PaymenttypeCollection = () => [
  { id: "1", title: "CASH" },
  { id: "2", title: "DEBIT CARDS" },
  { id: "3", title: "GOOGLE PAY" },
  { id: "4", title: "PHONEPAY" },
  { id: "5", title: "CREDIT CARD" },
  { id: "6", title: "CREDIT & DEBIT CARD" },
];

export const ValidAtCollection = () => [
  { id: "1", title: "Both" },
  { id: "2", title: "Online" },
  { id: "3", title: "Offline" },
];
export const ValidOnCollection = () => [
  { id: "1", title: "Emi&Non-Emi" },
  { id: "2", title: "Emi" },
  { id: "3", title: "Non-Emi" },
];

export const ButtonCollection = () => [
  { id: "1", title: "Emi" },
  { id: "2", title: "View" },
  { id: "3", title: "Tc" },
  { id: "4", title: "EMI & View" },
  { id: "5", title: "Emi&Tc" },
  { id: "6", title: "View&Tc" },
  { id: "7", title: "Emi&View&Tc" },
];

export const PaymentMethodCollection = () => [
  { id: "1", title: "CCA" },
  { id: "2", title: "PPG" },
];

export const ImageIconCollection = () => [
  { id: "1", title: "NoCostEMI" },
  { id: "2", title: "InstantCashback" },
  { id: "3", title: "ZerodownPayment" },
  { id: "4", title: "RewardPoints" },
  { id: "5", title: "Cashback" },
];

export const SpecialOffersCollection = () => [{ id: "1", title: "Bajaj PMI" }];

export const SpecialSubOffersCollection = () => [
  { id: "1", title: "Bajaj Finance" },
  { id: "2", title: "Tv & Laptop" },
];

export const SpecialFinancesCollection = () => [
  { id: "1", title: "Finance" },
  { id: "2", title: "Finance" },
];

export const EncodeGetParams = (params: any) => {
  return Object.entries(params)
    .map((key: any) =>
      key
        .map((k: any) => {
          k = typeof k === "object" ? JSON.stringify(k) : k;
          return encodeURIComponent(k);
        })
        .join("=")
    )
    .join("&");
};

export const toasterDuration = 2000;
export const offersListColumns = [
  "offersName",
  "offersType",
  "sponsor",
  "status",
  "startDate",
  "endDate",
  "modifiedDate",
  "action",
];

export const OffersPageElements: any = [
  { name: "View Offers", value: "VIEW_OFFERS" },
  { name: "Create offers", value: "CREATE_OFFERS" },
  { name: "Edit offer Info", value: "EDIT_OFFERS" },
  { name: "Delete Offers", value: "DELETE_OFFERS" },
  { name: "Edit Offer Status", value: "EDIT_OFFER_STATUS" },
  { name: "Add Offer Products", value: "ADD_OFFER_PRODUCTS" },
  { name: "Edit Offer Products", value: "EDIT_OFFER_PRODUCTS" },
  { name: "Delete Offer Products", value: "DELETE_OFFER_PRODUCTS" },
];
export const CouponsPageElements: any = [
  { name: "View Coupons", value: "VIEW_COUPONS" },
  { name: "Create And Edit coupons", value: "CREATE_AND_EDIT_COUPONS" },
  { name: "Delete Coupons", value: "DELETE_COUPONS" },
  { name: "Edit Coupons Status", value: "EDIT_COUPON_STATUS" },
  { name: "Coupons Info", value: "COUPON_INFO" },
  { name: "Market", value: "COUPON_MARKET" },
  { name: "Coupons Finance", value: "COUPON_FINANCE" },
  { name: "Coupons Value", value: "COUPON_VALUE" },
  { name: "Documents", value: "COUPON_DOCUMENTS" },
  { name: "Banner Info", value: "COUPON_BANNER_INFO" },
];

export const OffersRulesPageElements: any = [
  { name: "Add Rule", value: "addRules" },
];

export const OfferTypes: string[] = [
  "Cashback Instant",
  "Cashback Deffered",
  "Discount ( Activation offer )",
  "Exchange",
  "Combo",
  "Freebie",
];
export const OfferStatus: string[] = [
  "Draft",
  "Approved",
  "Inactive",
  "Active",
  "Rejected",
  "Expired",
];
export const OfferStatusAdmin: string[] = [
  "Draft",
  "Pending for Logistic Approval",
  "Pending for finance Approval",
  "Rejected by finance",
  "Rejected by Logistics",
  "Active",
  "Inactive",
  "Expired",
  "Rejected",
];
export const OfferStatusStaff: string[] = [
  "Draft",
  "Pending for Logistic Approval",
];
export const OfferStatusLogistics: string[] = [
  "Pending for Logistic Approval",
  "Pending for finance Approval",
  "Rejected by Logistics",
];
export const OfferStatusFinance: string[] = [
  "Pending for finance Approval",
  "Rejected by finance",
  "Active",
];
export const OfferStatusAll: string[] = [
  "All",
  "Draft",
  "Pending for Logistic Approval",
  "Pending for finance Approval",
  "Rejected by finance",
  "Rejected by Logistics",
];

/* coupons Related constants */
export const couponStatus: string[] = [
  "Draft",
  "Approved",
  "Inactive",
  "Active",
  "Rejected",
  "Expired",
];
export const couponStatusAdmin: string[] = [
  "Draft",
  "Pending for Logistic Approval",
  "Pending for finance Approval",
  "Rejected by finance",
  "Rejected by Logistics",
  "Active",
  "Inactive",
  "Expired",
  "Rejected",
];
export const couponStatusStaff: string[] = [
  "Draft",
  "Pending for Logistic Approval",
];
export const couponStatusLogistics: string[] = [
  "Pending for Logistic Approval",
  "Pending for finance Approval",
  "Rejected by Logistics",
];
export const couponStatusFinance: string[] = [
  "Pending for finance Approval",
  "Rejected by finance",
  "Active",
];
export const couponStatusAll: string[] = [
  "All",
  "Draft",
  "Pending for Logistic Approval",
  "Pending for finance Approval",
  "Rejected by finance",
  "Rejected by Logistics",
];

export const FinanceData: string[] = [
  "SF+ (DMI Finance)",
  "Bajaj Finserv",
  "TVS Credit",
  "Kodak",
  "ICICI",
  "HDFC",
  "Zest money",
  "HDB",
  "IDFC",
];

export const PublishObjTypes: string[] = [
  "OFFER",
  "COUPON",
  "OFFER_PRODUCT",
  "COUPON_PRODUCT",
  "FINANCE",
];
export const PublishStatus: string[] = [
  "PENDING",
  "NEW",
  "READY",
  "PUBLISHED",
  "FAILED",
];
export const PublishJobStatus: string[] = [
  "SCHEDULED",
  "READY",
  "PROCESSING",
  "COMPLETED",
];
export const PublishTypes: string[] = ["ADD", "UPDATE", "DELETE"];

export const ChannelList: string[] = ["All Channels", "Online", "Shop"];

export const sortTypes: string[] = ["Ascending Order", "Descending Order"];

export const sortColumnTypes: string[] = ["Item Code", "sku"];

export const exclusivity: string[] = ["No", "Yes"];
export const sponsorType: { name: string; id: number }[] = [
  { name: "Brand", id: 0 },
  { name: "Poorvika", id: 1 },
  { name: "Bank", id: 2 },
];
export const applicableDiscountType: string[] = ["Instant", "Later"];
export const MultiSelectValues = {
  channel: [
    { name: "Online", id: 0 },
    { name: "Shop", id: 1 },
  ],
  state: [
    { name: "Karnataka", id: 0 },
    { name: "Kerala", id: 1 },
    { name: "Maharashtra", id: 2 },
    { name: "Pondicherry", id: 3 },
    { name: "Tamil Nadu", id: 4 },
    { name: "All States", id: 5 },
  ],
  bannerFieldTypes: [
    { name: "TC", id: 0 },
    { name: "EMI", id: 1 },
  ],
  couponBannerField: [{ name: "TC", id: 0 }],
  couponUser: [
    { name: "Name1", id: 0 },
    { name: "Name2", id: 1 },
    { name: "Name3", id: 2 },
    { name: "Name4", id: 3 },
  ],
};

/* Offer ID will not change suppose we are not calling it as function */
export const createOfferInitialValues = () => {
  return {
    offerID: "CBI" + String(Math.floor(Math.random() * 900000) + 100000), //autogenerate 6 digit Values
    offersName: "",
    offersType: "Cashback",
    exclusivity: "Yes",
    startDate: new Date(),
    endDate: new Date(),
    status: "Draft",
    channel: [],
    state: [],
    Region: "",
    storeID: "",
    terminalID: "",
    sponsor: "Brand",
    sponsorID: "",
    exSchemeID: "",
    glCode: "",
    discountUnit: "Percentage",
    discountValue: "",
    applicable: "Instant",
    cashbackdays: "",
    maxDiscAmount: "",
    Attachments: [],
    offerDescription: "",
    offerTermsAndCondition: "",
  };
};

export const rulesInitialValues = {
  // offerType: "",
  paymentOption: "",
  instituteType: "",
  instituteName: "",
  cardType: "",
  tenure: "",
  minSwipe: "",
  maxSwipe: "",
};

export const rulesValidations = Yup.object().shape({
  // offerType: Yup.string().required('offer type is required'),
  paymentOption: Yup.string().required("payment Option  is required"),
  instituteType: Yup.string().required("instituteType is required"),
  instituteName: Yup.string().required("Institute Nameis required"),
  cardType: Yup.string().required("card Type is required"),
  minSwipe: Yup.string().required("minimum Swipe is required"),
  maxSwipe: Yup.string().required("max Swipe is required"),

  tenure: Yup.number().when("paymentOption", {
    is: "EMI",
    then: Yup.number().required("Tenure is required for EMI Transactions"),
  }),
});

export const createOffersValidationSchema = Yup.object().shape({
  offerID: Yup.string().required("offer id is required"),
  offersName: Yup.string().required("offer Name is required"),
  offersType: Yup.string().required("offer Type is required"),
  exclusivity: Yup.string().required("Exclusivity is required"),
  startDate: Yup.string().required("Start Date is required"),
  endDate: Yup.string().required("End Date is required"),
  status: Yup.string().required("Status is required"),
  channel: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        id: Yup.number(),
      })
    )
    .required("Channel is Required"),

  sponsor: Yup.string().required("Sponsor is required"),
  sponsorID: Yup.string(),
  exSchemeID: Yup.string().required("exSchemeID is required"),
  glCode: Yup.string(),

  discountUnit: Yup.string().required("discountUnit is required"),

  discountValue: Yup.number()
    .when("discountUnit", {
      is: "Percentage",
      then: Yup.number().max(100, "Maximum Allowed discount is 100%"),
    })
    .when("discountUnit", {
      is: "FlatDiscount",
      then: Yup.number().max(50000, "Maximum Flat discount is 50000"),
    })
    .required("Discount value is required"),

  applicable: Yup.string().required("applicable is required"),
  cashbackdays: Yup.number()
    .max(999, "Maximum Number of Days is 999")
    .required("cashbackdays is required"),
  maxDiscAmount: Yup.number().max(50000, "Maximum discount is 50000"),
});

export const createAttributeValidations = Yup.object().shape({
  name: Yup.string().required("Attribute name is required"),
  type: Yup.string(),
  isRule: Yup.boolean(),
  isLov: Yup.boolean(),
  lov: Yup.array().when("isLov", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        value: Yup.string().required("Value is Required"),
      })
    ),
  }),
});

export const rulesAttributes = [
  "Payment Option",
  "Institute Type",
  "Institute name",
  "Card Type",
  "Min Swipe",
  "Max Swipe",
  "Tenure",
  "Combo Item Code",
  "Combo Discount Unit",
  "Combo Discount Value",
  "Min Exchange value",
  "Item Code",
];

export const rulesConditions = [
  "Equals to",
  "Greater Than",
  "Less Than",
  "Greater Than or Equals to",
  "Less Than or Equals to ",
];

export const attributeTypes = ["STRING", "NUMBER"];
export const attributeValueVarient = ["Single", "Multiple"];

/* Sample Static Rules */
export const rules = [
  {
    _id: "62b930c18fe965abe2548a7a",
    dataType: "STRING",
    lov: [
      { name: "HDF", value: "HDFC" },
      { name: "ICI", value: "ICICI" },
      { name: "BOB", value: "BOB" },
      { name: "SBI", value: "SBI" },
    ],
    isActive: true,
    code: "HMUrZU",
    name: "Bank Name",
    isLov: true,
    isRule: true,
    createdby: "demo",
    createddate: "2022-06-27T04:23:29.137Z",
    modifiedby: "demo",
    modifieddate: "2022-06-27T04:23:29.138Z",
    __v: 0,
    index: 1,
  },
  {
    _id: "62b939648fe965abe2548a82",
    dataType: "STRING",
    lov: [
      { name: "CC", value: "Credit Card" },
      { name: "DC", value: "Debit Card" },
    ],
    isActive: true,
    code: "LgnMdF",
    name: "Payment Type",
    isLov: true,
    isRule: true,
    createdby: "demo",
    createddate: "2022-06-27T05:00:20.276Z",
    modifiedby: "demo",
    modifieddate: "2022-06-27T05:00:20.276Z",
    __v: 0,
    index: 2,
  },
  {
    _id: "62b939af8fe965abe2548a86",
    dataType: "STRING",
    lov: [
      { name: "MAS", value: "Mastercard" },
      { name: "RUP", value: "Rupay" },
      { name: "VIS", value: "Visa" },
    ],
    isActive: true,
    code: "gWeIsI",
    name: "Card Type",
    isLov: true,
    isRule: true,
    createdby: "demo",
    createddate: "2022-06-27T05:01:35.794Z",
    modifiedby: "demo",
    modifieddate: "2022-06-27T05:01:35.794Z",
    __v: 0,
    index: 3,
  },
];

export const ProductApprovalPermission = [
  { value: "PURCHASE_APPROVAL", name: "Avaiting Purchase Approval" },
  { value: "REJECTED", name: "Rejected" },
  { value: "FINANCE_APPROVAL", name: "Awaiting finance approval" },
  { value: "ACTIVE", name: "active" },
];

export const ComboOfferTypes = [
  "Product Price Discount",
  "over all Price Discount",
];

export const OperationArray: any = { "Equals to": "==" };

export const RoundOff = (value: number) => {
  const temp = Math.floor(value / 100) * 100;
  return temp >= value ? temp : temp + 100;
};

export const PriceSliderValues = [
  "MPV",
  "Minimum Transaction Amount",
  "Maximum Purchase Value",
];

export const offerType = [
  { name: "Instant Discount", value: "instantDiscount" },
  { name: "Freebies", value: "Freebies" },
  { name: "Combo Offer", value: "ComboOffer" },
  { name: "All Product", value: "AllProduct" },
  { name: "Offer Text", value: "OfferText" },
  { name: "TradeIn and Exchange", value: "TradeInExchange" },
];

// export const sidemenu = [
//   // {
//   //   name:"Dashboard",
//   //   navigationLink:"/offers/dashboard",
//   //   icon:faTag
//   // },
//   {
//     name:"Offers",
//     navigationLink:"/offers/list",
//     icon:faPercentage
//   },
//   {
//     name:"Show Offers",
//     navigationLink:"/offers/show_offers",
//     icon:faShop
//   },
//   {
//     name:"Offer Grouping",
//     navigationLink:"/offers/offer_grouping",
//     icon:faGroupArrowsRotate
//   },

//   {
//     name:"Coupons",
//     navigationLink:"/offers/coupons/list",
//     icon:faGift
//   },
//   {
//     name:"Coupon Reports",
//     navigationLink:"/offers/coupons/reports",
//     icon:faReceipt
//   },
//   {
//     name:"Coupons History",
//     navigationLink:"/offers/coupons/couponhistory",
//     icon:faHistory
//   },
//   {
//     name:"Price List",
//     navigationLink:"/offers/price_list",
//     icon:faIndianRupeeSign
//   },
//   {
//     name:"Finance List",
//     navigationLink:"/offers/finance_list",
//     icon:faCreditCard
//   },
//   {
//     name:"Settings",
//     navigationLink:"/offers/settings",
//     icon:faGear
//   },
//   {
//     name:"History",
//     navigationLink:"/offers/history",
//     icon:faHistory
//   }
// ]

export const allRegions = [
  "Discount Chennai Price List",
  "Discount ROTN Price List",
  "Discount Bangalore Price List",
  "Discount ROKA Price List",
  "Discount Kerala Price List",
  "Discount Maharashtra Price List",
  "Discount Comparison Area Price List",
  "Discount Appliances Price List",
  "Discount Special Price List",
  "Discount Mall Price List",
  "Online Website Price List",
];

export const productBulkUploadColumns = [
  "Item Code",
  "Discount Value",
  "Max Discount Value",
  "Min Value",
  "Max Value",
  "Start Date(DD-MM-YYYY HH:MM)",
  "End Date(DD-MM-YYYY HH:MM)",
];
export const OfferTextproductBulkUploadColumns = [
  "Item Code",
  "Start Date(DD-MM-YYYY HH:MM)",
  "End Date(DD-MM-YYYY HH:MM)",
];
export const TradeExProductBulkUploadColumns = [
  "Item Code",
  "Exchange Bonus",
  "Start Date(DD-MM-YYYY HH:MM)",
  "End Date(DD-MM-YYYY HH:MM)",
];
export const comboAndFreebieColumns = [
  "Item Code",
  "Start Date(DD-MM-YYYY HH:MM)",
  "End Date(DD-MM-YYYY HH:MM)",
];

// export const couponBulkUploadColumnsWithoutMax = [
//   'Item Code',
//   'Discount Value',
//   'Start Date(DD-MM-YYYY HH:MM)',
//   'End Date(DD-MM-YYYY HH:MM)'
// ];

// export const couponBulkUploadColumns = [
//   'Item Code',
//   'Discount Value',
//   'Max Discount Value',
//   'Start Date(DD-MM-YYYY HH:MM)',
//   'End Date(DD-MM-YYYY HH:MM)'
// ];

export const couponBulkUploadColumns = ["Item Code"];
export const couponAssignBulkUploadColumns = [
  "Name",
  "Gender",
  "Mobile",
  "Email",
  "Coupon Code",
];

export const financeBulkUploadColumns = [
  "Item Code",
  "Scheme Name",
  "Processing Fees",
  "Interest(%)",
  "Channel",
  "Start Date(DD-MM-YYYY HH:MM)",
  "End Date(DD-MM-YYYY HH:MM)",
];

export const OfferType = {
  InstantDiscount: "instantDiscount",
  Freebies: "Freebies",
  ComboOffer: "ComboOffer",
  AllProduct: "AllProduct",
};

export const DiscountType = {
  Percentage: "Percentage",
  FlatDiscount: "FlatDiscount",
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "Percentage",
  FLAT_AMOUNT: "Flat amount",
};

export const FinanceListPageElements: any = [
  { name: "View Financelist", value: "VIEW_FINANCE_LIST" },
  { name: "Create Financelist", value: "CREATE_FINANCE_LIST" },
  { name: "Delete Financelist", value: "DELETE_FINANCE_LIST" },
];

export const ShowOffersListPageElements: any = [
  { name: "View Showoffers", value: "VIEW_SHOW_OFFERS" },
];

export const OffersGroupListPageElements: any = [
  { name: "Create And Edit OffersGroup", value: "CREATE_AND_EDIT_OFFERSGROUP" },
  { name: "Delete OffersGroup", value: "DELETE_OFFERS_GROUP" },
  { name: "View OffersGroup", value: "VIEW_OFFERS_GROUP" },
  { name: "Edit OffersGroup Status", value: "EDIT_OFFERS_GROUP_STATUS" },
];

export const SettingsPageElements: any = [
  { name: "View Settings", value: "VIEW_SETTINGS" },
];
export const HistoryPageElements: any = [
  { name: "View History", value: "VIEW_HISTORY" },
];

export const paginationHandler = (
  product: any,
  page: any,
  rowsPerPage: any
) => {
  return product.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

export const SubMenus = ["BASIC INFO", "OFFERS", "HISTORY"];

export const roles = {
  SuperAdmin: "Super Admin",
  Admin: "Admin",
  Manager: "Manager",
  FinanceManager: "Finance",
  Logistics: "Logistics",
  Staff: "staff",
};

export const historyFilterStatus = [
  "Update",
  "Delete",
  "Create",
  "Status Change",
];
export const historyFilterStatusNbfc = ["Update", "Delete", "Create"];

export const financeMenus = ["Finance", "History", "Upload History"];
export const publishMenus = ["Published Objects"];

export const editorToolbar = [
  [{ header: "1" }, { header: "2" }, { font: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link", "image"],
  ["clean"],
];
