import React, { useContext, useState } from "react";
import { useForm, FieldError } from "react-hook-form";
import { LoginService } from "../../services/login.service";
import appContext from "../../context/app.context";
import { STATUS } from "../../config/config";
import { EToasterVariants } from "../../services/types/common.type";
import {
  ILoginResetPwd,
  ILoginError,
} from "../../services/interfaces/login.interface";
import { Box, Button } from "@mui/material";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ResetPasswordProps {
  email: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ email }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const { setSpinnerVisibility, showToaster } = useContext(appContext);
  const loginService: LoginService = new LoginService();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const password = watch("password");
  const [passwordShown, setPasswordShown] = useState(false);
  const [conformPasswordShown, setConformPasswordShown] = useState(false);

  const resetSubmit = async (data: { password: string }): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const encodedEmail = btoa(email);
      const encodedPassword = btoa(data.password);

      const loginResponse: ILoginResetPwd | ILoginError =
        await loginService.resetPassword({
          email: encodedEmail,
          password: encodedPassword,
        });

      if (loginResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Password Reset Successfully!");
        setShowResetPassword(true);
      } else {
        showToaster(EToasterVariants.error, "Password Reset Failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showToaster(EToasterVariants.error, "An error occurred");
    } finally {
      setSpinnerVisibility(false);
    }
  };

  if (showResetPassword) {
    return <LoginForm />;
  }
  const handleBackToLogin = () => {
    setShowResetPassword(true);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConformPasswordVisiblity = () => {
    setConformPasswordShown(conformPasswordShown ? false : true);
  };

  return (
    <>
      <h3 className="sign-in-name-wrapper resetpwd-lable">Reset Password</h3>

      <div className="mb-3">
        <form
          className="loginForm-container"
          autoComplete="off"
          onSubmit={handleSubmit(resetSubmit)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <div className="loginForm-container resetpwd-new-container">
              <label htmlFor="password" className="resetpwd-new-lable">
                <p className="seller-account-name">New Password : </p>
              </label>
              <div className="resetpwd-eye">
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter New Password"
                  autoComplete="off"
                  id="password"
                  {...register("password", {
                    required: "Please enter a new password",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      value:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z\d\W]).{8,}$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter,one number, and one special character",
                    },
                  })}
                  className="login-input-field-box"
                />
                {passwordShown ? (
                  <FontAwesomeIcon
                    className="password-toogle-ic newpwd-eye"
                    icon={faEye}
                    onClick={togglePasswordVisiblity}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="password-toogle-ic newpwd-eye"
                    icon={faEyeSlash}
                    onClick={togglePasswordVisiblity}
                  />
                )}
              </div>
              {errors.password && (
                <p className="login-error">
                  {(errors.password as FieldError).message}
                </p>
              )}
              <div className="resetpwd-eye">
                <div className="loginForm-container">
                  <label htmlFor="password" className="resetpwd-new-lable">
                    <p className="seller-account-name">
                      Confirm New Password :{" "}
                    </p>
                  </label>
                  <input
                    type={conformPasswordShown ? "text" : "password"}
                    placeholder="Confirm New Password"
                    autoComplete="off"
                    id="confirmPassword"
                    {...register("confirmPassword", {
                      required: "Please confirm your new password",
                      validate: {
                        matchesPassword: (value) =>
                          value === password || "Password does not match",
                      },
                    })}
                    className="login-input-field-box"
                  />
                  {conformPasswordShown ? (
                    <FontAwesomeIcon
                      className="password-toogle-ic conformpwd-eye"
                      icon={faEye}
                      onClick={toggleConformPasswordVisiblity}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="password-toogle-ic conform-eye conformpwd-eye"
                      icon={faEyeSlash}
                      onClick={toggleConformPasswordVisiblity}
                    />
                  )}
                </div>
              </div>
              {errors.confirmPassword && (
                <p className="login-error">
                  {(errors.confirmPassword as FieldError).message}
                </p>
              )}
            </div>

            <Button type="submit" variant="contained" className="verifyotp">
              Reset Password
            </Button>
          </Box>
        </form>
      </div>
      <div className="forgot-password-area forgot-otp-verify">
        <Link
          to="#"
          className="forgot-password forgot-password-link"
          onClick={handleBackToLogin}
        >
          Back to Login
        </Link>
      </div>
    </>
  );
};

export default ResetPassword;
