import React from "react";


type Tprops = {

}

export const CouponsReports: React.FC<Tprops> = () => {

  document.title = "Poorvika - Coupon Reports";

  return (
    <div className="attributes-table">
      CouponsReports
    </div>
  )
}

export default CouponsReports