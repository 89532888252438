import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  ILoginResponse,
  ILoginError,
  ILoginCredentials,
} from "../../services/interfaces/login.interface";
import "./LoginForm.scss";
import { LoginService } from "../../services/login.service";
import { STATUS } from "../../config/config";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "common/keycloak/UseKeyCloak";
import ForgotPassword from "./ForgotPassword";

type LoginCredentials = {
  email: string;
  password: string;
};

/* const formValidations = {
  email: {
    required: { value: true, message: '* Email field is empty' },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  password: {
    required: { value: true, message: '* Password field is empty' },
    minLength: { value: 6, message: '* Password must be at least 6 characters long' }
  }
}*/

const LoginForm: React.FC = () => {
  const loginService: LoginService = new LoginService();
  const [passwordShown, setPasswordShown] = useState(false);
  const [emailLabel, setEmailLabel] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const navigateTo = useNavigate();
  const keycloak: any = useAuth();

  // const userPermission: any = useSelector<RootStore>((state) => state.userDetails.userDetails.Permission);
  const { setSpinnerVisibility, showToaster } = useContext(appContext);

  /*const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };*/

  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm<LoginCredentials>({
    mode: "onChange",
  });

  const onSubmit = async (data: ILoginCredentials): Promise<void> => {
    try {
      localStorage.clear();

      setSpinnerVisibility(true);
      const loginResponse: ILoginResponse | ILoginError =
        await loginService.login({ ...data });

      setSpinnerVisibility(false);

      if (loginResponse.status === STATUS.SUCCESS) {
        const { data } = loginResponse as ILoginResponse;

        localStorage.setItem("user_mail", data.email);
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("userName", data.userName);
        localStorage.setItem("accessType", "OFFER_TOKEN");

        const sidemenu = [
          "VIEW_OFFERS",
          "VIEW_SHOW_OFFERS",
          "VIEW_OFFERS_GROUP",
          "VIEW_COUPONS",
          "VIEW_PRICE_LIST",
          "VIEW_FINANCE_LIST",
          "VIEW_SETTINGS",
          "VIEW_HISTORY",
        ];
        const isPermissionAllowed =
          _.intersection(data.allowedPermission, sidemenu).length > 0;

        if (!isPermissionAllowed) {
          localStorage.clear();
          navigateTo("/login");
          showToaster(EToasterVariants.error, "You dont have a permission");
          return;
        } else {
          showToaster(EToasterVariants.success, "Login Successful!");
          navigateTo("/offers");
        }
      } else {
        setSpinnerVisibility(false);
        showToaster(
          EToasterVariants.error,
          "Username or Password is incorrect"
        );
      }
    } catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "An error occurred");
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  return (
    <>
      {!showForgotPassword ? (
        <>
          <form
            className="loginForm-container"
            autoComplete="new-password"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group email-ip">
              <input
                {...register("email", {
                  required: { value: true, message: "Email field is empty" },
                  pattern: {
                    message: "Please enter valid emailid",
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  },
                })}
                id="email_id"
                className="login-input-field-box"
                placeholder="Login ID or Email"
                name="email"
                onClick={() => setEmailLabel(!emailLabel)}
              />
              <label htmlFor="email_id" className="label-helper">
                Login ID or Email{" "}
              </label>
              {errors?.email && (
                <p className="login-error">* {errors.email?.message as any}</p>
              )}
            </div>
            <div className="email-password-height" />
            <div className="form-group password-wrapper">
              <div className="password-ip">
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password field is empty",
                    },
                    //pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                  })}
                  autoComplete="new-password"
                  className="login-password-field-box"
                  placeholder="Enter your Password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  id="password"
                />
                <label htmlFor="password" className="label-password">
                  Password
                </label>
                {passwordShown ? (
                  <FontAwesomeIcon
                    className="password-toogle-ic"
                    icon={faEye}
                    onClick={togglePasswordVisiblity}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="password-toogle-ic"
                    icon={faEyeSlash}
                    onClick={togglePasswordVisiblity}
                  />
                )}
                {errors?.password && (
                  <p className="login-error">
                    * {errors.password?.message as any}
                  </p>
                )}
              </div>
            </div>

            <div className="forgot-password-area">
              <Link
                to="#"
                className="forgot-password"
                style={{ color: "red" }}
                onClick={toggleForgotPassword}
              >
                Forgot Password?
              </Link>
            </div>

            <button className="login-btn" type="submit">
              LOGIN
            </button>
          </form>
          <div className="sso-login">
            <div className="rules-divider">
              <p>OR</p>
            </div>
            <button
              onClick={() => keycloak?.login()}
              className="login-btn"
              type="submit"
            >
              SSO LOGIN
            </button>
          </div>
        </>
      ) : (
        <>{showForgotPassword && <ForgotPassword />}</>
      )}
    </>
  );
};

export default LoginForm;
