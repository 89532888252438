import { toLocaleNumber } from "common/commonFunc";
import "./styles.scss"
import cashImage from "assets/images/cash-image.png";

type TProp = {
    products?: any
}


export const DiscountCashCard: React.FC<TProp> = ({ products }) => {

    return (
        <>
            {products?.map((product: any, index: any) => {
                return (
                    <>
                        <div className="product-multiField-box">
                            {index !== 0 && <div className="btn-Wrapper">
                                <p>+</p>
                            </div>}
                            <div className="product-multiField">
                                <div className="product-details">
                                    <p>{"Cash Discount"}</p>
                                </div>

                                <div className="product-Img">
                                    <img className="cash-img" alt={"cash-img" + index} src={cashImage || product.cashImage} />
                                </div>

                                <div className="product-price">
                                    <p>{toLocaleNumber(product?.cashDiscount)}</p>
                                </div>

                            </div>
                        </div>


                    </>
                )
            })
            }

        </>
    )
}