import moment from 'moment';
import "./styles.scss";
import { getChannelNames } from 'common/commonFunc';
interface ProductOfferProp {
  emi_details: any;
  index: Number
}

export default function EmiOffer(props: ProductOfferProp) {
  const { emi_details } = props;

  return (

    <div className="emi-list">
      <div className="emi-name">
        <span>{emi_details._id}</span>
      </div>
      <div className="emi-table">
        <table>
          <thead>
            <tr>
              <th>Scheme</th>
              <th>
                <div>Advance EMI</div>
                <div>(EMI X Months)</div>
              </th>
              <th>
                <div>Remaining EMI</div>
                <div>(EMI X Months)</div>
              </th>
              <th>Processing Fee</th>
              <th>Down Payment</th>
              <th>Overall Loan Amount</th>
              <th>Channel</th>
              <th style={{ minWidth: '150px' }}>Period</th>
            </tr>
          </thead>

          <tbody>
            {
              emi_details?.schemas?.map((emi: any) => {
                return (<tr key={emi?._id}>
                  <td>{emi?.schemeName}</td>
                  <td>{`${emi?.emiPerMonth} X ${emi?.initalEmiMonths}`}</td>
                  <td>{`${emi?.emiPerMonth} X ${emi.totalEmiMonths - emi?.initalEmiMonths}`}</td>
                  <td>{emi?.processingFees}</td>
                  <td>{emi?.processingFees + (emi?.emiPerMonth * emi?.initalEmiMonths)}</td>
                  <td>{emi?.totalEmiAmt}</td>
                  <td>{getChannelNames(emi?.channel)}</td>
                  <td>
                    <div className='period-td'>
                      <span className='flex-1'> {moment(emi?.startDate).format('DD-MM-YY H:mm')}</span>
                      <span style={{fontWeight:'bold'}}>{" to "}</span>
                      <span className='flex-1'> {moment(emi?.endDate).format('DD-MM-YY H:mm')}</span>
                    </div>
                  </td>
                </tr>)

              })
            }
          </tbody>

        </table>
      </div>
    </div>
  )
};
