import "./styles.scss"
import noImageAvailable from "assets/images/no-image.png";


type TProp = {
    product?: any
}

export const ProductCard: React.FC<TProp> = ({ product }) => {


    return (
        <div className="combooff-product-card">
            {product && <>
                <img alt="Product-Img" className="img" src={product?.imageURL || product?.image?.all?.url || noImageAvailable} />
                <div>
                    <p className="product-name">{product?.varientName}</p>
                    <p className="item-code">{product?.itemCode}</p>
                </div></>
            }
        </div>
    )
}