import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import appContext from "context/app.context";
import { ProductionSelectionDialog } from "containers/Dialogs/ProductionSelectionDialog";
import { ProductCardTable } from "components/ProductCardTable";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import { CashDiscountDialog } from "containers/Dialogs/CashDiscountDialog";
import { DiscountCashCard } from "components/DiscountCashCard";
import { usePermission } from "components/Permission";

type ProductMultiSelectProps = {
  fieldName: string;
  disabled?: boolean
  product?: any,
  viewOnlyMode?: boolean,
  comboOffertype?: string,
  freebies?: string
};

const ProductMultiSelect: React.FC<ProductMultiSelectProps> = ({
  // selectedProds,
  fieldName,
  product,
  disabled,
  viewOnlyMode,
}) => {
  const { offerData } = useContext(appContext);
  const [dialog, setDialog] = useState<boolean>(false)
  const [cashdialog, setCashDialog] = useState<boolean>(false)
  const [productDialog, setProductDialog] = useState<boolean>(false)
  const [productIndex, setProductIndex] = useState();

  const [addProductPermission] = usePermission(["ADD_OFFER_PRODUCTS"]);
  const [deleteProductPermission] = usePermission(["DELETE_OFFER_PRODUCTS"])

  const offerfieldData = fieldName === "ComboOffer" ? (offerData?.comboOffers && offerData?.comboOffers[product.itemCode.replaceAll("\n", "").trim()]) : (offerData?.freebies && offerData?.freebies[product.itemCode.replaceAll("\n", "").trim()])
  const [selectedProducts, setSelectedProducts] = useState<any>(offerfieldData || []);

  useEffect(() => {
    const offerfieldData = fieldName === "ComboOffer" ? (offerData?.comboOffers && offerData?.comboOffers[product.itemCode.replaceAll("\n", "").trim()]) : (offerData?.freebies && offerData?.freebies[product.itemCode.replaceAll("\n", "").trim()]);
    setSelectedProducts(offerfieldData);
  }, [offerData])


  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);

  const onAddHandeler = () => {
    setDialog(true)
    setSliderVisibility(true)
  }

  const onCashHandeler = () => {
    setCashDialog(true)
  }

  const handleDelete = (item: any, indexno: number) => {
    setSelectedProducts(selectedProducts.filter((prod: any, index: number) => `${prod.name}-${index}` !== `${item.name}-${indexno}`))
  }

  const onPriceUpdate = (price: number, prod: any) => {
    const temp = selectedProducts?.filter((element: any) => {
      if (element.name === prod.name) {
        element.combooffers = price
        return element
      }
      else {
        return element
      }
    })
  };
  const productAddHandeler = (e: any, index: any) => {
    e.preventDefault();
    setProductIndex(index)
    setProductDialog(true)
  }

  const deleteHandeler = (deleteIndex: any) => {
    setSelectedProducts(selectedProducts.filter((data: any, index: number) => { return deleteIndex !== index }))
  }

  useEffect(() => {

    if (fieldName === "freebies") {
      !offerData?.freebies && (offerData.freebies = {})
      offerData.freebies[product.itemCode.replaceAll("\n", "").trim()] = selectedProducts
    }
    else {
      !offerData?.comboOffers && (offerData.comboOffers = {})
      offerData.comboOffers[product.itemCode.replaceAll("\n", "").trim()] = selectedProducts
    }

  }, [selectedProducts])

  useEffect(() => {
    if (fieldName === "freebies") {
      setSelectedProducts(offerData.freebies[product.itemCode.replaceAll("\n", "").trim()] || [])
    }
    else {
      setSelectedProducts(offerData.comboOffers[product.itemCode.replaceAll("\n", "").trim()] || [])
    }
  }, [])



  return (
    <div className={disabled ? "selected-multi-Products disabled" : "selected-multi-Products"}>
      {
        addProductPermission && <div className="button-field">
          <button
            className={!viewOnlyMode ? "btn primary" : "btn disabled"}
            type="button"
            onClick={() => !viewOnlyMode && onAddHandeler()}>
            Add Product
          </button>
          <button
            className={!viewOnlyMode ? "btn primary" : "btn disabled"}
            type="button"
            onClick={() => !viewOnlyMode && onCashHandeler()}>
            Add Cash Discount
          </button>
        </div>
      }
      <div className="freebiesorcomboproductCard">
        {selectedProducts?.length ? selectedProducts?.map((prod: any, index: number) => {
          return (
            <>
              <div className="product-multiField-container-flex">
                <div className="product-multiField-container">
                  {
                    prod?.[0]?.type === 'Cash' ?
                      <DiscountCashCard products={prod} />
                      : <ProductCardTable combooff={!viewOnlyMode} products={prod}
                        addProduct={product}
                        handleDelete={(item: any) => handleDelete(item, index)}
                        onPriceUpdate={(price: number) => onPriceUpdate(price, prod)}
                        productIndex={productIndex}
                        setSelectedProducts={setSelectedProducts}
                        setProductDialog={setProductDialog}
                        productDialog={productDialog}
                        selectedProducts={selectedProducts}
                        fieldName={fieldName}
                      />
                  }

                  {!viewOnlyMode && addProductPermission && prod?.[0]?.type !== 'Cash' && <div className="button-wrapper">
                    <button className="addIconBtn" onClick={(e) => { productAddHandeler(e, index) }}>+</button>
                  </div>}

                </div>
                {!viewOnlyMode && deleteProductPermission && <div className="product-deleteBtn" onClick={() => { deleteHandeler(index) }} >
                  <Icon icon={faTrash} />
                </div>}

              </div>
              {(index < selectedProducts.length - 1) && < div className="hr-lin">
                <hr className="hr-line" />
                <p className="add-btn">
                  OR
                </p>

              </div>}

            </>
          )
        }) : ""}
      </div>


      {
        dialog && <ProductionSelectionDialog fieldName={fieldName} show={dialog} onExit={(bool, data) => {

          if (bool) {
            setSelectedProducts([...selectedProducts, [data]])

            setDialog(false)
          }

          else {
            setDialog(false)
          }
        }} />
      }

      {
        cashdialog && <CashDiscountDialog fieldName={fieldName} show={cashdialog} onExit={(bool, data) => {

          if (bool) {
            setSelectedProducts([...selectedProducts, [data]])

            setCashDialog(false)
          }

          else {
            setCashDialog(false)
          }
        }} />
      }

    </div >
  );
};

export default ProductMultiSelect;
