
import React, { useContext, useEffect, useState } from 'react'

import { OffersPageElements, CouponsPageElements,    FinanceListPageElements, ShowOffersListPageElements, OffersGroupListPageElements, SettingsPageElements, HistoryPageElements } from 'common/constant'
import './styles.scss'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { RolesService } from 'services/roles.service'
import { RolesList } from '../../services/interfaces/Roles.interface'
import { useForm } from 'react-hook-form';
import InputField from 'components/InputField';
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import { useSelector } from 'react-redux';
import { RootStore } from 'redux/store';
import { EToasterVariants } from 'services/types/common.type';
import appContext from 'context/app.context';
import { STATUS } from 'config/config';
import { SettingsService } from 'services/settings.service';

const RolePermission: React.FC = () => {
    const { register, handleSubmit, reset, setValue, watch } = useForm({ mode: 'all' })


    const userRole: any = useSelector<RootStore>((state) => state.userDetails.userDetails.userRole)
    const [addRoles, setAddRoles] = React.useState<boolean>(false)
    const [offerAttributes, setOfferAttributes] = useState<Array<any>>([])
    const [ruleAttributes, setRuleAttributes] = useState<Array<any>>([])
    const [groupAttributes, setGroupAttributes] = useState<Array<any>>([])
    const [roleList, setRolesList] = useState<RolesList[] | []>([]);
    const [SellerInformation, setSellerInformation] = useState<any>([]);
    const [activeRole, setActiveRole] = useState<any>()
    const [pendingCard, setPendingCard] = useState<boolean>(true)
    const [roleName, setRoleName] = useState<string>();

    const settingsService = new SettingsService();
    const rolesService: RolesService = new RolesService()

    const context = useContext(appContext);
    const { setSpinnerVisibility } = useContext(appContext);

    const getAttributes = async (): Promise<void> => {
        try {
            setSpinnerVisibility(true);
            const attributesResponse = await settingsService.getAttributes();
            setSpinnerVisibility(false);
            if (attributesResponse.status === STATUS.SUCCESS) {
                const temp: any = { ruleAttributes: [], offerAttributes: [], groupAttributes: [] }
                attributesResponse.data.AttributesData.map((item: any) => {
                    item.isRule && (!item.isOffergroup || item.isOffergroup === undefined) && temp.ruleAttributes.push(item)
                    !item.isRule && (!item.isOffergroup || item.isOffergroup === undefined) && temp.offerAttributes.push(item)
                    item.isOffergroup && temp.groupAttributes.push(item)
                })
                setOfferAttributes(temp.offerAttributes)
                setRuleAttributes(temp.ruleAttributes)
                setGroupAttributes(temp.groupAttributes)
            } else {
                context.showToaster(
                    EToasterVariants.error,
                    "Unable to fetch Attributes"
                );
            }
        } catch (error) {
            context.showToaster(EToasterVariants.error, "Unable to fetch Attributes");
        }
    };


    const onSubmit = async (formData: any) => {


        if (!addRoles) {
            try {
                delete formData.Role
                delete formData.rulesAll
                delete formData.offerValueAll
                delete formData.groupRulesAll
                delete formData.offerPageElementAll
                delete formData.couponPageElementAll
                delete formData.offerProductPageElementAll
                delete formData.offerRulesPageElementAll
                const updateValidation = {
                    Role: roleName,
                    Permission: formData,
                }
                console.log(formData['VIEW_OFFERS']);

                if (!formData['VIEW_OFFERS'] && OffersPageElements.some((item: any) => formData[item.value])) {
                    context.showToaster(EToasterVariants.error, "Please Select View Offers");
                }
                else if (!formData['VIEW_COUPONS'] && CouponsPageElements.some((item: any) => formData[item.value]))
                    context.showToaster(EToasterVariants.error, "Please Select View Coupons");
                else {
                    await rolesService.updateRoles(updateValidation);
                    getRolesList();
                    setPendingCard(true)
                    setAddRoles(false)
                    setActiveRole("")
                }


            } catch (error) {

            }
        } else if (addRoles) {
            try {
                const rolesValidation = {
                    Role: formData.Role,
                    Permission: formData
                }

                if (!formData['VIEW_OFFERS'] && OffersPageElements.some((item: any) => formData[item.value]))
                    context.showToaster(EToasterVariants.error, "Please Select View Offers");
                else if (!formData['VIEW_COUPONS'] && CouponsPageElements.some((item: any) => formData[item.value]))
                    context.showToaster(EToasterVariants.error, "Please Select View Coupons");
                else {
                    await rolesService.addRoles(rolesValidation)
                    setPendingCard(true)
                    setAddRoles(false)
                    getRolesList();
                    reset()
                }



            } catch (error) {


            }
        }


    }

    const getRolesList = async (): Promise<void> => {
        const getRoles = await rolesService.getRolesList()
        setRolesList(getRoles.data)

    }

    const getRolesPermission = async (data: string): Promise<void> => {
        setRoleName(data)
        setPendingCard(false)
        setSellerInformation([])
        setActiveRole(data)

        try {
            const getPermission = await rolesService.getRolesPermission(data)
            const permissionData = getPermission.data[0]
            setAddRoles(false);
            setValue("Role", data)
            setSellerInformation(permissionData?.Permission)
        } catch {


        }


    }

    useEffect(() => {
        SellerInformation && Object.keys(SellerInformation).map((value: any) => {
            setValue(`${value}`, SellerInformation[value])
        })
        const isOfferPageAllSelected = OffersPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`offerPageElementAll`, isOfferPageAllSelected)
        const isCouponPageAllSelected = CouponsPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`couponPageElementAll`, isCouponPageAllSelected)
        const isShowOfferListValueAllSelected = ShowOffersListPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`showOffersListValueAll`, isShowOfferListValueAllSelected)
        const isGroupOffersListValueAllSelected = OffersGroupListPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`offersGroupListValueAll`, isGroupOffersListValueAllSelected)
        const isFinanceListValueAllSelected = FinanceListPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`financeListValueAll`, isFinanceListValueAllSelected)
        const isSettingsValueAllSelected = SettingsPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`settingsListValueAll`, isSettingsValueAllSelected)
        const isHistoryValueAllSelected = HistoryPageElements.every((e: any) => watch(`${e.value}`))
        setValue(`historyListValueAll`, isHistoryValueAllSelected)
    }, [SellerInformation])

    const addRolesHandleer = async () => {
        reset()
        setSellerInformation([])
        setAddRoles(true);
        setValue("Role", "")
        setPendingCard(false)
        setActiveRole("")
    }

    useEffect(() => {
        getRolesList();
        getAttributes()
    }, [])

    return (
        <div className="roles-and-permission-page">

            <div className="left-wrapper">

                {userRole === "Super Admin" && <div className="save-btn-wrap">
                    <Button color="#fb6c00" onClick={() => addRolesHandleer()}>+ Add Roles</Button>
                    {/* <ActionButton label="+ Add Roles"  /> */}
                </div>}

                {roleList ?
                    <>
                        {
                            roleList?.map((item: any) => (
                                <div key={item.Role} className={activeRole === item.Role ? "roles-list-wrapper bgactive" : "roles-list-wrapper"} onClick={() => {
                                    setSellerInformation([])
                                    reset()
                                    getRolesPermission(item.Role);
                                }}>

                                    <p className="roles-name">{item.Role}</p>
                                    <ArrowForwardIosIcon />
                                </div>

                            ))
                        }
                    </>
                    : <> </>}

            </div>
            {!pendingCard ?
                <>

                    {addRoles ?
                        <div className="right-wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="right-content">
                                    <p className="add-role-header">Add Role</p>

                                    <div className="save-btn-wrap ">
                                        <Button color="#fb6c00">Save</Button>

                                    </div>

                                    <div className="name-role-input">
                                        <InputField
                                            register={register("Role")}
                                            name="Role" helpertext={'Role'}
                                        />

                                    </div>
                                    <div className="right-content">
                                        <p className="permisssion-txt-wrap ">Permissions</p>
                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">Offer Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("offerPageElementAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("offerPageElementAll", checkedStatus)
                                                            OffersPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {OffersPageElements?.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (

                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = OffersPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("offerPageElementAll", true) : setValue("offerPageElementAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">ShowOffers Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("showOffersListValueAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("showOffersListValueAll", checkedStatus)
                                                            ShowOffersListPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {ShowOffersListPageElements.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (
                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = FinanceListPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("showOffersListValueAll", true) : setValue("showOffersListValueAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>

                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">OffersGroup Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("offersGroupListValueAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("offersGroupListValueAll", checkedStatus)
                                                            OffersGroupListPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {OffersGroupListPageElements.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (
                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = OffersGroupListPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("offersGroupListValueAll", true) : setValue("offersGroupListValueAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>

         
                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">Coupon Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("couponPageElementAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("couponPageElementAll", checkedStatus)
                                                            CouponsPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {CouponsPageElements?.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (

                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = CouponsPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("couponPageElementAll", true) : setValue("couponPageElementAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>

                                        

                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">Financelist Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("financeListValueAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("financeListValueAll", checkedStatus)
                                                            FinanceListPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {FinanceListPageElements.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (
                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = FinanceListPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("financeListValueAll", true) : setValue("financeListValueAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>

                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">Settings Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("settingsListValueAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("settingsListValueAll", checkedStatus)
                                                            SettingsPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {SettingsPageElements.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (
                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = SettingsPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("settingsListValueAll", true) : setValue("settingsListValueAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        <div className="roles-content-wrap">
                                            <div className="roles-header">
                                                <p className="roles-header-label">History Permission
                                                    <Checkbox
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch("historyListValueAll")}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue("historyListValueAll", checkedStatus)
                                                            HistoryPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                            {HistoryPageElements.map((item: any, index: number) => {
                                                watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                                return (
                                                    <div className="roles-select-wrap" key={index}>
                                                        <p>{item.name}</p>
                                                        <Checkbox key={index}
                                                            disabled={userRole === "Super Admin" ? false : true}
                                                            value={watch(`${item.value}`)}
                                                            onChange={(checkedStatus: boolean) => {
                                                                setValue(`${item.value}`, checkedStatus)
                                                                const isSelectedAll = HistoryPageElements.every((e: any) => watch(`${e.value}`))
                                                                isSelectedAll ? setValue("historyListValueAll", true) : setValue("historyListValueAll", false)
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        : <></>}

                    {!addRoles ?
                        <div className="right-wrapper editRole-custom-checkbox">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="right-content">
                                    <p className="add-role-header">Edit Role</p>
                                    {userRole === "Super Admin" && <div className="save-btn-wrap">
                                        <Button color="#fb6c00">Save</Button>
                                    </div>}

                                    <div className="name-role-input">
                                        <InputField
                                            register={register("Role")}
                                            name="Role" helpertext={'Role'}
                                            disabled={true}
                                        />

                                    </div>
                                    <p className="permisssion-txt-wrap ">Permissions</p>
                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">Offer Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("offerPageElementAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("offerPageElementAll", checkedStatus)
                                                        OffersPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {OffersPageElements?.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (

                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = OffersPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("offerPageElementAll", true) : setValue("offerPageElementAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">ShowOffers Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("showOffersListValueAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("showOffersListValueAll", checkedStatus)
                                                        ShowOffersListPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {ShowOffersListPageElements.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (
                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = FinanceListPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("showOffersListValueAll", true) : setValue("showOffersListValueAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">Coupon Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("couponPageElementAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("couponPageElementAll", checkedStatus)
                                                        CouponsPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {CouponsPageElements?.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (

                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = CouponsPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("couponPageElementAll", true) : setValue("couponPageElementAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">OffersGroup Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("offersGroupListValueAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("offersGroupListValueAll", checkedStatus)
                                                        OffersGroupListPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {OffersGroupListPageElements.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (
                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = OffersGroupListPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("offersGroupListValueAll", true) : setValue("offersGroupListValueAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>


                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">Financelist Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("financeListValueAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("financeListValueAll", checkedStatus)
                                                        FinanceListPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {FinanceListPageElements.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (
                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = FinanceListPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("financeListValueAll", true) : setValue("financeListValueAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">Settings Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("settingsListValueAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("settingsListValueAll", checkedStatus)
                                                        SettingsPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {SettingsPageElements.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (
                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = SettingsPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("settingsListValueAll", true) : setValue("settingsListValueAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <div className="roles-content-wrap">
                                        <div className="roles-header">
                                            <p className="roles-header-label">History Permission
                                                <Checkbox
                                                    disabled={userRole === "Super Admin" ? false : true}
                                                    value={watch("historyListValueAll")}
                                                    onChange={(checkedStatus: boolean) => {
                                                        setValue("historyListValueAll", checkedStatus)
                                                        HistoryPageElements.map((item: any, index: number) => setValue(`${item.value}`, checkedStatus))
                                                    }}
                                                />
                                            </p>
                                        </div>
                                        {HistoryPageElements.map((item: any, index: number) => {
                                            watch(`${item.value}`) === undefined && setValue(`${item.value}`, false)
                                            return (
                                                <div className="roles-select-wrap" key={index}>
                                                    <p>{item.name}</p>
                                                    <Checkbox key={index}
                                                        disabled={userRole === "Super Admin" ? false : true}
                                                        value={watch(`${item.value}`)}
                                                        onChange={(checkedStatus: boolean) => {
                                                            setValue(`${item.value}`, checkedStatus)
                                                            const isSelectedAll = HistoryPageElements.every((e: any) => watch(`${e.value}`))
                                                            isSelectedAll ? setValue("historyListValueAll", true) : setValue("historyListValueAll", false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        : <></>}
                </>
                :
                <></>
            }



        </div >
    )
}

export default RolePermission
