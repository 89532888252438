import React from "react";
import "./styles.scss";

import Headset from "assets/icons/Headset.svg";

const NoProducts: React.FC = () => {
  return (
    <tr className="no-product-container">
      <td colSpan={9}>
        <img src={Headset} alt="Headset" className="add-more" />
        <p>Add more products here</p>
      </td>
    </tr>
  );
};

export default NoProducts;
