import React, { useContext, useEffect, useState } from "react";
import {
  faPlus,
  faHistory,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import { STATUS } from "config/config";
import { BulkProductUpload } from "components/BulkProductUpload";

import { productBulkUploadColumns, TradeExProductBulkUploadColumns, OfferTextproductBulkUploadColumns, OfferType,  comboAndFreebieColumns } from "common/constant";
import DownloadTemplates from "components/DownloadeTemplates";
import ProductDialogbox from "components/ProductDialogbox"
import NoProducts from "components/NoProducts";
import TablePagination from "@mui/material/TablePagination";
import Input from "components/UseInput";
import BulkUploadHistory from "components/BulkUploadHistory";
import OfferProducts from "./OfferProducts";
import { ProductService } from "services/product.service";
import { defaultLabelDisplayedRows } from "common/commonFunc";
import Checkbox from "components/Checkbox";
import Icon from "components/Icon";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";

import "./styles.scss";
import { usePermission } from "components/Permission";

type AddProductsProps = {
  viewOnlyMode?: boolean;
};

const AddProducts: React.FC<AddProductsProps> = ({ viewOnlyMode }) => {
  const [bulkVisibility, setBulkVisibility] = useState<boolean>(false);
  const [historyVisibility, setHistoryVisibility] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);
  const [productLists, setProductLists] = useState<any>([]);
  const [filter, setfilter] = useState<any>({});
  const [addProductPermission] = usePermission(["ADD_OFFER_PRODUCTS"])
  const [editProductPermission] = usePermission(["EDIT_OFFER_PRODUCTS"])
  const [deleteProductPermission] = usePermission(["DELETE_OFFER_PRODUCTS"])
  const [createOfferPermission] = usePermission(["CREATE_OFFERS"]);
  const [editOfferPermission] = usePermission(["EDIT_OFFERS"]);


  const [isSelectAll, setSelectAll] = useState<boolean>(false);


  const { showToaster, setMenuIndex, offerData, setOfferData, setSpinnerVisibility } = useContext(appContext);

  const productServices = new ProductService();
  /* Pagination Actions */
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const goToPreviousFormHandler = async () => {
    setMenuIndex(0);
  }

  const downloadTemplateHandeler = () => {
    if (offerData?.selectedOfferType?.offerValues === OfferType.ComboOffer || offerData?.selectedOfferType?.offerValues === OfferType.Freebies) {
      return comboAndFreebieColumns
    }
    else if (offerData?.selectedOfferType?.offerTypes === 'TradeIn and Exchange') {
      return TradeExProductBulkUploadColumns
    }
    else if (offerData?.selectedOfferType?.offerTypes === 'Offer Text') {
      return OfferTextproductBulkUploadColumns
    }
    return productBulkUploadColumns
  }



  const getProductLists = async (offerId: string, filter = {}) => {
    setSpinnerVisibility(true);
    try {
      const apiResponse = await productServices.getOffersProductsList(offerId, rowsPerPage, page, filter);
      if (apiResponse.status === STATUS.SUCCESS) {
        setProductLists(apiResponse.data)
        setTotalPages(apiResponse.count)

      } else {
        showToaster(EToasterVariants.error, "Unable to get offer products");
      }
    } catch (error) {
      console.log(error);

    }

    setSpinnerVisibility(false);
  }

  const searchHandler = async (search: any) => {
    getProductLists(offerData._id, { ...filter, search })
  }

  const selectAllProduct = (checkedStatus: boolean) => {
    setSelectAll(checkedStatus);
    const result = productLists.map(
      (item: any, index: number) => {
        return { ...item, checked: checkedStatus };
      }
    );
    setProductLists(result);

  }

  const deleteProducts = () => {
    setDeleteDialog(true);

  }

  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    setSpinnerVisibility(true);
    if (confirmation) {
      try {
        const ids = selectedList.map((product: any) => product._id);
        const deleteResponse =
          await productServices.deleteMultipleProduct(ids, offerData._id);

        if (deleteResponse.status === STATUS.SUCCESS) {
          getProductLists(offerData._id, filter);
          setOfferData(deleteResponse.data);
          setDeleteDialog(false);
          setSelectAll(false);
          showToaster(EToasterVariants.success, deleteResponse.message);
        } else {
          showToaster(EToasterVariants.error, "Unable to delete the Product");
        }
      } catch (error) {
        showToaster(
          EToasterVariants.error,
          "Unable to delete the Finance Offer"
        );
      }
    } else {
      setDeleteDialog(false);
    }
    setSpinnerVisibility(false);

  };

  useEffect(() => {
    if (offerData && viewOnlyMode !== undefined)
      getProductLists(offerData._id, filter)
  }, [page, rowsPerPage, filter, offerData]);

  const selectedList = productLists.filter((prod: any) => prod.checked)


  return (
    <div className="add-products-page">

      <div className="product-header">
        {!viewOnlyMode && addProductPermission ? (
          <div className="btn-wrapper">
            <div
              className="btn-add-products"
              onClick={() => {
                setDialog(true);
              }}
            >
              <FontAwesomeIcon className="" icon={faPlus} />
              <p>Add Products</p>
            </div>
            <div
              className="btn-add-products"
              onClick={() => setBulkVisibility(true)}
            >
              <FontAwesomeIcon className="" icon={faPlus} />
              <p>Bulk Upload</p>
            </div>
            <DownloadTemplates heading={downloadTemplateHandeler()} ReportTitle={"Products"} height={true} />
            <div
              className="btn-add-products"
              onClick={() => setHistoryVisibility(!historyVisibility)}
            >
              <FontAwesomeIcon className="" icon={faHistory} />
              <p>{!historyVisibility ? 'Bulk History' : 'See Products'}</p>
            </div>
          </div>
        ) : <div></div>}
        <div className="searchField">
          <Input
            className="inputwrp"
            placeholder="Search Item code"
            onChange={searchHandler}
          />
        </div>
      </div>
      {
        !historyVisibility &&
        <>
          {!viewOnlyMode && deleteProductPermission && selectedList?.length > 0 && (
            <button
              onClick={deleteProducts}
              className="btn primary mr-2 cursor-pointer"
            >
              <Icon icon={faTrash} /> {`Delete ${selectedList?.length} products`}
            </button>
          )}
          <table className="rules-table">
            <thead>
              <tr>
                {
                  !viewOnlyMode && deleteProductPermission && <th>
                    <Checkbox
                      onChange={selectAllProduct}
                      value={isSelectAll}
                    />
                  </th>
                }

                <th>Image</th>
                <th>Variant Name</th>
                <th>Item Code</th>
                {offerData?.selectedOfferType?.offerValues === OfferType.InstantDiscount &&
                  offerData?.selectedOfferType?.offerTypes !== 'TradeIn and Exchange' &&
                  offerData?.selectedOfferType?.offerTypes !== 'Offer Text' &&
                  <>
                    <th>Discount</th>
                    <th>Maximum Discount</th>
                    <th>Minimum Purchase Value</th>
                    <th>Maximum Purchase Value</th>
                  </>
                }
                {offerData?.selectedOfferType?.offerTypes === 'TradeIn and Exchange' &&
                  <th>Exchange Bonus</th>
                }
                <th>Applicable From</th>
                <th>Applicable To</th>
                {!viewOnlyMode && (deleteProductPermission || editProductPermission) ? <th>Action</th> : ""}
              </tr>
            </thead>
            <tbody>
              {productLists?.length ? productLists?.map((product: any, index: number) => {

                return (
                  <OfferProducts
                    product={product}
                    viewOnlyMode={viewOnlyMode}
                    getProductLists={getProductLists}
                    filter={filter}
                    productLists={productLists}
                    setProductLists={setProductLists}
                    setSelectAll={setSelectAll}
                    isSelectAll={isSelectAll}
                  />
                );
              }) : <NoProducts />
              }
            </tbody>
          </table>

          {!historyVisibility && (

            <div className="pagination-wrapper">
              <TablePagination
                backIconButtonProps={
                  page === 1 ? { disabled: true } : { disabled: false }
                }

                nextIconButtonProps={
                  (page === (Math.ceil(totalPages / rowsPerPage) || 1))
                    ? { disabled: true }
                    : { disabled: false }
                }
                component="div"
                labelDisplayedRows={defaultLabelDisplayedRows}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                page={page}
                count={Math.ceil(totalPages / rowsPerPage) || 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}

          <DeleteConfirmationDialog
            show={deleteDialog}
            onConfirmation={deleteConfirmationHandler}
          />

          {  !viewOnlyMode && (editOfferPermission || createOfferPermission) && (
            <div className="btn-group-align">
              <button
                type="button"
                className="btn secondary"
                onClick={goToPreviousFormHandler}
              >
                Previous
              </button>
              <button onClick={() => setMenuIndex(2)} className="btn primary mr-2">
                Next
              </button>
            </div>
          )}
        </>

      }

      {bulkVisibility && (
        <BulkProductUpload
          setBulkVisibility={setBulkVisibility}
          offerTemplate={offerData?.selectedOfferType?.offerValues}
          offerType={offerData?.selectedOfferType?.offerTypes}
        />
      )}
      {historyVisibility && (
        <BulkUploadHistory module="Offers" Id={offerData?._id} />
      )}
      {dialog &&
        <ProductDialogbox
          setDialog={setDialog}
          type={"offers"}
          getProductLists={getProductLists}
          filter={filter}

        />}

    </div>
  );
};

export default AddProducts;
