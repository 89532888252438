import React, { useState, memo, useEffect } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { CrmService } from 'services/crm.service';
import "./styles.scss"

type TAutoSearchProps = {
    handleChange: (value: any) => void,
    setReset: (reset: boolean) => void
    reset: boolean
} & React.SelectHTMLAttributes<HTMLSelectElement>

const CustomerSearch: React.FC<TAutoSearchProps> = ({ reset, handleChange, setReset }) => {

    const crmService = new CrmService()
    const [value, setValue] = useState("");
    const [openSuggestion, setOpenSuggestions] = useState<boolean>(false)
    const [selectedValue, setSelectedValue] = useState<any>({});
    const [hover, setHover] = useState<number>(-1)
    const [customerData, setCustomerData] = useState([])

    useEffect(() => {
        if (reset) handleOnClear()
    }, [reset])

    const fetchCustomerDetails = async (searchText: string) => {
        try {
            const getCustomerDetails = await crmService.getCustomerDetails(searchText)
            setCustomerData(getCustomerDetails?.data?.data ?? [])

        } catch (error) {

        }
    }

    const handleOnSelect = (value: any) => {
        setHover(-1)
        handleChange(value)
        setValue(value.name || value.email || value.mobile)
        setOpenSuggestions(false)
    }

    const handleKeyDown = (keyCode: number) => {
        if (Number(keyCode) === 38) {
            let index = Number(hover) - 1
            if (index < 0) {
                setHover(customerData.length - 1)
                setSelectedValue(customerData[customerData.length - 1])
            } else {
                setHover(index)
                setSelectedValue(customerData[index])
            }
        } else if (Number(keyCode) === 40) {
            let index = Number(hover) + 1
            if (index > customerData.length - 1) {
                setHover(0)
                setSelectedValue(customerData[0])
            } else {
                setHover(index)
                setSelectedValue(customerData[index])
            }
        }

    }

    const handleOnChange = async (value: string) => {
        setOpenSuggestions(value ? true : false)
        setReset(false)
        setHover(-1)
        setValue(value)
        handleChange({});
        if (value?.trimEnd()) {
            await fetchCustomerDetails(value)
        }
    }

    const handleOnClear = () => {
        setValue('');
        handleChange('');
        setOpenSuggestions(false)
        setHover(-1)

    }

    const handleSubmit = () => {
        setHover(-1)
        handleChange(selectedValue)
        handleChange(selectedValue)
        setValue(selectedValue.name || selectedValue.email || selectedValue.mobile)
        setOpenSuggestions(false)
    }

    const Suggestions = () => {
        return (
            <ul className="suggestionsContainer">
                {customerData.length > 0 ? <>
                    {customerData.map((suggestion: any, index: number) =>
                        <li key={index} className={`suggestions ${hover === index ? "selected" : ""}`} onClick={() => handleOnSelect(suggestion)} >
                            <p>{suggestion?.name}</p>
                            {suggestion?.email && <p><span className='title'>Email</span>: {suggestion?.email}</p>}
                            {suggestion?.mobile && <p><span className='title'>Mobile</span>: {suggestion?.mobile}</p>}
                        </li>
                    )}
                </> :
                    <>
                        {value.length > 0 && <li className="no-suggestions"><p>No customer found. <span style={{ fontSize: "20px" }}>&#128542;</span></p></li>}
                    </>
                }
            </ul >
        );
    };

    return (
        <div className="autocomplete">
            <form onSubmit={(e: any) => { e.preventDefault(); handleSubmit() }}>
                <input
                    type="text"
                    className="input"
                    placeholder='Search customer by Mobile or Email or Name'
                    value={value}
                    onChange={(e) => handleOnChange(e.target.value)}
                    onKeyDown={(e: any) => handleKeyDown(e.keyCode)}
                />
                {value?.length > 0 && <ClearIcon onClick={() => handleOnClear()} />}
                {customerData && openSuggestion && <Suggestions />}
            </form>
        </div>
    )
}

export default memo(CustomerSearch)
