import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class FinanceListNameService extends BaseService {
  public async addFinanceName(eventData: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.FINANCE_NAME_LIST,
        eventData
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
  public async getAllFinanceName(
    page: number,
    limit: number
  ): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.FINANCE_NAME_LIST}?page=${page}&limit=${limit}`
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
  public async updateFinanceName(eventData: any): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.FINANCE_NAME_LIST}/${eventData._id}`,
        eventData
      );

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async deleteFinanceName(_id: string): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.delete(
        `${API_ENDPOINTS.FINANCE_NAME_LIST}/${_id}`
      );

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
}
