import React, { useContext } from 'react'

import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import loginPageIllustration from 'assets/images/Delivery-address-amico.svg'
import LoginForm from '../../form/LoginForm/LoginForm'

import './styles.scss';
import { useAuth } from 'common/keycloak/UseKeyCloak';
import appContext from 'context/app.context';
import { LoginService } from 'services/login.service';
import { useNavigate } from 'react-router-dom';
import { EToasterVariants } from 'services/types/common.type';
import { STATUS } from 'config/config';
import { ILoginResponse } from 'services/interfaces/login.interface';
import _ from 'lodash';

const LoginPage: React.FC = () => {
  const { setSpinnerVisibility, showToaster } = useContext(appContext);
  const loginService: LoginService = new LoginService();
  const navigateTo = useNavigate();
  const keycloak: any = useAuth();

  const ssoLogin = async () => {
    try {
      localStorage.clear();
      const token: string = keycloak?.token ?? ''
      const loginResponse: any = await loginService.ssoLogin(token)

      if (loginResponse.status === STATUS.SUCCESS) {
        const { data } = loginResponse as ILoginResponse;
        localStorage.setItem("user_mail", data.email);
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("userName", data.userName);
        localStorage.setItem("accessType", 'Bearer');
        showToaster(EToasterVariants.success, "Login Successful!");
        const sidemenu = ["VIEW_OFFERS", "VIEW_SHOW_OFFERS", "VIEW_OFFERS_GROUP", "VIEW_PRICE_LIST", "VIEW_FINANCE_LIST", "VIEW_SETTINGS", "VIEW_HISTORY"]
        const isPermissionAllowed = _.intersection(data.allowedPermission, sidemenu).length > 0
        if (!isPermissionAllowed) {
          localStorage.clear();
          showToaster(EToasterVariants.error, "You dont have access permission");
          navigateTo("/login");
          return;
        } else {
          navigateTo("/offers");
        }

      } else {
        setSpinnerVisibility(false);
        showToaster(EToasterVariants.error, "Something went wrong, please check");
      }
    } catch (error) {
      showToaster(EToasterVariants.error, "Something went wrong, please check");

    }


  }

  React.useEffect(() => {

    if (keycloak && keycloak?.isAuthenticated) {
      if (keycloak?.accessPermission) {
        setSpinnerVisibility(true)
        ssoLogin()
      } else {
        showToaster(EToasterVariants.error, "You don't have a permission");
      }
    }

  }, [keycloak?.isAuthenticated])


  return (
    <div className="login-page-wrapper">
      <div className="login-page">
        <div className="left-content">
          <div className="left-content-align">
            <img className="poorvika-svg-logo" alt='poorvika-svg-logo' draggable="false" src={PoorvikaLogo} />
            <h3 className="seller-portal-name-wrapper">OFFERS MANAGEMENT PORTAL</h3>
            <img
              alt="login illustration"
              className="login-illustration"
              draggable="false"
              src={loginPageIllustration}
            />
          </div>
        </div>
        <div className="right-content">
          <div>
            <h3 className="sign-in-name-wrapper">Sign in</h3>
            <p className="seller-account-name">To access Offers Portal</p>
            <div>
              <LoginForm />

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LoginPage