import React from "react";
import "./styles.scss";
import { ProductCardTable } from "components/ProductCardTable";

type AddProductsProps = {
  offers: []
};

const ComboDisplay: React.FC<AddProductsProps> = ({ offers }) => {


  return (
    <div className="combooffer-page">
      <div className="combooffer-selection">
        <div className="product-header">
          <div className="product-card" >
            <div className="combooffer-products">
              <div className="freebiesorcomboproductCard">
                {offers?.length ? offers?.map((prod: any, index: number) => {
                  return (
                    <>
                      <div className="product-multiField-container-flex">
                        <div className="product-multiField-container">
                          <ProductCardTable combooff={true} products={prod} />
                        </div>
                      </div>
                      {(index < offers.length - 1) && < div className="hr-lin">
                        <hr className="hr-line" />
                        <div className="add-btn">
                          or
                        </div>

                      </div>}

                    </>
                  )
                }) : ""}
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ComboDisplay;
