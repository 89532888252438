import { MultiSelectValues } from "common/constant";
import DateAndTimePicker from "components/DateTimePicker";
import DropDown from "components/DropDown";
import InputField from "components/InputField";
import MultiSelect from "components/MultiSelect";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FinanceListService } from "services/financeList.service";
import { EToasterVariants } from "services/types/common.type";
import _ from 'lodash';

const EditFinance = () => {

    /* Form related hooks */
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        setError
    } = useForm();
    const param = useParams();
    const navigateTo = useNavigate();


    const financeService = new FinanceListService();
    const [endDateErr, setEndDateErr] = useState<boolean>(false);
    const { showToaster } = useContext(appContext);

    const onSubmit = async (financeValues: any): Promise<void> => {
        console.log(financeValues, 'financeValues');
        const { schemeName } = financeValues;
        if (schemeName) {
            const schemes: any = _.split(schemeName, '|');
            if (schemes.length !== 2 || (isNaN(schemes[0]) || isNaN(schemes[1]))) {
                setError('schemeName', {
                    type: "manual",
                    message: "Scheme name is not valid one"
                })
                return;
            }

            try {
                const updateResponse = await financeService.updateFinanceOffer(param.id, financeValues)

                if (updateResponse.status === STATUS.SUCCESS) {
                    showToaster(EToasterVariants.success, updateResponse.message);
                    navigateTo("/offers/finance_list")
                } else {
                    showToaster(EToasterVariants.error, 'Something went wrong, please check');
                }
            } catch (error: any) {
                showToaster(EToasterVariants.error, error.message);
            }
        }


    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    /* Date Range Selection Validator & handler */
    const dateRangeSelectionHandler = async (
        dateFieldName: string,
        value: string
    ): Promise<void> => {
        if (dateFieldName === "startDate") {
            setValue("startDate", value);
        } else if (dateFieldName === "endDate") {
            setValue("endDate", value);
        }
        if (watch("startDate") && watch("endDate")) {
            if (new Date(watch("startDate")).getTime() >= new Date(watch("endDate")).getTime()) {
                setEndDateErr(true)
            }
            else {
                setEndDateErr(false)
            }
        }
    };

    const getOffers = async (id: string) => {
        const offerResponse = await financeService.getOffer(param.id);
        const { data, status } = offerResponse;

        if (status === STATUS.SUCCESS && data) {
            const {
                itemCode = '',
                financeName = '',
                schemeName = '',
                processingFees = '',
                interest = '',
                channel = [],
                startDate = '',
                endDate = '',
                isActive = false,
                publish = {}

            } = data;

            setValue('itemCode', itemCode)
            setValue('financeName', financeName)
            setValue('schemeName', schemeName)
            setValue('processingFees', processingFees)
            setValue('interest', interest)
            setValue('startDate', startDate)
            setValue('endDate', endDate)
            setValue('channel', channel)
            setValue('isActive', isActive)
            setValue('publish', publish)

        }
    }

    const handleKeyPress = (event: any) => {
        // Allow only numeric characters and a few special keys (e.g., backspace, delete)
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];

        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (param.id) {
            getOffers(param.id)
        }

    }, [param])


    return (
        <div className="offers-info-form" id="offerinfoForm">
            <div className="header">
                <div className="offers-page-heading">
                    <h3 className="offers-page-heading-label">Finance Offer</h3>

                </div>

            </div>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { handleKeyDown(e) }} autoComplete="off">
                <div className="input-fields-wrapper">
                    <InputField
                        disabled={true}
                        helpertext="Item Code"
                        hint="(ItemCode not Editable)"
                        register={register("itemCode")}
                        name="itemCode"
                    />

                    <InputField
                        disabled={true}
                        helpertext="Finance Name"
                        hint="(Finance not Editable)"
                        register={register("financeName")}
                        error={errors.financeName?.message as any}

                    />

                    <InputField
                        helpertext="Scheme Name"
                        hint="(Example : 12 | 2 )"
                        register={register("schemeName", { required: "Scheme Name is Required" })}
                        error={errors.schemeName?.message as any}
                    />

                    <InputField
                        helpertext="Interest Rate"
                        onKeyDown={handleKeyPress}
                        register={register("interest", { required: "Interest is Required" })}
                        error={errors.interest?.message as any}
                    />

                    <InputField
                        helpertext="Processing Fees"
                        onKeyDown={handleKeyPress}
                        register={register("processingFees", { required: "Processing Fees is Required" })}
                        error={errors.processingFees?.message as any}

                    />

                    <MultiSelect
                        fieldName="channel"
                        fields={MultiSelectValues.channel}
                        selectedValues={watch("channel")}
                        register={register("channel", { required: "Channel is Required" })}
                        error={errors.channel?.message as any}
                        onChange={(values) => setValue('channel', values)}
                    />

                    <DropDown
                        label="isActive"
                        options={['true', 'false']}
                        register={register("isActive", { required: "isActive Fees is Required" })}
                        error={errors.processingFees?.message as any}
                    />

                    <DateAndTimePicker
                        label="Start Date"
                        name="startDate"
                        value={watch("startDate")}
                        onDateSelection={(date) => {
                            dateRangeSelectionHandler("startDate", date)
                        }
                        }
                    />
                    <div className="endDatePicker">
                        <DateAndTimePicker
                            label="End Date"
                            name="endDate"
                            value={watch("endDate")}
                            onDateSelection={(date) => {
                                dateRangeSelectionHandler("endDate", date)
                            }
                            }
                            minDateTime={watch("startDate")}
                        />
                        {endDateErr && <p className="hint-text error-text">* End date should be greather than start date </p>}

                    </div>



                </div>
                <div className="buttons-wrapper">
                    <button
                        className="btn secondary"
                        type="button"
                        onClick={() => navigateTo("/offers/finance_list")}
                    >
                        Cancel
                    </button>
                    <button className="btn primary" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    )
}

export default EditFinance;