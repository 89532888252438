import React, { useState } from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import ShowProducts from "./ShowProducts";
import moment from "moment";

interface ProductOfferProp {
  offer_details: any;
  index: number;
  total_count: number;
  product: any;
}
export default function ProductOffer(props: ProductOfferProp) {
  const { offer_details: offer, index, product } = props;
  const [showTc, setShowTc] = useState<boolean>(false)
  const [showOffer, setShowOffer] = useState<boolean>(false)
  const [showStores, setShowStores] = useState<boolean>(false)
  const [showBan, setShowBan] = useState<boolean>(false)

  return (
    <div className="offers-section">
      <div className="offer-list offer-header ">
        <div className="offer-header-division">
          <span>{index === 0 ? "Best Offer" : "Max Offer " + index}</span>
          <p className="dateText">{`(Ends on ${moment(offer.endDate).format("DD-MMM-YYYY hh:mm A")})`}</p>
        </div> {
          (offer.offerType !== 'Offer Text' && offer.offerType !== 'Standard EMI') && <span>{`Max benefit Rs.${offer?.maxBenefites} `}</span>

        }
      </div>

      <div className="offer-content">
        <div className="offer-list">
          <div className="offer-name"><span>{offer?.offerName}</span>
            <div className="show-section">
              <span className="offer-tc" onClick={() => { setShowTc(!showTc); setShowBan(false); }}>{`Terms & Conditions`}</span>
              <span className="offer-tc" onClick={() => { setShowBan(!showBan); setShowTc(false) }}>{`Show Details`}</span>
            </div>


          </div>
          <div className="offer-amt">
            {
              (offer.offerType !== 'Offer Text' && offer.offerType !== 'Standard EMI') && <span>₹ {offer?.maxBenefites || 0}</span>
            }
          </div>

        </div>
        {showTc &&
          <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }}>
          </div>
        }
        {showBan &&
          <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }}>
          </div>
        }



        {(!_.isEmpty(offer.comboOffers) || !_.isEmpty(offer.freebies)) && <div className="newdiv">
          <div className="offerList">


            <div className="offer-expansion">
              <span className="offer-type">{offer.offerType + ' Products'}</span >
              <span onClick={() => setShowOffer(!showOffer)}>  <FontAwesomeIcon className="show-icon" icon={showOffer ? faMinus : faPlus} /></span>
            </div>

            {showOffer &&
              <div className="offer-expanded">
                {!_.isEmpty(offer.comboOffers) && <ShowProducts offers={offer.comboOffers[product?.erp_item_code]} />}

                {!_.isEmpty(offer.freebies) && <ShowProducts offers={offer.freebies[product?.erp_item_code]} />}
              </div>
            }

          </div>
        </div>}
        <div className='offer-stores'>
          <p>Applicable on:</p>
          {offer?.channel?.find((ch: any) => (ch.name === 'Online')) && <p>{`Online${offer?.state?.length > 0 ? ', ' : ''}`} </p>}

          {
            offer?.state?.length > 0 ? (<React.Fragment>
              {offer?.state?.map((st: any) => <p key={st?.id}>{st?.name}{offer?.state.at(-1).name === st.name ? "/" : ","}</p>)}
              <p>{offer.storeId[0]?.name}</p>
              {offer.storeId.length > 1 && <p className="stores-expand" onClick={() => setShowStores(!showStores)}>,more</p>}
            </React.Fragment>) : (<p>Online</p>
            )

          }

        </div>
        {showStores && (
          <div className='offer-expanded'>
            {offer?.storeId.map((store: any) => <p key={store?.index}>{store?.name}</p>)}
          </div>
        )}
      </div>


      {/* {total_count !== index + 1 && <span className="offer-or">OR</span>} */}

    </div>
  );
}
