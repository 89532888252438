import { faCaretDown, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TablePagination } from "@mui/material";
import Icon from "components/Icon";
import InputField from "components/InputField";
import MultiSelect from "components/MultiSelect";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { OffersService } from "services/offers.service";
import { SettingsService } from "services/settings.service";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss";

import { OFFER_SELECTION_OPTION, offerType, YES_NO_OPTION } from "common/constant";
import DropDown from "components/DropDown";
import moment from "moment";

const OffersTypeTable: React.FC = () => {

  /* State variables */
  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
  const [valueAttributes, setValueAttributes] = useState([]);
  const [rulesAttributes, setRulesAttributes] = useState([]);
  const [offerTypes, setOfferTypes] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number>(0);
  const [dialog, setDialog] = useState<boolean>(false)
  const [offertypeValues, setOffertypeValues] = useState([]);
  const [offersRules, setOfferrules] = useState([])


  const [offerValues, setOfferValues] = useState<string>("")
  /* Services */
  const settingsService = new SettingsService();
  const offersService = new OffersService();

  /* Context */
  const context = useContext(appContext);
  const { setSpinnerVisibility } = useContext(appContext);

  /* Form hooks */
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm();


  const sliderVisbilityHandler = async (isClosed: boolean) => {
    if (isClosed) {
      setDialog(false)
      setValueAttributes([])
      setRulesAttributes([])
      sliderVisibility && reset();
      setSliderVisibility(!sliderVisibility);
    }
    else {
      setDialog(false)
    }

  }

  const getAttributes = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const attributesResponse = await settingsService.getAttributes();
      setSpinnerVisibility(false);
      if (attributesResponse.status === STATUS.SUCCESS) {
        const values = attributesResponse.data.AttributesData?.filter(
          (data: any) => !data?.isRule && !data?.isOffergroup
        ).map((data: any, index: number) => {
          return { ...data, index };
        });

        const rules = attributesResponse.data.AttributesData?.filter(
          (data: any) => data?.isRule && !data?.isOffergroup
        ).map((data: any, index: number) => {
          return { ...data, index };
        });
        setValueAttributes(values)
        setOffertypeValues(values)
        setRulesAttributes(rules);
        setOfferrules(rules)
      } else {
        context.showToaster(
          EToasterVariants.error,
          "Unable to fetch Attributes"
        );
      }
    } catch (error) {
      context.showToaster(EToasterVariants.error, "Unable to fetch Attributes");
    }
  };

  const getOfferTypes = async (): Promise<void> => {
    try {
      const offerTypesResponse = await offersService.getOfferTypes(page + 1, rowsPerPage);
      if (offerTypesResponse.status === STATUS.SUCCESS) {
        setOfferTypes(offerTypesResponse.data.offerTypeData);
        setTotalPages(Math.ceil(offerTypesResponse.data.count / rowsPerPage))
      } else {
        context.showToaster(
          EToasterVariants.error,
          "Unable to get Offers Type"
        );
      }
    } catch (error) {
      context.showToaster(EToasterVariants.error, "Unable to get Offers Type");
    }
  };

  /* Offer Type Edit Handler */
  const offerTypeEditHanlder = async (data: any, attributeIndex: number): Promise<void> => {
    setValueAttributes(offertypeValues)
    setRulesAttributes(offersRules)
    setEditMode(true);
    setSelectedOfferIndex(attributeIndex);

    const { offerTypes, rules, values, offerValues, addBenefits, reduceEffective, bannerEnable, offerSelection
    } = data;
    setValue("offerTypes", offerTypes);
    setValue("rules", rules);
    setValue("values", values);
    /* Show Offer Settings */
    setValue("addBenefits", addBenefits);
    setValue("reduceEffective", reduceEffective);
    setValue("bannerEnable", bannerEnable);
    setValue("offerSelection", offerSelection);
    setOfferValues(offerValues)

    setSliderVisibility(true)

  }


  const offerTypeSubmissionHandler = async (values: any): Promise<void> => {
    try {
      context.setSpinnerVisibility(true);
      var offerTypeAction;
      values.offerValues = offerValues;
      if (editMode) {
        offerTypeAction = await offersService.updateOfferType({ ...values, _id: offerTypes[selectedOfferIndex]._id })
      } else {
        offerTypeAction = await offersService.createOfferType(values);
      }

      if (offerTypeAction.status === STATUS.SUCCESS) {
        setValueAttributes([])
        setRulesAttributes([])
        reset();
        await sliderVisbilityHandler(true);
        await getOfferTypes();
        context.setSpinnerVisibility(false);
      } else {
        context.setSpinnerVisibility(false);
        context.showToaster(
          EToasterVariants.error,
          `unable to ${editMode ? "Update" : "Add"} offer Type`
        );
      }



    } catch (error) {
      context.setSpinnerVisibility(false);
      context.showToaster(
        EToasterVariants.error,
        `unable to ${editMode ? "Update" : "Add"} offer Type`
      );
    }
  };



  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setExpandedRow(-1)
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setExpandedRow(-1)
  };

  useEffect(() => {
    getAttributes();
    getOfferTypes();
  }, [page, rowsPerPage]);


  const createOfferTypeHandler = () => {
    reset();
    setOfferValues(offerType[0].value);
    setValue("rules", []);
    setValue("values", []);
    setValueAttributes(offertypeValues)
    setRulesAttributes(offersRules)
    setSliderVisibility(true);
    setEditMode(false);
  }


  const [isExpandedRow, setExpandedRow] = useState<number>(-1);

  const offerTypeDetailsExpansionHandler = (rowIndex: number) => {

    setExpandedRow(isExpandedRow === rowIndex ? -1 : rowIndex);

  }


  return (
    <div className="offers-type-table">
      <div className="header">
        <h3> Offer Types</h3>
        <button
          className="btn primary"
          onClick={createOfferTypeHandler}
        >
          Create Offer Type
        </button>
      </div>
      <table className="shadow offerType-table">
        <thead>
          <tr>
            <th>Offer Type</th>
            <th>Created By</th>
            <th>Created At</th>
            <th>Action</th>
            <th><Icon icon={faCaretDown} /></th>

          </tr>
        </thead>
        <tbody>
          {offerTypes.map((offer: any, index: number) => {

            return (<>
              <tr>
                <td data-label="Offer Type">{offer.offerTypes}</td>
                <td data-label="Created By">{offer.createdBy}</td>
                <td data-label="Created At">{moment(offer.createdDate).format("DD-MMM-YYYY hh:mm A")}</td>

                <td data-label="Action" className="action">
                  <div onClick={() => offerTypeEditHanlder(offer, index)}>
                    <div className="action-edit-btn"><Icon icon={faPen} /></div>
                  </div>

                </td >
                <td onClick={() => offerTypeDetailsExpansionHandler(index)}>
                  <Icon icon={faCaretDown} /></td>

              </tr>

              <tr className={index === isExpandedRow ? "table-hidden" : "none"} key={index}>
                <td colSpan={6}>

                  <div className="tableExpandDetails">
                    <div className="tableExpand-type">
                      <h4>Offer type</h4>
                      <p>{offer.offerValues}</p>
                    </div>
                    <div className="tableExpand-Rules">
                      <h4>Rules</h4>
                      {offer.rules.map((rule: any, index: number) => {
                        return (

                          <p> {index + 1}.{rule.name}</p>
                        );
                      })
                      }
                    </div>
                    <div className="tableExpand-values">
                      <h4>Values</h4>
                      {offer.values.map((value: any, index: number) => {
                        return (
                          <p>
                            {index + 1}.{value.name}
                          </p>
                        );
                      })
                      }
                    </div>
                  </div>
                </td>
              </tr>
            </>

            );
          })}
        </tbody>
      </table>
      <TablePagination
        backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
        nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
        component="div"
        count={totalPages}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div className={`slider-right ${sliderVisibility ? "show" : "offertypehide"}`} >
        <div className="add-product-slider">
          <div className="slider-header">
            <h1>Create Offer Type</h1>
            <div onClick={() => {
              setValueAttributes([])
              setRulesAttributes([])
              reset()
              sliderVisbilityHandler(true)
            }}>
              <Icon icon={faXmark} />
            </div>
          </div>

          <div className="form-wrapper">

            <form onSubmit={handleSubmit((data) => offerTypeSubmissionHandler(data))}>

              <InputField
                helpertext="Name"
                name="offerTypes"
                register={register("offerTypes", { required: "Name is Required" })}
                error={errors.offerTypes?.message as any}
              />
              <DropDown
                options={offerType}
                onChange={(event) =>
                  setOfferValues(event.target.value)
                }
                label="Offer Template"
                name="offerType"
                value={offerValues}
              />

              <DropDown
                options={YES_NO_OPTION}
                label="Add to Benefits"
                register={register("addBenefits")}
              />
              <DropDown
                options={YES_NO_OPTION}
                label="Reduce on Effective"
                register={register("reduceEffective")}
              />
              <DropDown
                options={YES_NO_OPTION}
                label="Banner Enable"
                register={register("bannerEnable")}
              />
              <DropDown
                options={OFFER_SELECTION_OPTION}
                label="Offer Selection"
                register={register("offerSelection")}
              />
              <MultiSelect
                fieldName="Value Attributes"
                fields={valueAttributes}
                selectedValues={watch("values")}
                register={register("values")}
                onChange={(values: any) => setValue("values", values)}
                error={errors.values?.message as any}
              />
              <MultiSelect
                fieldName="Rules Attributes"
                fields={rulesAttributes}
                selectedValues={watch("rules")}
                register={register("rules")}
                onChange={(rules: any) => setValue("rules", rules)}
                error={errors.rules?.message as any}
              />
              <div className="action-buttons">
                <button
                  className="btn secondary"
                  type="button"
                  onClick={() => {
                    setValueAttributes([])
                    setRulesAttributes([])
                    reset()
                    sliderVisbilityHandler(true)
                  }}
                >
                  Cancel
                </button>
                <button className="btn primary" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffersTypeTable;