import React, { useEffect, useContext, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Input from "components/UseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appContext from "context/app.context";
import {
  faCircleInfo,
  faFilter,
  faPlus,
  faTable,
  faTrash,
  faStickyNote,
  faArrowDown,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import {
  offersListColumns,
  OfferStatusAdmin,
  OfferStatusStaff,
  OfferStatusFinance,
  OfferStatusLogistics,
  roles,
} from "common/constant";
import "./style.scss";
import { OffersService } from "services/offers.service";
import TablePagination from "@mui/material/TablePagination";
import { STATUS } from "config/config";
import { EToasterVariants } from "services/types/common.type";
import Icon from "components/Icon";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import { RootStore } from "redux/store";
import { useSelector } from "react-redux";
import DropDown from "components/DropDown";
import RejectedConfirmationDialog from "containers/Dialogs/RejectedConfirmationDialog";
import { RejectedNotesDialog } from "containers/Dialogs/RejectedNotesDialog";
import { SortingTrigger } from "./sortingComponent";
import { usePermission } from "components/Permission";
import OfferListFilter from "./OfferListFilter";
import _ from "lodash";
import CloneConfirmationDialog from "containers/Dialogs/CloneConfirmationDialog";
const OffersTable: React.FC = () => {
  let navigate = useNavigate();
  /* State Variables */
  const [offers, setOffers] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [offerId, setOfferId] = useState<string>("");
  const [cloneOffer, setCloneOffer] = useState<any>({});
  const [deleteConfirmationDialog, setDeleteConfirmationDialogVisibility] =
    useState<boolean>(false);
  const [cloneConfirmationDialog, setCloneConfirmationDialogVisibility] =
    useState<boolean>(false);
  const [rejectDialog, setRejectDialogVisibility] = useState<boolean>(false);
  const [rejectNotesDialog, setRejectNotesDialogVisibility] =
    useState<boolean>(false);
  const [rejectedNotes, setRejectedNotes] = useState<Array<any>>([]);
  const userRole: any = useSelector<RootStore>(
    (state) => state.userDetails.userDetails.userRole
  );
  const userName: any = useSelector<RootStore>(
    (state) => state.userDetails.userDetails.userName
  );
  const offerBasedOnRole =
    userRole === roles.SuperAdmin
      ? OfferStatusAdmin
      : userRole === roles.Logistics
      ? OfferStatusLogistics
      : userRole === roles.FinanceManager
      ? OfferStatusFinance
      : OfferStatusStaff;

  const {
    setSelectedProducts,
    setOfferData,
    setMenuIndex,
    showToaster,
    setSpinnerVisibility,
    offerListFilter,
    setHistoryNavigate,
  } = useContext(appContext);
  useState<boolean>(false);

  const [columns, setColumns] = useState<string[]>([]);
  const [columnfilterVisibility, setColumnFilterVisibility] =
    useState<boolean>(false);
  const [filterVisibility, setFilterVisibility] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [offerStatus, setOfferStatus] = useState("");

  /* Pagination Actions */
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [offerColumnName, setOfferColumnName] = useState<string>("");
  const [offerPageSortOrder, setOfferPageSortOrder] = useState<number>(1);

  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);
  const [deleteOffersPermission] = usePermission(["DELETE_OFFERS"]);
  const [offerStatusPermission] = usePermission(["EDIT_OFFER_STATUS"]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    getOffers(newPage, offerColumnName, offerPageSortOrder);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  /* Services */
  const offersService = new OffersService();

  const getOffers = async (
    pageNo = 1,
    offerColumnName?: string,
    offerPageSortOrder?
  ): Promise<void> => {
    setSpinnerVisibility(true);
    let {
      offerType = "",
      status = "",
      startDate = "",
      endDate = "",
      brand = "",
      category = "",
    } = offerListFilter;
    if (!_.isEmpty(brand)) brand = brand.value;
    if (!_.isEmpty(category)) category = category.value;
    if (status === "All Status") status = "";
    if (offerType === "All Types") offerType = "";
    if (brand === "All Brands") brand = "";
    if (category === "All Categories") category = "";

    const filter = {
      search,
      offerType,
      status,
      brand,
      category,
    };

    const offerListResponse = await offersService.getOffersList(
      rowsPerPage,
      pageNo,
      filter,
      startDate,
      endDate,
      offerColumnName,
      offerPageSortOrder
    );

    setSpinnerVisibility(false);
    if (offerListResponse.status === STATUS.SUCCESS) {
      addSponsorName(offerListResponse);
      setTotalPages(offerListResponse.data?.totalPages);
    } else {
      showToaster(EToasterVariants.error, "Unable to get Offer List");
    }
  };

  const showDeleteConfirmationHandler = (offerId: string) => {
    setOfferId(offerId);
    setDeleteConfirmationDialogVisibility(true);
  };
  const showCloneConfirmationHandler = (offerId: string, offerName: string) => {
    setCloneOffer({ offerId, offerName });
    setCloneConfirmationDialogVisibility(true);
  };

  const showRejectDialogHandler = (notes: string[]) => {
    setRejectedNotes(notes);
    setRejectNotesDialogVisibility(true);
  };

  useEffect(() => {
    getOffers(page, offerColumnName, offerPageSortOrder);
    setFilterVisibility(false);
  }, [
    rowsPerPage,
    search,
    offerListFilter,
    offerColumnName,
    offerPageSortOrder,
  ]);

  /* Dynamical column selection handler functionality */
  const dynamicColumnSelectionHandler = async (
    columnName: string
  ): Promise<void> => {
    const existingIndex = columns.findIndex((column) => column === columnName);
    let newColumns;

    /* If column already checked we need to uncheck on click action else we need to check the column */
    if (existingIndex >= 0) {
      const existingData = [...columns];
      existingData.splice(existingIndex, 1);
      newColumns = [...new Set(existingData)];
    } else {
      newColumns = [...columns, columnName];
    }
    setColumns(newColumns);
  };

  /* Don't Optimize - Purposefully set this state since the checkbox not checked on initial rendering */
  useEffect(() => {
    setColumns([
      "offersName",
      "offersType",
      "sponsor",
      "status",
      "startDate",
      "endDate",
      "modifiedDate",
      "version",
      "action",
    ]);
    setOfferData({});
    setMenuIndex(0);
  }, []);

  const handleStatus = async (id: string, status: string): Promise<void> => {
    setOfferStatus(status);
    if (status.includes("Rejected")) {
      setOfferId(id);
      setRejectDialogVisibility(true);
      return;
    }
    try {
      const statusChangeResponse = await offersService.statusChange(id, status);

      if (statusChangeResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Status Updated");
        await getOffers();
      } else {
        showToaster(
          EToasterVariants.error,
          statusChangeResponse.error.response.data.message
        );
      }
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to update Status");
    }
  };

  const rejectConfirmationHandler = async (confirmation: {
    status: boolean;
    note?: any;
    rejectedOn?: any;
  }): Promise<void> => {
    if (confirmation.status) {
      try {
        const statusChangeResponse = await offersService.statusChange(
          offerId,
          offerStatus,
          {
            rejectedBy: userName,
            note: confirmation.note,
            rejectedOn: confirmation.rejectedOn,
          }
        );
        if (statusChangeResponse.status === STATUS.SUCCESS) {
          showToaster(EToasterVariants.success, "Status Updated");
          await getOffers();
          setRejectDialogVisibility(false);
        } else {
          showToaster(EToasterVariants.error, "Unable to update Status");
        }
      } catch (error) {
        showToaster(EToasterVariants.error, "Unable to update Status");
      }
    } else {
      setRejectDialogVisibility(false);
    }
  };

  const newOfferHandler = () => {
    setMenuIndex(0);
    setSelectedProducts([]);
    setOfferData({});
    navigate("/offers/add");
  };

  const deleteConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    if (confirmation) {
      try {
        const deleteOfferResponse = await offersService.deleteOffer(offerId);
        if (deleteOfferResponse.status === STATUS.SUCCESS) {
          setDeleteConfirmationDialogVisibility(false);
          await getOffers();
        } else {
          showToaster(EToasterVariants.error, "Unable to delete the Offer");
        }
      } catch (error) {
        showToaster(EToasterVariants.error, "Unable to delete the Offer");
      }
    } else {
      setDeleteConfirmationDialogVisibility(false);
    }
  };
  const cloneConfirmationHandler = async (
    confirmation: boolean
  ): Promise<void> => {
    setCloneConfirmationDialogVisibility(false);
  };

  const defaultLabelDisplayedRows = (props: any) => {
    return `${props.page} of ${
      props.count !== -1 ? props.count : `more than ${props.to}`
    }`;
  };

  const JSONToCSVConvertor = async (JSONData: any, ReportTitle: string) => {
    setSpinnerVisibility(true);
    let {
      offerType = "",
      status = "",
      brand = "",
      category = "",
    } = offerListFilter;
    if (!_.isEmpty(brand)) brand = brand.value;
    if (!_.isEmpty(category)) category = category.value;
    if (status === "All Status") status = "";
    if (offerType === "All Types") offerType = "";
    if (brand === "All Brands") brand = "";
    if (category === "All Categories") category = "";

    const filter = {
      search,
      offerType,
      status,
      brand,
      category,
    };

    const offerListResponse = await offersService.getOffersList(
      1000,
      0,
      filter,
      "",
      "",
      "status",
      "",
      "isAll"
    );

    if (offerListResponse.status === STATUS.SUCCESS) {
      JSONData = offerListResponse.data.offerList;
    }

    const arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
    let CSV = "";
    let heading = [
      "offerId",
      "offerName",
      "offerType",
      "startDate",
      "endDate",
      "status",
      "modifiedDate",
    ];
    let row = "";

    for (const index in heading) {
      row += heading[index] + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";

    for (let i = 0; i < arrData.length; i++) {
      let row = "";

      for (const index in heading) {
        if (arrData[i][heading[index]]) {
          if (
            heading[index] === "sponsor" ||
            "comboOffers" ||
            "freebies" ||
            "region" ||
            "rules" ||
            "values" ||
            "rules" ||
            "state" ||
            "storeId"
          ) {
            if (
              [heading[index]][0] === "startDate" ||
              [heading[index]][0] === "endDate" ||
              [heading[index]][0] === "modifiedDate"
            ) {
              row +=
                '"' +
                JSON.stringify(
                  moment(arrData[i][heading[index]]).format(
                    "DD-MMM-YYYY hh:mm A"
                  )
                ).replace(/"/g, "") +
                '",';
            } else {
              row +=
                '"' +
                JSON.stringify(arrData[i][heading[index]]).replace(/"/g, "") +
                '",';
            }
          } else {
            row +=
              '"' + arrData[i][heading[index]].replace(/["]/g, '""') + '",';
          }
        } else {
          row += ",";
        }
      }
      row.slice(0, row.length - 1);
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      showToaster(EToasterVariants.error, "Invalid data");
      return;
    }

    let fileName = "Offers_";
    fileName += ReportTitle.replace(/ /g, "_");
    const uri = "data:text/csv;charset=utf-8," + escape(CSV);
    const link = document.createElement("a");
    link.href = uri;
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSpinnerVisibility(false);
  };

  /* Sponsor name add to the offers data*/

  const addSponsorName = (offerListResponse: any) => {
    setOffers(
      offerListResponse.data?.offerList?.map((e: any) => {
        let sponsorName = "";
        e.sponsor.forEach((element: any, index: number) => {
          sponsorName = `${sponsorName} ${element.name}`;
        });
        e.sponsorName = sponsorName;
        return e;
      })
    );
  };

  /* Searching offers with text */
  const searchHandler = async (search: any) => {
    if (search.length > 3) {
      setSearch(search);
    }
    if (search.length == 0) {
      setSearch("");
    }
  };

  return (
    <div>
      <div className="offers-list-action-wrapper">
        <div className="offers-header">
          <div className="action-button-wrapper">
            <div className="offer-btn-wrapper">
              {createOffersPermission && (
                <Button color={"#fb6c00"} onClick={newOfferHandler}>
                  <FontAwesomeIcon icon={faPlus} className="plus_icon" />
                  New Offer
                </Button>
              )}
              <div
                className="download-offer-btn"
                onClick={() => JSONToCSVConvertor(offers, "data")}
              >
                <FontAwesomeIcon className="" icon={faArrowDown} />
                <p>Download Report</p>
              </div>
            </div>
            {/* <div className="impt_offers dflex items-center">
              <FontAwesomeIcon icon={faArrowDown} />

              <p>Import Offers</p>
            </div> */}
          </div>

          <div className="icon_wpr dflex items-center justify-between w-82">
            <div>
              <Input
                className="inputwrp"
                placeholder="Search Offer Name"
                onChange={searchHandler}
              />
            </div>
            <div>
              <FontAwesomeIcon
                className="cursor-pointer sort-filter-icon"
                icon={faFilter}
                onClick={() => setFilterVisibility(!filterVisibility)}
              />
            </div>
            <div
              className={`dynamic-coloumn-wrapper ${
                columnfilterVisibility ? "request-loader" : ""
              }`}
            >
              <FontAwesomeIcon
                className={`cursor-pointer sort-filter-icon  ${
                  columnfilterVisibility ? "active" : ""
                }`}
                icon={faTable}
                onClick={() =>
                  setColumnFilterVisibility(!columnfilterVisibility)
                }
              />
              <div
                className={
                  columnfilterVisibility
                    ? "column-filter shadow"
                    : "hide-element"
                }
              >
                {offersListColumns?.map((columnName: string, index: number) => {
                  return (
                    <div
                      key={index}
                      className="column-option"
                      onClick={() => dynamicColumnSelectionHandler(columnName)}
                    >
                      {columns.includes(columnName) ? (
                        <input type="checkbox" checked={true} />
                      ) : (
                        <input type="checkbox" />
                      )}
                      <p>{columnName}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="offers-table-table">
          <table className="content-table offers-table" id="offertable">
            <thead>
              <tr>
                <th data-label="offersName">
                  <div className="tableHeader-Elements">
                    OfferID{" "}
                    <SortingTrigger
                      offerColumnName="offerId"
                      offers={offers}
                      setOfferColumnName={setOfferColumnName}
                      setOfferPageSortOrder={setOfferPageSortOrder}
                      sortedOffers={(sortedOffers: any) => {
                        setOffers(sortedOffers);
                      }}
                      currentPage={page}
                      getOffers={getOffers}
                    />
                  </div>
                </th>
                {columns.includes("offersName") && (
                  <th>
                    <div className="tableHeader-Elements">
                      Offer Name{" "}
                      <SortingTrigger
                        offerColumnName="offerName"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}
                {columns.includes("offersType") && (
                  <th>
                    <div className="tableHeader-Elements">
                      Offer Type{" "}
                      <SortingTrigger
                        offerColumnName="offerType"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}
                {columns.includes("sponsor") && (
                  <th>
                    <div className="tableHeader-Elements">Sponsor</div>
                  </th>
                )}
                {columns.includes("startDate") && (
                  <th>
                    <div className="tableHeader-Elements">
                      {" "}
                      Start Date{" "}
                      <SortingTrigger
                        offerColumnName="startDate"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}
                {columns.includes("endDate") && (
                  <th>
                    <div className="tableHeader-Elements">
                      {" "}
                      End Date{" "}
                      <SortingTrigger
                        offerColumnName="endDate"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}
                {columns.includes("modifiedDate") && (
                  <th>
                    <div className="tableHeader-Elements">
                      {" "}
                      Modified Date{" "}
                      <SortingTrigger
                        offerColumnName="modifiedDate"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}

                {columns.includes("version") && (
                  <th>
                    <div className="tableHeader-Elements">
                      {" "}
                      Version{" "}
                      <SortingTrigger
                        offerColumnName="version"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}

                {columns.includes("status") && (
                  <th>
                    <div className="tableHeader-Elements">
                      Status{" "}
                      <SortingTrigger
                        offerColumnName="status"
                        offers={offers}
                        setOfferColumnName={setOfferColumnName}
                        setOfferPageSortOrder={setOfferPageSortOrder}
                        sortedOffers={(sortedOffers: any) => {
                          setOffers(sortedOffers);
                        }}
                        currentPage={page}
                        getOffers={getOffers}
                      />
                    </div>
                  </th>
                )}
                {columns.includes("action") && <th>Action</th>}
              </tr>
            </thead>

            <tbody>
              {offers.map((item: any, index: any) => (
                <tr
                  key={index}
                  className="cursor-pointer"
                  onClick={() => navigate(`/offers/edit/${item._id}`)}
                >
                  <td data-label="offerId">{item.offerId}</td>
                  {columns.includes("offersName") && (
                    <td
                      data-label="offerName"
                      data-content={item.offerName}
                      className="tooltip"
                    >
                      {item.offerName}
                    </td>
                  )}
                  {columns.includes("offersType") && (
                    <td data-label="offersType">{item.offerType}</td>
                  )}
                  {columns.includes("sponsor") && (
                    <td data-label="sponsor">{item.sponsorName}</td>
                  )}
                  {columns.includes("startDate") && (
                    <td data-label="startDate">
                      {moment(item.startDate).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                  )}
                  {columns.includes("endDate") && (
                    <td data-label="endDate">
                      {moment(item?.endDate).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                  )}
                  {columns.includes("modifiedDate") && (
                    <td data-label="Modified Date">
                      {moment(item?.modifiedDate).format("DD-MMM-YYYY hh:mm A")}
                    </td>
                  )}
                  {columns.includes("version") && (
                    <td data-label="version">
                      {item?.publish ? (
                        <div>
                          <div style={{ color: "green", fontWeight: "bold" }}>
                            {`
                          Active Version : ${item?.publish?.version}`}
                          </div>
                          <div
                            className="tooltip"
                            data-content={moment(
                              item?.publish?.publishedAt
                            ).format("DD-MMM-YYYY hh:mm A")}
                            style={{ color: "green", fontWeight: "bold" }}
                          >
                            {`
                          Published At : ${moment(
                            item?.publish?.publishedAt
                          ).format("DD-MMM-YYYY hh:mm A")}
                        `}
                          </div>
                          <div style={{ color: "#ff8000", fontWeight: "bold" }}>
                            {`Current Version : ${item?.version}`}
                          </div>
                        </div>
                      ) : (
                        <div style={{ color: "green", fontWeight: "bold" }}>
                          {"New offer"}
                        </div>
                      )}
                    </td>
                  )}
                  {columns.includes("status") && (
                    <td data-label="status">
                      {offerStatusPermission ? (
                        <div
                          onClick={(e: any) => e.stopPropagation()}
                          className="inner-dropdown"
                        >
                          {offerBasedOnRole.includes(item.status) ? (
                            <DropDown
                              value={item.status}
                              options={offerBasedOnRole}
                              label="Status"
                              name="status"
                              onChange={(e: any) =>
                                handleStatus(item._id, e.target.value)
                              }
                            />
                          ) : (
                            <div
                              className={`status ${item.status
                                .toLowerCase()
                                .replaceAll(" ", "_")}`}
                            >
                              {item.status}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className={`status ${item.status
                            .toLowerCase()
                            .replaceAll(" ", "_")}`}
                        >
                          {item.status}
                        </div>
                      )}
                    </td>
                  )}
                  {columns.includes("action") && (
                    <td data-label="Action" className="action-icons">
                      <div
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setHistoryNavigate(true);
                          // showRejectDialogHandler(item?.rejectedNotes || []);
                          navigate(`/offers/edit/${item._id}`);
                          // setMenuIndex(6)
                        }}
                      >
                        <Icon icon={faCircleInfo} />
                      </div>
                      <div
                        onClick={(e: any) => {
                          e.stopPropagation();
                          showCloneConfirmationHandler(
                            item._id,
                            item.offerName
                          );
                        }}
                      >
                        <Icon icon={faClone} />
                      </div>
                      {item.status === "Draft" && deleteOffersPermission && (
                        <div
                          onClick={(e: any) => {
                            e.stopPropagation();
                            showDeleteConfirmationHandler(item._id);
                          }}
                        >
                          <Icon icon={faTrash} />
                        </div>
                      )}
                      {item.status.includes("Rejected") && (
                        <div
                          onClick={(e: any) => {
                            e.stopPropagation();
                            showRejectDialogHandler(item?.rejectedNotes || []);
                          }}
                        >
                          <Icon icon={faStickyNote} />
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {filterVisibility && (
          <OfferListFilter
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
          />
        )}

        <div
          className={`sidebar-overlay ${
            filterVisibility === true ? "active" : ""
          }`}
          onClick={() => setFilterVisibility(!filterVisibility)}
        ></div>

        <div className="pagination-wrapper">
          <TablePagination
            backIconButtonProps={
              page === 1 ? { disabled: true } : { disabled: false }
            }
            nextIconButtonProps={
              page === totalPages || totalPages === 0
                ? { disabled: true }
                : { disabled: false }
            }
            component="div"
            count={totalPages}
            labelDisplayedRows={defaultLabelDisplayedRows}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <DeleteConfirmationDialog
        show={deleteConfirmationDialog}
        onConfirmation={deleteConfirmationHandler}
      />
      <CloneConfirmationDialog
        show={cloneConfirmationDialog}
        onConfirmation={cloneConfirmationHandler}
        offer={cloneOffer}
        getOffers={getOffers}
      />
      <RejectedConfirmationDialog
        show={rejectDialog}
        onConfirmation={rejectConfirmationHandler}
      />
      {rejectNotesDialog && (
        <RejectedNotesDialog
          show={rejectNotesDialog}
          onExit={setRejectNotesDialogVisibility}
          notes={rejectedNotes}
        />
      )}
    </div>
  );
};

export default OffersTable;
