import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { OfferStatusAdmin } from "common/constant";
import DateAndTimePicker from "components/DateTimePicker";
import Icon from "components/Icon";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { OffersService } from "services/offers.service";
import { EToasterVariants } from "services/types/common.type";
import { PIMProductService } from "services/pimProduct.service";
import { Autocomplete, TextField } from "@mui/material";

const OfferListFilter = (props: any) => {
    const { setFilterVisibility, filterVisibility } = props;
    const { handleSubmit, setValue, watch, reset, control } = useForm();
    const { setOfferListFilter = () => { }, offerListFilter = {}, showToaster } = useContext(appContext);
 
    const offersService = new OffersService();
    const pimService = new PIMProductService();

    const [offerTypes, setOfferTypes] = useState([]);
    const [brands, setBrands] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);


    // Star Filter Offers
    const filterSubmissionHandler = async (filterValues: any): Promise<any> => {
        setOfferListFilter(filterValues);
    };

    const dateRangeSelectionHandler = async (dateFieldName: string, value: string): Promise<void> => {
        if (dateFieldName === "startDate") {
            setValue("startDate", value);
        } else if (dateFieldName === "endDate") {
            setValue("endDate", value);
        }
    };

    const clearFilterHandeler = () => {
        reset();
        setOfferListFilter({});
        setFilterVisibility(!filterVisibility);
    }

    const getOfferTypes = async (): Promise<void> => {
        try {
            const offerTypesResponse = await offersService.getOfferTypes();
            if (offerTypesResponse.status === STATUS.SUCCESS) {

                const offersArr = offerTypesResponse.data.offerTypeData.map(
                    (offerType: any) => offerType.offerTypes
                );

                setOfferTypes(offersArr);
                window.scrollTo(0, 0);

            } else {
                showToaster(EToasterVariants.error, "Unable to get Offers Type");
            }
        } catch (error) {
            showToaster(EToasterVariants.error, "Unable to get Offers Type");
        }
    };

    const getBrandList = async (): Promise<void> => {
        try {
            const { data: { brand = [] } } = await pimService.getBrandList();
            const brandList: [] = brand.map((item: any) => ({ label: item.name, value: item.code }));
            setBrands([{ label: 'All brands', value: '' }, ...brandList]);

        } catch (error) {
            showToaster(EToasterVariants.error, "Unable to fetch Brand list");
        }
    };

    const getCategoryList = async (): Promise<void> => {
        try {
            const { data: { categorylist = [] } } = await pimService.getCategoryList();
            const filteredCategory: [] = categorylist.map((item: any) => ({ label: item.name, value: item.code }));
            setCategories([{ label: 'All Category', value: '' }, ...filteredCategory]);

        } catch (error) {
            showToaster(EToasterVariants.error, "Unable to fetch Brand list");
        }
    };


    useEffect(() => {
        getOfferTypes();
        getBrandList();
        getCategoryList();
        const { startDate = '', endDate = '' } = offerListFilter;
        setValue('startDate', startDate)
        setValue('endDate', endDate)

    }, [])

    const { status = '', brand = '', category = '', offerType = '' } = offerListFilter;

    return (
        <div
            className={`slider-right filter-right show`}
        >
            <div className="add-product-slider">

                <div className="slider-header">
                    <h1>Filter</h1>
                    <div className="action-icon" onClick={() => setFilterVisibility(!filterVisibility)}>
                        <Icon icon={faXmark} />
                    </div>
                </div>

                <div className="filterTop">
                    <form onSubmit={handleSubmit(filterSubmissionHandler)}>
                        <div className="form-wrapper">
                            <div className="formfilter">
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue={status}
                                    render={(props) => (
                                        <Autocomplete
                                            {...props.field}
                                            disablePortal
                                            options={["All Status", ...OfferStatusAdmin]}
                                            size="small"
                                            sx={{ width: 300 }}
                                            onChange={(event: any, newValue: any) => {
                                                props.field.onChange(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Status" />}
                                        />
                                    )}
                                />
                            </div>
                            <div className="formfilter">
                                <Controller
                                    name="offerType"
                                    control={control}
                                    defaultValue={offerType}
                                    render={(props) => (
                                        <Autocomplete
                                            {...props.field}
                                            disablePortal
                                            options={["All Types", ...offerTypes]}
                                            size="small"
                                            sx={{ width: 300 }}
                                            onChange={(event: any, newValue: any) => {
                                                props.field.onChange(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Offer Type" />}
                                        />
                                    )}
                                />
                            </div>


                            <div className="formfilter">
                                <Controller
                                    name="brand"
                                    control={control}
                                    defaultValue={brand}
                                    render={(props) => (
                                        <Autocomplete
                                            {...props.field}
                                            disablePortal
                                            options={brands}
                                            size="small"
                                            sx={{ width: 300 }}
                                            onChange={(event: any, newValue: any) => {
                                                props.field.onChange(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Brands" />}
                                        />
                                    )}
                                />
                            </div>
                            <div className="formfilter">
                                <Controller
                                    name="category"
                                    control={control}
                                    defaultValue={category}
                                    render={(props) => (
                                        <Autocomplete
                                            {...props.field}
                                            disablePortal
                                            options={categories}
                                            size="small"
                                            sx={{ width: 300 }}
                                            onChange={(event: any, newValue: any) => {
                                                props.field.onChange(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Categories" />}
                                        />
                                    )}
                                />
                            </div>
                            <div className="formfilter">
                                <DateAndTimePicker
                                    label="Start Date"
                                    name="startDate"
                                    value={watch("startDate")}
                                    onDateSelection={(date) =>
                                        dateRangeSelectionHandler("startDate", date)
                                    }
                                />
                            </div>
                            <div className="formfilter">
                                <DateAndTimePicker
                                    label="End Date"
                                    name="endDate"
                                    value={watch("endDate")}
                                    onDateSelection={(date) =>
                                        dateRangeSelectionHandler("endDate", date)
                                    }
                                />
                            </div>
                            <div className="filter-action">
                                <button
                                    className="btn secondary"
                                    type="reset"
                                    onClick={clearFilterHandeler}
                                >
                                    Clear
                                </button>
                                <button className="btn primary" type="submit">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default OfferListFilter;

