import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class StoreService extends BaseService {
  public async getStores(): Promise<IAPIReponse> {
    try {
      const params = {
        headers: {
          "X-API-KEY": API_ENDPOINTS.INVENTORY_KEY || "",
        }
      }
      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_ALL_STORES, params);
      return data;

    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
}
