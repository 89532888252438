import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class FileUploadService extends BaseService {

  public async uploadFile(fileData: FormData): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.FILE_UPLOAD, fileData);
      return data;
    } catch (error) {
      console.log(error);

      return parseAxiosError(error as AxiosError);
    }
  }
}