import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  ILoginForgot,
  ILoginError,
} from "../../services/interfaces/login.interface";
import appContext from "../../context/app.context";
import { LoginService } from "../../services/login.service";
import { STATUS } from "../../config/config";
import { EToasterVariants } from "../../services/types/common.type";
import "./LoginForm.scss";
import ForgotOtpValidate from "./ForgotOtpValidate";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";

const ForgotPassword: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setSpinnerVisibility, showToaster } = useContext(appContext);
  const loginService: LoginService = new LoginService();
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const onForgotSubmit = async (data: { email: string }): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const loginResponse: ILoginForgot | ILoginError =
        await loginService.forgotOtp(data);

      if (loginResponse.status === STATUS.SUCCESS) {
        setOtpSent(true);
        setEmail(data.email);
        showToaster(EToasterVariants.success, "OTP Sent Successfully!");
      } else {
        showToaster(EToasterVariants.error, "User Not Found");
      }
    } catch (error) {
      showToaster(EToasterVariants.error, "An error occurred");
    } finally {
      setSpinnerVisibility(false);
    }
  };

  const handleBackToLogin = () => {
    setShowLoginForm(true);
  };

  return (
    <div>
      <>
        {!otpSent && !showLoginForm ? (
          <>
            <h3 className="sign-in-name-wrapper forgot-password-lable">
              Forgot Password
            </h3>
            <div className="forgot-password-mail-lable">
              Please enter your registered email Id
            </div>

            <form
              className="loginForm-container"
              autoComplete="new-password"
              onSubmit={handleSubmit(onForgotSubmit)}
            >
              <div className="loginForm-container">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email field is empty",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      id="email"
                      className="login-input-field-box"
                      placeholder="Enter Email"
                    />
                  )}
                />
                {errors?.email && (
                  <p className="login-error">
                    * {errors.email?.message as any}
                  </p>
                )}
              </div>
              <button className="login-btn" type="submit">
                Send OTP
              </button>

              <div className="forgot-password-area signin">
                <Link
                  to="#"
                  className="forgot-password forgot-password-link"
                  onClick={handleBackToLogin}
                >
                  Back to Login
                </Link>
              </div>
            </form>
          </>
        ) : (
          <>
            {otpSent && <ForgotOtpValidate email={email} />}
            {showLoginForm && <LoginForm />}
          </>
        )}
      </>
    </div>
  );
};

export default ForgotPassword;
