import { useCallback, useContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import appContext from 'context/app.context';
import { EToasterVariants } from 'services/types/common.type';
import _ from 'lodash';
import Keycloak from 'keycloak-js';

/**
 * Returns the auth info and some auth strategies.
 */
export const useAuth = () => {
    const { keycloak, initialized } = useKeycloak<Keycloak>();

    const { showToaster } = useContext(appContext);

    const fetchUserInfo = async () => {
        try {
            const userProfile = await keycloak?.loadUserProfile();
            const fullName = `${userProfile?.firstName} ${userProfile?.lastName}`;

            setUser({ ...userProfile, fullName });
        } catch (err) {
            showToaster(EToasterVariants.error, "You don't have permission");
        }
    };

    const [user, setUser] = useState({});

    useEffect(() => {
        if (!initialized) {
            return;
        }

        if (keycloak?.authenticated) {
            fetchUserInfo();
        }
    }, [initialized, keycloak]);

    const login = useCallback(() => {
        keycloak?.login();
    }, [keycloak]);

    const logout = useCallback(() => {
        return new Promise<boolean>((resolve, reject) => {
            keycloak?.logout()
                .then(() => {
                    resolve(true); // Resolve the promise on successful logout
                })
                .catch((error) => {
                    reject(error); // Reject the promise on logout failure
                });
        });
    }, [keycloak]);

    const register = useCallback(() => {
        keycloak?.register();
    }, [keycloak]);

   

    return {
        isAuthenticated: keycloak?.authenticated,
        initialized,
        meta: {
            keycloak,
        },
        token: keycloak?.token,
        user,
        roles: keycloak?.realmAccess,
        accessPermission: _.has(keycloak?.resourceAccess, process.env.REACT_APP_SSO_CLIENT),
        login,
        logout,
        register,
    };
};
