import { getProductPrice, toLocaleNumber } from 'common/commonFunc';
import React, { useEffect, useState } from 'react'
import { PIMProductService } from 'services/pimProduct.service';

function ProductMrp(props) {
    const { item_code = '' } = props;
    const pimservice = new PIMProductService();
    const [mrp, setMrp] = useState(0);

    useEffect(() => {
        const getProductMrp = async () => {
            let mrpPrice: any = 0;
            try {
                const { data: { product = {} }, status = 0 } = await pimservice.getVariantPrice(item_code);

                if (+status === 200) {
                    const { mrp } = getProductPrice(product);
                    mrpPrice = toLocaleNumber(mrp)
                    setMrp(mrpPrice)
                }

            } catch (error) {
                console.log(error);
                mrpPrice = 'Price Not Available'
            }
        }

        getProductMrp()
    }, [item_code])

    return (
        <React.Fragment>{`${mrp}`}</React.Fragment>
    )
}

export default ProductMrp