/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import InputField from "components/InputField";
import DateAndTimePicker from "components/DateTimePicker";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import { useNavigate, useParams } from "react-router-dom";
import { STATUS } from "config/config";
import { useForm } from "react-hook-form";
import moment from "moment";
import { CouponsService } from "services/coupon.service";
import slugify from "common/slugify";
import DropDown from "components/DropDown";
import MultiSelect from "components/MultiSelect";
import {
  DISCOUNT_TYPE,
  APPLY_ONCE_OPTION,
  COUPON_APPLIES_TO,
  COUPON_TYPE,
  MultiSelectValues,
  sponsorType,
  DISCOUNT_APPLIED_AT,
  editorToolbar,
} from "common/constant";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import "./styles.scss";
import DeleteConfirmationDialog from "containers/Dialogs/DeleteConfirmationDialog";
import { StoreService } from "services/store.service";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { usePermission } from "components/Permission";
import { CouponEventService } from "services/couponEvents.services";
import { FormControl } from "@mui/material";
import ReactQuill from "react-quill";

const CouponsInfoForm = (props: any) => {
  const navigateTo = useNavigate();
  const params = useParams();

  /* Context functionality*/
  const {
    showToaster,
    setMenuIndex,
    menuIndex,
    setSpinnerVisibility,
    setOfferData,
    offerData,
    viewOnlyMode = true,
  } = useContext(appContext);

  const [textEditorVisibility, setTextEditorVisibility] =
    useState<boolean>(true);
  /* Form related hooks */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm();

  /* Permission checcking */
  const [couponInfoPermission] = usePermission(["COUPON_INFO"]);
  const [couponMarketPermission] = usePermission(["COUPON_MARKET"]);
  const [couponFinancePermission] = usePermission(["COUPON_FINANCE"]);
  const [couponValuePermission] = usePermission(["COUPON_VALUE"]);
  const [couponDocumentsPermission] = usePermission(["COUPON_DOCUMENTS"]);
  const [couponBannerPermission] = usePermission(["COUPON_BANNER_INFO"]);

  const couponsService = new CouponsService();
  const storeAPIService = new StoreService();
  const couponEventService = new CouponEventService();

  const editorConfig = { extraPlugins: "colorbutton,colordialog, font" };
  const [allCouponsInfo, setAllCouponsInfo] = useState([]);
  const [isCouponCodeExist, setIsCouponCodeExist] = useState(false);
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false);
  const [showroomData, setshowroomdata] = useState<any>([]);
  const [stateData, setStateData] = useState<{ name: string; id: number }[]>(
    []
  );
  const [regionData, setRegionData] = useState<any[]>([]);
  const [storesData, setStoreData] = useState<{ name: string; id: number }[]>(
    []
  );
  const [toggleError, setToggleError] = useState<any>({});
  const [CouponEventResponse, setCouponEventResponse] = useState<any>([]);

  const ckeditorDiscriptionRef = useRef<HTMLDivElement>(null);
  const ckeditorBannerDescriptionRef = useRef<HTMLDivElement>(null);
  const ckeditorTACRef = useRef<HTMLDivElement>(null);

  const [ckeditorDiscriptionVisiblity, setCkeditorDiscriptionVisiblity] =
    useState(false);
  const [
    ckeditorBannerDescriptionVisiblity,
    setCkeditorBannerDescriptionVisiblity,
  ] = useState(false);
  const [ckeditorTACVisiblity, setckeditorTACVisiblity] = useState(false);

  /* Date Range Selection Validator & handler */
  const dateRangeSelectionHandler = async (
    dateFieldName: string,
    value: string
  ): Promise<void> => {
    if (dateFieldName === "publishStartDate") {
      setValue("publishStartDate", value);
    } else if (dateFieldName === "publishEndDate") {
      setValue("publishEndDate", value);
    } else if (dateFieldName === "validityStartDate") {
      setValue("validityStartDate", value);
    } else if (dateFieldName === "validityEndDate") {
      setValue("validityEndDate", value);
    }
  };

  /* Form submission Handler */
  const onSubmit = async (offerInfovalues: any) => {
    const now = moment();
    const publishStartDate = moment(offerInfovalues.publishStartDate);
    const publishEndDate = moment(offerInfovalues.publishEndDate);
    const validityStartDate = moment(offerInfovalues.validityStartDate);
    const validityEndDate = moment(offerInfovalues.validityEndDate);
    offerInfovalues.maximumClaimAmount = parseInt(
      offerInfovalues.maximumClaimAmount
    );
    offerInfovalues.discountValue = parseInt(offerInfovalues.discountValue);
    offerInfovalues.minCartValue = parseInt(offerInfovalues.minCartValue);

    if (offerInfovalues.minCartValue < offerInfovalues.maximumClaimAmount) {
      showToaster(
        EToasterVariants.error,
        "Minimum Cart Value is lesser than Maximum Claim"
      );
      setError("minCartValue", {
        message: "Minimum Cart Value is lesser than Maximum Claim",
      });
      return;
    }

    if (
      offerInfovalues.discountType === DISCOUNT_TYPE.FLAT_AMOUNT &&
      offerInfovalues.minCartValue < offerInfovalues.discountValue
    ) {
      showToaster(
        EToasterVariants.error,
        "Minimum Cart Value is lesser than Discount Value"
      );
      setError("minCartValue", {
        message: "Minimum Cart Value is lesser than Discount Value",
      });
      return;
    }

    offerInfovalues.couponCode = offerInfovalues?.couponCode
      ?.replace(/[-!@#$%^&*()_+\_=\[\]{};':"\\|,.<>\/?]/g, "")
      ?.toUpperCase();
    if (offerInfovalues?.appliesTo === COUPON_APPLIES_TO.PRODUCT) {
      delete offerInfovalues.maximumClaimAmount;
      delete offerInfovalues.minCartValue;
    }
    if (offerInfovalues?.couponCodeType === COUPON_TYPE.COMMON_COUPON) {
      if (offerInfovalues?.totalCouponIssues)
        delete offerInfovalues.totalCouponIssues;
      if (offerInfovalues?.noOfIssuePerCustomer)
        delete offerInfovalues.noOfIssuePerCustomer;
    }
    if (offerInfovalues?.couponCodeType === COUPON_TYPE.UNIQUE_COUPON) {
      if (offerInfovalues?.noOfUsesPerCustomer)
        delete offerInfovalues.noOfUsesPerCustomer;
      if (offerInfovalues?.couponCode) delete offerInfovalues.couponCode;
    }

    if (now > publishStartDate && !offerData?._id) {
      // showToaster(EToasterVariants.error, "publish Start Date is invalid");
      // return;
      offerInfovalues.publishStartDate = moment();
    }
    if (publishEndDate < publishStartDate) {
      showToaster(
        EToasterVariants.error,
        "publish End Date should be greather than publish start Date"
      );
      return;
    }
    let total = 0;
    if (
      offerInfovalues["sponsor"]?.length === 0 ||
      offerInfovalues["sponsor"] === undefined
    ) {
      const temp = toggleError;
      temp.sponser = true;
      setToggleError(temp);
      return;
    }
    offerInfovalues["sponsor"]?.map((element: any) => {
      total =
        total + parseInt(offerInfovalues[`${element.name.toLowerCase()}Share`]);
      return;
    });
    if (total !== 100) {
      const temp = toggleError;
      temp.percentage = true;
      setToggleError(temp);
      return;
    }

    if (now > validityStartDate && !offerData?._id) {
      // showToaster(EToasterVariants.error, "validity Start Date is invalid");
      // return;
      offerInfovalues.validityStartDate = moment();
    }

    if (validityEndDate < validityStartDate) {
      showToaster(
        EToasterVariants.error,
        "validity End Date should be greather than validity Start Date"
      );
      return;
    }
    if (isCouponCodeExist) {
      showToaster(EToasterVariants.error, "CouponCode already Exist");
      setError("couponCode", { message: "CouponCode already Exist" });
      return;
    }
    if (!isCouponCodeExist) {
      try {
        setSpinnerVisibility(true);
        if (!offerData?._id) {
          const createOfferResponse = await couponsService.createNewCoupon({
            ...offerInfovalues,
            publish: offerData.publish,
          });
          if (createOfferResponse.status === STATUS.SUCCESS) {
            showToaster(
              EToasterVariants.success,
              "New offer created Successfully"
            );
            setOfferData(createOfferResponse.data.couponData);
            setSpinnerVisibility(false);
            setMenuIndex(menuIndex + 1);
          } else {
            setSpinnerVisibility(false);
            showToaster(
              EToasterVariants.error,
              createOfferResponse?.error?.response?.data?.message
            );
          }
        } else {
          const couponDetailsResponse = await couponsService.updateCoupon({
            _id: offerData?._id,
            ...offerInfovalues,
            publish: offerData.publish,
          });
          if (couponDetailsResponse.status === STATUS.SUCCESS) {
            showToaster(EToasterVariants.success, "Offer Updated Successfully");
            setSpinnerVisibility(false);
            const offerDataResult = {
              ...couponDetailsResponse.data.couponData,
              products: couponDetailsResponse.data.product,
            };

            setOfferData(offerDataResult);
            setMenuIndex(menuIndex + 1);
          } else {
            setSpinnerVisibility(false);
            showToaster(
              EToasterVariants.error,
              couponDetailsResponse?.error?.response?.data?.message
            );
          }
        }
      } catch (error) {
        setSpinnerVisibility(false);
        showToaster(EToasterVariants.error, "Unable to create new coupon");
      }
    }
  };

  const getCouponDetails = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const couponDetailsResponse = await couponsService.getCouponDetails(
        params?.id ? params?.id : offerData._id
      );
      if (couponDetailsResponse.status === STATUS.SUCCESS) {
        const offerDataResult = {
          ...couponDetailsResponse.data.couponData,
        };
        setOfferData(offerDataResult);
        setSpinnerVisibility(false);

        const {
          couponId,
          couponName,
          couponContext,
          couponCodeType,
          discountType,
          discountValue,
          appliesTo,
          minimumPurchaseQty,
          minimumPurchaseAmount,
          applyOncePerOrder,
          maxCouponUses,
          publishStartDate,
          publishEndDate,
          validityStartDate,
          validityEndDate,
          noOfUsesPerCustomer,
          noOfIssuePerCustomer,
          couponDescription,
          couponCode,
          termsConditions,
          couponBannerField,
          couponBannerDescription,
          maximumClaimAmount,
          totalCouponIssues,
          sponsor,
          sponsorID,
          exSchemeID,
          glCode,
          taxOption,
          otpOption,
          channel,
          state,
          region,
          storeId,
          minCartValue,
          notifyEmail,
          notifySms,
          notifyWhatsApp,
          couponEventName,
          notifyTime,
          notifyUnit,
          issueOnSameDay,
        } = couponDetailsResponse.data.couponData;

        setValue("notifyEmail", notifyEmail);
        setValue("notifySms", notifySms);
        setValue("notifyWhatsApp", notifyWhatsApp);
        setValue("issueOnSameDay", issueOnSameDay);
        setValue("notifyTime", notifyTime);
        setValue("notifyUnit", notifyUnit);
        setValue("otpOption", otpOption);
        setValue("couponId", couponId);
        setValue("couponName", couponName);
        setValue("couponContext", couponContext);
        setValue("couponCodeType", couponCodeType);
        setValue("discountType", discountType);
        setValue("discountValue", discountValue);
        setValue("appliesTo", appliesTo);
        setValue("minimumPurchaseQty", minimumPurchaseQty);
        setValue("minimumPurchaseAmount", minimumPurchaseAmount);
        setValue("applyOncePerOrder", applyOncePerOrder);
        setValue("maxCouponUses", maxCouponUses);
        setValue("publishStartDate", publishStartDate);
        setValue("publishEndDate", publishEndDate);
        setValue("validityStartDate", validityStartDate);
        setValue("validityEndDate", validityEndDate);
        setValue("noOfUsesPerCustomer", noOfUsesPerCustomer);
        setValue("noOfIssuePerCustomer", noOfIssuePerCustomer);
        setValue("couponDescription", couponDescription);
        setValue("couponCode", couponCode);
        setValue("termsConditions", termsConditions);
        setValue("couponBannerField", couponBannerField);
        setValue("couponBannerDescription", couponBannerDescription);
        setValue("maximumClaimAmount", maximumClaimAmount);
        setValue("totalCouponIssues", totalCouponIssues);
        setValue("minCartValue", minCartValue);
        /* Offer Finance */
        setValue("sponsor", sponsor);
        sponsor?.map((element: any) => {
          setValue(
            `${element.name.toLowerCase()}Share`,
            couponDetailsResponse.data.couponData[
              `${element.name.toLowerCase()}Share`
            ]
          );
          return;
        });
        setValue("sponsorID", sponsorID);
        setValue("exSchemeID", exSchemeID);
        setValue("channel", channel);
        setValue("state", state);
        setValue("region", region);
        setValue("storeId", storeId);
        setValue("glCode", glCode);
        setValue("taxOption", taxOption ?? DISCOUNT_APPLIED_AT?.AFTER_TAX);

        setValue("couponEventName", couponEventName);
        reinitializeTextEditor();
      } else {
        setSpinnerVisibility(false);
        navigateTo("/offers/coupons/list");
        showToaster(EToasterVariants.error, "Unable to get Coupons details");
      }
    } catch (error) {
      setSpinnerVisibility(false);
      navigateTo("/offers/coupons/list");
      showToaster(EToasterVariants.error, "Unable to get Coupons details");
    }
  };

  const initializer = async (): Promise<void> => {
    if (params?.id || offerData?._id) {
      await getCouponDetails();
      return;
    } else {
      const couponListResponse = await couponsService.getCoupons();
      setAllCouponsInfo(couponListResponse.data.couponList);
      const lastDay = new Date().setHours(23, 59, 59);
      setValue("couponId", String(Math.floor(Math.random() * 900000) + 100000));
      setValue("publishStartDate", new Date());
      setValue("publishEndDate", new Date(lastDay));
      setValue("validityStartDate", new Date());
      setValue("validityEndDate", new Date(lastDay));
      setValue("discountType", DISCOUNT_TYPE.FLAT_AMOUNT);
      setValue("taxOption", DISCOUNT_APPLIED_AT?.AFTER_TAX);
      setValue("otpOption", APPLY_ONCE_OPTION.NO);
      setValue("applyOncePerOrder", APPLY_ONCE_OPTION.YES);
      setValue("notifyTime", "1");
      setValue("notifyUnit", "Minutes");
    }
  };

  const reinitializeTextEditor = async (): Promise<void> => {
    setTextEditorVisibility(false);
    setTimeout(() => {
      setTextEditorVisibility(true);
    }, 250);
  };

  const checkCouponCode = async (value?: string) => {
    let isError = false;
    allCouponsInfo.find((data: any) => {
      if (data.couponCode === (value || watch("couponCode"))) {
        showToaster(EToasterVariants.error, "CouponCode already Exist");
        setError("couponCode", { message: "CouponCode already Exist" });
        isError = true;
        setIsCouponCodeExist(true);
        return;
      } else {
        !isError && clearErrors("couponCode");
        !isError && setIsCouponCodeExist(false);
      }
    });
  };

  const handleOnRemoveProduct = async (isDelete: boolean) => {
    try {
      if (isDelete) {
        const deleteProductFromCoupon =
          await couponsService.removeProductFromCoupon({
            couponId: offerData?._id,
          });
        if (deleteProductFromCoupon?.status === STATUS.SUCCESS) {
          setOpenWarningPopup(false);
          setValue("appliesTo", COUPON_APPLIES_TO.CART);
        } else {
          showToaster(EToasterVariants.error, deleteProductFromCoupon?.message);
        }
      } else {
        setOpenWarningPopup(false);
      }
    } catch (error: any) {
      showToaster(EToasterVariants.error, error?.message);
    }
  };

  const getCouponEventInfo = async (): Promise<void> => {
    let page = 0;
    let limit = 0;
    try {
      const couponEventListResponse =
        await couponEventService.getAllCouponEvents(page, limit);
      if (couponEventListResponse.status === STATUS.SUCCESS) {
        const events = couponEventListResponse.data.map((event: any) => {
          return { name: event.eventName, value: event.eventName };
        });
        setCouponEventResponse([
          {
            name: "Not Applicable",
            value: "",
          },
          ...events,
        ]);
      } else {
        showToaster(EToasterVariants.error, "Unable to get User List");
      }
    } catch (error) {
      showToaster(EToasterVariants.error, "unable to fetch Store Informations");
    }
  };
  const getStoreInformations = async (): Promise<void> => {
    try {
      const { data } = await storeAPIService.getStores();
      setshowroomdata(data);
      const allStates = [
        "All States",
        ...new Set(data?.map((showroom: any) => showroom.state)),
      ];
      const AllStateOption: any = allStates?.map((state, index) => {
        const stateData = {
          name: state,
          id: index,
        };
        return stateData;
      });
      setStateData(AllStateOption);
    } catch (error) {
      showToaster(EToasterVariants.error, "unable to fetch Store Informations");
    }
  };

  const channelDecisionHandler = (values: any) => {
    setValue("state", []);
    setValue("region", []);
    setValue("storeId", []);
    const finalIndex = values?.length - 1;
    const isAllSelectedRecently = values[finalIndex]?.name === "All";

    if (isAllSelectedRecently) {
      setValue("channel", [{ name: "All", index: 0 }]);
      setValue("state", [{ name: "All States", index: 0 }]);
      setValue("region", [{ name: "All Regions", index: 0 }]);
      setValue("storeId", [{ name: "All Stores", index: 0 }]);
      prepareUniqueRegions();
    } else {
      const excludeAllOption = values?.filter(
        (value: any) => value?.name !== "All"
      );
      setValue("channel", excludeAllOption);
    }
  };

  const prepareUniqueRegions = () => {
    const allRegion = showroomData
      ?.filter((data: any) => data.district)
      .map((data: any) => data.district);
    const uniqueRegions: any = ["All Regions", ...new Set(allRegion)].map(
      (data, index) => {
        const region = {
          name: data,
          id: index,
        };

        return region;
      }
    );

    setRegionData(uniqueRegions);
  };

  const stateSelectionHandler = (values: any) => {
    if (!values?.length) {
      setValue("state", []);
      setValue("region", []);
      setValue("storeId", []);
      return;
    }

    const finalIndex = values?.length - 1;
    const isAllSelectedRecently = values[finalIndex]?.name === "All States";
    if (isAllSelectedRecently) {
      setValue("state", [{ name: "All States", index: 0 }]);
      setValue("region", [{ name: "All Regions", index: 0 }]);

      prepareUniqueRegions();
    } else {
      try {
        const excludeAllOption = values?.filter(
          (value: any) => value?.name !== "All States"
        );
        setValue("state", excludeAllOption);

        const selectedStateValues = values?.map((value: any) => value.name);
        const stateRegions = showroomData
          ?.filter(
            (showRoom: any) =>
              selectedStateValues?.indexOf(showRoom?.state) !== -1 &&
              showRoom.state
          )
          .map((data: any) => data?.district);
        const uniqueStateRegions = [...new Set(stateRegions)]
          .filter((value) => value)
          .map((value: any, index: number) => {
            const region = {
              name: value,
              id: index,
            };
            return region;
          });
        setValue("region", uniqueStateRegions);
        setRegionData(uniqueStateRegions);
      } catch (error) {}
    }
    setValue("storeId", [{ name: "All Stores", index: 0 }]);
  };
  const regionSelectionHandler = (values: any) => {
    const finalIndex = values?.length - 1;
    const isAllSelectedRecently = values[finalIndex]?.name === "All Regions";
    if (isAllSelectedRecently) {
      setValue("region", [{ name: "All Regions", id: 0 }]);
      setValue("storeId", [{ name: "All Stores", id: 0 }]);
    } else {
      try {
        const excludeAllOption = values?.filter(
          (value: any) => value?.name !== "All Regions"
        );
        setValue("region", excludeAllOption);

        const selectedRegionValues = excludeAllOption?.map(
          (value: any) => value.name
        );
        const stores = showroomData?.filter(
          (showRoom: any) =>
            selectedRegionValues?.indexOf(showRoom?.district) !== -1 &&
            showRoom.district
        );
        const storesWithBranchId = stores?.map((store: any, index: number) => {
          const data = {
            name: `${store.branch}`,
            id: index + 1,
          };
          return data;
        });
        setValue("storeId", [{ name: "All Stores", id: 0 }]);
        setStoreData([{ name: "All Stores", id: 0 }, ...storesWithBranchId]);
      } catch (error) {}
    }
  };

  const storeSelectionHandler = (values: any) => {
    const finalIndex = values?.length - 1;
    const isAllSelectedRecently = values[finalIndex]?.name === "All Stores";
    if (isAllSelectedRecently) {
      setValue("storeId", [{ name: "All Stores", id: 0 }]);
    } else {
      const excludeAllOption = values?.filter(
        (value: any) => value?.name !== "All Stores"
      );
      setValue("storeId", excludeAllOption);
    }
  };

  const handleOnAppliesToChange = (appliesTo: string) => {
    if (appliesTo === COUPON_APPLIES_TO.CART && offerData?.products?.length) {
      setValue("appliesTo", COUPON_APPLIES_TO.PRODUCT);
      setOpenWarningPopup(true);
    } else {
      setValue("appliesTo", appliesTo);
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
  };
  const handleDiscountType = (e: any) => {
    e.preventDefault();
    setValue("discountValue", "");
  };

  function handleKeyPress(e: any) {
    const discountType = watch("discountType");
    if (discountType === DISCOUNT_TYPE.FLAT_AMOUNT) {
      if (e.target.value.length === 7) {
        e.preventDefault();
        e.stopPropagation();
      }
    } else if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
      e.target.setAttribute("min", 0);
      e.target.setAttribute("max", 100);
      e.target.setAttribute("step", 0.1);

      let value = e.target.value;
      const pattern = /^100$|^100.00$|^\d{0,2}(\.\d{1,2})? *%?$/;
      if (pattern.test(value)) {
        return true;
      } else {
        e.target.value = "";
      }
    }
  }
  const keyPressHandler = (evt: any) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
    if (evt.target.value.length === 7) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  useEffect(() => {
    initializer();
    getStoreInformations();
    getCouponEventInfo();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ckeditorDiscriptionRef.current &&
        !ckeditorDiscriptionRef?.current?.contains(event.target)
      ) {
        setCkeditorDiscriptionVisiblity(false);
      } else if (
        ckeditorBannerDescriptionRef.current &&
        !ckeditorBannerDescriptionRef?.current?.contains(event.target)
      ) {
        setCkeditorBannerDescriptionVisiblity(false);
      } else if (
        ckeditorTACRef.current &&
        !ckeditorTACRef?.current?.contains(event.target)
      ) {
        setckeditorTACVisiblity(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ckeditorDiscriptionRef, ckeditorBannerDescriptionRef, ckeditorTACRef]);

  useEffect(() => {
    const regions = regionData.map((data) => data?.name);
    try {
      const filteredStores = showroomData
        .filter((showRoom: any) => regions?.indexOf(showRoom?.district) !== -1)
        .map((data: any, index: number) => {
          return { name: `${data.branch}`, id: index + 1 };
        });
      setStoreData([{ name: "All Stores", index: 0 }, ...filteredStores]);
    } catch (error) {}
  }, [regionData]);

  const timeoptions = Array.from({ length: 60 }, (_, i) => (i + 1).toString());

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="coupons-info-form">
          <div className="coupon-sub-section">
            <p className="section-heading">
              {" "}
              Coupons Info
              {viewOnlyMode && couponInfoPermission && (
                <span className="tooltipContainer ml-1">
                  <Icon icon={faCircleInfo} />
                  <div>{"You Don't Have a Permission to Edit"}</div>
                </span>
              )}
            </p>
            <div className="input-fields-wrapper">
              <InputField
                disabled={viewOnlyMode && couponInfoPermission}
                helpertext="Coupon Name"
                register={register("couponName", {
                  required: "Coupon Name is required",
                  pattern: {
                    value: /^[A-Za-z0-9 ]+$/,
                    message: "Special Character not Allowed",
                  },
                })}
                onChange={(e) => {
                  setValue("couponCode", slugify(e.target.value));
                  checkCouponCode();
                }}
                name="couponName"
                error={errors.couponName?.message as any}
                mandatory
                focusHandleText="Enter Coupon name ,This will be Internal Name to Poorvika."
                hoverHandleText={watch("couponName")}
              />

              <DropDown
                disabled={viewOnlyMode && couponInfoPermission}
                options={[
                  { value: "", name: "Select coupon type" },
                  {
                    value: COUPON_TYPE.COMMON_COUPON,
                    name: COUPON_TYPE.COMMON_COUPON,
                  },
                  {
                    value: COUPON_TYPE.UNIQUE_COUPON,
                    name: COUPON_TYPE.UNIQUE_COUPON,
                  },
                ]}
                value={watch("couponCodeType")}
                label="Coupon Type"
                name="couponCodeType"
                register={register("couponCodeType", {
                  required: "Coupon Code type is required",
                })}
                error={errors.couponCodeType?.message as any}
                focusHandleText={
                  <p>
                    CommonCoupon : One CouponCode defined in CouponCode, that
                    can be Redeemed by any Customers ( E.g GET100OFF ) <br />
                    UniqueCoupon : UniqueCoupon to be created , Redeem against
                    unique code
                  </p>
                }
              />

              <DropDown
                disabled={viewOnlyMode && couponValuePermission}
                value={watch("couponEventName")}
                options={CouponEventResponse}
                label="Select Coupon Event Type"
                name="couponEventName"
                register={register("couponEventName")}
              />

              {watch("couponCodeType") === COUPON_TYPE.COMMON_COUPON && (
                <InputField
                  disabled={viewOnlyMode && couponInfoPermission}
                  style={{ textTransform: "uppercase" }}
                  helpertext="Coupon Code"
                  register={register("couponCode", {
                    required: "Coupon Code is required",
                  })}
                  name="couponCode"
                  error={errors.couponCode?.message as any}
                  onChange={(e) => checkCouponCode(e.target.value)}
                  mandatory
                  focusHandleText="Enter a Couponcode that will be used for Redeem."
                  hoverHandleText={watch("couponCode")}
                />
              )}

              {false && (
                <InputField
                  disabled={viewOnlyMode && couponInfoPermission}
                  helpertext="Coupon Context"
                  register={register("couponContext")}
                  name="couponContext"
                />
              )}

              <DateAndTimePicker
                disabled={viewOnlyMode && couponInfoPermission}
                label="Publish Start Date"
                name="publishStartDate"
                value={watch("publishStartDate")}
                onDateSelection={(date) =>
                  dateRangeSelectionHandler("publishStartDate", date)
                }
              />
              <DateAndTimePicker
                disabled={viewOnlyMode && couponInfoPermission}
                label="Publish End Date"
                name="publishEndDate"
                value={watch("publishEndDate")}
                onDateSelection={(date) =>
                  dateRangeSelectionHandler("publishEndDate", date)
                }
              />

              <DateAndTimePicker
                disabled={viewOnlyMode && couponInfoPermission}
                label="Validity Start Date"
                name="validityStartDate"
                value={watch("validityStartDate")}
                onDateSelection={(date) =>
                  dateRangeSelectionHandler("validityStartDate", date)
                }
              />
              <DateAndTimePicker
                disabled={viewOnlyMode && couponInfoPermission}
                label="Validity End Date"
                name="validityEndDate"
                value={watch("validityEndDate")}
                onDateSelection={(date) =>
                  dateRangeSelectionHandler("validityEndDate", date)
                }
              />
            </div>
          </div>

          {watch("couponCodeType") === COUPON_TYPE.UNIQUE_COUPON && (
            <div className="coupon-sub-section">
              <p className="section-heading">
                {" "}
                Coupon Notification
                {viewOnlyMode === false && !couponValuePermission && (
                  <span className="tooltipContainer ml-1">
                    <Icon icon={faCircleInfo} />
                    <div>{"You Don't Have a Permission to Edit"}</div>
                  </span>
                )}
              </p>
              <div className="input-fields-wrapper">
                <DropDown
                  disabled={viewOnlyMode}
                  options={[
                    { name: "Yes", value: true },
                    { name: "No", value: false },
                  ]}
                  value={watch("notifySms")}
                  label="SMS"
                  onChange={(e: any) => setValue("notifySms", e.target.value)}
                  name="notifySms"
                  register={register("notifySms")}
                  error={errors.notifySms?.message as any}
                />
                <DropDown
                  disabled={viewOnlyMode}
                  options={[
                    { name: "Yes", value: true },
                    { name: "No", value: false },
                  ]}
                  value={watch("notifyEmail")}
                  label="Email"
                  onChange={(e: any) => setValue("notifyEmail", e.target.value)}
                  name="notifyEmail"
                  register={register("notifyEmail")}
                  error={errors.notifyEmail?.message as any}
                />
                <DropDown
                  disabled={viewOnlyMode}
                  options={[
                    { name: "Yes", value: true },
                    { name: "No", value: false },
                  ]}
                  value={watch("notifyWhatsApp")}
                  label="Whatsapp"
                  onChange={(e: any) =>
                    setValue("notifyWhatsApp", e.target.value)
                  }
                  name="notifyWhatsApp"
                  register={register("notifyWhatsApp")}
                  error={errors.notifyWhatsApp?.message as any}
                />
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ minWidth: "50px" }}>
                    <DropDown
                      disabled={viewOnlyMode}
                      options={timeoptions}
                      value={watch("notifyTime")}
                      label="Time"
                      onChange={(e: any) =>
                        setValue("notifyTime", e.target.value)
                      }
                      name="notifyTime"
                      register={register("notifyTime")}
                      error={errors.notifyTime?.message as any}
                    />
                  </div>
                  <div style={{ minWidth: "50px", marginLeft: "3px" }}>
                    <DropDown
                      disabled={viewOnlyMode}
                      options={["Seconds", "Minutes", "Hours"]}
                      value={watch("notifyUnit")}
                      label="Unit"
                      onChange={(e: any) =>
                        setValue("notifyUnit", e.target.value)
                      }
                      name="notifyUnit"
                      register={register("notifyUnit")}
                      error={errors.notifyUnit?.message as any}
                    />
                  </div>
                  <p className="hint-text green-text">
                    {`Notification will sent After ${watch(
                      "notifyTime"
                    )} ${watch("notifyUnit")}`}{" "}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="coupon-sub-section">
            <p className="section-heading">
              {" "}
              Coupon value
              {viewOnlyMode === false && !couponValuePermission && (
                <span className="tooltipContainer ml-1">
                  <Icon icon={faCircleInfo} />
                  <div>{"You Don't Have a Permission to Edit"}</div>
                </span>
              )}
            </p>
            <div className="input-fields-wrapper">
              <DropDown
                disabled={viewOnlyMode && couponValuePermission}
                options={[
                  { value: "", name: "Select applies to" },
                  {
                    value: COUPON_APPLIES_TO.CART,
                    name: COUPON_APPLIES_TO.CART,
                  },
                  {
                    value: COUPON_APPLIES_TO.PRODUCT,
                    name: COUPON_APPLIES_TO.PRODUCT,
                  },
                ]}
                value={watch("appliesTo")}
                label="Applies To"
                onChange={(e: any) => handleOnAppliesToChange(e.target.value)}
                name="appliesTo"
                register={register("appliesTo", {
                  required: "Applies To type is required",
                })}
                error={errors.appliesTo?.message as any}
                focusHandleText={`The Coupon Discount is applied to a Product or Cart`}
              />

              <DropDown
                disabled={viewOnlyMode && couponValuePermission}
                options={[DISCOUNT_TYPE.FLAT_AMOUNT, DISCOUNT_TYPE.PERCENTAGE]}
                label="Discount Type"
                name="discountType"
                register={register("discountType")}
                onChange={handleDiscountType}
              />

              <InputField
                disabled={viewOnlyMode && couponValuePermission}
                helpertext="Discount Value"
                name="discountValue"
                register={register("discountValue", {
                  required: "Discount Value is required",
                  min: {
                    value: 0.1,
                    message: "Maximum allowed limit cannot be 0",
                  },
                })}
                onWheel={(e: any) => e.target.blur()}
                error={errors.discountValue?.message as any}
                mandatory
                type="number"
                step={0.01}
                onKeyDown={(e) => handleKeyPress(e)}
                onPaste={(e) => handlePaste(e)}
              />
            </div>
          </div>

          <div className="coupon-sub-section">
            <p className="section-heading"> Coupon Criterias</p>
            <div className="input-fields-wrapper">
              <InputField
                disabled={viewOnlyMode}
                helpertext="Max Coupon Uses"
                register={register("maxCouponUses", {
                  required: "Max Coupon Uses is required",
                  min: {
                    value: 1,
                    message: "Max Coupon Uses Cannot be 0",
                  },
                })}
                name="maxCouponUses"
                error={errors.maxCouponUses?.message as any}
                type="number"
                step={0.01}
                mandatory
                focusHandleText="In Common Code Coupons , The Maximum number of Time the coupon Can be Redeemed."
                hoverHandleText={watch("maxCouponUses")}
                onKeyPress={keyPressHandler}
              />
              <DropDown
                disabled={viewOnlyMode}
                options={[
                  { value: "", name: "Select applyPerOrder" },
                  { value: APPLY_ONCE_OPTION.YES, name: APPLY_ONCE_OPTION.YES },
                  { value: APPLY_ONCE_OPTION.NO, name: APPLY_ONCE_OPTION.NO },
                ]}
                value={watch("applyOncePerOrder")}
                label="Apply once per order"
                name="applyOncePerOrder"
                register={register("applyOncePerOrder", {
                  required: "applyOncePerOrder type is required",
                })}
                error={errors.applyOncePerOrder?.message as any}
                focusHandleText={
                  <p>
                    If Yes , the Coupon applied only one Eligible product in a
                    Order. <br /> If No , It is applied to multiple eligible
                    product in a Order.
                  </p>
                }
              />
              {watch("couponCodeType") === COUPON_TYPE.UNIQUE_COUPON && (
                <InputField
                  disabled={viewOnlyMode}
                  helpertext="No Of Issue Per Customer"
                  register={register("noOfIssuePerCustomer", {
                    required: "noOfIssuePerCustomer is required",
                    min: {
                      value: 1,
                      message: "No Of Uses Per Customer Cannot be 0",
                    },
                    max: {
                      value: Number(watch("maxCouponUses")),
                      message: "Cannot be greater than Max Coupon Uses value",
                    },
                  })}
                  type="number"
                  step={0.01}
                  mandatory
                  error={errors.noOfIssuePerCustomer?.message as any}
                  name="noOfIssuePerCustomer"
                />
              )}

              {watch("couponCodeType") === COUPON_TYPE.COMMON_COUPON && (
                <InputField
                  disabled={viewOnlyMode}
                  helpertext="No Of Uses Per Customer"
                  mandatory
                  register={register("noOfUsesPerCustomer", {
                    required: "noOfUsesPerCustomer is required",
                    min: {
                      value: 1,
                      message: "No Of Uses Per Customer Cannot be 0",
                    },
                    max: {
                      value: Number(watch("maxCouponUses")),
                      message: "Cannot be greater than Max Coupon Uses value",
                    },
                  })}
                  type="number"
                  step={0.01}
                  error={errors.noOfUsesPerCustomer?.message as any}
                  name="noOfUsesPerCustomer"
                  focusHandleText="In Common Code Coupons , The Number of times a Same customer can Redeem the Coupon."
                  hoverHandleText={watch("noOfIssuePerCustomer")}
                />
              )}

              {watch("couponCodeType") === COUPON_TYPE.UNIQUE_COUPON && (
                <InputField
                  disabled={viewOnlyMode}
                  helpertext="Total coupon Issues"
                  register={register("totalCouponIssues", {
                    required: "totalCouponIssues is required",
                    min: {
                      value: 1,
                      message: "Total Coupon Issues Cannot be 0",
                    },
                  })}
                  name="totalCouponIssues"
                  error={errors.totalCouponIssues?.message as any}
                  type="number"
                  step={0.01}
                  mandatory
                />
              )}

              {watch("appliesTo") === COUPON_APPLIES_TO.CART && (
                <>
                  <InputField
                    disabled={viewOnlyMode}
                    helpertext="Max Claim amount"
                    register={register("maximumClaimAmount", {
                      required: "maximumClaimAmount is required",
                      min: {
                        value: 1,
                        message: "Maximum Claim Amount Cannot be 0",
                      },
                    })}
                    name="maximumClaimAmount"
                    error={errors.maximumClaimAmount?.message as any}
                    type="number"
                    step={0.01}
                    mandatory
                  />
                  <InputField
                    disabled={viewOnlyMode}
                    helpertext="Minimum Cart value"
                    register={register("minCartValue", {
                      required: "Minimum cart value is required",
                      min: {
                        value: 1,
                        message: "Minimum Cart Value Cannot be 0",
                      },
                    })}
                    name="minCartValue"
                    error={errors.minCartValue?.message as any}
                    type="number"
                    step={0.01}
                    mandatory
                  />
                </>
              )}
              <DropDown
                disabled={viewOnlyMode}
                options={[APPLY_ONCE_OPTION.YES, APPLY_ONCE_OPTION.NO]}
                label="OTP Option"
                register={register("otpOption")}
              />
              <DropDown
                disabled={viewOnlyMode}
                options={[
                  { name: "No", value: false },
                  { name: "Yes", value: true },
                ]}
                value={watch("issueOnSameDay")}
                label="Issue on Same Day"
                onChange={(e: any) =>
                  setValue("issueOnSameDay", e.target.value)
                }
                name="issueOnSameDay"
                register={register("issueOnSameDay")}
                error={errors.issueOnSameDay?.message as any}
              />
            </div>
          </div>
          <div className="coupon-sub-section">
            <p className="section-heading">
              {" "}
              Market
              {viewOnlyMode && couponMarketPermission && (
                <span className="tooltipContainer ml-1">
                  <Icon icon={faCircleInfo} />
                  <div>{"You Don't Have a Permission to Edit"}</div>
                </span>
              )}
            </p>
            <div className="input-fields-wrapper">
              <MultiSelect
                disabled={viewOnlyMode && !couponMarketPermission}
                fieldName="Channel"
                fields={MultiSelectValues.channel}
                selectedValues={watch("channel")}
                register={register("channel", {
                  required: "Channel is required",
                })}
                error={errors.channel?.message as any}
                onChange={channelDecisionHandler}
                mandatory={true}
                focusHandleText={
                  <p>
                    Online : Only applicable to Poorvika Website and App ,<br />{" "}
                    Store : Only applicable to Poorvika Retail Stores ,<br />{" "}
                    All : Applicable both to Online and Stores
                  </p>
                }
              />
              {watch("channel")?.some(
                (code: any) => code.name !== "Online"
              ) && (
                <>
                  <MultiSelect
                    disabled={viewOnlyMode && couponMarketPermission}
                    fieldName="state"
                    fields={stateData}
                    selectedValues={watch("state")}
                    register={register("state", {
                      required: "State is Required",
                    })}
                    error={errors.state?.message as any}
                    onChange={stateSelectionHandler}
                  />
                  <MultiSelect
                    disabled={viewOnlyMode && couponMarketPermission}
                    fieldName="region"
                    fields={regionData}
                    selectedValues={watch("region")}
                    register={register("region", {
                      required: "Region is Required",
                    })}
                    error={errors.region?.message as any}
                    onChange={regionSelectionHandler}
                  />
                  <MultiSelect
                    disabled={viewOnlyMode && couponMarketPermission}
                    fieldName="Store ID"
                    selectedValues={watch("storeId")}
                    register={register("storeId", {
                      required: "Store Id is Required",
                    })}
                    fields={storesData}
                    error={errors.storeId?.message as any}
                    onChange={storeSelectionHandler}
                  />
                </>
              )}
            </div>
          </div>
          <div className="sub-section">
            <p className="section-heading">
              {" "}
              Coupon Finance
              {viewOnlyMode && !couponFinancePermission && (
                <span className="tooltipContainer ml-1">
                  <Icon icon={faCircleInfo} />
                  <div>{"You Don't Have a Permission to Edit"}</div>
                </span>
              )}
            </p>

            <div className="input-fields-wrapper">
              <MultiSelect
                disabled={viewOnlyMode && couponFinancePermission}
                fieldName="Sponsor"
                selectedValues={watch("sponsor")}
                fields={sponsorType}
                register={register("sponsor", {
                  required: "Sponsor is required",
                })}
                onChange={(values: any) => {
                  setValue("sponsor", values);
                  watch("sponsor").map((element: any, index: number) => {
                    watch("sponsor")?.length === 1 && index === 0
                      ? setValue(`${element.name.toLowerCase()}Share`, 100)
                      : setValue(`${element.name.toLowerCase()}Share`, "");
                  });
                }}
                error={errors.sponsor?.message as any}
                focusHandleText={
                  <p>
                    Multiple Options can be selected : <br /> ( If the Coupon is
                    shared by 2 parties like Poorvika + Bank , Select Both
                    Poorvika and Bank and define the percentage of Cost owned by
                    each parties )<br />
                    <br /> Brand : Coupon Provided by Brand and Brand will own
                    the cost of the Coupon ,<br /> Poorvika : Coupon Provided by
                    Poorvika and Poorvika will own the cost of the Coupon ,
                    <br /> Bank : Coupon Provided by Bank and Bank will own the
                    cost of the Coupon{" "}
                  </p>
                }
              />
              {watch("sponsor")?.length
                ? watch("sponsor").map((element: any, index: number) => {
                    return (
                      <InputField
                        mandatory
                        disabled={viewOnlyMode && couponFinancePermission}
                        helpertext={`${element.name} share in %`}
                        name={`${element.name.toLowerCase()}Share`}
                        register={register(
                          `${element.name.toLowerCase()}Share`,
                          {
                            required: `${element?.name?.toLowerCase()} is required`,
                            min: {
                              value: 0.1,
                              message: "Percentage cannot be 0",
                            },
                            max: {
                              value: 100,
                              message: `Maximum allowed limit crossed`,
                            },
                          }
                        )}
                        type="number"
                        step={0.01}
                        error={
                          toggleError.percentage
                            ? "Total percentage Share Should be 100"
                            : (errors[`${element.name.toLowerCase()}Share`]
                                ?.message as any)
                        }
                        hoverHandleText={watch(
                          `${element.name.toLowerCase()}Share`
                        )}
                      />
                    );
                  })
                : ""}
              <InputField
                disabled={viewOnlyMode && couponFinancePermission}
                helpertext="Sponsor ID"
                name="sponsorID"
                register={register("sponsorID", {
                  required: "Sponsor ID is required",
                })}
                error={errors.sponsorID?.message as any}
                focusHandleText="APX ID for the Sponsor "
                hoverHandleText={watch("sponsorID")}
              />
              <InputField
                disabled={viewOnlyMode && couponFinancePermission}
                helpertext="Ex Scheme ID"
                name="exSchemeID"
                register={register("exSchemeID", {
                  required: "External Scheme ID is required",
                })}
                error={errors.exSchemeID?.message as any}
                focusHandleText="Enter  Scheme ID , mentioned in the Offer letter from Brand/Bank/Paymentgateway"
                hoverHandleText={watch("exSchemeID")}
              />
              <InputField
                disabled={viewOnlyMode && couponFinancePermission}
                helpertext="GL Code"
                name="glCode"
                register={register("glCode", {
                  required: "GL Code is required",
                })}
                error={errors.glCode?.message as any}
                focusHandleText="Enter the Ledger Name Create for this offer in APX"
                hoverHandleText={watch("glCode")}
              />

              <DropDown
                disabled={viewOnlyMode}
                options={[
                  DISCOUNT_APPLIED_AT.BEFORE_TAX,
                  DISCOUNT_APPLIED_AT.AFTER_TAX,
                ]}
                label="Tax option"
                name="taxOption"
                register={register("taxOption")}
              />
            </div>
          </div>
          <div className="coupon-sub-section">
            <p className="section-heading">
              {" "}
              Documents
              {viewOnlyMode === false && !couponDocumentsPermission && (
                <span className="tooltipContainer ml-1">
                  <Icon icon={faCircleInfo} />
                  <div>{"You Don't Have a Permission to Edit"}</div>
                </span>
              )}
            </p>
            <div className="editor">
              <div className="editor-field-wrapper">
                <div className="headingAndInformation">
                  <p className="coupon-banner-heading">Terms & Conditions</p>
                  <div
                    className="informationField"
                    onClick={() => {
                      setckeditorTACVisiblity(!ckeditorTACVisiblity);
                    }}
                  >
                    ?
                  </div>
                  {ckeditorTACVisiblity && (
                    <>
                      <div
                        className="ckeditorInformation"
                        ref={ckeditorTACRef}
                      >{`Enter Offer Terms and conditions from Scheme definition, This will be displayed to Customers and Store Agents.`}</div>
                      <div className="ckeditorFocusHandleCaret">
                        <Icon className="ckeditorfocusCaret" icon={faCaretUp} />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {textEditorVisibility && (
                <ReactQuill
                  readOnly={
                    viewOnlyMode !== undefined
                      ? couponDocumentsPermission
                        ? viewOnlyMode
                        : true
                      : false
                  }
                  theme="snow"
                  value={watch("termsConditions")}
                  onChange={(value: any) => setValue("termsConditions", value)}
                  modules={{
                    toolbar: editorToolbar,
                  }}
                />
              )}
            </div>
            <div className="editor1">
              <div className="headingAndInformation">
                <p style={{ margin: "20px 0px" }}> Coupon Description </p>
                <div
                  className="informationField"
                  onClick={() => {
                    setCkeditorDiscriptionVisiblity(
                      !ckeditorDiscriptionVisiblity
                    );
                  }}
                >
                  ?
                </div>
                {ckeditorDiscriptionVisiblity && (
                  <>
                    <div
                      className="ckeditorInformation"
                      ref={ckeditorDiscriptionRef}
                    >
                      {
                        "(Description of Offer Communication from Brand / Bank / Poorvika. Add the Content of the offer so this can be view by Other departments like Finance , Logistics)"
                      }
                    </div>
                    <div className="ckeditorFocusHandleCaret">
                      <Icon className="ckeditorfocusCaret" icon={faCaretUp} />
                    </div>
                  </>
                )}
              </div>
              {textEditorVisibility && (
                <ReactQuill
                  readOnly={
                    viewOnlyMode !== undefined
                      ? couponFinancePermission
                        ? viewOnlyMode
                        : true
                      : false
                  }
                  theme="snow"
                  value={watch("couponDescription")}
                  onChange={(value: any) =>
                    setValue("couponDescription", value)
                  }
                  modules={{
                    toolbar: editorToolbar,
                  }}
                />
              )}
            </div>
            <div className="sub-section">
              <div
                className="headingAndInformation"
                style={{ marginBottom: "10px" }}
              >
                <p className="coupon-banner-heading">
                  Banner Info
                  {viewOnlyMode && couponDocumentsPermission && (
                    <span className="tooltipContainer ml-1">
                      <Icon icon={faCircleInfo} />
                      <div>{"You Don't Have a Permission to Edit"}</div>
                    </span>
                  )}
                </p>
                {viewOnlyMode && couponDocumentsPermission && (
                  <div
                    className="informationField"
                    onClick={() => {
                      setCkeditorBannerDescriptionVisiblity(
                        !ckeditorBannerDescriptionVisiblity
                      );
                    }}
                  >
                    ?
                  </div>
                )}
                {ckeditorBannerDescriptionVisiblity && (
                  <>
                    <div
                      className="ckeditorInformation"
                      ref={ckeditorBannerDescriptionRef}
                    >
                      <p>
                        This will be the Name of Coupon Displayed in Website /
                        App and CRM
                      </p>
                    </div>
                    <div className="ckeditorFocusHandleCaret">
                      <Icon className="ckeditorfocusCaret" icon={faCaretUp} />
                    </div>
                  </>
                )}
              </div>
              <div className="coupon-input-fields-wrapper">
                <MultiSelect
                  disabled={viewOnlyMode && couponBannerPermission}
                  fieldName="Coupon Banner Fields"
                  fields={MultiSelectValues.couponBannerField}
                  selectedValues={watch("couponBannerField")}
                  register={register("couponBannerField", {
                    required: "Coupon Banner Field is Required",
                  })}
                  error={errors.couponBannerField?.message as any}
                  onChange={(values: any) =>
                    setValue("couponBannerField", values)
                  }
                  mandatory={true}
                  focusHandleText={
                    viewOnlyMode &&
                    couponDocumentsPermission &&
                    `Select the Buttons that needs to be seen in the Coupon Display in Website / App and Store.`
                  }
                />
              </div>
              <div className="editor1">
                <div className="editor-field-wrapper banner-description">
                  <p className="coupon-banner-heading">Banner Description</p>
                </div>
                {textEditorVisibility && (
                  <ReactQuill
                    readOnly={viewOnlyMode && couponBannerPermission}
                    theme="snow"
                    value={watch("couponBannerDescription")}
                    onChange={(value: any) =>
                      setValue("couponBannerDescription", value)
                    }
                    modules={{
                      toolbar: editorToolbar,
                    }}
                  />
                )}
              </div>
              <div className="offerBox h-auto">
                <div className="offerBox dottedborder">
                  <div
                    className="offer"
                    dangerouslySetInnerHTML={{
                      __html: watch("couponBannerDescription"),
                    }}
                  />
                  {/* <div>
                  </div> */}
                  <div className="BannerOfferFieldTypes">
                    {watch("couponBannerField")?.map((data: any) => (
                      <span>{data.name}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!viewOnlyMode && (
            <div className="coupon-buttons-wrapper">
              <button
                className="btn secondary"
                type="button"
                onClick={() => navigateTo("/offers/coupons/list")}
              >
                Cancel
              </button>
              <button
                className="btn primary"
                type="submit"
                disabled={viewOnlyMode}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </form>
      {openWarningPopup && (
        <DeleteConfirmationDialog
          show={openWarningPopup}
          heading="Warning !"
          primaryBtnName="Yes"
          secondaryBtnName="No"
          onConfirmation={(isDelete: boolean) =>
            handleOnRemoveProduct(isDelete)
          }
          description={`There ${
            offerData?.products?.length > 1 ? "are" : "is"
          } ${offerData?.products?.length} product${
            offerData?.products?.length > 1 ? "s" : ""
          } added to this coupon if its is changed to cart then the products will be removed`}
        />
      )}
    </>
  );
};

export default CouponsInfoForm;
