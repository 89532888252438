import React, { useContext, useEffect, useState } from "react";
import AppHeader from "components/AppHeader";

import "./styles.scss";
import appContext from "context/app.context";
import SideMenu from "components/SideMenu";
import { Navigate, Route, Routes } from "react-router-dom";
import OffersListPage from "pages/OffersPage";
import DashboardPage from "pages/DashboardPage";
import ComponentsPage from "pages/ComponentsPage";
import ListOfflineOrders from "pages/OffersPage/ListOfflineOrders/index";
import AddOffersPage from "pages/OffersPage/AddOffersPage";
import ShowOfferPage from "pages/ShowOfferPage";
import SettingsPage from "pages/SettingsPage";
import EditOffersPage from "pages/OffersPage/EditOffersPage";
import { OfferGroupingPage } from "pages/OfferGroupingPage";
import AddOfferGroupPage from "pages/OfferGroupingPage/AddOfferGroup";
import EditOffersGroupPage from "pages/OfferGroupingPage/EditOfferGroup";

import CouponsLandingPage from "pages/CouponsLandingPage";
import HistorySideMenu from "pages/HistoryPage";
import { RootStore } from "redux/store";
import { useSelector } from "react-redux";
import { usePermission } from "components/Permission";
import FinancePage from "pages/FinanceList";
import EditFinance from "pages/FinanceList/EditFinance";
import PublishPage from "pages/PublishList";

const LandingPage: React.FC = () => {
  const { sideMenuVisibility } = useContext(appContext);
  const userPermission: any = useSelector<RootStore>((state) => state.userDetails.userDetails.Permission);
  const userRole: any = useSelector<RootStore>((state) => state.userDetails.userDetails.userRole);
  const sidemenu = ["VIEW_OFFERS", "VIEW_SHOW_OFFERS", "VIEW_OFFERS_GROUP", "VIEW_COUPONS", "VIEW_PRICE_LIST", "VIEW_FINANCE_LIST", "VIEW_SETTINGS", "VIEW_HISTORY"]
  const isAdmin = userRole === "Super Admin"
  const [defaultPage, setDefaultPage] = useState("")

  const handleOnRoutes = () => {

    let currentPermisstion = "";
    sidemenu?.some((all) => {
      if (userPermission?.includes(all)) {
        currentPermisstion = all
        return true;
      }
      return false;
    })

    switch (currentPermisstion) {
      case sidemenu[0]: {
        setDefaultPage("/offers/list")
        break;
      }
      case sidemenu[1]: {
        setDefaultPage("/offers/show_offers")
        break;
      }
      case sidemenu[2]: {
        setDefaultPage("/offers/offer_grouping")
        break;
      }
      case sidemenu[3]: {
        setDefaultPage("/offers/coupons/list")
        break;
      }
      case sidemenu[4]: {
        setDefaultPage("/offers/publish")
        break;
      }
      case sidemenu[5]: {
        setDefaultPage("/offers/finance_list")
        break;
      }
      case sidemenu[6]: {
        setDefaultPage("/offers/settings")
        break;
      }
      case sidemenu[7]: {
        setDefaultPage("/offers/history")
        break;
      }
      default: {
        setDefaultPage("/login")
      }
    }
  }

  /* Check Route Permission */
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"])
  const [editOffersPermission] = usePermission(["EDIT_OFFERS"])
  const [viewOffersPermission] = usePermission(["VIEW_OFFERS"])
  const [viewShowoffersPermission] = usePermission(["VIEW_SHOW_OFFERS"])
  const [viewFinancelistPermission] = usePermission(["VIEW_FINANCE_LIST"])
  const [viewSettingsPermission] = usePermission(["VIEW_SETTINGS"])
  const [viewHistoryPermission] = usePermission(["VIEW_HISTORY"])
  const [viewCouponsPermission] = usePermission(["VIEW_COUPONS"])
  const [createAndEditOffersgroupPermission] = usePermission(["CREATE_AND_EDIT_OFFERSGROUP"])
  const [viewOffersgroupPermission] = usePermission(["VIEW_OFFERS_GROUP"])


  useEffect(() => {
    handleOnRoutes()
  }, [])

  return (
    <div className="landing-page">
      <div className={sideMenuVisibility ? "sidemenu" : "hide-side-menu"}>
        <SideMenu />
      </div>
      <div className={sideMenuVisibility ? "page-layout" : "full-page-layout"}>
        <AppHeader />

        <div className="page-content">
          <Routes>
            <Route
              path={"/*"}
              element={<Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/dashboard"}
              element={<DashboardPage />}
            />
            <Route
              path={"/list"}
              element={(viewOffersPermission || isAdmin) ? <OffersListPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/add"}
              element={(createOffersPermission || isAdmin) ? <AddOffersPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/edit/:id"}
              element={(viewOffersPermission || editOffersPermission || isAdmin) ? <EditOffersPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/components"}
              element={<ComponentsPage />}
            />
            <Route
              path={"/show_offers"}
              element={(viewShowoffersPermission || isAdmin) ? <ShowOfferPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/publish"}
              element={(viewShowoffersPermission || isAdmin) ? < PublishPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/offer_grouping"}
              element={(viewOffersgroupPermission || isAdmin) ? <OfferGroupingPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/offer_grouping/add"}
              element={(createAndEditOffersgroupPermission || isAdmin) ? <AddOfferGroupPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/offer_grouping/edit/:id"}
              element={(viewOffersgroupPermission || isAdmin) ? <EditOffersGroupPage /> : <Navigate replace to={defaultPage} />}
            />


            <Route
              path={"/finance_list"}
              element={(viewFinancelistPermission || isAdmin) ? <FinancePage /> : <Navigate replace to={defaultPage} />}
            />

            <Route
              path={"finance_list/edit/:id"}
              element={(viewOffersgroupPermission || isAdmin) ? <EditFinance /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/settings/"}
              element={(viewSettingsPermission || isAdmin) ? <SettingsPage /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/history"}
              element={(viewHistoryPermission || isAdmin) ? <HistorySideMenu name="History" /> : <Navigate replace to={defaultPage} />}
            />
            <Route
              path={"/coupons/*"}
              element={(viewCouponsPermission || isAdmin) ? <CouponsLandingPage /> : <Navigate replace to={defaultPage} />}

            />
            <Route
              path={"/listOfflineOrders/:id"}
              element={<ListOfflineOrders />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
