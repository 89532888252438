import { AxiosError } from "axios";
import { COMMON_API, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";

export class PIMProductService extends BaseService {
  public async getBrandList(): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.get(COMMON_API.BRANDS_LIST_API, { headers: { channel: "ONLINE", locale: "en-IN" } });
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getCategoryList(): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.get(COMMON_API.CATEGORY_LIST_API, { headers: { channel: "pim", locale: "en-IN" } });
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }
  public async getProductsByBrand(brand?: string[], page?: number): Promise<IAPIReponse> {
    try {
      const { data } = await this.httpClient.post(
        `${COMMON_API.PIM_PRODUCT_API_PRODUCT}`, { brand: brand }, { headers: { channel: "ONLINE", locale: "en-IN" } });
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getProductsByName(product: string, page: number): Promise<IAPIReponse> {
    try {
      const params = {
        headers: {
          channel: "ONLINE"
        },
      };
      if (product === undefined)
        product = ''
      const { data } = await this.httpClient.get(
        `${COMMON_API.PIM_PRODUCT_API}?page=${page}&product=${product}&isactive_flag=1`,
        params
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getVariantProduct(erp_item_code: string = '', channel: string = 'ONLINE'): Promise<IAPIReponse> {
    try {
      channel = channel.toUpperCase();
      
      const params = {
        headers: {
          channel: channel,
          locale: "en-IN",
        },
      };
      const { data } = await this.httpClient.get(
        `${COMMON_API.PIM_PRODUCT_API}/${erp_item_code}?isactive_flag=1`,
        params
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getVariantByCode(erp_item_code: string, page: number): Promise<IAPIReponse> {
    try {
      const params = {
        headers: {
          channel: "ONLINE"
        },
      };
      if (erp_item_code === undefined)
        erp_item_code = ''
      const { data } = await this.httpClient.get(
        `${COMMON_API.PIM_PRODUCT_API}?page=${page}&&erp_item_code=${erp_item_code}&isactive_flag=1`,
        params
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getProductByTypesense(search: string = '*'): Promise<any> {
    try {
      const params = {
        headers: {
          channel: "ONLINE",
          accept: "application/json",
          "x-typesense-api-key": COMMON_API.TYPESENSE_KEY || ''
        },
      };
 
      const { data } = await this.httpClient.post(
        `${COMMON_API.TYPESENSE_API}`, {
        "searches": [
          {
            "collection": "productsnew",
            "q": search,
            "query_by": "name,code,item_code,sku",
            // "query_by_weights": "4,3,2,1",
            // "sort_by": "starrating:desc",
            // "filter_by": "sellable:=1",
            "page": 1,
            "per_page": 25
          }
        ]
      },
        params
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }

  public async getVariantPrice(item_code: any, channel = 'ONLINE'): Promise<IAPIReponse> {
    try {

      const { data } = await this.httpClient.get(
        `${COMMON_API.PIM_PRICE_API}${item_code}?isactive_flag=1`,{
          headers: {
              channel,
              locale: 'en-IN'
          }
      }
      );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError);
    }
  }


}
