import DropDown from 'components/DropDown'
import InputField from 'components/InputField'
import React from 'react'

type dynamicFieldProps = {
  values: any,
  handleChange?: any,
  register?: any,
  name?: any,
  disabled?: boolean,
  error?: string
}

/*Written  to dynamically manipulate the fields for values and rules */
const DynamicField: React.FC<dynamicFieldProps> = ({ values, register, name, disabled, handleChange, error }) => {
  return (
    <div>
      {!values?.isLov && (<InputField type={values?.dataType ==="NUMBER" ? "number": "text"} defaultValue={values?.value} helpertext={values.name} name={name} disabled={disabled} onChange={handleChange} register={register} error={error} mandatory hoverHandleText={values.value}/>)}
      {(values?.isLov && !values?.isMultiselect) && (<DropDown options={values.lov} disabled={disabled} label={values.name} name={name} onChange={handleChange} register={register} placeholder={`Select ${values.name}`} error={error}/>)}
    </div>
  )

}

export default DynamicField