import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

type TProp = {
  offerColumnName: string,
  offers: any,
  sortedOffers: any,
  getOffers:any,
  currentPage:number,
  setOfferColumnName:any,
  setOfferPageSortOrder:any,
}

export const SortingTrigger: React.FC<TProp> = ({ offerColumnName,getOffers,currentPage,setOfferColumnName,setOfferPageSortOrder }) => {
  const [sortOrder, setSortOrder] = useState(true)
  
// const pageSortOrder = sortOrder ? setOfferPageSortOrder(1) : setOfferPageSortOrder(-1);
const onColumnClick = (ColumnName) =>{
  setOfferColumnName(ColumnName)
}
const changeSortOrder = !sortOrder ? 1 : -1

  return (
    <div onClick={() => {
      onColumnClick(offerColumnName)
      setSortOrder(!sortOrder)
      setOfferPageSortOrder(!sortOrder ? 1 : -1)
      getOffers(currentPage,offerColumnName,changeSortOrder)
    }}>
      <FontAwesomeIcon className="" icon={sortOrder ? faArrowDown : faArrowUp } />
    </div>
  )
}

