import { useContext, useRef } from "react";
import InputField from "components/InputField";
import DropDown from "components/DropDown";
import MultiSelect from "components/MultiSelect";
import Icon from "components/Icon";
import {
  faTrash,
  faCircleInfo,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "components/Checkbox";
import { RadioGroup } from "components/RadioGroup";
import { editorToolbar, sponsorType } from "common/constant";
import { usePermission } from "components/Permission";
import appContext from "context/app.context";
import _ from "lodash";
import ReactQuill from "react-quill";

const FinanceSection = (props: any) => {
  const ckeditorFinanceRef = useRef<HTMLDivElement>(null);
  const {
    watch,
    viewOnlyMode,
    register,
    errors,
    setValue,
    permissionError,
    textEditorVisibility,
    editorConfig,
    ckeditorFinanceVisiblity,
    setCkeditorFinanceVisiblity,
    handleError,
    removeRulesHandler,
    toggleError,
    fields,
    append,
  } = props;

  const order = ["1st", "2nd", "3rd", "4th"];
  const { offerData } = useContext(appContext);

  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

  const financeInfoPermission = _.isEmpty(offerData)
    ? createOffersPermission
    : !viewOnlyMode && editOffersPermission;

  return (
    <div className="sub-section">
      <p className="section-heading">
        4. Offer Finance
        {financeInfoPermission && (
          <div className="tooltipContainer">
            <Icon icon={faCircleInfo} />
            <div>{permissionError}</div>
          </div>
        )}
      </p>

      <div className="input-fields-wrapper">
        <MultiSelect
          disabled={!financeInfoPermission}
          fieldName="Sponsor"
          selectedValues={watch("sponsor")}
          fields={sponsorType}
          register={register("sponsor", { required: "sponsor is Required" })}
          onChange={(values: any) => {
            setValue("sponsor", values);
            watch("sponsor").map((element: any, index: number) => {
              watch("sponsor")?.length === 1 && index === 0
                ? setValue(`${element.name.toLowerCase()}Share`, 100)
                : setValue(`${element.name.toLowerCase()}Share`, "");
            });
          }}
          error={errors.sponsor?.message as any}
          focusHandleText={
            <p>
              Multiple Options can be selected : <br /> ( If the offer is shared
              by 2 parties like Poorvika + Bank , Select Both Poorvika and Bank
              and define the percentage of Cost owned by each parties )<br />
              <br /> Brand : Offer Provided by Brand and Brand will own the cost
              of the offer ,<br /> Poorvika : Offer Provided by Poorvika and
              Poorvika will own the cost of the offer ,<br /> Bank : Offer
              Provided by Bank and Bank will own the cost of the offer{" "}
            </p>
          }
        />
        {watch("sponsor")?.length
          ? watch("sponsor").map((element: any, index: number) => {
              return (
                <InputField
                  mandatory
                  disabled={!financeInfoPermission}
                  helpertext={`${element.name} share in %`}
                  name={`${element.name.toLowerCase()}Share`}
                  register={register(`${element.name.toLowerCase()}Share`, {
                    required: "Share percentage cant be Empty",
                    max: {
                      value: 100,
                      message:
                        "Maximum allowed limit for Percentage is crossed",
                    },
                  })}
                  type="number"
                  step={0.01}
                  error={
                    toggleError.percentage
                      ? "Total percentage Share Should be 100"
                      : (errors[`${element.name.toLowerCase()}Share`]
                          ?.message as any)
                  }
                  hoverHandleText={watch(`${element.name.toLowerCase()}Share`)}
                />
              );
            })
          : ""}
        <InputField
          disabled={!financeInfoPermission}
          helpertext="Sponsor ID"
          name="sponsorID"
          register={register("sponsorID", {
            required: "Sponsor ID is Required",
          })}
          error={errors.sponsorID?.message as any}
          focusHandleText="APX ID for the Sponsor "
          hoverHandleText={watch("sponsorID")}
        />
        <InputField
          disabled={!financeInfoPermission}
          helpertext="Ex Scheme ID"
          name="exSchemeID"
          register={register("exSchemeID", {
            required: "External Scheme Id is required",
          })}
          error={errors.exSchemeID?.message as any}
          focusHandleText="Enter  Scheme ID , mentioned in the Offer letter from Brand/Bank/Paymentgateway"
          hoverHandleText={watch("exSchemeID")}
        />
        <InputField
          disabled={!financeInfoPermission}
          helpertext="GL Code"
          name="glCode"
          register={register("glCode", {
            required: "GL Code is required",
          })}
          columnSpan={2}
          error={errors.glCode?.message as any}
          focusHandleText="Enter the Ledger Name Create for this offer in APX"
          hoverHandleText={watch("glCode")}
        />
      </div>
      <div className="editor">
        <p className="heading">
          Tax Option <strong className="mandatory"> *</strong>
        </p>
        <div className="radioBtn">
          <RadioGroup
            disabled={!financeInfoPermission}
            options={[
              "Discount Applied Before Tax",
              "Discount Applied after Tax",
            ]}
            defaultValue={watch("taxOption")}
            formValue={(value) => setValue("taxOption", value)}
            register={register("taxOption", {
              required: "tax option is required",
            })}
          />
          {errors.taxOption !== undefined && (
            <p className="error">
              <span>* </span>
              {errors?.taxOption?.message as any}
            </p>
          )}
        </div>
      </div>
      <div className="editor">
        <p className="heading">Reimbursement</p>
        <table className="reimbursement-table">
          <thead>
            <th className="particulars">
              <p>Particulars</p>
            </th>
            <th className="days">
              <p>Days</p>
            </th>
            <th className="percentage">
              <p>Percentage</p>
            </th>
            <th className="type">
              <p>Type</p>
            </th>
            {!financeInfoPermission && fields.length > 1 && (
              <th className="action">
                <p>Action</p>
              </th>
            )}
          </thead>
          <tbody>
            {fields?.map((ruleGroup: any, index: number) => {
              return (
                <tr key={index}>
                  <td data-label="Particulars" className="particulars">
                    <p>
                      {index < 4 ? order[index] : `${index + 1}th`} Installment
                    </p>
                  </td>
                  <td data-label="Days" className="days">
                    <InputField
                      disabled={!financeInfoPermission}
                      helpertext="Days"
                      type="number"
                      step={0.01}
                      name={`reimbursement.${index}.days`}
                      register={register(`reimbursement.${index}.days`, {
                        required: "value can't be null",
                      })}
                      error={handleError(index, "days") as any}
                      hoverHandleText={watch(`reimbursement.${index}.days`)}
                    />
                  </td>
                  <td data-label="percentage" className="percentage">
                    <InputField
                      disabled={!financeInfoPermission}
                      helpertext="Percentage"
                      type="number"
                      step={0.01}
                      name={`reimbursement.${index}.percentage`}
                      register={register(`reimbursement.${index}.percentage`, {
                        required: "value can't be null",
                        min: {
                          value: 1,
                          message: "Value must be between 1-100",
                        },
                        max: {
                          value: 100,
                          message: "Value must be between 1-100",
                        },
                      })}
                      error={handleError(index, "percentage") as any}
                      hoverHandleText={watch(
                        `reimbursement.${index}.percentage`
                      )}
                    />
                  </td>
                  <td data-label="Type" className="type">
                    <DropDown
                      disabled={!financeInfoPermission}
                      options={["CN", "Stocks", "Payment"]}
                      defaultValue={watch(`reimbursement.${index}.type`)}
                      label="Type"
                      name="type"
                      onChange={(e: any) => {
                        setValue(`reimbursement.${index}.type`, e.target.value);
                      }}
                      register={register(`reimbursement.${index}.type`)}
                    />
                  </td>
                  {!financeInfoPermission && index > 0 && (
                    <td className="action">
                      <div>
                        {index === fields.length - 1 && index !== 0 && (
                          <div onClick={() => removeRulesHandler(index)}>
                            <Icon icon={faTrash} />
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="reimbursement-btn">
          {financeInfoPermission ? (
            <button
              className="btn primary"
              type="button"
              onClick={() => append({ name: "" })}
            >
              Add
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="editor">
        <p className="heading">Reimbursement Procedure</p>
        <div className="input-fields-wrapper">
          <DropDown
            disabled={!financeInfoPermission}
            options={["None", "Amount", "Percentage"]}
            label="Charges/Deductions if any"
            name="chargesAndDeductions"
            register={register("chargesAndDeductions")}
            focusHandleText={
              "Mention Deductions if any during Reimbursement, If no deduction Please select None."
            }
          />
          {watch("chargesAndDeductions") ? (
            watch("chargesAndDeductions") !== "None" ? (
              <InputField
                disabled={!financeInfoPermission}
                helpertext="charges/Deductions Value"
                name="chargesAndDeductionsValue"
                register={register("chargesAndDeductionsValue", {
                  required: `${watch("chargesAndDeductions")} is required`,
                  max: {
                    value:
                      watch("chargesAndDeductions") === "Percentage"
                        ? 100
                        : 10000000,
                    message: `Maximum allowed limit for ${watch(
                      "chargesAndDeductions"
                    )} is  crossed`,
                  },
                  min: {
                    value: 1,
                    message: `Minimum chargesAndDeductions value cannot be 0`,
                  },
                  shouldUnregister: true,
                })}
                error={errors.chargesAndDeductionsValue?.message as any}
                type="number"
                step={0.01}
                mandatory
                hoverHandleText={watch("chargesAndDeductionsValue")}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div className="checkbox">
            <Checkbox
              disabled={!financeInfoPermission}
              value={watch("TDSApplicablity")}
              lable="TDS Applicablity"
              onChange={(checkedStatus: boolean) =>
                setValue("TDSApplicablity", checkedStatus)
              }
            />
            <Checkbox
              disabled={!financeInfoPermission}
              value={watch("GSTInvoice")}
              lable="GST Invoice"
              onChange={(checkedStatus: boolean) =>
                setValue("GSTInvoice", checkedStatus)
              }
            />
          </div>
        </div>
      </div>
      <div className="editor">
        <div className="editor-field-wrapper">
          <div className="headingAndInformation">
            <p className="heading"> Finance Notes</p>
            <div
              className="informationField"
              onClick={() => {
                setCkeditorFinanceVisiblity(!ckeditorFinanceVisiblity);
              }}
            >
              ?
            </div>
            {ckeditorFinanceVisiblity && (
              <>
                <div className="ckeditorInformation" ref={ckeditorFinanceRef}>
                  {"(Enter Finance related information)"}
                </div>
                <div className="ckeditorFocusHandleCaret">
                  <Icon className="ckeditorfocusCaret" icon={faCaretUp} />
                </div>
              </>
            )}
          </div>
        </div>
        {textEditorVisibility && (
          <ReactQuill
            readOnly={!financeInfoPermission}
            theme="snow"
            value={watch("financeNotes")}
            onChange={(value: any) => setValue("financeNotes", value)}
            modules={{
              toolbar: editorToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FinanceSection;
