import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";


export class RolesService extends BaseService {
    public async addRoles(rolesData:any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_ROLES, rolesData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getRolesList(): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ROLESLIST}`)


            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getRolesPermission(roleName: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ROLEPERMISSION}?role=${encodeURIComponent(roleName)}`)


            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
    public async updateRoles(rolesData:any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.UPDATE_ROLES, rolesData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}