import IconTextField from "./IconTextField";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import Checkbox from "components/Checkbox";
import DateAndTimePicker from "components/DateTimePicker";
import MultiRangeSlider from "components/PriceSlider/priceSlider";
import { RoundOff } from "common/constant";
import _ from "lodash";
import React from "react";


type AddProductsProps = {
  mobileToggle?: boolean;
  setMobileToggle?: any;
  offersFilterOptions: any;
  activeOffersFilterOptions: any;
  offersFilter: any;
  setOffersFilter: any;
  setOffersFilterUpdate: any;
  offersFilterUpdate: any;
  setOffersFilterOptions: any
  index?: any;
  maxBenefit?: number,
  offerList: any,
  filteredOffer: any,
  SetFilteredOffers: any

};

const SideMenuOffer: React.FC<AddProductsProps> = (props) => {

  const { offersFilterOptions, offerList, SetFilteredOffers, setOffersFilterOptions,
    offersFilter, setOffersFilter, mobileToggle, setMobileToggle } = props;


  const { reset } = useForm()
  const [updateValue, setUpdateValue] = useState<boolean>(false)
  const [updateMax, setUpdateMax] = useState<boolean>(false)
  const [maxBenefits, setMaxBenefits] = useState<number>(offersFilterOptions?.maxBenefits || 0)
  const [offerFilterHeading, setOfferFilterHeading] = useState<any>(offersFilterOptions ? Object.keys(offersFilterOptions) : [])


  const filterSelectionHandler = (status: boolean, filterVal: any, index: number, item: any) => {
    let newoffersFilterOptions = offersFilterOptions;

    if (status) setOffersFilter((prevFilter: any) => ({
      ...prevFilter,
      filters: [...(prevFilter.filters).filter((preVal: any) => Object.keys(filterVal.filter)[0] !== Object.keys(preVal)[0]), filterVal.filter]
    }))
    else {
      setOffersFilter((prevFilter: any) => ({
        ...prevFilter,
        filters: prevFilter.filters.filter((item: any) => JSON.stringify(item) !== JSON.stringify(filterVal.filter))
      }))
    }
    newoffersFilterOptions[item] = newoffersFilterOptions[item].map((option: any) => {
      option?.checked && delete option?.checked
      return option
    })
    newoffersFilterOptions[item][index]['checked'] = status;
    setOffersFilterOptions(newoffersFilterOptions)

  }

  const clearFilter = () => {
    reset()
    setOffersFilter({ filters: [], startDate: "", endDate: "" })
    setUpdateMax(false)
    let offersFilterOptionsClr: any = [];
    offerFilterHeading?.map((item: any, index: number) => {
      offersFilterOptionsClr[item] = offersFilterOptions[item]?.map((offerOption: any, i: number) => {
        offerOption.checked = false;
        return offerOption;
      })
    })
    setOffersFilterOptions(offersFilterOptionsClr)
  }

  useEffect(() => {
    setMaxBenefits(offersFilterOptions?.maxBenefits || 0)
    setOfferFilterHeading(offersFilterOptions ? Object.keys(offersFilterOptions) : [])
    reset()
    setUpdateValue(!updateValue)
  }, [offersFilterOptions])

  useEffect(() => {
    if (!_.isEmpty((offerList))) {
      const [item] = offerList
      setMaxBenefits(item.maxBenefites)
      setUpdateValue(!updateValue)

    }
  }, [offerList])

  
  return (
    <div className={mobileToggle ? 'view-side-con active' : 'view-side-con'}>
      <div className="overlay" onClick={() => setMobileToggle(false)}></div>
      <div className="view-side">
        {/* <img className="filter-close-btn" src={CloseIcon} onClick={() => setMobileToggle(false)} /> */}
        <div className="side-child">
          <div className="child-span">
            <div className="side-filter-header" >
              <span className="side-filter-title">Filter</span>
              {(JSON.stringify(offersFilter) !== JSON.stringify({ filters: [], startDate: "", endDate: "" }) || updateMax) &&
                <span style={{ cursor: "pointer" }}
                  onClick={() => clearFilter()}
                  className="side-filter-clear">Clear</span>}
            </div>
            <span className="side-filter-option">
              <IconTextField onChange={(e: any) => {
                if (e?.target?.value?.length) {
                  setOfferFilterHeading(Object.keys(offersFilterOptions).filter(value => value.toLowerCase().replaceAll(" ", "").includes(e.target.value.toLowerCase().replaceAll(" ", ""))))
                }
                else {
                  setOfferFilterHeading(Object.keys(offersFilterOptions))
                }
              }} label="Search " iconEnd={<Search />} size="small" />
            </span>
          </div>
        </div>
        <div className="side-child">
          <div className="child-span">
            <span className="side-filter-title">Start Date</span>
            <span className="side-filter-option">
              <DateAndTimePicker
                value={offersFilter?.startDate ? offersFilter.startDate : ""}
                onDateSelection={(date) => {
                  setOffersFilter((prevFilter: any) => ({
                    ...prevFilter,
                    startDate: date
                  }))
                }
                }
                label="Start Date"
                name="startDate" />
            </span>
          </div>
        </div>
        <div className="side-child">
          <div className="child-span">
            <span className="side-filter-title">End Date</span>
            <span className="side-filter-title">
              <DateAndTimePicker
                minDateTime={offersFilter?.startDate ? offersFilter.startDate : ""}
                value={offersFilter?.endDate ? offersFilter.endDate : ""}
                onDateSelection={(date) => {
                  setOffersFilter((prevFilter: any) => ({
                    ...prevFilter,
                    endDate: date
                  }))
                }
                }
                label="End Date"
                name="startDate" />
            </span>
          </div>
        </div>
        <div className="side-child">
          <div className="child-span">
            <span className="side-filter-title">Max Benefites</span>
            <span className="side-filter-title">
              <MultiRangeSlider min={0} max={RoundOff(maxBenefits)} onChange={(e: any) => {
                SetFilteredOffers(offerList.filter((item: any) => (item.maxBenefites >= e.min && item.maxBenefites <= e.max))
                )
                setUpdateMax(true)
              }} update={updateValue} />
            </span>
          </div>
        </div>
        {offerFilterHeading?.map((item: any, index: number) => {
          return (
            <div className="side-child" key={index}>
              <div className="child-span">
                <span className="side-filter-title">{item}</span>
                <span className="side-filter-option">
                  {offersFilterOptions[item]?.map((value: any, i: number) => {
                    return (typeof value.name === 'string') && <div className="sidemenu-checkbox-wrapper">
                      <Checkbox value={value?.checked} key={i} lable={`${value.name}`} onChange={(checkedStatus: boolean) => filterSelectionHandler(checkedStatus, value, i, item)} />
                    </div>
                  })}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default React.memo(SideMenuOffer);