import "./styles.scss";
import appContext from "context/app.context";
import Icon from "components/Icon";
import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import {
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Controller, useForm } from "react-hook-form";
import DropDown from "components/DropDown";
import DateAndTimePicker from "components/DateTimePicker";
import {  MultiSelectValues } from "common/constant";
import { Autocomplete, TextField } from "@mui/material";
import { PIMProductService } from "services/pimProduct.service";
import { FinanceListService } from "services/financeList.service";
import { STATUS } from "config/config";

const FinanceFilter: React.FC<any> = (props: any) => {
  const financeListService = new FinanceListService();
  const { setFilterVisibility, filterVisibility } = props;
  const {
    showToaster,
    financeFilter,
    setFinanceFilter = () => { }
  } = useContext(appContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control
  } = useForm();

  useState<boolean>(false);
  const [brands, setBrands] = useState<any>([]);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [endDateExceedErr, setEndDateExceedErr] = useState<boolean>(false);
  const [financeListName, setFinanceName] = useState<any>([]);
  const pimService = new PIMProductService();

  const getBrandList = async (): Promise<void> => {
    try {
      const { data: { brand = [] } } = await pimService.getBrandList();
      const brandList: [] = brand.map((item: any) => ({ label: item.name, value: item.code }));
      setBrands([{ label: 'All brands', value: '' }, ...brandList]);

    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to fetch Brand list");
    }
  };
  const clearHandler = () => {
    reset()
    setFinanceFilter({})
    setFilterVisibility(!filterVisibility)
  }
  const filterSubmissionHandler = async (
    financeFilterValues: any
  ): Promise<any> => {
    console.log(financeFilterValues);

    let startDateValue = watch("startDate");
    let endDateValue = watch("endDate");

    let startDateNotEmpty = financeFilterValues?.startDate !== "";
    let endDateNotEmpty = financeFilterValues?.endDate !== "";
    let startDateValidate = startDateNotEmpty && !Number.isNaN(startDateValue) ? true : false;
    let endDateValidate = endDateNotEmpty && !Number.isNaN(endDateValue) ? true : false;

    if (financeFilterValues.financeName === 'All Finance')
      financeFilterValues.financeName = ''
    if (financeFilterValues.channel === 'All Channel')
      financeFilterValues.channel = ''


    if (financeFilterValues?.startDate === "" && financeFilterValues?.endDate === "") {
      setFinanceFilter(financeFilterValues)
    }
    else if (startDateValidate && (financeFilterValues?.endDate === "" ||
      endDateValidate) && (financeFilterValues?.startDate === "" ||
        startDateValidate) && endDateValidate) {
      setFinanceFilter(financeFilterValues)
    }
    else if ((startDateValidate && !endDateValidate) || (endDateValidate && !startDateValidate)) {
      return false;
    }
    else {
      return false;
    }
  };

  const dateRangeSelectionHandler = async (
    dateFieldName: string,
    value: string
  ): Promise<void> => {
    if (dateFieldName === "startDate") {
      setValue("startDate", value);
    } else if (dateFieldName === "endDate") {
      setValue("endDate", value);
    }
    if (watch("startDate") && watch("endDate")) {
      if (new Date(watch("startDate")).getTime() >= new Date(watch("endDate")).getTime()) {
        setEndDateExceedErr(true);
        setEndDateError(false);
      }
      else {
        setEndDateExceedErr(false)

      }

    }

  };
  const handleBlur = () => {
    if (watch("startDate")) {
      if (new Date(watch("startDate")).getTime()) {
        setStartDateError(false)
      }
      else {
        setStartDateError(true);
      }
    }

    if ((watch("endDate"))) {
      if (new Date(watch("endDate")).getTime()) {
        setEndDateError(false);
      }
      else {
        setEndDateError(true);
        setEndDateExceedErr(false)
      }
    }
  }

  const getFinanceList = async (): Promise<void> => {
    const financeListResponse = await financeListService.financeListName();

    if (financeListResponse.status === STATUS.SUCCESS) {
      let response = financeListResponse.data;
      var financeName = [];
      response.forEach((element: any) => {
        financeName.push(element.financeName);
      });
      setFinanceName(financeName);
    } else {
      showToaster(EToasterVariants.error, "Unable to get Finance Name List");
    }
  };
  
  useEffect(() => {
    getFinanceList();
    getBrandList();
    const { startDate = '', endDate = '' } = financeFilter;
    setValue('startDate', startDate)
    setValue('endDate', endDate)
  }, [])
  const { financeName = "", brand = "", channel = "" } = financeFilter;
  return (

    <div
      className={`slider-right mobile-filter-right ${filterVisibility ? "show" : "hide"}`}>
      <div className="add-product-slider">
        <div className="slider-header">
          <h1>Filter</h1>
          <div className="action-icon" onClick={() => setFilterVisibility(!filterVisibility)}>
            <Icon icon={faXmark} />
          </div>
        </div>

        <div className="filterTop">
          <form onSubmit={handleSubmit(filterSubmissionHandler)}>
            <div className="form-wrapper">
              <div className="formfilter">
              <DropDown
                  options={['All Finance', ...financeListName.map((values: any) => values)]}
                  register={register("financeName")}
                  label="Finance Name"
                  name="financeName"
                  defaultValue={financeName}
                />
              </div>

              <div className="formfilter">
                <Controller
                  name="brand"
                  control={control}
                  defaultValue={brand}
                  render={(props) => (
                    <Autocomplete
                      {...props.field}
                      disablePortal
                      options={brands}
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={(event: any, newValue: any) => {
                        props.field.onChange(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} label="Brands" />}
                    />
                  )}
                />
              </div>
              <div className="formfilter">
                <DropDown
                  options={[{ name: "All Channel", id: '' }, ...MultiSelectValues.channel]}
                  register={register("channel")}
                  label="Channel"
                  name="channel"
                  defaultValue={channel}
                />
              </div>
              <div className="formfilter">
                <DateAndTimePicker
                  label="Start Date"
                  name="startDate"
                  value={watch("startDate")}
                  error
                  helperText="Your error message"
                  onDateSelection={(date) =>
                    dateRangeSelectionHandler("startDate", date)
                  }
                  handleBlur={handleBlur}
                />
                {startDateError && <p className="hint-text error-text">Please enter valid start date and time </p>}
              </div>

              <div className="formfilter">
                <DateAndTimePicker
                  label="End Date"
                  name="endDate"
                  value={watch("endDate")}
                  onDateSelection={(date) =>
                    dateRangeSelectionHandler("endDate", date)
                  }
                  handleBlur={handleBlur}
                />
                {endDateError && <p className="hint-text error-text">Please enter valid end date and time </p>}
                {endDateExceedErr && <p className="hint-text error-text">End date should be greather than start date </p>}
              </div>

              <div className="filter-action">
                <button
                  className="btn secondary"
                  type="reset"
                  onClick={clearHandler}
                >
                  clear
                </button>
                <button className="btn primary" type="submit">
                  Filter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
};

export default FinanceFilter;
