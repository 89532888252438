import React, { useContext, useState } from "react";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import appContext from "context/app.context";
import OffersInfoForm from "../Forms/OffersInfoForm";
import AddProducts from "../AddOffersPage/AddProducts";
import DynamicRulesForm from "../Forms/DynamicRulesForm";

const SubMenus = ["INFO", "PRODUCTS", "RULES"];

const Orderlist: React.FC = () => {
  const { menuIndex, setMenuIndex } = useContext(appContext);
  const [editMode, setEditMode] = useState<boolean>(true);

  return (
    <div className="offers-page">
      <div className="header">
        <h3 className="page-heading">Add Offers</h3>
       {editMode && <div className="edit-offer-action-warpper" onClick={() => setEditMode(false)}>
          <FontAwesomeIcon className="action-icon" icon={faPencil} />
          <p>Edit Offer</p>
        </div>}
      </div>

      <div className="tabs">
        {SubMenus?.map((menu: string, index: number) => {
          return (
            <div
              className={index === menuIndex ? "active" : ""}
              onClick={() => setMenuIndex(index)}
            >
              <p>{menu}</p>
            </div>
          );
        })}
      </div>
      <div className="add-offers-page-content">
      {menuIndex === 0 && <div className="offer-info shadow"> <OffersInfoForm/></div>}
      {menuIndex === 1 && <div className="offer-info shadow"> <AddProducts/></div>}
      {menuIndex === 2 && <div className="offer-info shadow"> <DynamicRulesForm/></div>}
      </div>
    </div>
  );
};

export default Orderlist;
