import CouponsHistory from 'containers/CouponsHistory';
import { CouponsReports } from 'containers/CouponsReports';
import Coupons from 'pages/CouponsPage';
import AddCouponsPage from 'pages/CouponsPage/AddcouponsPage';
import CouponCategoryPage from 'pages/CouponsPage/CouponCategoryPage';
import EditCouponsPage from 'pages/CouponsPage/EditCouponsPage';
import { Route, Routes } from "react-router-dom";
function CouponsLandingPage() {
  const couponRoutes=[{
    path:"/list",
    element:Coupons
  },
  {
    path:"/reports",
    element:CouponsReports
  },
  {
    path:"/couponhistory",
    element:CouponsHistory
  },
  {
    path:"/couponType",
    element:CouponCategoryPage
  },
  {
    path:"/add",
    element:AddCouponsPage
  },
  {
    path:"/edit/:id",
    element:EditCouponsPage
  },
]
  return (
    <>
      <Routes>
            {couponRoutes?.map((routes, index: number)=>{
              return <Route key={index} path= {routes.path} element={<routes.element />} />
            })}    
      </Routes>
    </>
  )
}

export default CouponsLandingPage