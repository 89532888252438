import DynamicField from "components/DynamicField";
import InputField from "components/InputField";
import MultiSelect from "components/MultiSelect";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OffersGroupService } from "services/offerGroup.service";
import { SettingsService } from "services/settings.service";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss"

type DynamicRulesFormProps = {
    viewOnlyMode?: boolean;
};


export const RulesTab: React.FC<DynamicRulesFormProps> = ({ viewOnlyMode }) => {
    const navigateTo = useNavigate();

    const { register, handleSubmit, setValue, watch } = useForm();
    const [attributes, setAttributes] = useState<Array<any>>()
    const { setMenuIndex, offerGroupData, setOfferGroupData, showToaster } = useContext(appContext);
    const offersGroupService = new OffersGroupService()
    const settingsService = new SettingsService();

    const goToPreviousFormHandler = async (): Promise<void> => {
        setMenuIndex(1);
    }

    const getAttributesInfo = async (): Promise<void> => {
        try {
            const offergroup: boolean = true;
            const rule: boolean = false;

            const getAttributeResponse = await settingsService.getAttributes({ rule: rule, offergroup: offergroup });
            if (getAttributeResponse.status === STATUS.SUCCESS) {
                setAttributes(getAttributeResponse?.data?.AttributesData);
            }
        } catch (error) {
            showToaster(EToasterVariants.error, "Unable to get Attributes List");
        }
    };


    const onSubmit = async (offerInfovalues: any): Promise<void> => {
        const updatedOfferGroup = { ...offerGroupData, rules: { ...offerGroupData.rules, ...offerInfovalues.rule } }
        const offerDetailsResponse = await offersGroupService.updateOfferGroup(updatedOfferGroup);
        if (offerDetailsResponse.status === "success") {
            setMenuIndex(0)
            setOfferGroupData([]);
            showToaster(EToasterVariants.success, "Offer Group Updated successfully");
            setMenuIndex(0);
            navigateTo("/offers/offer_grouping");
        }
        else {
            showToaster(EToasterVariants.error, "Offer Group Updated failed");
        }

    };

    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };

    const rulesHandler = (rule: any, index: number) => {
        if (rule?.isLov && rule?.isMultiselect) {
            const dropDownValues = rule.lov.map(
                (lovValue: any, lovIndex: number) => ({
                    ...lovValue,
                    lovIndex,
                })
            );
            let tempValue = []
            try {
                tempValue = rule.name
            } catch (err) {
                console.log(err)
            }

            return (
                <MultiSelect
                    disabled={viewOnlyMode}
                    fieldName={rule.name}
                    selectedValues={offerGroupData?.rules ? Object.keys(offerGroupData?.rules)?.filter((e: any) => e === rule.name).length ? offerGroupData?.rules[rule.name] : [] : []}
                    fields={dropDownValues}
                    onChange={(selectedValues: any) =>
                        setValue(`rule.${rule.name}`, selectedValues)
                    }
                />
            );
        }
        watch(`rule.${rule.name}`) === undefined && setValue(`rule.${rule.name}`, offerGroupData?.rules ? Object.keys(offerGroupData?.rules)?.filter((e: any) => e === rule.name).length ? offerGroupData?.rules[rule.name] : "" : "")
        return (
            <DynamicField
                disabled={viewOnlyMode}
                values={rule}
                name={`rule.${rule.name}`
                }
                register={register(`rule.${rule.name}`)}
            />
        );
    };

    useEffect(() => {
        getAttributesInfo()
        if (viewOnlyMode === false || viewOnlyMode === true) {
        }
    }, [])
    return (
        <div className="rules-page">
            <div className="header">
                <h3>Offer Claim Rules</h3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e)=>{handleKeyDown(e)}}>
                <div className="rules-list" >
                    {attributes?.map((rule: any, index: number) => {
                        return (
                            <div className="dynamic-rules-group">
                                <div className="rules-row">
                                    <div className="dynamic-field-label">
                                        <p>{rule.name} : </p>
                                    </div>
                                    <div className="dynamic-field">

                                        {rulesHandler(rule, index)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {
                    !viewOnlyMode && <div className="buttons-wrapper">
                        <button className="btn secondary" type="button"  onClick={goToPreviousFormHandler}>
                            Previous
                        </button>
                        <button className="btn primary" type="submit">
                            Save
                        </button>
                    </div>
                }
            </form>
        </div>
    )
}
