import "./styles.scss"
import noImageAvailable from "assets/images/no-image.png";
import { ProductionSelectionDialog } from "containers/Dialogs/ProductionSelectionDialog";
import ProductMrp from "./ProductMrp";


type TProp = {
    products?: any
    combooff?: boolean
    handleDelete?: (product: any) => void
    onPriceUpdate?: (price: number) => void,
    freebies?: string,
    setDialog?: any
    productIndex?: any
    setSelectedProducts?: any
    selectedProducts?: any
    productDialog?: any
    setProductDialog?: any
    addProduct?: any
    fieldName?: string
}


export const ProductCardTable: React.FC<TProp> = ({ products, selectedProducts, productIndex, setSelectedProducts, setProductDialog, productDialog, fieldName }) => {

    return (
        <>
            {products?.map((product: any, index: any) => {
                return (
                    <>
                        <div className="product-multiField-box">
                            {index !== 0 && <div className="btn-Wrapper">
                                <p>+</p>
                            </div>}
                            <div className="product-multiField">
                                <div className="product-Img">
                                    <img className="combo-img" alt={"combo-img" + index} src={product?.image?.url || noImageAvailable} />
                                </div>
                                <div className="product-details">
                                    <p>{product?.model?.name}</p>
                                </div>

                                <div className="variant">

                                    {product.addVariant && product?.addVariant?.map((variantData: any, index: number) => {
                                        return (
                                            <>

                                                <p>(

                                                    {Object.keys(variantData["attr"])?.map((varian: any) => {
                                                        return (variantData?.attr[varian] + ',')
                                                    })}
                                                    {variantData?.erp_item_code ? variantData?.erp_item_code : "not provided"}
                                                    )</p>
                                                {index !== product?.addVariant?.length - 1 && <p>  |   </p>}
                                            </>
                                        )
                                    })}
                                </div>
                                <div className="product-price">
                                    <p className="lineThrough">
                                        <ProductMrp item_code={product.item_code} />
                                    </p>
                                    {product?.offerPrice && <p className="newPrice">{`Rs: ${product?.offerPrice} `}</p>}
                                </div>
                            </div>
                        </div>


                    </>
                )
            })
            }

            {productDialog && <ProductionSelectionDialog fieldName={fieldName} show={productDialog} onExit={(bool, data) => {
                if (bool) {
                    let temp = [...selectedProducts]
                    temp[productIndex].push(data)
                    setSelectedProducts([...temp])
                    setProductDialog(false)
                }

                else {
                    setProductDialog(false)
                }
            }} />
            }
        </>
    )
}