import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import { OffersService } from "services/offers.service";
import { STATUS } from "config/config";
import { ProductCard } from "components/ProductCard";
import ProductMultiSelect from "components/ProductMultiSelect";
import { useForm } from "react-hook-form";
import { faHistory, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BulkProductUpload } from "components/BulkProductUpload";
import DownloadTemplates from "components/DownloadeTemplates";
import TablePagination from "@mui/material/TablePagination";
import BulkUploadHistory from "components/BulkUploadHistory";
import { ProductService } from "services/product.service";
import { defaultLabelDisplayedRows } from "common/commonFunc";
import { usePermission } from "components/Permission";

type AddProductsProps = {
  viewOnlyMode?: boolean;
};

const FreeOfferPage: React.FC<AddProductsProps> = ({ viewOnlyMode }) => {
  const { handleSubmit } = useForm({ mode: "all" });
  const offersService = new OffersService();
  const productServices = new ProductService();

  const { showToaster, setMenuIndex, setOfferData, offerData, setSpinnerVisibility } = useContext(appContext);

  const [bulkVisibility, setBulkVisibility] = useState<boolean>(false);
  const [historyVisibility, setHistoryVisibility] = useState<boolean>(false);
  const [productLists, setProductLists] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [filter, setfilter] = useState<any>({});

  const [addProductPermission] = usePermission(["ADD_OFFER_PRODUCTS"]);
  const [createOfferPermission] = usePermission(["CREATE_OFFERS"]);
  const [editOfferPermission] = usePermission(["EDIT_OFFERS"]);

  const heading = ["Product Item Code", "Product Name", "Product 1", "Product 2", "Product 3", "Product 4", "Cash Discount"];

  /* Pagination Actions */
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log("page", page)
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToPreviousFormHandler = () => {
    setMenuIndex(1);
  }

  const onSubmit = async (values: any): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      const updateOfferResponse = await offersService.updateOffer(offerData);
      if (updateOfferResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Offer Updated Successfully");
        setSpinnerVisibility(false);
        const offerDataResult = { ...updateOfferResponse.data.offerData, products: updateOfferResponse?.data?.product, selectedOfferType: offerData.selectedOfferType }

        setOfferData(offerDataResult);
        setMenuIndex(3);
      } else {
        setSpinnerVisibility(false);
      }

    }
    catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "Unable to create new offer");
    }
  };

  const getProductLists = async (offerId: string, filter = {}) => {
    const apiResponse = await productServices.getOffersProductsList(offerId, rowsPerPage, page, filter);
    if (apiResponse.status === STATUS.SUCCESS) {
      setProductLists(apiResponse.data)
      setTotalPages(apiResponse.count)
    } else {
      showToaster(EToasterVariants.error, "Unable to get offer products");
    }

  }

  useEffect(() => {
    if (offerData && viewOnlyMode !== undefined)
      getProductLists(offerData._id, filter)
  }, [page, rowsPerPage, filter, offerData]);


  return (
    <div className="freebies-page add-couponproducts">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="freebies-selection">
          <div className="product-header">

            {!viewOnlyMode && addProductPermission &&

              <>
                <div className="btn-wrapper">

                  <div
                    className="btn-add-products"
                    onClick={() => setBulkVisibility(true)}
                  >
                    <FontAwesomeIcon className="" icon={faPlus} />
                    <p>Bulk Upload</p>
                  </div>
                  <DownloadTemplates heading={heading} height ReportTitle="freebies" />

                  <div
                    className="btn-add-products"
                    onClick={() => setHistoryVisibility(!historyVisibility)}
                  >
                    <FontAwesomeIcon className="" icon={faHistory} />
                    <p>{!historyVisibility ? 'Bulk History' : 'See Products'}</p>
                  </div>
                </div>

              </>
            }
            {
              !historyVisibility ?
                <div className="productlist">
                  <div className="product-header-fixed">
                    <p className="product-headersp1">Products</p>  <p className="product-headersp2">Free Products</p>
                    <div></div>
                  </div>
                  {productLists?.map((product: any, index: number) => {
                    return (
                      <div className="product-card" key={index + (page * rowsPerPage)}>
                        <ProductCard product={product} />
                        <div className="freebies-products">
                          <ProductMultiSelect fieldName={"freebies"} product={product} viewOnlyMode={viewOnlyMode}
                          />

                        </div>
                      </div>
                    )
                  })}
                  <div className="pagination-wrapper">
                    <TablePagination
                      backIconButtonProps={
                        page === 1 ? { disabled: true } : { disabled: false }
                      }

                      nextIconButtonProps={
                        page === totalPages || totalPages === 0
                          ? { disabled: true }
                          : { disabled: false }
                      }
                      component="div"
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                      page={page}
                      count={Math.ceil(totalPages / rowsPerPage)}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                  {!viewOnlyMode && (editOfferPermission || createOfferPermission) &&
                    <div className="btn-group-align">
                      <button type="button" className="btn secondary" onClick={goToPreviousFormHandler}>
                        Previous
                      </button>
                      <button type="submit" className="btn primary mr-2">
                        Save
                      </button>
                      {bulkVisibility &&
                        <BulkProductUpload setBulkVisibility={setBulkVisibility} importVarient={"freebies"} />
                      }
                    </div>}
                </div>
                : <BulkUploadHistory module="OfferFreebies" Id={offerData?._id} />
            }
          </div>
        </div>

      </form>

    </div >
  );
};

export default FreeOfferPage;
