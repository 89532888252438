import { AxiosError } from "axios";
import { API_ENDPOINTS, COMMON_API, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";
import { encodeGetParams } from "common/commonFunc";

export class OffersService extends BaseService {

    public async getOffersList(rowsPerPage = 10, pageNo = 1, filter: any = {}, startDate: any = "", endDate: any = "",sortName:any = "",sort:any = "", isAll = ""): Promise<IAPIReponse> {
        
        try {
            const { search = "", offerType = "", status = "", brand = "", category = "" } = filter;

            const newObject = {
                limit: rowsPerPage,
                page: pageNo,
                search,
                offerType,
                status,
                brand,
                category,
                startDate,
                endDate,
                sortName,
                sort,
                isAll
            }
            const queryParams = encodeGetParams(newObject);

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER}?${queryParams}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }


    public async getActiveOffersList(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER}?startDate=${offerData.startDate}&endDate=${offerData.endDate}&status=${offerData.status}&limit=${offerData.rowsPerPage}&page=${offerData.pageNo}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }


    public async createNewOffer(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.post(API_ENDPOINTS.OFFER, offerData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async updateOffer(offerData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.OFFER}/${offerData["_id"]}`, offerData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteOffer(offerId: string): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.delete(`${API_ENDPOINTS.OFFER}/${offerId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async cloneOffer(offerId: string, isProduct: boolean): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER}/clone/${offerId}?isProduct=${isProduct}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async statusChange(offerId: string, status: string, datas?: any): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.OFFER}/change_status/${offerId}`, { status: status, rejectedNotes: datas });
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }


    public async getOfferDetails(offerId: string): Promise<IAPIReponse> {
        try {

            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER}/${offerId}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOfferTypes(page?: number, rowsPerPage?: number): Promise<IAPIReponse> {

        try {
            if (page) {
                const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER_TYPE}?status=true&pageNo=${page}&pageSize=${rowsPerPage}`);
                return data;
            }
            else {
                const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFER_TYPE}?status=true`);
                return data;
            }

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async createOfferType(offerTypeData: any): Promise<IAPIReponse> {

        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.OFFER_TYPE, offerTypeData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async updateOfferType(offerTypeData: any): Promise<IAPIReponse> {

        try {
            const { data } = await this.httpClient.put(API_ENDPOINTS.OFFER_TYPE, offerTypeData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOfferByCode(itemCode: string): Promise<IAPIReponse> {

        try {
            const { data } = await this.httpClient.get(COMMON_API.OFFER_DETAILS + '?itemCode=' + itemCode);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOfferEmi(itemCode: string, channel: string): Promise<IAPIReponse> {

        try {


            const { REACT_APP_API_URL = '' } = process.env;

            const { data } = await this.httpClient.get(`${REACT_APP_API_URL}${COMMON_API.OFFER_EMI_DETAILS}/${itemCode}`, {
                headers: {
                    "x-api-key": COMMON_API.OFFER_API_KEY || '',
                    channel: channel
                },
            },)

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async bulkProductUpload(file: any, id: any, offerType: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.BULK_PRODUCT_UPLOAD}/${id}/${offerType}`, file);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async comboBulkUpload(file: any, id: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.COMBO_BULK_UPLOAD}${id}`, file);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async freebiesBulkUpload(file: any, id: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.FREEBIE_BULK_UPLOAD}${id}`, file);
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }


    public async getAllAtributesForRuleEngine(): Promise<IAPIReponse> {

        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.ALL_ATTRIBUTE}`);
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}