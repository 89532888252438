import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../assets/icons/Close.svg"
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from 'components/Checkbox';
import noImageAvailable from 'assets/images/no-image.png';
import Pagination from 'components/Pagination';
import { appContext } from 'context/app.context';
import { PIMProductService } from "services/pimProduct.service";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss"
import NoProductsSad from "components/NoProductsSad";
import buffer from 'assets/buffer.gif';
import { ProductService } from "services/product.service";
import { CouponProductService } from "services/couponProduct.service";
import { STATUS } from "config/config";

type productProps = {
  setDialog: (data: boolean) => void
  type?: string
  getProductLists?: any,
  filter?: any
}

const ProductDialogbox: React.FC<productProps> = ({ setDialog, type, getProductLists, filter }) => {
  const pimService = new PIMProductService();
  const productService = new ProductService();
  const couponProductService = new CouponProductService();

  const { page, setPage, showToaster, selectedProducts, offerData, setSpinnerVisibility } = useContext(appContext);

  const [currentPage, setCurrentpage] = useState<number>(0)
  const [cssindex, setcssindex] = useState<string>('')
  const [brand, setBrand] = useState<string>('')
  const [products, setProducts] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [temporarySelectedProducts, setTemporarySelectedProduct] = useState<any>(selectedProducts || []);

  const [totalPageCount, setPagecount] = useState<number>(0)
  const [inputValue, setInputValue] = useState("");
  const [checkAll, setCheckall] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [allbrand, setAllbrand] = useState('')
  const [searchVal, setSearchVal] = useState('')
  const getCurrentpage = (currentpage: number) => {
    setCurrentpage(currentpage)
  }

  const getBrandList = async (): Promise<void> => {
    try {
      setSpinner(true)
      const { data } = await pimService.getBrandList();
      await brandOnChangeHandler(data?.brand[0]?.name)
      setSpinner(false)
      setBrandList(data?.brand);
      setBrand(data?.brand[0]?.name)
      setcssindex(data?.brand[0]?.name)
      setCurrentpage(1)
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to fetch Brand list");
    }
  };

  const productSelection = async (product: any): Promise<void> => {
    const existingIndex = temporarySelectedProducts?.findIndex((everyProduct: any) => everyProduct.erp_item_code === product.erp_item_code || everyProduct.itemCode === product.erp_item_code);
    let newColumns;


    /* If column already checked we need to uncheck on click action else we need to check the column */
    if (existingIndex >= 0) {
      const existingData = [...temporarySelectedProducts];
      existingData.splice(existingIndex, 1);
      newColumns = [...new Set(existingData)];
    } else {
      newColumns = [...temporarySelectedProducts, product];
    }
    setTemporarySelectedProduct(newColumns);
  }


  const addProductsHandler = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);

      temporarySelectedProducts?.forEach(async (product: any) => {

        const name = product?.name?.['en-IN'] ? product?.name?.['en-IN'] : product?.varientName;
        const erp_item_code = product?.erp_item_code ? product?.erp_item_code : product?.itemCode;
        const imageURL = product?.image?.all?.url ? product?.image?.all?.url : product?.imageURL;
        const minValue = "";
        const maxValue = "";
        const startDate = offerData.startDate;
        const endDate = offerData.endDate;
        const discountValue = offerData.discountValue;
        const model =  product?.model?.name?.['en-IN'] ? product?.model?.name?.['en-IN'] : product?.modelName;

        let { brand = {}, category = {}, cat_anc_codes = [] } = product;

        category.cat_anc_codes = [category.code, ...cat_anc_codes];

        const couponData = {
          varientName: name,
          modelName: model,
          itemCode: erp_item_code,
          imageURL: imageURL,
          startDate: offerData.validityStartDate,
          endDate: offerData.validityEndDate,
          discountValue: discountValue,
          category,
          brand
        }

        const  data= {
          varientName: name,
          modelName: model,
          itemCode: erp_item_code,
          imageURL: imageURL,
          minValue: minValue,
          maxValue: maxValue,
          startDate: startDate,
          endDate: endDate,
          discountValue: discountValue,
          category,
          brand
        }

        if (type === 'offers') {
          const apiResponse = await productService.addOfferProduct({ offerID: offerData._id, ...data });
          if (apiResponse.status === STATUS.FAILURE) {
            showToaster(EToasterVariants.error, `${erp_item_code} - ${apiResponse.message}`)
          }

        } else {
          const apiResponse = await couponProductService.addCouponProduct({ couponID: offerData._id, ...couponData });
          if (apiResponse.status === STATUS.FAILURE) {
            showToaster(EToasterVariants.error, `${erp_item_code} - ${apiResponse.message}`)
          }
        }

      });

      setTimeout(() => {
        getProductLists(offerData._id, filter);
      }, 1000);

      setPage(1);
      setDialog(false);
      setSpinnerVisibility(false);


    } catch (error) {
      console.log(error);

    }

  }

  const searchHandler = async (value: any) => {
    setSpinner(true)
    setInputValue(value);
    try {
      const { results } = await pimService.getProductByTypesense(value);
      setSpinner(false)
      const [data] = results;
      if (data?.found > 0) {
        const foundItems = data?.hits?.map((record: any) => {
          const { image_en_in = '', name = '', erp_item_code = '', model = '' } = record?.document;

          const item = {
            image: { all: { url: image_en_in } },
            name: { "en-IN": name },
            erp_item_code: erp_item_code,
            model: model
          }
          return item;
        });
        setProducts(foundItems);
      } else {
        setProducts([]);
      }
      setPagecount(data?.page || 0);
    } catch (err) {
      showToaster(EToasterVariants.error, "Unable to Search Products");
    }
  }

  const brandOnChangeHandler = async (brandId: string): Promise<void> => {
    try {
      setSpinner(true)
      const { data, pagecount } = await pimService.getProductsByBrand([brandId], page + 1);
      if (data) {
        setSpinner(false)
      }
      setPagecount(pagecount || 0)
      setProducts(data["productlisting"] ?? []);
      setCheckall(false)
    } catch (error) {
      showToaster(EToasterVariants.error, "Unable to fetch Brand list");
    }
  }

  useEffect(() => {
    getBrandList()
  }, [])

  useEffect(() => {
    const timedOut = setTimeout(() => {
      searchHandler(searchVal)
    }, 1000)
    return () => clearTimeout(timedOut)
  }, [searchVal])

  useEffect(() => {
    inputValue === "" && brand && brandOnChangeHandler(brand)
    inputValue.length > 0 && searchHandler(inputValue)
  }, [page])

  const selectAllProductsHandler = async (isAllSelected: boolean) => {
    setCheckall(isAllSelected);
    setAllbrand(brand)
    if (isAllSelected) {
      setTemporarySelectedProduct(products);
    } else {
      setTemporarySelectedProduct([]);
    }
  }

  return (
    <div className="bulk-uploader-result-page" >
      <div className="bulk-uploader-result">
        <div className="header-wrapper">
          <img alt="close-icon" className="close-icon" src={CloseIcon} onClick={() => setDialog(false)} />
          <div className="header-bar">
            <p>Add products</p>
          </div>
        </div>
        <div className="brand-wrapper">
          {!inputValue ? <div className="brand">Select Brand</div> : <div className="brand"><p className="string-line">Type More than 3 letters to start the search</p></div>}
          <div className="search-box1">
            <input style={{ "border": "none", "outline": "none" }} placeholder="Search brand or product" onChange={(e) => {
              if ((e.target.value).length === 0) {
                setPage(0)
                brandOnChangeHandler(brand)
              }
              setInputValue(e.target.value)
              if ((e.target.value).length > 2) {
                setSearchVal(e.target.value)
                setPage(0)
                setCurrentpage(1)
              }
            }
            } />
            <FontAwesomeIcon
              className="search-icon"
              icon={faSearch}
            />
          </div>
        </div>
        <div className="images-choose-product">
          {!inputValue &&
            <div className="brands-img-wrapper">
              {brandList?.map((product: any, index: number) => {
                return (
                  <div key={index} className={cssindex === product.name ? "active-brand-img" : "brand-img"} onClick={() => {
                    setBrand(product?.name)
                    brandOnChangeHandler(product?.name);
                    setPage(0)
                    setCurrentpage(1)
                    setcssindex(product?.name)
                  }}>
                    <img src={product?.image?.url ? product?.image?.url : noImageAvailable} alt="product" className="product-image" />
                    <p>{product?.name}</p>
                  </div>
                )
              })}
            </div>
          }
          <div className="choose-products">Choose Product</div>

          <div className="table">
            <table>
              <thead className="header-1">
                <th>
                  <Checkbox value={checkAll} onChange={selectAllProductsHandler} />
                </th>
                <th>Image</th>
                <th>ERP Item Code</th>
                <th>Variant Name</th>
              </thead>
              {spinner ?
                <tbody className="buffer-body">
                  <tr>
                    <td colSpan={4}>
                      <img src={buffer} alt="loader" className="animation-buffer" />
                    </td>
                  </tr>
                </tbody> :
                <tbody className="tbody">
                  {products?.length ? products?.map((product: any, index: number) => {
                    return <tr className="add-product-row" onClick={() => productSelection(product)} key={index}
                    >
                      <td className="products-data">
                        <Checkbox
                          value={
                            checkAll ? true : temporarySelectedProducts?.filter((prod: any) => prod.erp_item_code === product?.erp_item_code || prod.itemCode === product?.erp_item_code).length ? true : false}
                        />

                      </td>
                      <td className="products-data">
                        <img src={product?.image?.all?.url ? product?.image?.all?.url : noImageAvailable} alt="product" className="product-image" />
                      </td>
                      <th>{product?.erp_item_code}</th>
                      <td className="products-data">{product?.name?.['en-IN']}</td>
                    </tr>
                  })
                    : <NoProductsSad text={"No products Matches your Search"} coloumn={4} />}
                </tbody>}
            </table>

          </div>
        </div>
        <div className="button-wrapper">
          <button type="button" className="btn secondary" onClick={() => setDialog(false)}>
            Cancel
          </button>
          {products.length ?
            <button type="button" className="btn primary mr-2" onClick={() => {
              addProductsHandler();
            }}>
              Add
            </button> : <></>
          }
        </div>
        <div className="button-wrapper1">

          <button type="button" disabled={(currentPage === 0 || page === 0)} className={(currentPage === 0 || page === 0) ? "disabled-btn" : "next-btn"} onClick={() => {
            setPage(page - 1)
            setCurrentpage(currentPage - 1)
          }}>
            Previous
          </button>


          {Array(totalPageCount).fill(0).map((e, index) => {
            return (
              <Pagination pagecount={index + 1} index={index}
                getCurrentpage={getCurrentpage}
                key={index}
              />
            )
          })
          }

          <button type="button" disabled={(currentPage === totalPageCount)} className={(currentPage === totalPageCount) ? "disabled-btn" : "next-btn"} onClick={() => {
            setPage(page + 1)
            setCurrentpage(currentPage + 1)
          }}>
            Next
          </button>
        </div>
      </div>
    </div >
  )
}

export default ProductDialogbox;
