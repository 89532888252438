import appContext from "context/app.context";
import React, { useContext } from "react";
import OfferGroupForm from "../Forms/OfferGroupForm";

import "./styles.scss";
import AddOffersTab from './AddOffersTab/index';
import { RulesTab } from './RulesTab/index';
import { SubMenus } from "common/constant";

const AddOfferGroupPage: React.FC = () => {


    const { menuIndex, setMenuIndex } = useContext(appContext);
    return (
        <div className="offers-page">
            <div className="offers-page-heading">
                <h3 className="offers-page-heading-label">Add Offer Group</h3>
            </div>
            <div className="tabs">
                {SubMenus?.map((menu: string, index: number) => {
                    return (
                        <div
                            className={menuIndex === index ? "active" : ""}
                            
                            // onClick={() => setMenuIndex(index)}
                        >
                            <p>{menu}</p>
                        </div>
                    );
                })}
            </div>
            <div className="add-offers-page-content">
                {menuIndex === 0 && <div className="offer-info shadow"> <OfferGroupForm /></div>}
                {menuIndex === 1 && <div className="offer-info shadow"> <AddOffersTab /></div>}
                {menuIndex === 2 && <div className="offer-info shadow"> <RulesTab  viewOnlyMode={false}/></div>}
            </div>
        </div>
    );
};

export default AddOfferGroupPage;
