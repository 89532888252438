import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import React, { useEffect, useState } from "react";

import "./styles.scss";

type CheckboxProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  lable?: string,
  disabled?: boolean,
};

const Checkbox: React.FC<CheckboxProps> = ({ value, onChange, lable, disabled }) => {
  const [selected, setSelected] = useState<boolean>(false);
  const selectionHandler = () => {
    setSelected(!selected);
    if (onChange) {
      onChange(!selected)
    }
  };

  useEffect(() => {
    setSelected(value as boolean)
  }, [value])

  return (
    <div className={disabled ? "checkbox disabled" : selected ? "checkbox selected" : "checkbox"}>
      <p>{lable}</p>
      {disabled ?
        <div className={selected ? "box" : "box unSelected"}>
          <Icon icon={faCheck} />
        </div> : <div className="box" onClick={selectionHandler}>
          <Icon icon={faCheck} />
        </div>

      }

    </div>
  );
};

export default Checkbox;
