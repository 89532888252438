import { AxiosError } from "axios";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { BaseService } from "./htttp";
import { IAPIReponse } from "./interfaces/common.interface";


export class UserService extends BaseService {
    public async addUser(userData: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_USER, userData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getUserList(page: number, limit: number): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_USER_LIST}/${limit}/${page}/all`)


            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getUserDetails(id: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_USER_DETAILS}/${id}}`)


            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
    public async updateUser(userData: any): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.put(API_ENDPOINTS.UPDATE_USER, userData);

            return data
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

}