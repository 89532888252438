import { encodeGetParams } from "common/commonFunc";
import { BaseService } from "./htttp";
import { API_ENDPOINTS, parseAxiosError } from "config/config";
import { AxiosError } from "axios";
import { IAPIReponse } from "./interfaces/common.interface";

export class ProductService extends BaseService {
    public async getOffersProductsList(offerId = '', rowsPerPage = 10, pageNo = 1, filter: any = {}, startDate: any = "", endDate: any = "", sortName: any = "", sort: any = "", isAll = ""): Promise<IAPIReponse> {
        try {
            const { search = "", offerType = "", status = "", brand = "", category = "" } = filter;

            const newObject = {
                limit: rowsPerPage,
                page: pageNo,
                search,
                offerType,
                status,
                brand,
                category,
                startDate,
                endDate,
                sortName,
                sort,
                isAll
            }
            const queryParams = encodeGetParams(newObject);
            const { data } = await this.httpClient.get(`${API_ENDPOINTS.OFFERSPRODUCTS}/${offerId}?${queryParams}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async addOfferProduct(productData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.post(`${API_ENDPOINTS.OFFERSPRODUCTS}`, productData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async updateOfferProduct(offerId: string, productData: any): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.put(`${API_ENDPOINTS.OFFERSPRODUCTS}/${offerId}`, productData);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteOfferProduct(id: string): Promise<IAPIReponse> {

        try {

            const { data } = await this.httpClient.delete(`${API_ENDPOINTS.OFFERSPRODUCTS}/${id}`);
            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async deleteMultipleProduct(ids: [string], offerID: string): Promise<IAPIReponse> {
        try {
            const { data } = await this.httpClient.post(`${API_ENDPOINTS.MULTI_DELETE_PRODUCTS}`, { ids, offerID });
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }
}