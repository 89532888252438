import { financeMenus } from 'common/constant'
import appContext from 'context/app.context'
import HistorySideMenu from 'pages/HistoryPage'
import React, { useContext, useEffect } from 'react'
import FinanceList from './financeList'
import "./styles.scss"
import BulkUploadHistory from 'components/BulkUploadHistory'

const FinancePage: React.FC = () => {

  const { menuIndex, setMenuIndex } = useContext(appContext)

  useEffect(() => {
    setMenuIndex(0)
  }, [])

  return (
    <div className='financePage'>
      <div className='financeHeader'>
        <div className="tabs">
          {financeMenus?.map((menu: string, index: number) => {
            return (
              <div className={menuIndex === index ? "active" : ""} onClick={() => setMenuIndex(index)}>
                <p>{menu}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="finance-page-content">
        {menuIndex === 0 && <div className="offer-info shadow"> <FinanceList /></div>}
        {menuIndex === 1 && <div className="offer-info shadow"> <HistorySideMenu module="Nbfc" name="Finance History" /></div>}
        {menuIndex === 2 && <div className="offer-info shadow"> <BulkUploadHistory module="Nbfc" Id=''/></div>}

      </div>
    </div>
  )
}

export default FinancePage