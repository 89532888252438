import {  getProductPrice } from "common/commonFunc";
import appContext from "context/app.context";
import _ from "lodash";
import { useEffect, useState, useContext } from "react";
import { EToasterVariants } from "services/types/common.type";

interface ProductDetailsProps {
  product: any,
  getSelectedProduct: any
}

const ProductDetails = (props: ProductDetailsProps) => {

  const { product, getSelectedProduct } = props;
  const [image, setImage] = useState<string>("")
  const [price, setPrice] = useState<number>(0)
  const { showToaster } = useContext(appContext);

  const handleChangeVariant = (key: string, value: string) => {
    let variant = product?.variant?.attr;
    variant[key] = value;
    const selectedVariant = product.model.variants.find((item: any) => _.isEqual(item.attr, variant))
    if (!_.isEmpty(selectedVariant)) {
      getSelectedProduct(selectedVariant.erp_item_code)
    } else {
      showToaster(EToasterVariants.error, "Variant not found on Pim");

    }
  }

  useEffect(() => {
    try {
      if (product) {
        const { sp } = getProductPrice(product);
        setPrice(sp);
        setImage(product.gallery[0].media.url)
      }

    } catch (error) {
      console.log(error);

      setImage('')
    }
  }, [product])


  return (
    <div className="product-title product-UI">
      <div className="product-img">
        <img
          src={image}
          height="150"
          width="150"
          alt=""
        ></img>
      </div>
      <div className="product-content">
        <div className="item-name">{product?.name}</div>
        <div className="item-code">
          Item Code : {product?.erp_item_code}
        </div>
        {
          product?.model?.variant_attr_scheme?.map((item: any) => {
            return (
              <div>
                <div className="variant-title">
                  <h3>{item.name}</h3>
                </div>
                <div className="variant-attr">
                  {item.values.map((attr: any) => {
                    return (
                      <button onClick={() => handleChangeVariant(item.code, attr.code)} className={product?.variant?.attr[item.code] === attr.code ? 'variant-btn current-product' : 'variant-btn'} >
                        {attr?.image !== '' && <div className="variang-img-outer">
                          <div className="variang-img-inner">
                            <img alt="" className="variant-img" src={attr?.image} srcSet={attr?.image} />
                          </div>
                        </div>}

                        <label className="variant-btn-label">{attr.name}</label>
                      </button>
                    )

                  })}
                </div>
              </div>
            )
          })
        }

      </div>
      <div className="product-price">
        ₹ {price}
      </div>



    </div>
  );
}

export default ProductDetails;

