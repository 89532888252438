import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { generateFilters, generateRules } from "common/rulesEngine/rules.constants";
import DropDown from "components/DropDown";
import DynamicField from "components/DynamicField";
import Icon from "components/Icon";
import MultiSelect from "components/MultiSelect";
import { usePermission } from "components/Permission";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OffersService } from "services/offers.service";
import { EToasterVariants } from "services/types/common.type";
import _ from 'lodash';
import "./styles.scss";

type DynamicRulesFormProps = {
  viewOnlyMode?: boolean;
  autoPopulate?: boolean
};


const DynamicRulesForm: React.FC<DynamicRulesFormProps> = ({ viewOnlyMode, autoPopulate }) => {
  /* Form related hooks */
  const { register, handleSubmit, control, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({ name: "rule", control });


  /* Serives */
  const offersService = new OffersService();
  const navigateTo = useNavigate();
  const option1 = [
    "Greater Than",
    "Less Than",
    "Equals to",
    "Greater than or Equals",
    "Less than or Equals"
  ]
  const option2 = [
    "Equals to"
  ]
  const option3 = [
    "In"
  ]

  /* Context values */
  const {
    offerData,
    setSpinnerVisibility,
    showToaster,
    setOfferData,
    setSelectedProducts,
    setMenuIndex,
  } = useContext(appContext);

  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);
  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);

  const ruleInfoPermission = _.isEmpty(offerData) ? createOffersPermission : !viewOnlyMode && editOffersPermission;



  const [rulesCount, setRulesCount] = useState<any[]>([0]);
  const removeRulesHandler = async (deletedIndex: number): Promise<void> => {
    const existingRules = [...rulesCount];
    existingRules.splice(deletedIndex, 1);
    setRulesCount(existingRules);
    remove(deletedIndex);
  };

  const rulesHandler = (rule: any, index: number, i: number, viewOnlyMode?: boolean) => {
    let tempValue: any = []
    try {
      offerData.rules[index]?.forEach((e: any) => {
        if (e.name === rule.name) {
          tempValue = e.value
          setValue(`rule.${index}.${i}.${rule.name}`, tempValue)
        }
      })
    } catch (err) {
      console.log(err)
    }
    if (rule?.isLov && rule?.isMultiselect) {
      const dropDownValues = rule.lov.map(
        (lovValue: any, lovIndex: number) => ({
          ...lovValue,
          lovIndex,
        })
      );

      return (
        <MultiSelect
          disabled={!ruleInfoPermission}
          fieldName={rule.name}
          selectedValues={typeof (tempValue) === "string" ? [] : tempValue}
          fields={dropDownValues}
          register={register(`rule.${index}.${i}.${rule.name}`)}
          onChange={(selectedValues: any) => {
            setValue(`rule.${index}.${i}.${rule.name}`, selectedValues)
          }

          }
        />
      );
    }

    rule.value = tempValue
    rule?.value?.length > 0 && setValue(`rule.${index}.${i}.${rule.name}`, typeof (rule?.value) === "object" ? undefined : rule?.value)
    return (
      <DynamicField
        disabled={!ruleInfoPermission}
        values={rule}
        name={`rule.${index}.${i}.${rule.name}`}
        register={register(`rule.${index}.${i}.${rule.name}`)}
      />
    );
  };


  const goToPreviousFormHandler = () => {
    (offerData?.selectedOfferType?.offerValues === "Freebies" || offerData?.selectedOfferType?.offerValues === "ComboOffer") ? setMenuIndex(2) : setMenuIndex(1)
  }



  useEffect(() => {
    if (viewOnlyMode !== undefined) {
      append(offerData.rules)
    }
    else if (autoPopulate) {
      append({ name: "rule" })
    }
  }, []);

  /* Create Offers */
  const onSubmit = async (rules: any): Promise<void> => {
    const ruleNames: any = []
    offerData?.selectedOfferType?.rules?.filter((element: any) => ruleNames.push(element.name))
    let flag = false;

    rules?.rule?.forEach((rule: Object | [], index: number) => {
      if (_.isArray(rule)) {

        let innerFlag = false
        rule?.forEach((item: any) => {
          ruleNames?.forEach((ruleName: any) => {
            if (!_.isEmpty(item[ruleName]))
              innerFlag = true
          })
        })
        if (!innerFlag) {
          flag = true;
          return;
        }
      } else if (_.isObject(rule)) {
        let innerFlag = false
        let obj: any = rule;
        Object.keys(obj).forEach((key) => {

          ruleNames?.forEach((ruleName: any) => {
            if (!_.isEmpty(obj[key][ruleName]))
              innerFlag = true
          })
        })

        if (!innerFlag) {
          flag = true;
          return;
        }
      } else {
        flag = true;
        return;
      }


    })

    if (flag) {
      showToaster(EToasterVariants.error, 'Please fill or remove empty Rules');
      return;
    }
    let finalRule: any = []

    /* Need to review this part of code */
    rules.rule.forEach((element: any) => {
      const tempRule: any = []
      if (Array.isArray(element)) {
        element.forEach((x) => {
          ruleNames.find((e: any) => {
            if (_.has(x, e)) {
              x.name = e;
              x.operation !== "In" && (x.value = x[e])
              if (Object.keys(x).includes(x.name)) {
                (x.value = x[x.name])
                delete x[x.name]
              }
              tempRule.push(x)
            }
          })

        })
      }
      else {
        delete element.name
        for (let i = 0; i < Object.keys(element).length; i++) {
          const temp: any = {}
          temp.operation = element[i]?.operation
          delete element[i]?.operation
          if (ruleNames.find((e: any) => JSON.stringify(e) === JSON.stringify(Object.keys(element[i])[0]))) {
            temp.name = Object.keys(element[i])[0]
            temp.value = element[i][Object.keys(element[i])[0]]
            tempRule.push(temp)
          }
        }

      }
      finalRule = [...finalRule, tempRule]
    })



    try {
      const finalOfferData = {
        ...offerData,
        conditions: await generateRules(finalRule, offerData.channel, offerData.state),
        filters: await generateFilters(finalRule, offerData.channel, offerData.state),
        rules: finalRule
      };
      setSpinnerVisibility(true);
      const updateOfferResponse = await offersService.updateOffer(
        finalOfferData
      );
      if (updateOfferResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Offer Updated Successfully");
        setSpinnerVisibility(false);
        setOfferData({});
        setSelectedProducts([]);
        setMenuIndex(0);
        navigateTo("/offers/list");
      } else {
        setSpinnerVisibility(false);
      }
    } catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "Unable to create new offer");
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className="rules-page">
      <div className="header">
        <h3>Offer Claim Rules</h3>
        {ruleInfoPermission && !!(offerData?.selectedOfferType?.rules.length != 0) && <button
          className="btn primary"
          type="button"
          onClick={() => append({ name: "" })
          }
        >
          Add Additional Rule
        </button>}
      </div>
      {
        !!(offerData?.selectedOfferType?.rules.length != 0) ?
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { handleKeyDown(e) }}>
            {fields?.map((ruleGroup: any, index: number) => {
              return (
                <div className="rules-list" key={index}>
                  <div className={"dynamic-rules shadow"}>
                    <div className="fields">

                      {offerData?.selectedOfferType?.rules?.map((rule: any, i: number) => {
                        let tempValue: string = ""
                        try {
                          offerData.rules[index]?.forEach((e: any) => {
                            if (e.name === rule.name) {
                              tempValue = e.operation
                            }
                          })
                        } catch (err) {
                          console.log(err)
                        }
                        if (tempValue?.length > 0) {
                          const tempOptions: any = rule.isLov ? rule.isMultiselect ? option3 : option2 : option1
                          if (tempOptions?.find((e: any) => e === tempValue)) {
                            setValue(`rule.${index}.${i}.operation`, tempValue)
                          }
                          else {
                            setValue(`rule.${index}.${i}.operation`, tempOptions[0])
                          }
                        }
                        else {
                          setValue(`rule.${index}.${i}.operation`, rule.isLov ? rule.isMultiselect ? option3[0] : option2[0] : option1[0])
                        }
                        return (
                          <div className="rules-row">
                            <div className="dynamic-field">
                              <p>{rule.name} : </p>
                            </div>

                            <div className="dynamic-field">
                              <DropDown
                                disabled={!ruleInfoPermission}
                                options={rule.isLov ? rule.isMultiselect ? option3 : option2 : option1}
                                register={register(`rule.${index}.${i}.operation`)}
                                name={`rule.${index}.${i}.operation`}
                                label=""
                              />
                            </div>
                            <div className="dynamic-field">

                              {rulesHandler(rule, index, i, viewOnlyMode)}
                            </div>
                          </div>
                        );



                      })}
                    </div>
                    {ruleInfoPermission && <div className="actions">
                      <div
                        className="shadow"
                        onClick={() => removeRulesHandler(index)}
                      >
                        <Icon icon={faTrash} />
                      </div>
                    </div>}
                  </div>
                  {fields.length - 1 !== index && (
                    <div className="rules-divider">
                      <p>OR</p>
                    </div>
                  )}
                </div>
              );
            })}
            {ruleInfoPermission && <div className="buttons-wrapper">
              <button className="btn secondary" type="button" onClick={() => goToPreviousFormHandler()}>
                Previous
              </button>
              <button className="btn primary" type="submit">
                Save
              </button>
            </div>}
          </form>
          :
          <p className="text-center pb-2">Please configure rules in settings for this offer type.</p>
      }
    </div>
  );
};

export default DynamicRulesForm;
