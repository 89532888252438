import React, { useContext } from 'react'
import appContext from 'context/app.context';
import DynamicField from "components/DynamicField";
import MultiSelect from "components/MultiSelect";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { DiscountVariable } from "config/config";
import Icon from "components/Icon";
import InputField from "components/InputField";
import DropDown from "components/DropDown";
import {
  DiscountType,

} from "common/constant";
import { usePermission } from 'components/Permission';
import _ from 'lodash';
const OfferValueSection = (props: any) => {
  const {
    watch,
    viewOnlyMode,
    register,
    errors,
    setValue,
    permissionError,
    selectedOfferType
  } = props;

  const {
    setOfferData,
    offerData,
    userPermission,
  } = useContext(appContext);

  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

  const offerValuePermission = _.isEmpty(offerData) ? createOffersPermission : !viewOnlyMode && editOffersPermission;


  return (
    <div className="sub-section">
      <p className="section-heading">5. Offer Value
        {offerValuePermission &&
          <div className="tooltipContainer">
            <Icon icon={faCircleInfo} />
            <div>{permissionError}</div>
          </div>
        }
      </p>
      <div className="input-fields-wrapper">
        <DropDown
          disabled={!offerValuePermission}
          options={selectedOfferType?.offerTypes === 'TradeIn and Exchange' ? ["FlatDiscount"] : ["FlatDiscount", "Percentage"]}
          label="Discount Unit"
          name="discountUnit"
          register={register("discountUnit")}
        />
        <InputField
          disabled={!offerValuePermission}
          helpertext="Discount Value"
          name="discountValue"
          register={register("discountValue", {
            required: "Discount Value is Required",
            min: {
              value: 0,
              message: "Maximum allowed limit cannot be 0"
            },
            max: watch("discountUnit") === DiscountType.Percentage ? {
              value: 100,
              message: `Maximum allowed limit for ${watch(
                "discountUnit"
              )} is  crossed`,
            } : undefined,
          })}
          error={errors.discountValue?.message as any}
          type="number"
          step={0.01}

          mandatory
          hoverHandleText={watch("discountValue")}
        />
        {selectedOfferType?.values?.map((values: any) => {
          const isAllowe =
            userPermission &&
              Object.keys(userPermission).find(
                (value: any) => value === values.name
              )
              ? userPermission[values.name]
              : false;
          if (values?.isLov && values?.isMultiselect) {
            const dropDownValues = values.lov.map(
              (lovValue: any, index: number) => ({ ...lovValue, index })
            );

            return (
              <MultiSelect
                selectedValues={watch(values.name)}
                disabled={!offerValuePermission}
                fieldName={values.name}
                fields={dropDownValues}
                register={register(values.name, {
                  required: `${values.name} is Required`
                })}
                onChange={(selectedValues: any) =>
                  setValue(values.name, selectedValues)
                }
                error={errors[values.name]?.message as any}
              />
            );
          }
          if (values.name === DiscountVariable.discountType) {
            if (watch(values.name) === DiscountVariable.flatDiscount) {
              offerData.discountType = 1;
              setOfferData(offerData);
            } else {
              offerData.discountType = 2;
              setOfferData(offerData);
            }
          }
          return (
            <DynamicField
              disabled={!offerValuePermission}
              values={values}
              name={values.name}
              register={register(values.name, {
                required: `${values.name} is Required`
              })}
              error={errors[values.name]?.message as any}
            />
          );
        })}
      </div>
    </div>
  )
}

export default OfferValueSection