import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Icon from "components/Icon";
import ProductOfferList from "components/ShowOfferTabs/ProductOfferList";
import { STATUS } from "config/config";
import appContext from "context/app.context";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { OffersService } from "services/offers.service";
import { PIMProductService } from "services/pimProduct.service";
import { RuleEngineService } from "services/rulesEngine.service";
import { EToasterVariants } from "services/types/common.type";
import ProductDetails from "./ProductDetails";
import SideMenuOffer from "./SideMenuOffer";
import "./styles.scss"
import OfferChannel from "./OfferChannel";

type ProductViewProps = {
  index: number
  title: any
  setTitle: any
}

interface IProduct {
  name: string;
  erp_item_code: string;
}
const ProductView = (props: ProductViewProps) => {
  const [options, setOptions] = React.useState<readonly IProduct[]>([]);

  const [product, setProduct] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [offersList, setOffersList] = useState<any>([])
  const [offerGroup, setOfferGroup] = useState<any>([])
  const [filteredList, SetFilteredOffers] = useState<any>([])
  const [emiList, setEmiList] = useState<any>([])
  const [offersFilter, setOffersFilter] = useState<any>({ filters: [], startDate: "", endDate: "" })
  const [offersFilterUpdate, setOffersFilterUpdate] = useState<any>({})
  const [activeOffersFilterOptions, setActiveOffersFilterOptions] = useState<any>({})
  const [editMode, setEditMode] = useState<boolean>(false);
  const [channel, setChannel] = React.useState("Shop");
  const [open, setOpen] = React.useState(false);

  /* Context */
  const { setSpinnerVisibility, offersFilterOptions, setOffersFilterOptions } = useContext(appContext);

  const loading = open && options.length === 0;


  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);
  const { showToaster } = useContext(appContext);

  const { index, title, setTitle } = props;

  const pimservice = new PIMProductService();
  const ruleEngineService = new RuleEngineService();
  const offerListService = new OffersService();


  /* Getting List of Filters  */
  const getFilters = async (itemCode: string): Promise<void> => {
    const filterOptions = await ruleEngineService.getFilters(itemCode, channel);
    if (filterOptions.status === "success") {
      setOffersFilterOptions(filterOptions?.data);
    }
  }


  const getOfferList = async (itemCode: string) => {
    if (itemCode) {
      const offer_data = await ruleEngineService.getFilteredOffers(offersFilter, itemCode, channel);
      setActiveOffersFilterOptions(offer_data?.data?.activeFilterOptions)
      let offers_details = offer_data.data.offerList;
      offers_details = _.orderBy(offers_details, ["maxBenefites"], ['desc']);
      setOffersList(offers_details);
      setOfferGroup(offer_data.data.offergroupList);
    }

  }

  const getEMIList = async (itemCode: string): Promise<void> => {
    const couponListResponse = await offerListService.getOfferEmi(itemCode, channel)
    if (couponListResponse.status === STATUS.SUCCESS) {
      const { data: { financeModelList = [] } } = couponListResponse;

      if (financeModelList !== undefined) {
        setEmiList(financeModelList)
      }


    } else {
      showToaster(EToasterVariants.error, "Unable to get FinanceModelList");
    }
  };

  const sliderVisibilityHandler = () => {
    setSliderVisibility(!sliderVisibility);
    setEditMode(false);
  };


  useEffect(() => {

    if (!_.isEmpty(product)) {
      getOfferList(product?.erp_item_code);
    }
  }, [offersFilter]);

  useEffect(() => {
    SetFilteredOffers(offersList)
  }, [offersList]);

  const getSelectedProduct = async (erp_item_code: any) => {
    getOfferList(erp_item_code)
    getEMIList(erp_item_code);
    getFilters(erp_item_code)

    try {
      setSpinnerVisibility(true);
      const { data: { product = {} } } = await pimservice.getVariantProduct(erp_item_code, channel);
      if (!_.isEmpty(product)) {
        let title_add = [...title]
        title_add[index] = product.name;
        setTitle(title_add)
        setProduct(product);
        setSelectedProduct(product);
        setSpinnerVisibility(false);
      }
      else {
        showToaster(EToasterVariants.error, "Product not configured correctly on Pim");
        setSpinnerVisibility(false);

      }

    } catch (err) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "Product not found on Pim");
    }
  }

  const onSearchProduct = async (newInputValue: string) => {
    setOptions([]);
    try {
      const products: any = await pimservice.getProductByTypesense(newInputValue);
      const newArray: any = _.get(products, "results[0].hits", []);
      setOptions(newArray.map((item: any) => ({ name: item.document.name, erp_item_code: item.document.erp_item_code })));
    } catch (err) {
      console.log(err);
    }
  }


  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);





  return (
    <div className="product-inner">


      <Autocomplete
        id="asynchronous-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={async (event: any, item: any) => {
          if (!_.isEmpty(item))
            getSelectedProduct(item.erp_item_code)
        }}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={options}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            label="Search Product Name or Item Code"
            variant="outlined"
            onChange={ev => {
              if (ev.target.value !== "" || ev.target.value !== null) {
                onSearchProduct(ev.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {!_.isEmpty(product) && (
        <div className="view-content">
          <div className="filter-btn">
            <button type="reset" onClick={sliderVisibilityHandler}>
              <Icon icon={faFilter} /> Filter
            </button>
          </div>

          {sliderVisibility && (
            <><div className={`sidebar-overlay ${sliderVisibility && "active"}`} onClick={sliderVisibilityHandler}></div>
              <div
                className={`slider-left ${sliderVisibility ? "show" : "hide"}`}
              >
                <div className="add-product-slider">
                  <div className="slider-header">
                    <div className="filter-head">
                      <h4>Filters</h4>
                    </div>
                    <div
                      className="action-icon filter-icon"
                      onClick={sliderVisibilityHandler}
                    >
                      <Icon icon={faXmark} />
                    </div>
                  </div>
                </div>
                <div className={`sidemenu-wrp ${sliderVisibility && "active"}`}>
                  <SideMenuOffer
                    offersFilterOptions={offersFilterOptions}
                    setOffersFilterOptions={setOffersFilterOptions}
                    offersFilter={offersFilter}
                    activeOffersFilterOptions={activeOffersFilterOptions}
                    setOffersFilter={setOffersFilter}
                    setOffersFilterUpdate={setOffersFilterUpdate}
                    offersFilterUpdate={offersFilterUpdate}
                    offerList={offersList}
                    SetFilteredOffers={SetFilteredOffers}
                    filteredOffer={filteredList}
                    mobileToggle
                    setMobileToggle />
                </div>
              </div></>
          )}

          <div className="sidemenu-wrp">
            <SideMenuOffer
              offersFilterOptions={offersFilterOptions}
              setOffersFilterOptions={setOffersFilterOptions}
              offersFilter={offersFilter}
              activeOffersFilterOptions={activeOffersFilterOptions}
              setOffersFilter={setOffersFilter}
              setOffersFilterUpdate={setOffersFilterUpdate}
              offersFilterUpdate={offersFilterUpdate}
              offerList={offersList}
              SetFilteredOffers={SetFilteredOffers}
              filteredOffer={filteredList}
              mobileToggle
              setMobileToggle />
          </div>

          <div className="view-product">
            <OfferChannel
              channel={channel}
              setChannel={setChannel}
              product={product}
              getSelectedProduct={getSelectedProduct} />
            <ProductDetails product={product} getSelectedProduct={getSelectedProduct} />
            {
              filteredList?.length > 0 && <ProductOfferList channel = {channel} product={product} offerGroup={offerGroup} emiList={emiList} offerList={filteredList} itemName={product?.name?.['en-IN']} />
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ProductView);
