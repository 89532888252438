import "./styles.scss"
import CloseIcon from "../../assets/icons/Close.svg"
import AddFile from "../../assets/icons/add-file.svg"
import { useContext, useEffect, useRef, useState } from "react"
import { EToasterVariants } from "services/types/common.type"
import { OffersService } from "services/offers.service"
import appContext from "context/app.context"
import { CouponsService } from "services/coupon.service"
import { STATUS } from "config/config"

type TProps = {
    setBulkVisibility: (a: boolean) => void
    importVarient?: string
    offerTemplate?: string
    offerType?: string
}

export const BulkProductUpload: React.FC<TProps> = ({ setBulkVisibility, importVarient, offerTemplate, offerType }) => {

    const [file, setFile] = useState<File>()
    const [isCompleted, setIsCompleted] = useState<boolean>(false)
    const { showToaster, setSpinnerVisibility, offerData, setOfferData, setSelectedProducts, selectedProducts = [] } = useContext(appContext);
    const offersService = new OffersService();
    const couponsService = new CouponsService();
    const [dialogMode, setDialogMode] = useState<string>("READY_TO_UPLOAD");

    const onClickOutsideListener = () => {
        // setBulkVisibility(false)
        document.removeEventListener("click", onClickOutsideListener)
    }
    const drop: any = useRef(null);
    const input: any = useRef(null);


    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;

        if (files && files.length && !isCompleted) {
            setFile(files[0])
        }
    };

    useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);

        return () => {
            drop?.current?.removeEventListener('dragover', handleDragOver);
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    }, []);


    const uploadActionHandler = async (event: any) => {
        try {
            if (!!event.taget?.files) {
                return
            }

            setSpinnerVisibility(true);
            const uploadfile = new FormData();
            var uploadResult;

            uploadfile.append("uploadfile", event.target?.files[0], event.target?.files[0].name);

            if (importVarient === 'couponAssign')
                uploadResult = await couponsService.bulkAssignUpload(uploadfile, offerData._id);
            else if (importVarient === "coupons") {
                uploadResult = await couponsService.bulkProductUpload(uploadfile, offerData._id);
            }
            else if (importVarient === "combo") {
                uploadResult = await offersService.comboBulkUpload(uploadfile, offerData._id);
            } else if (importVarient === "freebies")
                uploadResult = await offersService.freebiesBulkUpload(uploadfile, offerData._id);
            else {
                let offerVal = offerTemplate;
                if (offerType === 'TradeIn and Exchange')
                    offerVal = 'TradeInExchange';
                else if (offerType === 'Offer Text')
                    offerVal = 'OfferText'

                uploadResult = await offersService.bulkProductUpload(uploadfile, offerData._id, offerVal);

            }

            if (uploadResult.status === STATUS.SUCCESS) {
                if (importVarient !== 'couponAssign') {
                    const offerDataResult = { ...uploadResult.data.offerData, products: uploadResult.data.product, discountType: offerData.discountType, selectedOfferType: offerData.selectedOfferType }
                    setSelectedProducts([...selectedProducts, ...uploadResult?.data?.product]);
                    setOfferData(offerDataResult);
                }

                showToaster(EToasterVariants.success, "File Uploaded Successfully, Further details check history");
            }
            else if (uploadResult.status === STATUS.FAILURE)
                showToaster(EToasterVariants.error, uploadResult.error.response.data.message);
            setBulkVisibility(false);
            setSpinnerVisibility(false);

        } catch (error: any) {
            setSpinnerVisibility(false);
            showToaster(EToasterVariants.error, error.message || "Unable to upload the document");
        }
    }



    return (
        <div className="bulk-uploader-page" onMouseEnter={() => {
            document.addEventListener("click", onClickOutsideListener)
        }}>
            <div className="bulk-uploader" ref={drop} onMouseLeave={() => {
                document.addEventListener("click", onClickOutsideListener)
            }} onMouseEnter={() => {
                document.removeEventListener("click", onClickOutsideListener)
            }}>
                <img alt="close-icon" className="close-icon" src={CloseIcon} onClick={() => setBulkVisibility(false)} />
                <div className="header-bar">
                    <p>File Upload</p>
                </div>
                <div className="header-body">
                    {(dialogMode === "READY_TO_UPLOAD") && <div className="drag-and-drop" onClick={() => input.current.click()}>
                        <div className="csv">
                        </div>
                        <input type="file" accept=".csv, .xlsx" disabled={isCompleted} style={{ display: "none" }} ref={input} onChange={(event: any) => uploadActionHandler(event)} />
                        <img alt="addfile" src={AddFile} />
                        <p>Drag and drop or <span style={{ color: "#FB6C00", cursor: "pointer" }}>browse</span> your files (.csv, .xlsx)</p>
                    </div>}



                </div>
            </div>
        </div >
    )
}