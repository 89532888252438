import { publishMenus } from 'common/constant'
import appContext from 'context/app.context'
import React, { useContext, useEffect } from 'react'
import PublishList from './PublishList'
import "./styles.scss"

const PublishPage: React.FC = () => {

  const { menuIndex, setMenuIndex } = useContext(appContext)

  useEffect(() => {
    setMenuIndex(0)
  }, [])

  return (
    <div className='financePage'>
      <div className='financeHeader'>
        <div className="tabs">
          {publishMenus?.map((menu: string, index: number) => {
            return (
              <div className={menuIndex === index ? "active" : ""} onClick={() => setMenuIndex(index)}>
                <p>{menu}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="finance-page-content">
        {menuIndex === 0 && <div className="offer-info shadow"> <PublishList /></div>}

      </div>
    </div>
  )
}

export default PublishPage