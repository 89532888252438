import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "components/InputField";
import appContext from "context/app.context";
import React, { useContext, useState } from "react";
import { EToasterVariants } from "services/types/common.type";

import "./style.scss";

const ComponentsPage: React.FC = () => {

  const [sliderVisibility, setSliderVisibility] = useState<boolean>(false);

  const { showToaster, setSpinnerVisibility } = useContext(appContext);

  return (
    <div className="components-page">
      <p className="title-text">
        This page is created for component reference purpose
      </p>
      <div className="contents">
        <div className="content-wrapper">
          <p className="sub-heading"> Headers</p>
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
        </div>
        <div className="content-wrapper">
          <p className="sub-heading">Buttons</p>

          <button className="btn primary"> Primary </button>
          <button className="btn secondary"> Secondary </button>
          <button className="btn disabled"> Disabled </button>
        </div>
        <div className="content-wrapper">
          <p className="sub-heading">Text Fields</p>
          <input className="textField" type="text" placeholder="Place Holder" />
          <input
            className="textField"
            disabled
            type="text"
            placeholder="Place Holder"
          />
          <select className="selection" name="Brand" id="Brand">
            <option value="Nokia">Nokia</option>
            <option value="Samsung">Samsung</option>
            <option value="RealMe">RealMe</option>
            <option value="Redmi">Redmi</option>
          </select>

         <InputField helpertext="Place Holder"/>
         <br />
         <InputField helpertext="Error Field" error={"error Text"}/>
         <p className="error-text">Error Text</p>
          </div>

        <div className="content-wrapper">
          <p className="sub-heading">Popups & Dialogs</p>
          <button
            className="btn primary"
            onClick={() => setSliderVisibility(!sliderVisibility)}
          >
            {" "}
            Add Product{" "}
          </button>
          <button
            className="btn secondary"
            onClick={() => setSpinnerVisibility(true)}
          >
            {" "}
            Show Spinner{" "}
          </button>
        </div>
       
        <div className={`slider-right ${sliderVisibility ? "show" : "hide"}`}>
          <div className="add-product-slider">
            <div className="slider-header">
              <h1>Add Product</h1>
              <FontAwesomeIcon
                className="action-icon"
                icon={faXmark}
                onClick={() => setSliderVisibility(!sliderVisibility)}
              />
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <p className="sub-heading">Toast Messages</p>
          <button
            className="btn primary"
            onClick={() =>
              showToaster(EToasterVariants.success, "Success Message")
            }
          >
            {" "}
            Success Toast{" "}
          </button>
          <button
            className="btn secondary"
            onClick={() =>
              showToaster(EToasterVariants.error, "Failure Message")
            }
          >
            {" "}
            Error Toast{" "}
          </button>
          <button
            className="btn secondary"
            onClick={() =>
              showToaster(EToasterVariants.warning, "Warning Message")
            }
          >
            {" "}
            Warning Toast{" "}
          </button>
        </div>
      </div>
    </div>
   
  );
};

export default ComponentsPage;
