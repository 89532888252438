import CouponType from 'components/CouponType';
import appContext from 'context/app.context';
import React, { useState,useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import "./styles.scss";

let CouponCategory = [
{name:'Total Card Value', value:'(1 lakh)' },
{name:'Premium Product', value:'Offers' }
  ]


function CouponCategoryPage() {

    let navigate = useNavigate();

    const [selectedCouponCategory, setSelectedCouponCategory] =useState(CouponCategory[0]);

      /* Context  */
  const {  setMenuIndex, setOfferData, setSelectedProducts } = useContext(appContext);

    const handleChangeCouponCategory =(type:any)=>{
        setSelectedCouponCategory(type)
    }

    return (
        <div className="coupons-category">
            <div>
                <div className="coupons-category-header">
                    <h3>Choose Coupon Category</h3>
                </div>
                <div className='couponCardContainer'>
                    {CouponCategory?.map((type)=>{
                        return (
                            < CouponType
                            label={type?.name}
                            value={type?.value}
                            active={selectedCouponCategory.name === type.name}
                            onClick={() => handleChangeCouponCategory(type)}
                            />
                        )
                    })}
                </div>
            </div>
            <button className="btn primary submitBtn" type="submit" 
             onClick={() => {
                navigate("/offers/coupons/add")
                setMenuIndex(0)
                setSelectedProducts([]);
                setOfferData({});
              }}
            >
                 Continue
                </button>
        </div>
    )
}

export default CouponCategoryPage