import React, { useContext, useEffect, useRef, useState } from "react";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FileUploadService } from "services/fileupload.service";
import { STATUS } from "config/config";
import { OffersService } from "services/offers.service";
import { useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../redux/store";
import { RolesService } from "services/roles.service";
import { usePermission } from "components/Permission";
import { generateFilters, generateRules } from "common/rulesEngine/rules.constants";
import ChannelSection from "./ChannelSection";
import InfoSection from "./InfoSection";
import TradeInAndExchange from "./TradeInAndExchange";
import FinanceSection from "./FinanceSection";
import OfferValueSection from "./OfferValueSection";
import DocumentsSection from "./DocumentsSection";
import BannerInfoSection from "./BannerInfoSection";
import ShowOffersSection from "./ShowOffersSection";
import { OfferType } from "common/constant";

type OffersInfoFormProps = {
  viewOnlyMode?: boolean;
};

const editorConfig = { extraPlugins: "colorbutton,colordialog, font" };

const OffersInfoForm: React.FC<OffersInfoFormProps> = ({ viewOnlyMode }) => {
  const navigateTo = useNavigate();
  const location = useLocation();

  const userRole = useSelector<RootStore>(
    (state) => state.userDetails.userDetails.userRole
  );
  /* Context functionality*/
  const permissionError = "You don't have a permission"
  /* Permission checcking */
  const [editOfferPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOfferPermission] = usePermission(["CREATE_OFFERS"]);

  const {
    showToaster,
    setOfferData,
    setMenuIndex,
    setSpinnerVisibility,
    offerData,
    setUserPermission,
  } = useContext(appContext);

  /* Form related hooks */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
    control,
    setFocus,
    clearErrors
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    name: "reimbursement",
    control,
  });

  const [files, setFiles] = useState<any>([]);
  // const [discountUnit, setDiscountUnit] = useState<string>("Percentage");
  const [existingOfferValues, setExistingOfferValues] = useState({});
  const [offerDetails, setOfferDetails] = useState({});
  /* Text Editor Visibility */
  const [textEditorVisibility, setTextEditorVisibility] =
    useState<boolean>(true);
  const [dynamicText, setDynamicText] = useState<string>('{{Discount Amount}}');

  /* Services */
  const rolesService: RolesService = new RolesService();
  const fileUploadService = new FileUploadService();
  const offersService = new OffersService();
  const [toggleError, setToggleError] = useState<any>({});
  const [reimbursementCount, setReimbursementCount] = useState<any[]>([0]);
  const [type, setType] = useState<string>("file");
  const [endDateErr, setEndDateErr] = useState<boolean>(false);
  const [selectedOfferType, setSelectedOfferType] = useState<any>();

  const ckeditorDiscriptionRef = useRef<HTMLDivElement>(null);
  const ckeditorFinanceRef = useRef<HTMLDivElement>(null);
  const ckeditorTACRef = useRef<HTMLDivElement>(null);

  const [ckeditorDiscriptionVisiblity, setCkeditorDiscriptionVisiblity] = useState(false)
  const [ckeditorFinanceVisiblity, setCkeditorFinanceVisiblity] = useState(false)
  const [ckeditorTACVisiblity, setckeditorTACVisiblity] = useState(false)
  const [offerTypesList, setOfferTypesList] = useState([]);

  useEffect(() => {
    initializer();
  }, []);

  /* Need to reinitialize the text editor visbility to bind the exisiting data from API*/
  const reinitializeTextEditor = async (): Promise<void> => {
    setTextEditorVisibility(false);
    setTimeout(() => {
      setTextEditorVisibility(true);
    }, 500);
  };

  /* Fetching existing offer Details */
  const getOfferDetails = async (): Promise<void> => {
    try {
      setSpinnerVisibility(true);
      let id = "";
      if (offerData?._id) {
        id = offerData?._id;
      } else {
        id = location.pathname.split("/")[3];
      }

      const offerDetailsResponse = await offersService.getOfferDetails(id);
      if (offerDetailsResponse.status === STATUS.SUCCESS) {
        setSpinnerVisibility(false);
        const offerDataResult = {
          ...offerDetailsResponse.data.offerData,
          products: offerDetailsResponse.data.product,
        };
        setOfferData(offerDataResult);
        setOfferDetails(offerDataResult);

        /* Form Binding Actions */
        const {
          offerId,
          offerName,
          offerType,
          exclusivity,
          startDate,
          endDate,
          status,
          offerDescription,
          channel,
          state,
          region,
          storeId,
          sponsor,
          sponsorID,
          exSchemeID,
          glCode,
          values,
          termsConditions,
          financeNotes,
          documents,
          reimbursement,
          tradeIn = [],
          chargesAndDeductions,
          chargesAndDeductionsValue,
          GSTInvoice,
          TDSApplicablity,
          discountUnit,
          discountValue,
          bannerFieldTypes,
          bannerDiscription,
          taxOption,
          addBenefits,
          reduceEffective,
          bannerEnable,
          offerSelection
        } = offerDetailsResponse.data.offerData;

        /* Offer Info */
        setValue("offerId", offerId);
        setValue("offerName", offerName);

        setValue("exclusivity", exclusivity);
        setValue("startDate", startDate);
        setValue("endDate", endDate);
        setValue("status", status);
        setValue("offerDescription", offerDescription);

        /* Show Offer Settings */
        setValue("addBenefits", addBenefits);
        setValue("reduceEffective", reduceEffective);
        setValue("bannerEnable", bannerEnable);
        setValue("offerSelection", offerSelection);

        /* Market */
        setValue("channel", channel);
        setValue("state", state);
        setValue("region", region);
        setValue("storeId", storeId);

        /* Offer Finance */
        setValue("sponsor", sponsor);
        sponsor?.map((element: any) => {
          setValue(`${element.name.toLowerCase()}Share`, element.share);
          return
        });
        setValue("sponsorID", sponsorID);
        setValue("exSchemeID", exSchemeID);
        setValue("glCode", glCode);
        setValue("financeNotes", financeNotes);
        setValue("reimbursement", reimbursement);
        setValue("tradeIn", tradeIn);
        setValue("GSTInvoice", GSTInvoice);
        setValue("TDSApplicablity", TDSApplicablity);
        setValue(
          "chargesAndDeductions",
          chargesAndDeductions ? chargesAndDeductions : "None"
        );
        setValue("chargesAndDeductionsValue", chargesAndDeductionsValue);
        setValue("taxOption", taxOption)

        /* Offer Value */
        // setDiscountUnit(discountUnit);
        setValue("discountUnit", discountUnit);
        setValue("discountValue", discountValue);
        setExistingOfferValues(values);

        /* banner Fields */
        setValue("bannerFieldTypes", bannerFieldTypes);
        setValue("bannerDiscription", bannerDiscription)
        /* Documents */
        setValue("termsConditions", termsConditions);
        setFiles(documents)

        reinitializeTextEditor();
      } else {
        setSpinnerVisibility(false);
        navigateTo("/offers/list");
        showToaster(EToasterVariants.error, "Unable to get Offers details");
      }
    } catch (error) {
      setSpinnerVisibility(false);
      navigateTo("/offers/list");
      showToaster(EToasterVariants.error, "Unable to get Offers details");
    }
  };

  const initializer = async (): Promise<void> => {
    /* Offer View mode handling */
    if (viewOnlyMode === false || viewOnlyMode === true || offerData?._id) {
      await getOfferDetails();
      userRole && (await getRolesPermission(userRole as string));
      return;
    }

    setValue("offerId", String(Math.floor(Math.random() * 900000) + 100000));
    setValue("status", "Draft");
    setValue("discountUnit", "Percentage");
    append({ name: "" });
  };

  /* Form submission Handler */
  const onSubmit = async (offerInfovalues: any): Promise<void> => {

    if (endDateErr) {
      showToaster(
        EToasterVariants.error, "End date should be greather than start date")
      return
    }

    let total = 0;
    if (
      offerInfovalues["sponsor"]?.length === 0 ||
      offerInfovalues["sponsor"] === undefined
    ) {
      const temp = toggleError;
      temp.sponser = true;
      setToggleError(temp);
      return;
    }
    offerInfovalues["sponsor"]?.map((element: any) => {
      total =
        total + parseInt(offerInfovalues[`${element.name.toLowerCase()}Share`]);
    });
    if (total !== 100) {
      const temp = toggleError;
      temp.percentage = true;
      setToggleError(temp);
      return;
    }
    /* Date Related Validations */
    const startDate = moment(offerInfovalues.startDate);
    const endDate = moment(offerInfovalues.endDate);
    if (!offerInfovalues.startDate) {
      showToaster(
        EToasterVariants.error,
        "Start Date is required"
      )
      return
    } else if (!offerInfovalues.endDate) {
      showToaster(
        EToasterVariants.error,
        "End Date is required"
      )
      return
    } else if (endDate < startDate) {
      showToaster(
        EToasterVariants.error,
        "Offer End Date should be greather than start Date"
      );
      return;
    }

    try {
      setSpinnerVisibility(true);
      const values = selectedOfferType?.values?.map((value: any) => {
        return { ...value, value: offerInfovalues[value.name] };
      });
      const share = offerInfovalues?.sponsor?.map((value: any) => {
        return {
          ...value,
          share: offerInfovalues[`${value.name.toLowerCase()}Share`],
        };
      });
      offerInfovalues.sponsor = share;
      if (viewOnlyMode === undefined && offerData._id === undefined) {
        const createOfferResponse = await offersService.createNewOffer({
          ...offerInfovalues,
          values: values,
        });
        if (createOfferResponse.status === STATUS.SUCCESS) {
          showToaster(
            EToasterVariants.success,
            createOfferResponse?.message
          );
          setSpinnerVisibility(false);
          const offerDataResult = {
            ...createOfferResponse.data.offerData,
            discountType: offerInfovalues.discountType,
            selectedOfferType: selectedOfferType,
          };
          setOfferData(offerDataResult);
          setMenuIndex(1);
        } else {
          showToaster(
            EToasterVariants.success,
            "New offer created Successfully"
          );
          setSpinnerVisibility(false);
        }
      } else {
        const discountType = offerData.discountType

        const finalData = {
          _id: offerData["_id"],
          ...offerInfovalues,
          values: values,
          publish: offerData.publish ,
          rules: offerData.rules,
          conditions: await generateRules(offerData.rules, offerInfovalues.channel, offerInfovalues.state),
          filters: await generateFilters(offerData.rules, offerInfovalues.channel, offerInfovalues.state),
          products: offerData.products,
          comboOffers: offerData.comboOffers,
          comboAll: offerData.comboAll,
          comboType: offerData.comboType,
        }
        const updateOfferResponse = await offersService.updateOffer(finalData);
        if (updateOfferResponse.status === STATUS.SUCCESS) {
          showToaster(EToasterVariants.success, "Offer Updated Successfully");
          setSpinnerVisibility(false);
          const offerDataResult = {
            ...updateOfferResponse.data.offerData,
            discountType: discountType,
            products: updateOfferResponse?.data?.product,
            selectedOfferType: selectedOfferType,
          };
          setOfferData(offerDataResult);
          setMenuIndex(1);
        } else {
          setSpinnerVisibility(false);
          showToaster(EToasterVariants.error, updateOfferResponse?.error?.response?.data?.message);
        }
      }
    } catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "Unable to create new offer");
    }
    // setMenuIndex(1);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const removeRulesHandler = async (deletedIndex: number): Promise<void> => {
    const existingRules = [...reimbursementCount];
    existingRules.splice(deletedIndex, 1);

    setReimbursementCount(existingRules);
    remove(deletedIndex);
  };

  const getRolesPermission = async (data: string): Promise<void> => {
    try {
      const getPermission = await rolesService.getRolesPermission(data);
      const permissionData = getPermission?.data[0];
      setUserPermission(permissionData?.Permission);
    } catch { }
  };

  const handleError = (index: number, field: string) => {

    const tempError: any = errors
    if (tempError?.reimbursement && tempError?.reimbursement[index]) {
      return tempError?.reimbursement[index][field]?.message
    }

  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ckeditorDiscriptionRef.current && !ckeditorDiscriptionRef?.current?.contains(event.target)) {
        setCkeditorDiscriptionVisiblity(false)
      }
      else if (ckeditorFinanceRef.current && !ckeditorFinanceRef?.current?.contains(event.target)) {
        setCkeditorFinanceVisiblity(false)
      }
      else if (ckeditorTACRef.current && !ckeditorTACRef?.current?.contains(event.target)) {
        setckeditorTACVisiblity(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ckeditorDiscriptionRef, ckeditorFinanceRef, ckeditorTACRef]);


  return (
    <div className="offers-info-form" id="offerinfoForm">
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { handleKeyDown(e) }} autoComplete="off">
        <InfoSection
          watch={watch}
          viewOnlyMode={viewOnlyMode}
          register={register}
          errors={errors}
          setValue={setValue}
          setFocus={setFocus}
          permissionError={permissionError}
          offerData={offerData}
          setOfferData={setOfferData}
          selectedOfferType={selectedOfferType}
          setSelectedOfferType={setSelectedOfferType}
          offerTypesList={offerTypesList}
          setOfferTypesList={setOfferTypesList}
          setDynamicText={setDynamicText}
          setEndDateErr={setEndDateErr}
          endDateErr={endDateErr}
          ckeditorDiscriptionVisiblity={ckeditorDiscriptionVisiblity}
          setCkeditorDiscriptionVisiblity={setCkeditorDiscriptionVisiblity}
          textEditorVisibility={textEditorVisibility}
          editorConfig={editorConfig}
          ckeditorDiscriptionRef={ckeditorDiscriptionRef}
          offerDetails={offerDetails}
        />
        <ChannelSection
          watch={watch}
          viewOnlyMode={viewOnlyMode}
          register={register}
          errors={errors}
          setValue={setValue}
          permissionError={permissionError}

        />
        <ShowOffersSection
          viewOnlyMode={viewOnlyMode}
          register={register}
          permissionError={permissionError}
        />
        <FinanceSection
          watch={watch}
          viewOnlyMode={viewOnlyMode}
          register={register}
          errors={errors}
          setValue={setValue}
          permissionError={permissionError}
          textEditorVisibility={textEditorVisibility}
          editorConfig={editorConfig}
          ckeditorFinanceVisiblity={ckeditorFinanceVisiblity}
          setCkeditorFinanceVisiblity={setCkeditorFinanceVisiblity}
          handleError={handleError}
          removeRulesHandler={removeRulesHandler}
          toggleError={toggleError}
          fields={fields}
          append={append}
        />
        {(selectedOfferType?.offerValues === OfferType.InstantDiscount || selectedOfferType?.offerValues === OfferType.AllProduct) &&

          <OfferValueSection
            watch={watch}
            viewOnlyMode={viewOnlyMode}
            register={register}
            errors={errors}
            setValue={setValue}
            permissionError={permissionError}
            selectedOfferType={selectedOfferType}
          />
        }
        <div className="sub-section">
          {
            selectedOfferType?.offerTypes === 'TradeIn and Exchange' &&
            <TradeInAndExchange
              viewOnlyMode={viewOnlyMode}
              watch={watch}
              control={control}
              setError={setError}
              errors={errors}
              clearErrors={clearErrors}
              register={register}
            />
          }
        </div>
        <DocumentsSection
          watch={watch}
          viewOnlyMode={viewOnlyMode}
          setValue={setValue}
          permissionError={permissionError}
          ckeditorTACVisiblity={ckeditorTACVisiblity}
          setckeditorTACVisiblity={setckeditorTACVisiblity}
          editorConfig={editorConfig}
          textEditorVisibility={textEditorVisibility}
          files={files}
          type={type}
          setType={setType}
          dynamicText={dynamicText}
          ckeditorTACRef={ckeditorTACRef}
          setFiles={setFiles}
        />
        <BannerInfoSection
          watch={watch}
          viewOnlyMode={viewOnlyMode}
          register={register}
          errors={errors}
          setValue={setValue}
          permissionError={permissionError}
          dynamicText={dynamicText}
          textEditorVisibility={textEditorVisibility}
          editorConfig={editorConfig}
        />
          {  !viewOnlyMode && (editOfferPermission || createOfferPermission) && (
          <div className="buttons-wrapper">
            <button
              className="btn secondary"
              type="button"
              onClick={() => navigateTo("/offers/list")}
            >
              Cancel
            </button>
            <button className="btn primary" type="submit">
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default OffersInfoForm;
