import React, { useContext, useEffect, useState } from "react";

import "./styles.scss";
import appContext from "context/app.context";
import { EToasterVariants } from "services/types/common.type";
import { OffersService } from "services/offers.service";
import { STATUS } from "config/config";
import { useForm } from "react-hook-form";
import { ProductCard } from "components/ProductCard";
import ProductMultiSelect from "components/ProductMultiSelect";
import DownloadTemplates from "components/DownloadeTemplates";
import { faHistory, faPlus } from "@fortawesome/free-solid-svg-icons";
import { BulkProductUpload } from "components/BulkProductUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePagination from "@mui/material/TablePagination";
import BulkUploadHistory from "components/BulkUploadHistory";
import { ProductService } from "services/product.service";
import { defaultLabelDisplayedRows } from "common/commonFunc";
import { usePermission } from "components/Permission";


type AddProductsProps = {
  viewOnlyMode?: boolean;
};

const ComboOfferPage: React.FC<AddProductsProps> = ({ viewOnlyMode }) => {
  const offersService = new OffersService();
  const {
    handleSubmit,
  } = useForm();

  const goToPreviousFormHandler = () => {
    setMenuIndex(1);
  }

  const { showToaster, setMenuIndex, setOfferData, offerData, setSpinnerVisibility } = useContext(appContext);
  const heading = ["Product Item Code", "Product Name", "Product 1", "Product 1 Selling Price", "Product 2", "Product 2 Selling Price", "Product 3", "Product 3 Selling Price", "Product 4", "Product 4 Selling Price", "Cash Discount"];
  const [bulkVisibility, setBulkVisibility] = useState<boolean>(false);
  const [historyVisibility, setHistoryVisibility] = useState<boolean>(false);

  const [addProductPermission] = usePermission(["ADD_OFFER_PRODUCTS"]);
  const [createOfferPermission] = usePermission(["CREATE_OFFERS"]);
  const [editOfferPermission] = usePermission(["EDIT_OFFERS"]);

  /* Pagination Actions */
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [filter, setfilter] = useState<any>({});
  const [productLists, setProductLists] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  const productServices = new ProductService();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log("page", page)
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async () => {
    try {
      setSpinnerVisibility(true);
      const updateOfferResponse = await offersService.updateOffer(offerData);
      if (updateOfferResponse.status === STATUS.SUCCESS) {
        showToaster(EToasterVariants.success, "Offer Updated Successfully");
        setSpinnerVisibility(false);
        const offerDataResult = { ...updateOfferResponse.data.offerData, selectedOfferType: offerData.selectedOfferType }
        setOfferData(offerDataResult);
        setMenuIndex(3);
      } else {
        setSpinnerVisibility(false);
      }

    }
    catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.error, "Unable to create new offer");
    }
  };

  const getProductLists = async (offerId: string, filter = {}) => {
    const apiResponse = await productServices.getOffersProductsList(offerId, rowsPerPage, page, filter);
    if (apiResponse.status === STATUS.SUCCESS) {
      setProductLists(apiResponse.data)
      setTotalPages(apiResponse.count)
    } else {
      showToaster(EToasterVariants.error, "Unable to get offer products");
    }
  }

  useEffect(() => {
    if (offerData && viewOnlyMode !== undefined)
      getProductLists(offerData._id, filter)
  }, [page, rowsPerPage, filter, offerData]);

  return (

    <div className="combo-page add-couponproducts">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="combo-selection">
          <div className="product-header">
            {!viewOnlyMode && addProductPermission &&

              <>
                <div className="btn-wrapper">

                  <div
                    className="btn-add-products"
                    onClick={() => setBulkVisibility(true)}
                  >
                    <FontAwesomeIcon className="" icon={faPlus} />
                    <p>Bulk Upload</p>
                  </div>
                  <DownloadTemplates heading={heading} height ReportTitle="combo" products={offerData.products} />

                  <div
                    className="btn-add-products"
                    onClick={() => setHistoryVisibility(!historyVisibility)}
                  >
                    <FontAwesomeIcon className="" icon={faHistory} />
                    <p>{!historyVisibility ? 'Bulk History' : 'See Products'}</p>
                  </div>
                </div>

              </>
            }
            {
              !historyVisibility ?
                <div className="productlist">

                  <div className="product-header-fixed">
                    <p className="product-headersp1">Products</p>  <p className="product-headersp2">Combo Products</p>
                    <div></div>
                  </div>
                  {
                    productLists?.map((product: any, index: number) => {
                      return (
                        <div className="freebie-product-card" key={index}>
                          <ProductCard product={product} />
                          <div className="freebie-products">
                            <ProductMultiSelect fieldName={"ComboOffer"} product={product} viewOnlyMode={viewOnlyMode} comboOffertype={"Product Price Discount"} />
                          </div>
                        </div>
                      )
                    })}
                  <div className="pagination-wrapper">
                    <TablePagination
                      backIconButtonProps={
                        page === 1 ? { disabled: true } : { disabled: false }
                      }

                      nextIconButtonProps={
                        page === totalPages || totalPages === 0
                          ? { disabled: true }
                          : { disabled: false }
                      }
                      component="div"
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                      page={page}
                      count={Math.ceil(totalPages / rowsPerPage)}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                  </div>
                  {  !viewOnlyMode && (editOfferPermission || createOfferPermission) && 
                    <div className="btn-group-align">
                      <button type="button" className="btn secondary" onClick={goToPreviousFormHandler}>
                        Previous
                      </button>
                      <button type="submit" className="btn primary mr-2">
                        Save
                      </button>
                      {bulkVisibility &&
                        <BulkProductUpload setBulkVisibility={setBulkVisibility} importVarient={"combo"} />
                      }
                    </div>}
                </div>
                : <BulkUploadHistory module="OfferCombo" Id={offerData?._id} />
            }
          </div>
        </div>
      </form>
    </div>

  );
};

export default ComboOfferPage;
