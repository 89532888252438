import { useEffect, useState } from "react";
import _ from 'lodash';

import OtherOffers from "./OtherOffers";
import PaymentOffers from "./PaymentOffers";
import ExchangeOffers from "./ExchangeOffers";
import { getProductPrice, toLocaleNumber } from "common/commonFunc";
import AdditionalOffers from "./AdditionalOffers";
import "./styles.scss";


interface ProductOfferProp {
    offerList: any;
    product: any;
    channel: string
}

export default function OffersView(props: ProductOfferProp) {
    const { offerList, product, channel } = props;

    const [benefitPrice, setBenefitPrice] = useState<number>(0)
    const [discountPrice, setDiscountPrice] = useState<number>(0)
    const [effectivePrice, setEffectivePrice] = useState<number>(0)
    const [paymentOffers, setPaymentOffers] = useState<any>([]);
    const [otherOffers, setOtherOffers] = useState<any>([]);
    const [exchangeOffers, setExchangeOffers] = useState<any>([]);
    const [additionalOffers, setAdditionalOffers] = useState<any>([]);
    const [actualPrice, setActualPrice] = useState<number>(0)

    useEffect(() => {
        if (offerList.length > 0) {
            const offerGroups: any = {
                exchanges: [],
                payments: [],
                additionals: [],
                others: [],
            };

            offerList?.forEach((item: any) => {
                item.checked = !(
                    item.offerSelection === "notselected" ||
                    item.offerType === "Instant Discount" ||
                    item.offerType === "Deferred Cashback"
                );
                item.showTc = false;
                item.showBan = false;
                item.showOffer = false;

                switch (item.offerType) {
                    case "Servify Exchange Bonus offer":
                    case "Samsung Upgrade Program":
                    case "TradeIn and Exchange":
                        if (item.checked && item.exchangeBonus > 0 && _.isEmpty(item.tradeValues)) {
                            item.maxBenefites = item.exchangeBonus;
                            item.exchangeVal = item.exchangeBonus;
                            item.deviceVal = 0;
                        }
                        offerGroups.exchanges.push(item);
                        break;
                    case "Instant Discount":
                    case "Deferred Cashback":
                        offerGroups.payments.push(item);
                        break;
                    case "Offer Text":
                    case "Standard EMI":
                        offerGroups.additionals.push(item);
                        break;
                    default:
                        offerGroups.others.push(item);
                }
            });

            let { exchanges, payments, additionals, others } = offerGroups;
            exchanges = _.orderBy(exchanges, "maxBenefites", "desc");
            payments = _.orderBy(payments, "maxBenefites", "desc");

            if (payments.length > 0) {
                payments[0].checked = true;
                payments.push({ offerName: "Other Payments", maxBenefites: 0, checked: false, offerId: "others" });
            }
            additionals = _.orderBy(additionals, "maxBenefites", "desc");

            // Find exclusive offers
            const exclusiveOffers = {
                'Freebie Offer': null,
                'Combo Offer': null,
                'Sellout/Activation Offers': null
            };

            others.forEach((offer: any) => {
                if (offer.exclusivity === 'Yes' && _.has(exclusiveOffers, offer.offerType)) {
                    if (!exclusiveOffers[offer.offerType] || offer.maxBenefites > exclusiveOffers[offer.offerType].maxBenefites) {
                        exclusiveOffers[offer.offerType] = offer;
                    }
                }
            });

            // Uncheck non-exclusive offers
            others.forEach((offer: any) => {
                if (offer.exclusivity === 'Yes' && _.has(exclusiveOffers, offer.offerType)) {
                    if (offer.offerId !== exclusiveOffers[offer.offerType].offerId) {
                        offer.checked = false;
                    }
                }
            });

            setOtherOffers(others);
            setExchangeOffers(exchanges);
            setPaymentOffers(payments);
            setAdditionalOffers(additionals);
        }
    }, [offerList]);

    useEffect(() => {
        let benefits = 0;
        let discount = 0;
        let comboPrice = 0;

        [...paymentOffers, ...otherOffers, ...exchangeOffers].forEach((item) => {
            if (item.checked) {
                if (item.offerType === "TradeIn and Exchange") {
                    benefits += item.exchangeVal ? item.exchangeVal : 0;
                } else {
                    benefits += item.addBenefits ? item.maxBenefites : 0;
                }

                if (item.offerType === "Combo Offer") {
                    const comboOffersForProduct = item.comboOffers?.[product?.erp_item_code] || [];

                    for (const comboProducts of comboOffersForProduct) {
                        if (comboProducts[0].type !== 'Cash') {
                            const discountValue = comboProducts.reduce((acc: any, offer: any) => acc + offer.discountValue, 0);
                            const totalComboPrice = comboProducts.reduce((acc: any, offer: any) => acc + Number(offer.offerPrice), 0);

                            if (discountValue === item.maxBenefites) {
                                comboPrice += totalComboPrice;
                                break;
                            }
                        }
                    }
                }

                if (item.cashDiscount || item.reduceEffective) {
                    if (item.offerType === "TradeIn and Exchange") {
                        discount += item.exchangeVal + item.deviceVal;
                    } else {
                        discount += item.maxBenefites;
                    }
                }
            }
        });

        setDiscountPrice(discount);
        setBenefitPrice(benefits);
        setEffectivePrice(Number(actualPrice) + comboPrice - discount);
    }, [paymentOffers, otherOffers, exchangeOffers, actualPrice]);

    useEffect(() => {
        if (product) {
            const { sp } = getProductPrice(product, channel);
            setActualPrice(sp);
        }
    }, [product]);

    return (
        <div className="offers-view-section">
            <div className="offer-list offer-header ">
                <div className="offer-header-division">
                    <span>Actual Product Price</span>
                </div> {
                    <span>{toLocaleNumber(actualPrice)}</span>
                }
            </div>

            <OtherOffers otherOffers={otherOffers} setOtherOffers={setOtherOffers} product={product} />

            {
                paymentOffers?.length > 0 &&

                <div className="payment-section">
                    <div className="payment-text">
                        <h3>Payment Offers</h3>
                        <span className="payment-details">Mutually exclusive offer only one can opted below</span>
                    </div>
                    <PaymentOffers paymentOffers={paymentOffers} setPaymentOffers={setPaymentOffers} product={product} />

                </div>
            }

            {
                exchangeOffers?.length > 0 &&

                <div className="payment-section">
                    <div className="payment-text">
                        <h3>Exchange Offers</h3>
                    </div>
                    <ExchangeOffers exchangeOffers={exchangeOffers} setExchangeOffers={setExchangeOffers} product={product} />

                </div>
            }

            <div className="discount-section">
                <div className="discount-details">
                    <div className="text">Total Benefits</div>
                    <div className="price">{toLocaleNumber(benefitPrice)}</div>
                </div>
                <div className="discount-details">
                    <div className="text">Total Discounts</div>
                    <div className="price">{toLocaleNumber(discountPrice)}</div>
                </div>
                <div className="discount-details effective">
                    <span className="text">Final Effective Price</span>
                    <span className="price">{toLocaleNumber(effectivePrice)}</span>
                </div>
            </div>

            {
                additionalOffers?.length > 0 &&

                <div className="payment-section">
                    <div className="payment-text">
                        <h3>Additional Offers</h3>
                    </div>
                    <AdditionalOffers additionalOffers={additionalOffers} setAdditionalOffers={setAdditionalOffers} product={product} />

                </div>
            }

        </div>
    );
}
