import { grid } from "@mui/system";
import React, { useRef } from "react";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss";
import Icon from "components/Icon";

type InputFieldProps = {
  textarea?: boolean;
  helpertext: string;
  error?: string;
  hint?: string;
  mandatory?: boolean;
  register?: any;
  disabled?: boolean;
  columnSpan?: number;
  focusHandleText?: string;
  hoverHandleText?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const InputField: React.FC<InputFieldProps> = ({ register, ...props }) => {
  const inputRef = useRef<any>();

  const focusHanlder = () => {
    inputRef?.current?.focus();
  };

  const manipulateStyle = (): string => {
    let classname = "input-wrapper";

    if (props.disabled) {
      classname = "input-wrapper disabled";
    } else if (props.error && props.error?.length > 0) {
      classname = "input-wrapper error";
    }
    return classname;
  };

  return (
    <div className={`span${props?.columnSpan}`} >
      <div className={manipulateStyle()} onClick={focusHanlder}>
        <div className="focusHandleHover">
          <span className="floating-label">
            {props.helpertext} {props.mandatory && <strong>*</strong>}
          </span>
          <input
            ref={inputRef}
            type="text"
            {...register}
            className={props.disabled ? "inputText disabled" : "inputText"}
            {...props}
          />
          {props?.focusHandleText && <div className="focusHandle" >
            <label className="focus">{props.focusHandleText}</label>
            <div className="focusHandleCaret">
              <Icon className="focusCaret" icon={faCaretUp} />
            </div>
          </div>}
        </div>
        {!!(props.hoverHandleText) && <p className="hoverText">{props.hoverHandleText}</p>}

      </div>


      {props.hint && <p className="hint-text">{props.hint}</p>}

      {props.error && props.error?.length > 0 && (
        <p className="hint-text error-text">* {props.error}</p>
      )}
    </div>
  );
};

export default InputField;
