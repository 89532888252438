import React, { useRef, useContext, useState } from "react";
import appContext from "context/app.context";
import { usePermission } from "components/Permission";
import {
  faCaretUp,
  faCloudArrowUp,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon";
import moment from "moment";
import * as XLSX from "xlsx";
import { FileUploadService } from "services/fileupload.service";
import UploadedFile from "components/UploadedFile";
import { EToasterVariants } from "services/types/common.type";
import { STATUS } from "config/config";
import _ from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editorToolbar } from "common/constant";

const DocumentsSection = (props: any) => {
  const {
    watch,
    viewOnlyMode,
    setValue,
    permissionError,
    ckeditorTACVisiblity,
    setckeditorTACVisiblity,
    files,
    type,
    setType,
    dynamicText,
    ckeditorTACRef,
    setFiles,
  } = props;
  const { showToaster, setSpinnerVisibility, offerData } =
    useContext(appContext);

  const [uploadedFiles, setUploadedFileURLs] = useState<string[]>([]);
  const fileUploadService = new FileUploadService();

  const [editOffersPermission] = usePermission(["EDIT_OFFERS"]);
  const [createOffersPermission] = usePermission(["CREATE_OFFERS"]);

  const documentsPermission = _.isEmpty(offerData)
    ? createOffersPermission
    : !viewOnlyMode && editOffersPermission;

  const uploadButton = useRef<any>();
  const convertCSVToArray = async (
    data: any,
    delimiter = ",",
    omitFirstRow = false
  ) => {
    try {
      const splittedItems = data
        .slice(omitFirstRow ? data?.indexOf("\n") + 1 : 0)
        .split("\n")
        .map((v: any) => v.split(delimiter));
      return splittedItems;
    } catch (error) {}
  };
  const downloadItemHandler = async (file: any): Promise<void> => {
    const ReportTitle = "download";
    const reader = new FileReader();

    reader.onload = async (evt: any) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const getHeadings = XLSX.utils.sheet_to_csv(ws);
      const xlData: any = XLSX.utils.sheet_to_json(ws);
      const [headingValues] = await convertCSVToArray(getHeadings);
      let CSV = "";
      let row = "";

      for (const index in headingValues) {
        row += headingValues[index] + ",";
      }
      row = row.slice(0, -1);
      CSV += row + "\r\n";

      for (let i = 0; i < xlData.length; i++) {
        let row = "";

        for (const index of headingValues) {
          if (xlData[i][index]) {
            row +=
              '"' + xlData[i][index].toString().replace(/["]/g, '""') + '",';
          } else {
            row += ",";
          }
        }
        row.slice(0, row.length - 1);
        CSV += row + "\r\n";
      }

      if (CSV === "") {
        alert("Invalid data");

        return;
      }

      let fileName = "Template_";
      fileName += ReportTitle.replace(/ /g, "_");
      fileName += moment();
      const uri = "data:text/csv;charset=utf-8," + escape(CSV);
      const link = document.createElement("a");
      link.href = uri;
      link.download = fileName + ".csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    reader.readAsBinaryString(file);
  };

  const modulateInputType = (): void => {
    setType("text");
    setType("file");
  };

  const fileSelectionHandler = async (event: any): Promise<void> => {
    try {
      if (event.target.files && event.target.files[0]) {
        const data = new FormData();
        data.append("file", event.target.files[0]);
        setSpinnerVisibility(true);
        const uploadResult = await fileUploadService.uploadFile(data);
        setSpinnerVisibility(false);
        if (uploadResult.status === STATUS.SUCCESS) {
          setValue("documents", [uploadResult.data.remotePath, ...files]);
          setUploadedFileURLs([uploadResult.data.remotePath, ...uploadedFiles]);
          setFiles([uploadResult.data.remotePath, ...files]);
          modulateInputType();
        } else {
          showToaster(EToasterVariants.warning, "Unable to upload the file");
        }
      }
    } catch (error) {
      setSpinnerVisibility(false);
      showToaster(EToasterVariants.warning, "Unable to upload the file");
    }
  };

  const fileRemoveHandler = async (index: number): Promise<void> => {
    const existingFiles = [...files];
    const exisitngRemotePaths = [...uploadedFiles];
    exisitngRemotePaths.splice(index, 1);
    existingFiles.splice(index, 1);
    setFiles(existingFiles);
    setUploadedFileURLs(exisitngRemotePaths);
  };

  return (
    <div className="sub-section">
      <p className="section-heading">
        7. Documents
        {documentsPermission && (
          <div className="tooltipContainer">
            <Icon icon={faCircleInfo} />
            <div>{permissionError}</div>
          </div>
        )}
      </p>

      <div className="document-uploader">
        <p>Select Document</p>
        <button
          disabled={!documentsPermission}
          className={viewOnlyMode ? "btn disabled" : "btn secondary"}
          type="button"
          onClick={() => uploadButton.current.click()}
        >
          <Icon icon={faCloudArrowUp} /> Upload
        </button>
        <input
          type={type}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          disabled={!documentsPermission}
          ref={uploadButton}
          className="hide-element"
          onChange={fileSelectionHandler}
        />
      </div>
      {files?.length !== 0 && (
        <div className="uploaded-files">
          <p>Uploaded Files: </p>
          <div className="files">
            {files?.map((file: any, index: number) => (
              <UploadedFile
                name={String(file.split("/").splice(-1))}
                index={index}
                onRemove={fileRemoveHandler}
                onDownload={downloadItemHandler}
                file={file}
              />
            ))}
          </div>
        </div>
      )}
      <div className="editor">
        <div className="editor-field-wrapper">
          <div className="headingAndInformation">
            <p className="heading">Terms & Conditions :</p>
            <div
              className="informationField"
              onClick={() => {
                setckeditorTACVisiblity(!ckeditorTACVisiblity);
              }}
            >
              ?
            </div>
            {ckeditorTACVisiblity && (
              <>
                <div className="ckeditorInformation" ref={ckeditorTACRef}>
                  {" "}
                  {
                    "(Enter Offer Terms and conditions from Scheme definition, This will be displayed to Customers and Store Agents. )"
                  }
                </div>
                <div className="ckeditorFocusHandleCaret">
                  <Icon className="ckeditorfocusCaret" icon={faCaretUp} />
                </div>
              </>
            )}
          </div>
          <p>{`Note : use constant value ${dynamicText}`}</p>
        </div>

        <ReactQuill
          readOnly={!documentsPermission}
          theme="snow"
          value={watch("termsConditions")}
          onChange={(value: any) => setValue("termsConditions", value)}
          modules={{
            toolbar: editorToolbar,
          }}
        />
      </div>
    </div>
  );
};

export default DocumentsSection;
