import React, { useEffect, useState } from "react";
import "./styles.scss";

import EmptyImage from "components/Img/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

interface ProductOfferProp {
  offers: any;
  index: number
}
interface dataProps {
  itemName: string
  data: {
    [key: string]: Array<{
      name: string,
      image?: any,
      erp_item_code: string,
      brand: {
        code: string
      },
      combooffers?: string
    }>
  }
}


const FunctData = (props: dataProps) => {
  const { data, itemName } = props;
  return (

    <div className="offerList-item">
      {data[itemName]?.map((item: any, index: number) => {
        return (
          <React.Fragment>
            {index !== 0 && <div className="container">OR</div>}

            <div className="container" key={index}>
              <div className="card">


                <div className="images">
                  {item?.image?.all?.url ? <img src={item?.image?.all?.url} alt="index" className="image" /> : <EmptyImage />}

                </div>
                <div className="details">
                  <div className="cardItemName">
                    <div className="ellipsis-text"> {item.name}</div>
                  </div>
                  <div className="itemNumber">
                    Item Code: {item.erp_item_code}
                  </div>
                  <div className="cardPrice">
                    <p className="overline">₹ 20000</p>
                    {item.combooffers ? <p>₹ {item.combooffers}</p> : <p>Free</p>}
                  </div>
                </div>

              </div>
            </div>
          </React.Fragment>

        )
      })}


    </div>)


}

export default function GroupOffer(props: ProductOfferProp) {
  const { offers, index } = props;
  const [offer, setOffer] = useState<any>({});
  const [showTc, setShowTc] = useState<boolean>(false)
  const [showBan, setShowBan] = useState<boolean>(false)
  const [showOffer, setShowOffer] = useState<boolean>(false)
  useEffect(() => {
    setOffer(offers);
  }, [offers]);

  return (
    <div className="offers-group-section">
      <div className="offer-group-list offergroup-header">
        <div className="offer-header-division">
          <span>{index === 0 ? "Group Best Offer" : "Group Max Offer " + (index + 1)}</span>
          <p className="dateText">{`(Ends on ${moment(offer.endDate).format("DD-MMM-YYYY hh:mm A")})`}</p>
        </div>
        <span>Max Benefit Rs. {offers?.offerMaxBenefits || 0}</span>
      </div>
      <div className="offergroup-body">
        {offers.offers.map((offer: any, index: number) => {
          return (
            <>

              <div className={offers.offers.length - 1 === index ? "offergroup-content1" : "offergroup-content"}>
                <div className="offer-group-list">
                  <div className="offer-name">
                    <span>{offer?.offerName}</span>
                    <div className="show-section">
                      <span className="offer-tc" onClick={() => { setShowTc(!showTc); setShowBan(false); }}>{`Terms & Conditions`}</span>
                      <span className="offer-tc" onClick={() => { setShowBan(!showBan); setShowTc(false) }}>{`Show Details`}</span>
                    </div>
                  </div>
                  <div className="offer-amt">
                    ₹ {offer?.maxBenefites || 0}
                  </div>

                </div>
                {showTc &&
                  <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }}>
                  </div>
                }
                {showBan &&
                  <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }}>
                  </div>
                }



                {(offer.offerType === "Combo Offer" || offer.offerType === "freebies") && <div className="newdiv">
                  <div className="offer-group-list">


                    <div className="offer-expansion">
                      <span className="offer-type">{offer.offerType}</span >
                      <span onClick={() => setShowOffer(!showOffer)}>  <FontAwesomeIcon className="show-icon" icon={showOffer ? faMinus : faPlus} /></span>
                    </div>

                    {/* {showOffer &&
                    <div className="offer-expanded">
                      {!_.isEmpty(offer.comboOffers) && <FunctData data={offer.comboOffers} itemName={itemName} />}

                      {!_.isEmpty(offer.freebies) && <FunctData data={offer.freebies} itemName={itemName} />}
                    </div>
                  } */}

                  </div>
                </div>}
              </div>
            </>
          )

        })}
      </div >
    </div>
  );
}
