import React from 'react'
import OffersTable from './OffersTable'
import "./style.scss";

const OffersListPage: React.FC = () => {
  document.title = "Poorvika - Offers";
  
  return (
      <OffersTable/>
  )
}

export default OffersListPage