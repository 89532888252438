import { STATUS } from "config/config";
import appContext from "context/app.context";
import React, { useContext, useEffect, useState } from "react";
import { EToasterVariants } from "services/types/common.type";
import moment from "moment";
import { TablePagination } from "@mui/material";
import { removeFalsy } from "common/constant";
import { IUploadHistory } from "services/interfaces/coupon.interface";

import "./styles.scss";
import { HistoryService } from "services/history.service";

type TProps = {
    Id: string,
    module: string,
}

const BulkUploadHistory: React.FC<TProps> = ({ Id, module }) => {

    const [couponsAssigned, setCouponsAssigned] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);


    /* Context  */
    const { showToaster } = useContext(appContext);

    /* Services */
    const historyService = new HistoryService();


    const getUserByCouponId = async (search?: string): Promise<void> => {
        try {
            const payload: IUploadHistory = {
                Id: Id,
                page: page,
                limit: rowsPerPage,
                module: module
            }
            if (search) payload.search = search?.trim()
            const couponListResponse = await historyService.getUploadHistory(removeFalsy(payload));
            if (couponListResponse.status === STATUS.SUCCESS) {
                setCouponsAssigned(couponListResponse?.data)
                setTotalPages(couponListResponse?.data?.totalCount)
            } else {
                showToaster(EToasterVariants.error, "Unable to get user List");
            }
        } catch (error: any) {
            showToaster(EToasterVariants.error, error?.message);
        }
    };

    const defaultLabelDisplayedRows = (props: any) => {

        return `${props.page + 1} of ${props.count !== -1 ? props.count : `more than ${props.to}`}`;
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getFileName = (file: string) => {
        const fileName = file.split('/').at(-1) || 'Click here';
        return fileName;
    }

    useEffect(() => {
        getUserByCouponId()
    }, [page, rowsPerPage])

    return (
        <div className="bulk-uploader">
            <div className="header-body">
                <div className="apply-coupon">
                    <h3>Bulk Uploaded History</h3>

                    <table className="shadow couponinner-table" style={{overflowWrap: "anywhere"}}>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Uploaded File</th>
                                <th>Status</th>
                                <th>UploadedAt</th>
                                <th>CompletedAt</th>
                                <th>Result File</th>
                            </tr>
                        </thead>
                        <tbody>
                            {couponsAssigned?.historyList?.length > 0 ? couponsAssigned?.historyList?.map((coupons: any, index: number) => {
                                return (
                                    <tr key={index} className="cursor-pointer">
                                        <td>{index + 1}</td>
                                        <td><a href={coupons?.originalFile}> {getFileName(coupons?.originalFile)}</a ></td>
                                        <td>{coupons?.status ? <span className="Active">{`${coupons?.successItem} Success ${coupons?.failedItem} Failed `}</span> : <span className="Expired">In Progress</span>}</td>
                                        <td>{moment(coupons?.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                                        <td>{coupons?.updatedAt ? moment(coupons?.updatedAt).format('DD-MM-YYYY hh:mm A') : '-'}</td>
                                        <td><a href={coupons?.resultFile}>{coupons?.resultFile && getFileName(coupons?.resultFile)}</a></td>
                                    </tr>
                                );
                            }) : <tr> <td colSpan={3}>No data found !</td></tr>}
                        </tbody>
                    </table>
                    <TablePagination
                        backIconButtonProps={page === 0 ? { disabled: true } : { disabled: false }}
                        nextIconButtonProps={(page + 1 === totalPages || totalPages === 0) ? { disabled: true } : { disabled: false }}
                        component="div"
                        count={Math.ceil(totalPages / rowsPerPage)}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50]}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default BulkUploadHistory;