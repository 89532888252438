import React from 'react';

import './styles.scss';

type DeleteConfirmationProps = {
    show: boolean, 
    onConfirmation?: (visibility: boolean) => void 
    heading?:string
    description?:string
    secondaryBtnName?:string
    primaryBtnName?:string
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationProps> = ({show, onConfirmation,heading,description,primaryBtnName,secondaryBtnName}) => {

    const dialogVisibilityHandler = (deleteConfirmation: boolean) => {
      if (onConfirmation) {
        onConfirmation(deleteConfirmation);
      }
    }

  return (
    <div className={show ? "delete-confirmation-dialog show": "delete-confirmation-dialog hide"}>
      <div className="dialog-container shadow">
        <p>{heading ?? `Do you really want to delete this record ?`}</p>
        <div className="warning-text">
          <p>
            {description ?? `Once the record is deleted, you can't recover it at any cost because it is permanently removed from our database`}
          </p>
        </div>
        <div className="buttons-wrapper">
          <button className="btn secondary" onClick={() => dialogVisibilityHandler(false)}>{secondaryBtnName ?? `Cancel`}</button>
          <button className="btn primary" onClick={() => dialogVisibilityHandler(true)}>{primaryBtnName ?? `Delete`}</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationDialog