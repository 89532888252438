import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faBars, faUser } from '@fortawesome/free-solid-svg-icons'

import "./styles.scss"
import { useNavigate } from 'react-router-dom'
import appContext from 'context/app.context';
import { useAuth } from 'common/keycloak/UseKeyCloak'

const AppHeader: React.FC = () => {

  const navigatorTo = useNavigate();
  const { sideMenuVisibility, setSideMenuVisibility } = useContext(appContext);
  const keycloak = useAuth();
  const userName = localStorage.getItem('userName') || ''

  const logoutHandler = async (): Promise<void> => {
    localStorage.clear();
    if (keycloak?.isAuthenticated) {
      const isLogout = await keycloak.logout();

      if (isLogout)
        navigatorTo("/");
    }
    
    navigatorTo("/");
  }

  return (
    <div className="app-header" id="top-header">
      <div className='brand-wrapper'>
        <FontAwesomeIcon className='action-icon' icon={faBars} onClick={() => setSideMenuVisibility(!sideMenuVisibility)} />
        Poorvika Offers Management Platform
      </div>
      <div className="icons-wrapper">
        <div className='user-name'><FontAwesomeIcon className='action-icon' icon={faUser} /><span style={{ marginLeft: '0.4em' }}>{userName}</span></div>
        <div className="logout-button">
          <FontAwesomeIcon className='action-icon cursor-pointer ' icon={faArrowRightFromBracket} onClick={() => logoutHandler()} />
          <p>Logout</p>
        </div>


      </div>

    </div>
  )
}

export default AppHeader
