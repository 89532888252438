import React from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { AdapterMoment  } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { DateTimeField } from "@mui/x-date-pickers";

import "./styles.scss";

type DatePickerProps = {
  label: string;
  onDateSelection?: (value: any) => void;
  value?: any
  disabled?: boolean
  minDateTime?: any,
  maxDateTime?: any,
  handleBlur?: (value: any) => void;
} & TextFieldProps;

const DateAndTimePicker: React.FC<DatePickerProps> = ({
  label,
  onDateSelection,
  minDateTime,
  maxDateTime,
  value,
  disabled,
  handleBlur
}) => {

  const dateAndTimeSelectionHandler = async (date:any) => {      
    // setError('')
    if (onDateSelection) {
      onDateSelection(date.startOf('minutes'));
    }
  };


  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
    >
      <DateTimeField
        disabled={disabled}
        label={label}
        format="DD-MM-YYYY hh:mm A"
        minDateTime={minDateTime ? moment(minDateTime) : ''}
        maxDateTime={maxDateTime ? moment(maxDateTime) : ''}
        size="small"
        value={value ? moment(value) : null}
        onChange={dateAndTimeSelectionHandler}
        className="date-and-time-picker"
        onBlur={handleBlur}
      />
    </LocalizationProvider>
  );
};

export default DateAndTimePicker;
